import React from "react";

export default function Loading(props) {
    return <div className="row">
        <div className="col">
            <h3 className="text-center">
                <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                </div>&nbsp;&nbsp;Loading...
    </h3>
        </div>
    </div>
}
