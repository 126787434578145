import axios from 'axios';
import { toastr } from 'react-redux-toastr'
import routeConfig from '../routeConfig';

function filterByTeam(state) {
    if (!state.teamFilter) {
        return state;
    }

    const searchResults = Object.values(state.filteredApplications).filter(a => {
        if (state.subTeamFilter) {
            return a.subTeam === state.subTeamFilter;
        }

        return a.team === state.teamFilter;
    });

    state.filteredApplications = createAppsObject(searchResults);
    state.pageNumber = 0;

    return state;
}

function filterByType(state) {
    if (!state.typeFilter) {
        return state;
    }

    const searchResults = Object.values(state.filteredApplications).filter(a => {
        if (state.subtypeFilter) {
            return a.subtype === state.subtypeFilter;
        }

        return a.type === state.typeFilter;
    });

    state.filteredApplications = createAppsObject(searchResults);
    state.pageNumber = 0;

    return state;
}

function createAppsObject(apps) {
    let appsObj = {};
    for (let i = 0; i < apps.length; i++) {
        appsObj[apps[i]._id] = apps[i];
    }

    return appsObj;
}

function filterByWpiiImpactStatus(state) {
    if (!state.wpiiImpactStatusFilter || state.wpiiImpactStatusFilter === '' ||
        (Array.isArray(state.wpiiImpactStatusFilter) && state.wpiiImpactStatusFilter[0] === '')) {
        return state;
    }

    const searchResults = Object.values(state.filteredApplications).filter(a => {
        if (Array.isArray(state.wpiiImpactStatusFilter)) {
            return state.wpiiImpactStatusFilter.findIndex((s) => {
                return a.wpiiImpactStatus === s;
            }) > -1;
        }
        return a.wpiiImpactStatus === state.wpiiImpactStatusFilter;
    });

    state.filteredApplications = createAppsObject(searchResults);
    state.pageNumber = 0;

    return state;
}

function filterByCloudReporting(state) {
    if (!state.cloudReportingFilter || state.cloudReportingFilter === '')
        return state;

    var apps = Object.values(state.filteredApplications).filter(a => {
        return a.cloudReporting === state.cloudReportingFilter;
    });

    state.filteredApplications = createAppsObject(apps);
    return state;
}

function filterByHostingType(state) {
    if (!state.hostingTypeFilter || state.hostingTypeFilter === '')
        return state;

    var apps = Object.values(state.filteredApplications).filter(a => {
        return a.hostingType === state.hostingTypeFilter;
    });

    state.filteredApplications = createAppsObject(apps);
    return state;
}

function filterByPalmImpact(state) {
    if (!state.palmImpactFilter || state.palmImpactFilter === '')
        return state;

    var apps = Object.values(state.filteredApplications).filter(a => {
        return a.palmIsImpacted === state.palmImpactFilter;
    });

    state.filteredApplications = createAppsObject(apps);
    return state;
}

function filterByInventoryStatus(state) {
    let searchResults;

    if (state.inventoryStatusFilter && state.inventoryStatusFilter !== '') {
        searchResults = Object.values(state.filteredApplications).filter(a => {
            return a.wpiiInventoryStatus === state.inventoryStatusFilter;
        });
    } else {
        searchResults = Object.values(state.filteredApplications);
    }

    state.filteredApplications = createAppsObject(searchResults);
    return state;
}

function filterByComponents(state) {
    if (state.filterWithComponents) {
        const results = Object.values(state.applications).filter(a => {
            return a.components && a.components.length > 0;
        });

        state.filteredApplications = createAppsObject(results);
    } else {
        state.filteredApplications = state.applications;
    }

    return state;
}

function filterByCorrectionsOnly(state) {
    if (state.onlyCorrectionsFilter) {
        const results = Object.values(state.filteredApplications).filter(a => {
            return state.appsWithCorrections[a._id] === true;
        });

        state.filteredApplications = createAppsObject(results);
    }

    return state;
}

function filterBySearch(state) {
    if (state.searchTerm) {
        const results = Object.values(state.applications).filter(a => {
            return (a.name && a.name.toUpperCase().indexOf(state.searchTerm.toUpperCase()) > -1) ||
                (a.description && a.description.toUpperCase().indexOf(state.searchTerm.toUpperCase()) > -1) ||
                // eslint-disable-next-line
                (a.oitId && a.oitId == state.searchTerm) ||
                (a.acronym && a.acronym.toUpperCase().indexOf(state.searchTerm.toUpperCase()) > -1);
        });

        state.filteredApplications = createAppsObject(results);
    }

    return state;
}

function filterAll(state) {
    const filteredByComponents = filterByComponents(state);
    const filteredByCorrections = filterByCorrectionsOnly(filteredByComponents);
    const filteredByTeam = filterByTeam(filteredByCorrections);
    const filteredByType = filterByType(filteredByTeam);
    const filteredByWpiiImpactStatus = filterByWpiiImpactStatus(filteredByType);
    const filteredByHostingType = filterByHostingType(filteredByWpiiImpactStatus);
    const filteredByCloudReporting = filterByCloudReporting(filteredByHostingType);
    const filteredByPalmImpact = filterByPalmImpact(filteredByCloudReporting);
    const searchResults = filterBySearch(filteredByPalmImpact);
    return filterByInventoryStatus(searchResults);
}

export const Applications = {
    state: {
        currentApplicationId: null,
        applications: {},
        applicationsCompletions: {},
        filteredApplications: {},
        inventoryStatusFilter: 'Active',
        teamFilter: 'Select',
        staffFilter: null,
        searchTerm: '',
        typeFilter: '',
        palmImpactFilter: '',
        subtypeFilter: '',
        filterByCloudReporting: '',
        hostingTypeFilter: '',
        appsWithCorrections: {},
        subTeamFilter: '',
        onlyCorrectionsFilter: false,
        wpiiImpactStatusFilter: null,
        numberPerPage: 20,
        pageNumber: 0,
        showOnlyPending: false,
        supportInfoOpen: false,
        functionalInfoOpen: false,
        showPalmAssessmentModal: false,
        technicalInfoOpen: false,
        dependencyInfoOpen: false,
        interfaceDependencyInfoOpen: false,
        supplementalQuestionsOpen: false,
        documentsOpen: false,
        interfacesToComponentsOpen: false,
        canEdit: true,
        azureDevOpsProjects: [],
        azureDevOpsRepos: [],
        currentHistory: 'Current',
        showApplicationModal: false,
        applicationModalIsEditing: false,
        assetInfoOpen: true,
        assessmentInfoOpen: false,
        impactedDataOpen: false,
        interfaceOpen: false,
        componentsOpen: false,
        correctionsOpen: false,
        historyOpen: false,
        relatedApplicationsOpen: false,
        filterWithComponents: false,
        certify: false
    },
    reducers: {
        setApplicationHistory(state, history) {
            const sortedHistory = history.sort(function (a, b) {
                return new Date(b.updatedAt) - new Date(a.updatedAt);
            });

            return {
                ...state,
                applicationHistory: sortedHistory
            }
        },
        loadCorrections(state, corrections) {
            const correctionObj = corrections.reduce((a, b) => {
                a[b.application] = true;
                return a;
            }, {})
            return {
                ...state,
                appsWithCorrections: correctionObj
            }
        },
        removeCorrection(state, applicationId) {
            let correctionObj = {
                ...state.appsWithCorrections
            };

            delete correctionObj[applicationId];

            return filterAll({
                ...state,
                appsWithCorrections: correctionObj
            });
        },
        addCorrection(state, applicationId) {
            return filterAll({
                ...state,
                appsWithCorrections: {
                    ...state.appsWithCorrections,
                    [applicationId]: true
                }
            });
        },
        loadApplications(state, applications) {
            let loadedApps = {};
            for (let i = 0; i < applications.length; i++) {
                loadedApps[applications[i]._id] = applications[i];
                if (loadedApps[applications[i]._id].wpiiImpactResponsibleStaff && loadedApps[applications[i]._id].wpiiImpactResponsibleStaff.length > 0) {
                    loadedApps[applications[i]._id].wpiiImpactResponsiblePerson = loadedApps[applications[i]._id].wpiiImpactResponsibleStaff[0].firstName
                        + ' ' + loadedApps[applications[i]._id].wpiiImpactResponsibleStaff.lastName;
                }
                loadedApps[applications[i]._id].wpiiHasProjectImpactYesNo = loadedApps[applications[i]._id].wpiiHasProjectImpact ? 'Yes' : 'No';
                loadedApps[applications[i]._id].wpiiHasContractImpactYesNo = loadedApps[applications[i]._id].wpiiHasContractImpact ? 'Yes' : 'No';
                loadedApps[applications[i]._id].wpiiHasBudgetImpactYesNo = loadedApps[applications[i]._id].wpiiHasBudgetImpact ? 'Yes' : 'No';
            }
            return {
                ...state,
                applications: loadedApps,
                filteredApplications: loadedApps
            };
        },
        addApplication(state, app) {
            return {
                ...state,
                applications: {
                    ...state.applications,
                    [app._id]: app
                },
                //currentApplicationId: app._id
            }
        },
        addApplicationCompletion(state, applicationsCompletion) {
            return {
                ...state,
                applicationsCompletions: {
                    ...state.applicationsCompletions,
                    [applicationsCompletion.application]: applicationsCompletion
                },
                //currentApplicationId: app._id
            }
        },
        setTabVisible(state, prop) {
            let newState = {
                ...state,
                assetInfoOpen: false,
                interfaceOpen: false,
                componentsOpen: false,
                correctionsOpen: false,
                historyOpen: false,
                relatedAssetsOpen: false,
                supportInfoOpen: false,
                functionalInfoOpen: false,
                technicalInfoOpen: false,
                assessmentInfoOpen: false,
                impactedDataOpen: false,
                dependencyInfoOpen: false,
                cmdbOpen: false,
                dimInfoOpen: false,
                interfaceDependencyInfoOpen: false,
                supplementalQuestionsOpen: false,
                documentsOpen: false,
                dataInfoOpen: false,
                businessImpactOpen: false,
                rolesOpen: false,
                interfacesToComponentsOpen: false,
                cloudFirstReportingOpen: false
            };

            newState[prop] = true;

            return newState;
        },
        setCertify(state, certify) {
            return {
                ...state,
                certify: certify
            }
        },
        saveApplication(state, app) {
            var newState = {
                ...state,
                isCreatingNewApplication: false,
                applications: {
                    ...state.applications,
                    [app._id]: {
                        ...app
                    }
                }
            }
            return filterAll(newState);
        },
        updateSuggestionRejections(state, rejectionObj) {
            var newState = {
                ...state,
                applications: {
                    ...state.applications,
                    [rejectionObj.applicationId]: {
                        ...state.applications[rejectionObj.applicationId],
                        rejectedSuggestions: {
                            ...state.applications[rejectionObj.applicationId].rejectedSuggestions,
                            [rejectionObj.impactSuggestion]: rejectionObj.rejections
                        }
                    }
                }
            }
            return filterAll(newState);
        },
        updateAssetSuggestionRejections(state, rejectionObj) {
            var newState = {
                ...state,
                applications: {
                    ...state.applications,
                    [rejectionObj.applicationId]: {
                        ...state.applications[rejectionObj.applicationId],
                        rejectedAssets: rejectionObj.rejections
                    }
                }
            }
            return filterAll(newState);
        },
        updateTableSuggestionRejections(state, rejectionObj) {
            var newState = {
                ...state,
                applications: {
                    ...state.applications,
                    [rejectionObj.applicationId]: {
                        ...state.applications[rejectionObj.applicationId],
                        rejectedTables: rejectionObj.rejections
                    }
                }
            }
            return filterAll(newState);
        },
        updateColumnSuggestionRejections(state, rejectionObj) {
            var newState = {
                ...state,
                applications: {
                    ...state.applications,
                    [rejectionObj.applicationId]: {
                        ...state.applications[rejectionObj.applicationId],
                        rejectedColumns: rejectionObj.rejections
                    }
                }
            }
            return filterAll(newState);
        },
        changeHistory(state, history) {
            return {
                ...state,
                currentHistory: history
            };
        },
        filterWithComponents(state, filterWithComponents) {
            var newState = {
                ...state,
                filterWithComponents: filterWithComponents
            };
            return filterAll(newState);
        },
        filterByTeam(state, teamId) {
            const newState = {
                ...state,
                teamFilter: teamId,
                subTeamFilter: ''
            }
            return filterAll(newState);
        },
        filterByCorrectionsNeeded(state, onlyCorrections) {
            const newState = {
                ...state,
                onlyCorrectionsFilter: onlyCorrections
            }

            return filterAll(newState);
        },
        filterBySubTeam(state, subTeamId) {
            const newState = {
                ...state,
                subTeamFilter: subTeamId
            }
            return filterAll(newState);
        },
        filterByPalmImpact(state, palmImpact) {
            const newState = {
                ...state,
                palmImpactFilter: palmImpact
            }
            return filterAll(newState);
        },
        filterByType(state, type) {
            const newState = {
                ...state,
                typeFilter: type
            };
            return filterAll(newState);
        },
        filterByWpiiImpactStatus(state, wpiiImpactStatus) {
            const newState = {
                ...state,
                wpiiImpactStatusFilter: wpiiImpactStatus
            };
            return filterAll(newState);
        },
        filterByCloudReporting(state, filterByCloudReporting) {
            const newState = {
                ...state,
                cloudReportingFilter: filterByCloudReporting
            };
            return filterAll(newState);
        },
        filterByHostingType(state, hostingType) {
            const newState = {
                ...state,
                hostingTypeFilter: hostingType
            };
            return filterAll(newState);
        },
        filterOnlyPending(state, filterPending) {
            const impactStatus = filterPending ? null : state.wpiiImpactStatusFilter;
            const newState = {
                ...state,
                showOnlyPending: impactStatus
            }
            return filterAll(newState);
        },
        filterByInventoryStatus(state, inventoryStatusFilter) {
            const newState = {
                ...state,
                inventoryStatusFilter: inventoryStatusFilter
            }
            return filterAll(newState);
        },
        search(state, searchTerm) {
            const newState = {
                ...state,
                searchTerm: searchTerm
            };
            return filterAll(newState);
        },
        filterAll(state) {
            return filterAll(state);
        },
        changeNumberPerPage(state, number) {
            return {
                ...state,
                numberPerPage: number,
                pageNumber: 0
            };
        },
        changePage(state, pageNumber) {
            return {
                ...state,
                pageNumber: pageNumber
            };
        },
        resetFilters(state) {
            return {
                ...state,
                teamFilter: '',
                staffFilter: null,
                searchTerm: '',
                typeFilter: '',
                subtypeFilter: '',
                wpiiImpactStatusFilter: '',
                filteredApplications: state.applications
            }
        },
        setCurrentApplicationId(state, id) {
            return {
                ...state,
                currentApplicationId: id,
                isCreatingNewApplication: false
            };
        },
        createNewApplication(state, user) {
            return {
                ...state,
                isCreatingNewApplication: true,
                applicationModalIsEditing: true,
                showApplicationModal: true,
                currentApplicationId: 'NewApplication',
                applications: {
                    ...state.applications,
                    'NewApplication': {
                        name: '',
                        acronym: '',
                        type: '',
                        roadsId: '',
                        description: '',
                        technicalNotes: '',
                        wpiiHasProjectImpact: false,
                        wpiiHasContractImpact: false,
                        wpiiHasBudgetImpact: false,
                        wpiiImpactStatus: '8. Unknown',
                        wpiiImpactAssessment: '',
                        wpiiNotes: '',
                        wpiiInventoryStatus: 'Pending',
                        wpiiImpactResponsibleStaff: [],
                        wpiiLiasons: [],
                        updatePersons: [user],
                        relatedAssets: []
                    },
                }
            };
        },
        loadAzureDevOpsProjects(state, projects) {
            return {
                ...state,
                azureDevOpsProjects: projects
            };
        },
        loadAzureDevOpsRepos(state, repos) {
            return {
                ...state,
                azureDevOpsRepos: repos
            };
        },
        setGridRows(state, gridRows) {
            return {
                ...state,
                gridRows: gridRows
            };
        },
        changeApplicationModalVisibility(state, show) {
            return {
                ...state,
                showApplicationModal: show,
                showWpiiAssessmentModal: false,
                showCloudAssessmentModal: false,
                showDimAssessmentModal: false,
                showQuickAssessmentModal: false,
                showPalmAssessmentModal: false,
            }
        },
        changeQuickAssessmentModalVisibility(state, show) {
            return {
                ...state,
                showApplicationModal: false,
                showWpiiAssessmentModal: false,
                showCloudAssessmentModal: false,
                showDimAssessmentModal: false,
                showPalmAssessmentModal: false,
                showQuickAssessmentModal: show
            }
        },
        changeWpiiAssessmentModalVisibility(state, show) {
            return {
                ...state,
                showWpiiAssessmentModal: show,
                showApplicationModal: false,
                showCloudAssessmentModal: false,
                showDimAssessmentModal: false,
                showPalmAssessmentModal: false,
                showQuickAssessmentModal: false
            }
        },
        changeCloudAssessmentModalVisibility(state, show) {
            return {
                ...state,
                showCloudAssessmentModal: show,
                showApplicationModal: false,
                showWpiiAssessmentModal: false,
                showDimAssessmentModal: false,
                showPalmAssessmentModal: false,
                showQuickAssessmentModal: false
            }
        },
        changeDimAssessmentModalVisibility(state, show) {
            return {
                ...state,
                showDimAssessmentModal: show,
                showApplicationModal: false,
                showCloudAssessmentModal: false,
                showWpiiAssessmentModal: false,
                showPalmAssessmentModal: false,
                showQuickAssessmentModal: false
            }
        },
        changePalmAssessmentModalVisibility(state, show) {
            return {
                ...state,
                showDimAssessmentModal: false,
                showApplicationModal: false,
                showCloudAssessmentModal: false,
                showWpiiAssessmentModal: false,
                showPalmAssessmentModal: show,
                showQuickAssessmentModal: false
            }
        },
        changeApplicationModalIsEditing(state, isEditing) {
            return {
                ...state,
                applicationModalIsEditing: isEditing
            }
        },
        setCurrentHistoryItem(state, index) {

        },
    },
    selectors: ((slice) => ({
        currentApplication() {
            return slice(Applications => {
                return Applications.applications[Applications.currentApplicationId];
            });
        },
        currentApplicationCompletion() {
            return slice(Applications => {
                return Applications.applicationsCompletions[Applications.currentApplicationId];
            });
        }
    })),
    effects: {
        async getApplications(payload, rootState) {
            const resp = await axios.get(`${routeConfig.baseUrl}/Applications`);
            const corrections = await axios.get(`${routeConfig.baseUrl}/Correction?query={"status":{"$ne":"Closed"}}`);
            this.loadCorrections(corrections.data);
            this.loadApplications(resp.data);
            this.filterAll();
        },
        async linkInterface(linkInterface) {
            const assetResponse = await axios.post(routeConfig.baseUrl + '/LinkAsset/' + linkInterface._id);
            this.saveApplication(assetResponse.data);
            toastr.success('Asset Created', '');
        },
        async persistNewApplication(app, rootState) {
            let config = {
                headers: {
                    socketId: rootState.ApplicationLoadState.socketId,
                }
            };
            const response = await axios.post(`${routeConfig.baseUrl}/Application`, app, config);
            this.saveApplication(response.data);
            this.changeApplicationModalIsEditing(false);
            this.changeApplicationModalVisibility(false);
            toastr.success('New Application Saved', '');
        },
        async persistApplication(app, rootState) {
            let config = {
                headers: {
                    socketId: rootState.ApplicationLoadState.socketId,
                }
            };
            try {
                const response = await axios.put(`${routeConfig.baseUrl}/Application/${rootState.Applications.currentApplicationId}`, app, config);
                this.saveApplication(response.data);
                toastr.success('Application Saved', '');
            }
            catch (error) {
                const errorResponse = error.response;
                let message = '';
                console.log(errorResponse.data);
                for (let reference of errorResponse.data) {
                    let tables = '';
                    for (let table of reference.tablesUsed) {
                        if (tables !== '') {
                            tables += ',';
                        }
                        tables += table;
                    }
                    message += `${reference.name} uses tables (${tables})`;
                }
                toastr.error("Tables are used by other assets", message)

            }
        },
        async rejectSuggestion({ suggestion, impactSuggestion }, rootState) {
            const response = await axios.patch(`${routeConfig.baseUrl}/RejectSuggestion/${rootState.Applications.currentApplicationId}`, { suggestion, impactSuggestion });
            this.updateSuggestionRejections({
                applicationId: rootState.Applications.currentApplicationId,
                impactSuggestion: impactSuggestion,
                rejections: response.data.sugg
            });
            toastr.success('Suggestion Rejected', '');
        },
        async rejectAssetSuggestion({ suggestion, impactSuggestion }, rootState) {
            const response = await axios.patch(`${routeConfig.baseUrl}/RejectAssetSuggestion/${rootState.Applications.currentApplicationId}`, { suggestion, impactSuggestion });
            this.updateAssetSuggestionRejections({
                applicationId: rootState.Applications.currentApplicationId,
                rejections: response.data
            });
            toastr.success('Suggestion Rejected', '');
        },
        async rejectTableSuggestion({ suggestion, impactSuggestion }, rootState) {
            const response = await axios.patch(`${routeConfig.baseUrl}/RejectTableSuggestion/${rootState.Applications.currentApplicationId}`, { suggestion, impactSuggestion });
            this.updateTableSuggestionRejections({
                applicationId: rootState.Applications.currentApplicationId,
                rejections: response.data
            });
            toastr.success('Suggestion Rejected', '');
        },
        async rejectColumnSuggestion({ suggestion, impactSuggestion }, rootState) {
            console.log(suggestion);
            const response = await axios.patch(`${routeConfig.baseUrl}/RejectColumnSuggestion/${rootState.Applications.currentApplicationId}`, { suggestion, impactSuggestion });
            this.updateColumnSuggestionRejections({
                applicationId: rootState.Applications.currentApplicationId,
                rejections: response.data
            });
            toastr.success('Suggestion Rejected', '');
        },
        async persistApplicationCompletion(appCompletion, rootState) {
            let response;

            if (appCompletion._id) {
                response = await axios.put(`${routeConfig.baseUrl}/ApplicationCompletion/${appCompletion._id}`, appCompletion);
            } else {
                response = await axios.post(`${routeConfig.baseUrl}/ApplicationCompletion`, appCompletion);
            }

            this.addApplicationCompletion(response.data);
            toastr.success('Tab Certification Saved', '');
        },
        async loadApplication(id, rootState) {
            const response = await axios.get(`${routeConfig.baseUrl}/Application/${id}`);
            this.addApplication(response.data);
            const completionResponse = await axios.get(`${routeConfig.baseUrl}/ApplicationCompletion?query={"application":"${id}"}`);

            const completion = completionResponse.data && completionResponse.data.length === 1 ? completionResponse.data[0] : {};
            this.addApplicationCompletion(completion);
            //Renable once DIM is added back
            /*this.addApplication(calculateDimScores(rootState.DimScoring.dimScoring, response.data,
                Object.values(rootState.Database.databases), Object.values(rootState.ApplicationPlatform.applicationPlatforms)));
                */
        },
        async loadApplicationWithoutCompletions(id, rootState) {
            const response = await axios.get(`${routeConfig.baseUrl}/Application/${id}`);
            this.addApplication(response.data);
        },
        async getProjects() {
            const resp = await axios.get(`${routeConfig.baseUrl}/AzureDevOps/Projects`);
            var projects = resp.data;
            projects.sort();
            this.loadAzureDevOpsProjects(projects);
        },
        async getRepos(project, rootState) {
            const resp = await axios.get(`${routeConfig.baseUrl}/AzureDevOps/Projects/${project}/Repos`);
            var repos = resp.data;
            repos.sort();
            this.loadAzureDevOpsRepos(repos);
        },
        async updateStatus(status, rootState) {
            const resp = await axios.post(`${routeConfig.baseUrl}/Application/${rootState.Applications.currentApplicationId}/Status/${status}`);
            this.addApplication(resp.data);
            toastr.success(`Application Status changed to ${status}`, '');
        },
        async getApplicationHistory(appId, rootState) {
            const resp = await axios.get(`${routeConfig.baseUrl}/Application/${appId}/History`);
            this.setApplicationHistory(resp.data);
        }
    }
}
