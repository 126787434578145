import React from 'react';

export default function ErrorView(props) {
    return <div className="row">
        <div className="">
            An error has occured. Please refresh your browser and try again.
        </div>

    </div>
}

