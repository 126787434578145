import React, { Suspense, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Route, Switch, useHistory, useLocation } from 'react-router';
import Home from './Home';
import NoMatch from './NoMatch';
import Loading from './Loading';
import Header from './Header';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import ReduxToastr from 'react-redux-toastr';
import NotAuthorized from './NotAuthorized';
import ErrorView from './ErrorView';
import { appInsights } from '../appInsights';
import ReadOnlyList from './Application/List/ReadOnlyList';
import ReportsComingSoon from './Reports/ReportsComingSoon';
import Help from './Help';
import SocketHandler from './SocketHandler';
import InterfaceList from './Interface/InterfacesList';
import EditInterface from './Interface/EditInterface';
import PalmDashboard from './Dashboard/PalmDashboard';
import DimComingSoon from './Assessment/Dim/DimComingSoon';
import IncompleteCloudFirstReport from './Reports/IncompleteCloudFirstReport';
import QuickAssessmentReport from './Reports/QuickAssessmentReport';
import Dashboard from './Dashboard/Dashboard';
import localforage from 'localforage';
import ErrorBoundary from 'react-error-boundary';

const ImportList = React.lazy(() => import('./Application/List/ImportList'));
const Teams = React.lazy(() => import('./Administration/Teams/Teams'));
const DatabaseList = React.lazy(() => import('./Administration/DatabasePlatforms/DatabaseList'));
const ImpactSuggestion = React.lazy(() => import('./Administration/ImpactSuggestions/ImpactSuggestion'));
const ImpactSuggestionList = React.lazy(() => import('./Administration/ImpactSuggestions/ImpactSuggestionList'));
const EditDatabase = React.lazy(() => import('./Administration/DatabasePlatforms/EditDatabase'));
const AuthorGitStatistics = React.lazy(() => import('./Dashboard/AuthorGitStatistics'));
const EditApplicationPlatform = React.lazy(() => import('./Administration/ApplicationPlatforms/EditApplicationPlatform'));
const ApplicationPlatformList = React.lazy(() => import('./Administration/ApplicationPlatforms/ApplicationPlatformList'));
const EditTeam = React.lazy(() => import('./Administration/Teams/EditTeam'));
const WpiiDashboard = React.lazy(() => import('./Dashboard/WpiiDashboard'));
const ImportInterface = React.lazy(() => import('./Interface/ImportInterface'));
const UpdateUsers = React.lazy(() => import('./Administration/UpdateUsers'));
const ImportCloudFirstAssessment = React.lazy(() => import('./Administration/ImportCloudFirstAssessment'));
const Admin = React.lazy(() => import('./Administration/Admin'));
const AssetChangeReport = React.lazy(() => import('./Reports/AssetChangeReport'));
const InventoryStatusReport = React.lazy(() => import('./Reports/InventoryStatusReport'));
const InactiveStaffReport = React.lazy(() => import('./Reports/InactiveStaffReport'));
const EditHelp = React.lazy(() => import('./Administration/EditHelp'));
const MenuConfiguration = React.lazy(() => import('./Administration/MenuConfiguration'));
const DimScoring = React.lazy(() => import('./Administration/DimScoring'));
const CronJobDashboard = React.lazy(() => import('./Administration/CronJobDashboard'));
const DuplicateNameReport = React.lazy(() => import('./Reports/DuplicateNameReport'));
const CloudStrategicPlan = React.lazy(() => import('./Reports/CloudStrategicPlan'));
const NpmAuditReport = React.lazy(() => import('./Reports/NpmAuditReport'));
const PackageAnalysisReport = React.lazy(() => import('./Reports/PackageAnalysisReport'));
const ImpactedTableReport = React.lazy(() => import('./Reports/ImpactedTableReport'));
const QuickAssessmentList = React.lazy(() => import('./Administration/QuickAssessments/QuickAssessmentList'));
const EditQuickAssessment = React.lazy(() => import('./Administration/QuickAssessments/EditQuickAssessment'));
const QueryBuilder = React.lazy(() => import('./Reports/QueryBuilder/QueryBuilder'))
const QueryBuilderList = React.lazy(() => import('./Reports/QueryBuilder/QueryBuilderList'));
const DashboardConfiguration = React.lazy(() => import('./Dashboard/DashboardConfiguration'))
const DashboardList = React.lazy(() => import('./Dashboard/DashboardList'));

export default function Routes(props) {
    const dispatch = useDispatch();
    const location = useLocation();
    const user = useSelector(state => state.User.user);
    const isLoaded = useSelector(state => state.ApplicationLoadState.isLoaded);
    const dashboards = useSelector(state => state.Dashboard.dashboards);
    const history = useHistory();

    useEffect(() => {
        const load = async () => {
            const user = await dispatch.User.getUser();
            if (user && user.staffId) {
                const redirectUrl = await localforage.getItem('deep-link-redirect-url');
                if (redirectUrl) {
                    await localforage.removeItem('deep-link-redirect-url');
                    window.location.href = redirectUrl;
                } else {
                    await dispatch.Dashboard.loadDashboards(user);
                    await dispatch.QuickAssessment.getQuickAssessments();
                    await dispatch.ApplicationLoadState.loadEnvironmentVariables();
                    await dispatch.ApplicationLoadState.loadMenuConfiguration();
                    appInsights.setAuthenticatedUserContext(user.displayName);
                    await dispatch.Applications.getApplications();
                    await dispatch.Teams.getTeams();
                    dispatch.ApplicationPlatform.getApplicationPlatforms();
                    dispatch.Database.getDatabases();
                }
            }

            dispatch.ApplicationLoadState.setIsLoaded();
        }

        load();
        // eslint-disable-next-line 
    }, []);

    useEffect(() => {
        if (location && location.pathname === '/' && dashboards && dashboards.length > 0) {
            const homepageDashboard = dashboards.find(d => {
                return d.isHomePage === true;
            });

            if (homepageDashboard) {
                history.push('/Dashboard/' + homepageDashboard._id);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dashboards])

    const myErrorHandler = (error, componentStack) => {
        console.log(error);
        console.log(componentStack);
    };

    const getAdminRoutes = () => {
        if (user && user.isAdmin === true) {
            return <React.Fragment>
                <Route path="/Teams/:id" component={EditTeam} exact />
                <Route path="/Teams" component={Teams} exact />
                <Route path="/Databases/:id" component={EditDatabase} exact />
                <Route path="/Databases" component={DatabaseList} exact />
                <Route path="/ApplicationPlatforms/:id" component={EditApplicationPlatform} exact />
                <Route path="/ApplicationPlatforms" component={ApplicationPlatformList} exact />
                <Route path="/ImportList" component={ImportList} exact />
                <Route path="/ImportCloudFirstAssessment" component={ImportCloudFirstAssessment} exact />
                <Route path="/ImportInterface" component={ImportInterface} exact />
                <Route path="/UpdateUsers" component={UpdateUsers} exact />
                <Route path="/Admin" component={Admin} exact />
                <Route path="/EditHelp" component={EditHelp} exact />
                <Route path="/MenuConfiguration" component={MenuConfiguration} exact />
                <Route path="/DimScoring" component={DimScoring} exact />
                <Route path="/ImportCloudFirstAssessment" component={ImportCloudFirstAssessment} exact />
                <Route path="/QuickAssessment" component={QuickAssessmentList} exact />
                <Route path="/QuickAssessment/:id" component={EditQuickAssessment} exact />
                <Route path="/ImpactSuggestion" component={ImpactSuggestionList} exact />
                <Route path="/ImpactSuggestion/:id" component={ImpactSuggestion} exact />
                <Route path="/CronJobDashboard" component={CronJobDashboard} exact />
            </React.Fragment>
        }
    }

    const MyFallbackComponent = ({ componentStack, error }) => (
        <div className="container">
            <div className="row">
                <div className="col">
                    <h2 className="text-danger">An Error Has Occurred in Compass!!!</h2>
                </div>
            </div>
        </div>
    )

    const getAuthenticatedRoutes = () => {
        if (user && user.oid) {
            return <>
                <Route path="/AuthorGitStatistics" component={AuthorGitStatistics} exact />
                <Route path="/Inventory/:id" component={ReadOnlyList} exact />
                <Route path="/Inventory" component={ReadOnlyList} exact />
                <Route path="/Search" component={ReadOnlyList} exact />
                <Route path="/Assessment/CloudFirst" component={ReadOnlyList} exact />
                <Route path="/Assessment/CloudFirst/:id" component={ReadOnlyList} exact />
                <Route path="/Assessment/Wpii" component={ReadOnlyList} exact />
                <Route path="/Assessment/Wpii/:id" component={ReadOnlyList} exact />
                <Route path="/Assessment/Quick/:quickAssessmentId" component={ReadOnlyList} exact />
                <Route path="/Assessment/Quick/:quickAssessmentId/:id" component={ReadOnlyList} exact />
                <Route path="/Assessment/Dim" component={DimComingSoon} exact />
                <Route path="/Assessment/Palm" component={ReadOnlyList} exact />
                <Route path="/Reports/AssetChangeReport" component={AssetChangeReport} exact />
                <Route path="/ReportsComingSoon" component={ReportsComingSoon} exact />
                <Route path="/Reports/Dashboard" component={WpiiDashboard} exact />
                <Route path="/Reports/PalmDashboard" component={PalmDashboard} exact />
                <Route path="/Reports/DuplicateNameReport" component={DuplicateNameReport} exact />
                <Route path="/Reports/CloudStrategicPlan" component={CloudStrategicPlan} exact />
                <Route path="/Reports/IncompleteCloudFirstReport" component={IncompleteCloudFirstReport} exact />
                <Route path="/Reports/InactiveStaff" component={InactiveStaffReport} exact />
                <Route path="/Reports/PackageAnalysisReport" component={PackageAnalysisReport} exact />
                <Route path="/Reports/InventoryStatus" component={InventoryStatusReport} exact />
                <Route path="/Solution/:id" component={EditInterface} exact></Route>
                <Route path="/Solutions" component={InterfaceList} exact></Route>
                <Route path="/Dashboard/:id" component={Dashboard} exact></Route>
                <Route path="/Interfaces/:id" component={EditInterface} exact></Route>
                <Route path="/Interfaces" component={InterfaceList} exact></Route>
                <Route path="/Reports/QuickAssessment/:id" component={QuickAssessmentReport} exact></Route>
                <Route path="/Reports/NpmAuditReport" component={NpmAuditReport} exact></Route>
                <Route path="/Reports/ImpactedTableReport" component={ImpactedTableReport} exact></Route>
                <Route path="/Reports/QueryBuilder" component={QueryBuilderList} exact></Route>
                <Route path="/Reports/QueryBuilder/:id" component={QueryBuilder} exact></Route>
                <Route path="/Reports/DashboardConfiguration" component={DashboardList} exact></Route>
                <Route path="/Reports/DashboardConfiguration/:id" component={DashboardConfiguration} exact></Route>
                {getAdminRoutes()}
            </>
        }
    }

    return <div>
        <ReduxToastr
            timeOut={3000}
            newestOnTop={false}
            preventDuplicates
            position="top-center"
            transitionIn="fadeIn"
            transitionOut="fadeOut"
            progressBar
            closeOnToastrClick />
        <SocketHandler></SocketHandler>
        <Header />
        <div className="container-fluid">
            <div className="row">
                <div className="col"></div>
            </div>
        </div>
        <div>
            {isLoaded ?
                <Suspense fallback={<Loading></Loading>}>
                    <ErrorBoundary onError={myErrorHandler} FallbackComponent={MyFallbackComponent}>
                        <Switch>
                            <Route exact path="/" component={Home} />
                            <Route path="/NotAuthorized" component={NotAuthorized}></Route>
                            <Route path="/Error" component={ErrorView}></Route>
                            <Route path="/Help" component={Help} />
                            {getAuthenticatedRoutes()}
                            <Route component={NoMatch} />
                        </Switch>
                    </ErrorBoundary>
                </Suspense>
                : <div className="container">
                    <div className="row">
                        <div className="col">
                            <h3>
                                <div className="spinner-border text-primary" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>&nbsp;&nbsp;Compass Is Loading...
                        </h3>
                        </div>
                    </div>
                </div>
            }
        </div>
    </div>
}
