import React from 'react';
import PropTypes from 'prop-types';
import Card from '../../Card';
import AddButton from '../../AddButton';
import EditQuickAssessmentQuestions from './EditQuickAssessmentQuestions';
const expr = require('expression-eval');

export default function EditAssessmentGroup(props) {
    const { group, state, setNestedNestedPropertyState, selectedAssessment } = props;

    const addRow = () => {
        const newObject = {}

        for (let q of group.questions) {
            newObject[q._id] = '';
        }
        const newArray = [...state.quickAssessments[selectedAssessment._id][group._id], newObject];
        setNestedNestedPropertyState('quickAssessments', selectedAssessment._id, group._id, newArray);
    }

    const getOptions = (question) => {
        const options = [<option key="null" value=''>Select</option>];
        question.options.forEach((option, i) => {
            options.push(<option key={i} value={option}>{option}</option>);
        });

        return options;
    }

    const isQuestionDisabled = (question, index) => {
        if (!question.disabledExpression || question.disabledExpression === '') {
            return false;
        }

        let ast = null;

        try {
            ast = expr.parse(question.disabledExpression);
        } catch (ex) {

        }

        if (!ast) {
            return false;
        }

        const objParams = {};

        for (let group of selectedAssessment.groups) {
            for (let q of group.questions) {
                if (q.expressionVariable && q.expressionVariable !== '') {
                    if (group.isArray) {
                        const array = state.quickAssessments[selectedAssessment._id][group._id];
                        objParams[q.expressionVariable] = array[index][q._id];
                    } else {
                        objParams[q.expressionVariable] = state.quickAssessments[selectedAssessment._id][q._id];
                    }
                }
            }
        }

        const value = expr.eval(ast, objParams);
        return value;
    }

    const isGroupHidden = () => {
        if (!group.visibilityExpression || group.visibilityExpression === '') {
            return false;
        }

        let ast = null;

        try {
            ast = expr.parse(group.visibilityExpression);
        } catch (ex) {

        }

        if (!ast) {
            return false;
        }

        const objParams = {};

        for (let group of selectedAssessment.groups) {
            for (let q of group.questions) {
                if (q.expressionVariable && q.expressionVariable !== '') {
                    objParams[q.expressionVariable] = state.quickAssessments[selectedAssessment._id][q._id];
                }
            }
        }

        const value = expr.eval(ast, objParams);
        return value;
    }

    const updateMultiSelectValue = (e, question) => {
        const arrayValue = Array.from(e.target.options).filter(opt => {
            return opt.selected;
        }).map((opt, i) => {
            return opt.value;
        });

        setNestedNestedPropertyState('quickAssessments', selectedAssessment._id, question._id, arrayValue);
    }

    const getInput = (group, index, question) => {
        const isDisabled = isQuestionDisabled(question, index);
        const value = state.quickAssessments[selectedAssessment._id][group._id][index][question._id];

        const setValue = (e) => {
            let val = [...state.quickAssessments[selectedAssessment._id][group._id]];
            val[index] = {
                ...val[index],
                [question._id]: e.target.checked ? e.target.checked : e.target.value
            };
            setNestedNestedPropertyState('quickAssessments', selectedAssessment._id, group._id, val)
        }

        switch (question.questionType) {
            case "Dropdown":
                return <select className="form-control" disabled={isDisabled}
                    value={value} onChange={e => setValue(e)}>
                    {getOptions(question)}
                </select>
            case "MultiSelect":
                return <select disabled={isDisabled} multiple className="form-control"
                    onChange={e => updateMultiSelectValue(e, question)} value={value ?
                        value : []}>
                    {getOptions(question)}
                </select>
            case "Input":
                return <input className="form-control" type="text" disabled={isDisabled}
                    value={value}
                    onChange={e => setValue(e)}></input>
            case "Number":
                return <input className="form-control" type="number" disabled={isDisabled}
                    value={value}
                    onChange={e => setValue(e)}></input>
            case "Date":
                return <input className="form-control" type="date" disabled={isDisabled}
                    value={value}
                    onChange={e => setValue(e)}></input>

            case "Text Area":
                return <textarea className="form-control" type="text" disabled={isDisabled}
                    value={value}
                    onChange={e => setValue(e)}></textarea>
            default:
                return <input className="form-control" type="checkbox" disabled={isDisabled}
                    checked={value}
                    onChange={e => setValue(e)}></input>
        }
    }

    const getTableHeaders = () => {
        return <thead><tr>
            {group.questions.map((q, i) => {
                return <th key={i}>{q.text}</th>
            })}
        </tr>
        </thead>
    }

    const getTableBody = () => {
        return <tbody>
            {state.quickAssessments[selectedAssessment._id][group._id].map((row, i) => {
                return <tr key={i}>
                    {group.questions.map((q, questionIndex) => {
                        return <td key={questionIndex}>
                            {getInput(group, i, q)}
                        </td>
                    })}
                </tr>
            })}
        </tbody>
    }

    const getArrayContent = () => {
        return <>
            <div className="row">
                <div className="col text-right">
                    <AddButton text="Row" onClick={addRow} isSmall={true}></AddButton>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <table className="table table-sm table-bordered table-striped">
                        {getTableHeaders()}
                        {getTableBody()}
                    </table>
                </div>
            </div>
        </>
    }

    if (isGroupHidden()) {
        return <></>
    }

    return <Card headerText={group.headerText}>
        {group.isArray ? getArrayContent() : <EditQuickAssessmentQuestions state={state}
            setNestedNestedPropertyState={setNestedNestedPropertyState}
            questions={group.questions}
            selectedAssessment={selectedAssessment}></EditQuickAssessmentQuestions>}
    </Card>
}

EditAssessmentGroup.propTypes = {
    group: PropTypes.object.isRequired,
    state: PropTypes.object.isRequired,
    setNestedNestedPropertyState: PropTypes.func.isRequired,
    selectedAssessment: PropTypes.object.isRequired
}
