import React, { useRef } from "react";
import { useSelector, useDispatch } from 'react-redux';
import Store from '../../Store';
import SaveCancel from '../SaveCancel';
import { useNestedArrayState } from '../../hooks/useNestedArrayState';
import { appInsights } from '../../appInsights';
import { toastr } from 'react-redux-toastr'
import TeamOptions from '../TeamOptions';
import useEventListener from '@use-it/event-listener';
import { Typeahead } from 'react-bootstrap-typeahead';
import useApplicationTypeaheadOptions from '../../hooks/useApplicationTypeaheadOptions';
import EditPpmProjects from "./PpmProProjects/EditPpmProjects";
import DeleteButton from '../DeleteButton';

export default function EditSimplifiedAsset() {
    const dispatch = useDispatch();
    const currentApplication = useSelector(state => Store.select.Applications.currentApplication(state));
    const applications = useSelector(state => state.Applications.applications);
    const isCreatingNewApplication = useSelector(state => state.Applications.isCreatingNewApplication);
    const user = useSelector(state => state.User.user);
    const appOptions = useApplicationTypeaheadOptions(true);
    const form = useRef(null);
    const {
        state,
        setStateProperty,
        addToArrayProperty,
        removeFromArrayProperty
    } = useNestedArrayState(currentApplication);

    const cancel = () => {
        if (isCreatingNewApplication) {
            dispatch.Applications.changeApplicationModalVisibility(false);
        }
        dispatch.Applications.changeApplicationModalIsEditing(false);
    }

    useEventListener('AppChanged', (e) => {
        if (e.detail.applicationId === currentApplication._id) {
            toastr.info('This asset has been updated', 'This asset has been reloaded with another user\'s changes.', { timeOut: 12000 });
            cancel();
        }
    });

    const addApplication = () => {
        delete state._id;
        const saveState = { ...state };
        if (saveState.type === 'Other' && (!saveState.otherSubtype || saveState.otherSubtype === '')) {
            saveState.otherSubtype = 'Unknown';
        } else if (saveState.type !== 'Other') {
            saveState.otherSubtype = '';
        }
        dispatch.Applications.persistNewApplication(saveState);

        appInsights.trackEvent({
            name: 'Asset Added',
            properties: {
                appName: currentApplication.name,
                appId: currentApplication._id
            }
        });
    }

    const save = (e) => {
        e.returnValue = false;
        e.preventDefault();

        if (!form.current.reportValidity()) {
            if (isCreatingNewApplication || user.isAdmin) {

                toastr.error('Enter the required fields', 'Name, Responsible Group, Responsible Sub-Group, Description');
            } else {

                toastr.error('Enter the required field', 'Name, Description');
            }
            return;
        }

        if (state.wpiiImpactResponsibleStaff.length === 0 && state.wpiiLiasons.length === 0 && state.updatePersons.length === 0) {
            toastr.error('One Asset Role is required!');
            return;
        }

        if (isCreatingNewApplication) {
            toastr.confirm('Are you sure you want to add this new Asset', {
                onOk: addApplication
            });
        } else {
            const saveState = { ...state };
            if (saveState.type === 'Other' && (!saveState.otherSubtype || saveState.otherSubtype === '')) {
                saveState.otherSubtype = 'Unknown';
            } else if (saveState.type !== 'Other') {
                saveState.otherSubtype = '';
            }
            dispatch.Applications.persistApplication(saveState);
            dispatch.Applications.changeApplicationModalIsEditing(false);

            appInsights.trackEvent({
                name: 'Simplified Asset Save',
                properties: {
                    appName: currentApplication.name,
                    appId: currentApplication._id
                }
            });
        }
    }

    if (!currentApplication) {
        return <div></div>
    }

    return <form onSubmit={e => save(e)} ref={form}>
        <div className="row">
            <div className="col"></div>
        </div>
        <div className="row my-1">
            <div className="col-2 font-weight-bold">Name:
            <span className="text-danger">&nbsp;*</span>
            </div>
            <div className="col">
                <input className="form-control-sm form-control" type="text" required value={state.name}
                    onChange={e => setStateProperty('name', e)}></input>
            </div>
            <div className="col-2 font-weight-bold">Acronym:</div>
            <div className="col">
                <input className="form-control-sm form-control" type="text" value={state.acronym}
                    onChange={e => setStateProperty('acronym', e)}></input>
            </div>
        </div>
        <div className="row my-1">
            <div className="col-2 font-weight-bold">IT Asset Type:
            <span className="text-danger">&nbsp;*</span>
            </div>
            <div className="col-4">
                <select className="form-control-sm form-control" value={state.type} onChange={e => setStateProperty('type', e)} required>
                    <option value=''>Select</option>
                    <option value="Application">Application</option>
                    <option value="Automated Job/Process">Automated Job/Process</option>
                    <option value="Interface">Interface</option>
                    <option value="Other">Other</option>
                    <option value="Report">Report</option>
                </select>
            </div>
            {state.type === 'Other' ? <>
                <div className="col-2 font-weight-bold">IT Asset Other SubType:
                </div>
                <div className="col-4">
                    <Typeahead
                        onChange={selected => {
                            console.log(selected);
                            const val = selected && selected.length > 0 ? selected[0].label ? selected[0].label : selected[0] : null;
                            console.log(val);
                            setStateProperty('otherSubtype', val)
                        }}
                        defaultInputValue={state.otherSubtype}
                        allowNew={true}
                        allowMultiple={false}
                        id="otherSubType"
                        options={['Database/Data Set', 'GIS', 'Tool']} />
                </div>
            </> : <></>}
        </div>

        <div className="row my-1">
            <div className="col-2 font-weight-bold">IT Asset Status:</div>
            <div className="col-4">
                {isCreatingNewApplication ?
                    'Pending' :
                    user.isAdmin ?
                        <select className="form-control-sm form-control" value={state.wpiiInventoryStatus}
                            onChange={e => setStateProperty('wpiiInventoryStatus', e)}>
                            <option value="Active">Active</option>
                            <option value="Duplicate">Duplicate</option>
                            {user.isAdmin ?
                                <option value="Pending">Pending</option> : ''}
                            <option value="Obsolete">Obsolete</option>
                        </select>
                        : <>{state.wpiiInventoryStatus}</>}
            </div>
            {state.wpiiInventoryStatus === 'Duplicate' ?
                <>
                    <div className="col-2 font-weight-bold">Duplicate Asset:</div>
                    <div className="col-4">
                        <Typeahead
                            value={state.duplicateId}
                            onChange={selected => {
                                setStateProperty('duplicateId',
                                    selected && selected.length > 0 ? selected[0].id : null)
                            }}
                            placeholder="Select Duplicate IT Asset"
                            id="ApplicationRelatedItAsset"
                            options={appOptions} />
                    </div> </> : <></>
            }
        </div>
        {isCreatingNewApplication || user.isAdmin ?
            <div className="row">
                <div className="col-2 font-weight-bold">Responsible Group:
                <span className="text-danger">&nbsp;*</span></div>
                <div className="col">
                    <select className="form-control form-control-sm " required value={state.team}
                        onChange={e => setStateProperty('team', e)}>
                        <TeamOptions></TeamOptions>
                    </select>
                </div>
                <div className="col-2 font-weight-bold" style={{ paddingRight: 0 }}>Responsible Sub-Group:
                <span className="text-danger">*</span></div>
                <div className="col">
                    <select className="form-control form-control-sm" required disabled={!state.team}
                        value={state.subTeam} onChange={e => setStateProperty('subTeam', e)}>
                        <TeamOptions team={state.team}></TeamOptions>
                    </select>
                </div>
            </div> : ''
        }
        <div className="row my-1">
            <div className="col-2 font-weight-bold">
                Description:            <span className="text-danger">&nbsp;*</span>
            </div>
            <div className="col">
                <textarea className="form-control-sm form-control" required type="text" value={state.description}
                    onChange={e => setStateProperty('description', e)}></textarea>
            </div>
        </div >
        <div className="row my-1">
            <div className="col-2 font-weight-bold">
                Business Metadata:
            </div>
            <div className="col">
                <input className="form-control-sm form-control" type="text" value={state.businessMetadata}
                    onChange={e => setStateProperty('businessMetadata', e)}></input>
            </div>
        </div >
        <div className="row my-1">
            <div className="col-2 font-weight-bold">
                Ring Fenced:
            </div>
            <div className="col">
                <input className="form-control-sm form-control" type="checkbox" checked={state.isRingFenced}
                    onChange={e => setStateProperty('isRingFenced', e)}></input>
            </div>
            <div className="col-2 font-weight-bold">ROADS ID:</div>
            <div className="col">
                <input className="form-control-sm form-control" type="number" value={state.roadsId}
                    onChange={e => setStateProperty('roadsId', e)}></input>
            </div>
        </div>
        <div className="row my-1">
            <div className="col-2 font-weight-bold">Related Assets:</div>
            <div className="col-4">
                <Typeahead
                    value={state.duplicateId}
                    onChange={selected => {
                        const val = selected && selected.length > 0 ? selected[0].id : null;
                        if (val === state._id) {
                            toastr.error('You can not select the same asset you are currently on.');
                            return;
                        }
                        if (state.relatedAssets.filter(r => {
                            return r === val;
                        }).length > 0) {
                            toastr.error('This asset has already been related.');
                            return;
                        }

                        if (val) {
                            addToArrayProperty('relatedAssets', val);
                        }
                    }}
                    placeholder="Select Related IT Asset"
                    id="RelatedItAsset"
                    options={appOptions} />
            </div>
        </div>
        <div className="row my-1">
            <div className="col">
                {state.relatedAssets ? state.relatedAssets.map((a, i) => {
                    return <React.Fragment key={a}><span className="badge badge-secondary" >{applications[a].name}&nbsp;
                        <DeleteButton isSmall={true} hideText={true} onClick={() => removeFromArrayProperty('relatedAssets', i)}>
                        </DeleteButton>
                    </span>&nbsp;&nbsp;
                    </React.Fragment>
                }) : <></>}
            </div>
        </div>
        {!isCreatingNewApplication ?
            <EditPpmProjects state={state} addToArrayProperty={addToArrayProperty}
                removeFromArrayProperty={removeFromArrayProperty}></EditPpmProjects>
            : <></>}
        <div className="row">
            <div className="col"></div>
        </div>
        <SaveCancel isSubmit={true} onCancelClick={cancel}></SaveCancel>
    </form >
}
