import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Card from "../Card";
import InterfaceDependencyList from './InterfaceDependencyList';

export default function EditLibrary(props) {
    return <Card headerText="Classes" badgeCount={props.classes.length} collapsed={true}>
        <div className="row">
            <div className="col text-right">
                <button className="btn btn-primary" onClick={() => props.addToArrayProperty('classes', { dependencies: [] })} title="Add Class">
                    <FontAwesomeIcon icon="plus"></FontAwesomeIcon>&nbsp;&nbsp;Class</button>
            </div>
        </div>
        <div className="row">
            <div className="col">
                <ul className="list-group">
                    {props.classes ? props.classes.map((libraryClass, classIndex) => {
                        return <li className="list-group-item" key={classIndex}>
                            <div className="row">
                                <label className="col-1 font-weight-bold">
                                    Class Name:</label>
                                <div className="col">
                                    <input className="form-control" value={libraryClass.name}
                                        onChange={e => props.setStateArrayProperty('classes', classIndex, 'name', e)} />
                                </div>
                            </div>
                            <div className="row">
                                <label className="col-1 font-weight-bold">
                                    Description:</label>
                                <div className="col">
                                    <textarea className="form-control" value={libraryClass.description}
                                        onChange={e => props.setStateArrayProperty('classes', classIndex, 'description', e)} />
                                </div>
                            </div>
                            <Card headerText="Methods" collapsed={true}>
                                <div className="row">
                                    <div className="col text-right">
                                        <button className="btn btn-primary" title="Add Method"
                                            onClick={() => { props.addToNestedArrayProperty('classes', classIndex, 'methods', {}) }}>
                                            <FontAwesomeIcon icon="plus"></FontAwesomeIcon>&nbsp;&nbsp;Method
                                    </button>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <ul className="list-group">
                                            {props.classes[classIndex].methods ? props.classes[classIndex].methods.map((method, index) => {
                                                return <li key={index} className="list-group-item">
                                                    <div className="row">
                                                        <div className="col-1 font-weight-bold">Name:</div>
                                                        <div className="col">
                                                            <input className="form-control" value={method.name}
                                                                onChange={e => props.setStateArrayofArrayProperty('classes', classIndex, 'methods', index, 'name', e)} />
                                                        </div>

                                                    </div>
                                                    <div className="row">
                                                        <div className="col-1 font-weight-bold">
                                                            Description:
                                                        </div>
                                                        <div className="col">
                                                            <textarea className="form-control" value={method.description}
                                                                onChange={e => props.setStateArrayofArrayProperty('classes', classIndex, 'methods', index, 'description', e)} />
                                                        </div>

                                                    </div>
                                                    <div className="row">
                                                        <div className="co">
                                                            <button aria-label="Delete Method" title="Delete Method" className="btn btn-danger"
                                                                onClick={() => props.removeFromNestedArrayProperty('classes', classIndex, 'methods', index)} >
                                                                <FontAwesomeIcon icon="trash-alt" ></FontAwesomeIcon>&nbsp;&nbsp;Delete Method</button>
                                                        </div>
                                                    </div>
                                                </li>
                                            }) : <></>}
                                        </ul>
                                    </div>
                                </div>
                            </Card>
                            <br />
                            <div className="row">
                                <div className="col">
                                    <InterfaceDependencyList dependencies={libraryClass.dependencies}
                                        parentArrayName='classes'
                                        parentIndex={classIndex}
                                        setStateArrayofArrayProperty={props.setStateArrayofArrayProperty}
                                        addToNestedArrayProperty={props.addToNestedArrayProperty}
                                        removeFromNestedArrayProperty={props.removeFromNestedArrayProperty}>
                                    </InterfaceDependencyList>
                                </div>
                            </div>
                            <br />
                            <div className="row">
                                <div className="col">
                                    <button className="btn btn-danger" onClick={() => props.removeFromArrayProperty('classes', classIndex)}>
                                        <FontAwesomeIcon icon="trash-alt"></FontAwesomeIcon>
                                        &nbsp;&nbsp;Remove Class
                        </button>
                                </div>
                            </div>
                        </li>
                    }) : ''}
                </ul>
            </div>
        </div>
    </Card>
}
