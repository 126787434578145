import React from 'react';
import { useSelector, useStore } from "react-redux";

export default function ViewSolutionApplicationPlatforms() {
    const store = useStore();
    const applicationPlatforms = useSelector(state => state.ApplicationPlatform.applicationPlatforms);
    const currentInterface = useSelector(state => store.select.AssetInterface.currentAssetInterface(state));

    return <>
        <div className="row">
            <div className="col">
                <table className="table table-striped table-bordered">
                    <thead>
                        <tr>
                            <th>Platform</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentInterface.applicationPlatforms ? currentInterface.applicationPlatforms.map((platform, i) => {
                            return <tr key={i}>
                                <td>{applicationPlatforms[platform].name}</td>
                            </tr>
                        }) : ''}
                    </tbody>
                </table>
            </div>
        </div>
    </>
}
