import React, { useEffect, useState } from 'react';
import { useSelector, useStore } from 'react-redux';
import axios from 'axios';
import routeConfig from '../../../routeConfig';
import Loading from '../../Loading';
import { format } from 'date-fns'

export default function ViewCmdb(props) {
    const [cmdbData, setCmdbData] = useState({});
    const store = useStore();
    const application = useSelector(state => store.select.Applications.currentApplication(state));
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (application && application.cmdbRecId) {
            setIsLoading(true);
            axios.get(`${routeConfig.baseUrl}/Cherwell/${application.cmdbRecId}`).then(resp => {
                setIsLoading(false);
                setCmdbData(resp.data);
            })
        } else {
            setCmdbData(null);
        }
    }, [application]);
    //lifecylce, ownerDistrict, enterprise, keySystem, location, 

    if (isLoading) {
        return <Loading></Loading>
    }

    if (!cmdbData) {
        return <div className="row">
            <div className="col">
                No CMDB Data Available
            </div>
        </div>
    }
    return <>
        <div className="row">
            <div className="col font-weight-bold">
                Functional Coordinator Name:
            </div>
            <div className="col">
                {cmdbData.functionalCoordinatorName}
                {cmdbData.functionalCoordinatorEmail ? <a target="_blank" title={`Chat with ${cmdbData.functionalCoordinatorName} in Teams`}
                    href={`https://teams.microsoft.com/l/chat/0/0?users=${cmdbData.functionalCoordinatorEmail}`}>
                    <img src='./Teams-32x32.png'></img></a> : ''}
            </div>
            <div className="col font-weight-bold">
                EDS Functional Area:
            </div>
            <div className="col">
                {cmdbData.edsFunctionalArea}
            </div>
        </div>
        <div className="row">
            <div className="col font-weight-bold">
                Functional Areas:
            </div>
            <div className="col">
                {cmdbData.functionalAreas}
            </div>
            <div className="col font-weight-bold">
                Developed By:
            </div>
            <div className="col">
                {cmdbData.developedBy}
            </div>
        </div>
        <div className="row">
            <div className="col font-weight-bold">
                EDS Review Date:
            </div>
            <div className="col">
                {format(new Date(cmdbData.edsReviewDate), 'MM/DD/YYYY')}
            </div>
            <div className="col font-weight-bold">
                Enterprise Data Steward:
            </div>
            <div className="col">
                {cmdbData.enterpriseDataSteward}
            </div>
        </div>
        <div className="row">
            <div className="col font-weight-bold">
                Owned By Name:
            </div>
            <div className="col">
                {cmdbData.ownedByName}
                {cmdbData.ownedByEmail ? <a target="_blank" title={`Chat with ${cmdbData.ownedByName} in Teams`}
                    href={`https://teams.microsoft.com/l/chat/0/0?users=${cmdbData.ownedByEmail}`}>
                    <img src='./Teams-32x32.png'></img></a> : ''}
            </div>
            <div className="col font-weight-bold">
                Owner Office:
            </div>
            <div className="col">
                {cmdbData.ownerOffice}
            </div>
        </div>
        <div className="row">
            <div className="col font-weight-bold">
                Owner District:
            </div>
            <div className="col">
                {cmdbData.ownerDistrict}
            </div>
            <div className="col font-weight-bold">
                Owned By Team:
            </div>
            <div className="col">
                {cmdbData.ownedByTeam}
            </div>
        </div>
        <div className="row">
            <div className="col font-weight-bold">
                Cost Center:
            </div>
            <div className="col">
                {cmdbData.costCenter}
            </div>
            <div className="col font-weight-bold">
                EDS Functional Area:
            </div>
            <div className="col">
                {cmdbData.edsFunctionalArea}
            </div>
        </div>
        <div className="row">
            <div className="col font-weight-bold">
                Lifecycle:
            </div>
            <div className="col">
                {cmdbData.lifecycle}
            </div>
            <div className="col font-weight-bold">
                Location:
            </div>
            <div className="col">
                {cmdbData.location}
            </div>
        </div>
        <div className="row">
            <div className="col font-weight-bold">
                Primary Technical Support:
            </div>
            <div className="col">
                {cmdbData.primaryTechnicalSupport}
            </div>
            <div className="col font-weight-bold">
                Secondary Technical Support:
            </div>
            <div className="col">
                {cmdbData.secondaryTechnicalSupport}
            </div>
        </div>
    </>
}