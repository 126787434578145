import { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
export default function useApplicationTypeaheadOptions(useCompassId) {
    const [typeAheadOptions, setTypeAheadOptions] = useState([]);
    const applications = useSelector(state => state.Applications.applications);

    useEffect(() => {
        if (!applications) {
            setTypeAheadOptions([]);
        }
        setTypeAheadOptions(Object.values(applications).filter(a => {
            return a.wpiiInventoryStatus === 'Active'
        }).sort((a, b) => {
            if (useCompassId) {
                return a.oitId - b.oitId;
            }
            if (a.name < b.name) {
                return -1;
            }
            if (a.name > b.name) {
                return 1;
            }
            return 0;
        }).map((value, i) => {
            return {
                id: value._id,
                label: useCompassId === true ? `${value.oitId} - ${value.name}` : `${value.name} (${value.wpiiImpactResponsibleGroup})`
            }
        }));
    }, [applications]);

    return typeAheadOptions;
}
