import React, { useState } from "react";
import { useDispatch } from 'react-redux';
import Card from '../../Card';


export default function SearchList(props) {
    const [searchCriteria, setSearchCriteria] = useState('');
    const dispatch = useDispatch();

    const search = (e) => {
        e.preventDefault();
        dispatch.Applications.search(searchCriteria);
    }

    return <><Card headerText={`Inventory Search Criteria`} className="my-1 mp-1">
        <form onSubmit={e => search(e)}>
            <div className="row">
                <div className="col">
                    Enter a search term that will be used to search across Asset Name, Description, Compass ID, and Acronym.
                </div>
                <div className="col">
                    <div className="input-group">
                        <input type="text" className="form-control" value={searchCriteria} onChange={e => setSearchCriteria(e.target.value)} />
                        <div className="input-group-append">
                            <button className="btn btn-primary" onClick={e => search(e)}
                                disabled={!searchCriteria || searchCriteria.length === 0}>Search</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </Card>
        <br />
    </>
}
