import axios from 'axios';
import routeConfig from '../routeConfig';

export const User = {
    state: {
        user: null
    },
    reducers: {
        loadUser(state, user) {
            return {
                ...state,
                user: user
            }
        }
    },
    effects: {
        async getUser(payload, rootstate) {
            if (rootstate.User.user) {
                return null;
            }

            const resp = await axios.get(`${routeConfig.baseUrl}/User`, { withCredentials: true });
            this.loadUser(resp.data);
            return resp.data;
        }
    }
}
