import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector, useStore } from 'react-redux';
import SaveCancel from '../../SaveCancel';
import { useNestedArrayState } from '../../../hooks/useNestedArrayState';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EditDatabase from '../../Interface/EditDatabase';
import { toastr } from 'react-redux-toastr';
import useApplicationTypeaheadOptions from '../../../hooks/useApplicationTypeaheadOptions';
import Card from '../../Card';
import deepCopy from 'deep-copy';
import { FilePond } from "react-filepond";
import routeConfig from '../../../routeConfig';
import { Typeahead } from 'react-bootstrap-typeahead';
import axios from 'axios';
import FontAwesomeButton from '../../FontAwesomeButton';

export default function EditData(props) {
    const store = useStore();
    const dispatch = useDispatch();
    const currentApplication = useSelector(state => store.select.Applications.currentApplication(state));
    const applications = useSelector(state => state.Applications.applications);
    const user = useSelector(state => state.User.user);
    const appOptions = useApplicationTypeaheadOptions(true);
    const [assets, setAssets] = useState([]);

    const [selectedApplication, setSelectedApplication] = useState(null);
    const [selectedTable, setSelectedTable] = useState('');
    const [table, setTable] = useState('');

    const {
        state,
        setStateProperty,
        setStateArrayProperty,
        setStateArrayofArrayProperty,
        clearFromNestedNestedArrayProperty,
        addToNestedNestedArrayProperty,
        addToNestedArrayProperty,
        removeFromNestedArrayProperty,
        addToArrayProperty,
        removeFromArrayProperty,
        removeFromNestedNestedArrayProperty
    } = useNestedArrayState(deepCopy(currentApplication));

    const onSave = () => {
        dispatch.Applications.persistApplication({ ...state });
        dispatch.Applications.changeApplicationModalIsEditing(false);
    };

    useEffect(() => {
        axios.get(`${routeConfig.baseUrl}/AssetTables`).then(resp => {
            setAssets(resp.data);
        });
    }, []);

    const options = useMemo(() => {
        if (!assets) {
            return [];
        }

        let tables = [];

        for (let asset of assets) {
            for (let table of asset.tables) {
                if (table.name) {
                    tables.push({
                        name: `${table.name.trim()} (${asset.name})`,
                        _id: table._id,
                        asset: asset._id
                    })
                }
            }
        }

        tables = tables.sort((a, b) => {
            if (a.name < b.name) {
                return -1;
            }
            if (a.name > b.name) {
                return 1;
            }
            return 0;
        }).map(p => {
            return {
                id: p._id,
                label: p.name,
                asset: p.asset
            }
        });

        return tables;
    }, [assets]);

    const selectApp = (selected) => {
        let returnVal;
        if (selected && selected.length > 0) {
            returnVal = selected[0].id;
        }
        setSelectedApplication(returnVal);
    }

    const addApplication = () => {
        const applicationExists = state.applicationDependencies.some((d) => {
            return d === selectedApplication;
        });

        if (applicationExists) {
            toastr.error("The IT Asset has already been added to the dependencies.")
        }
        else {
            dispatch.Applications.loadApplication(selectedApplication);
            addToArrayProperty('applicationDependencies', { asset: selectedApplication, use: '', type: 'Database' });
        }
    }

    const getFieldsUsedCard = (propertyName, subPropertyName, table, index, tableIndex, lookupProperty) => {
        const foundTable = lookupProperty[state[propertyName][index][subPropertyName]].tables.find(t => {
            return t._id === table.table;
        });

        if (!foundTable) {
            return <></>
        }

        const fields = foundTable.fields.sort((a, b) => {
            return a.name < b.name ? -1 : 1;
        });

        const unselect = () => {
            clearFromNestedNestedArrayProperty(propertyName, index, 'tablesUsed', tableIndex, 'fields');
        }

        const select = () => {
            for (let i = 0; i < fields.length; i++) {
                addToNestedNestedArrayProperty(propertyName, index, 'tablesUsed', tableIndex, 'fields', fields[i]._id);
            }
        }

        return <Card headerText="Fields Used" collapsed={true}>
            <div className="row">
                <div className="col">
                    <button className="btn btn-sm btn-primary" type="button" onClick={select}>Select All</button>
                    &nbsp;&nbsp;
                    <button className="btn btn-sm btn-primary" type="button" onClick={unselect}>Unselect All</button>
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    {getFieldTable(propertyName, subPropertyName, 0, Math.floor(fields.length / 2), fields, table, index, tableIndex)}
                </div>
                <div className="col-6">
                    {getFieldTable(propertyName, subPropertyName, Math.floor(fields.length / 2), fields.length, fields, table, index, tableIndex)}
                </div>
            </div>
        </Card>
    }

    const selectTable = (selected) => {
        let returnVal;

        if (selected && selected.length > 0) {
            returnVal = selected[0];
        }

        setTable(returnVal);
    }

    const addTable = () => {
        const assetIndex = state.applicationDependencies.findIndex((d) => {
            return d === table.asset;
        });

        if (assetIndex > -1) {
            setStateArrayProperty('applicationDependencies', assetIndex, 'tablesUsed', [...state.applicationDependencies[assetIndex].tablesUsed, { table: table.id, fields: [] }]);
        }
        else {
            dispatch.Applications.loadApplication(table.asset);
            addToArrayProperty('applicationDependencies', { asset: table.asset, use: '', type: 'Database', tablesUsed: [{ table: table.id, fields: [] }] });
        }
    }

    const getFieldTable = (propertyName, subPropertyName, sliceIndex1, sliceIndex2, fields, table, index, tableIndex) => {
        return <table className="table table-striped table-bordered table-sm">
            <thead>
                <tr>
                    <th>Used</th>
                    <th>Name</th>
                    <th>Logical Name</th>
                </tr>
            </thead>
            <tbody>
                {fields.slice(sliceIndex1, sliceIndex2).map((field, fieldIndex) => {
                    return <tr key={fieldIndex}>
                        <td>
                            <input type="checkbox" onChange={e => checkFieldChanged(field._id, propertyName, index, tableIndex, e)}
                                checked={table.fields.findIndex(f => {
                                    return f === field._id
                                }) > -1}></input>
                        </td>
                        <td>
                            {field.name}
                        </td>
                        <td>
                            {field.logicalName}
                        </td>
                    </tr>
                })}
            </tbody>
        </table>
    }

    const checkFieldChanged = (fieldId, property, index, tableIndex, e) => {
        if (e.target.checked) {
            addToNestedNestedArrayProperty(property, index, 'tablesUsed', tableIndex, 'fields', fieldId);
        } else {
            const fieldIndex = state[property][index].tablesUsed[tableIndex].fields.findIndex(f => {
                return f === fieldId;
            })
            removeFromNestedNestedArrayProperty(property, index, 'tablesUsed', tableIndex, 'fields', fieldIndex);
        }
    }

    const openAsset = (appId) => {
        window.open(`/Inventory/${appId}`);
    }

    return <>{user.isAdmin ?
        <><Card headerText="Import Database" collapsed={true}>
            <p>The database is immediately updated when the file is uploaded and replaces the existing data!</p>
            <div className="row">
                <div className="col">
                    <FilePond
                        name="list"
                        allowMultiple={false}
                        maxFiles={1}
                        allowRevert={false}
                        server={{
                            url: `${routeConfig.baseUrl}/ImportDatabase/${currentApplication._id}`,
                            revert: (files, load, error) => {
                                this.removeDocuments(files);
                                load();
                            }
                        }}
                        onerror={() => {
                        }}
                        onprocessfiles={() => {
                            dispatch.Applications.loadApplication(currentApplication._id);
                        }}
                    />
                </div>
            </div>
        </Card>
            <div className="row">
                <div className="col"></div>
            </div>
        </> : <></>
    }
        <EditDatabase state={state}
            hideDatabaseName={true}
            setStateProperty={setStateProperty}
            setStateArrayProperty={setStateArrayProperty}
            setStateArrayofArrayProperty={setStateArrayofArrayProperty}
            addToArrayProperty={addToArrayProperty}
            addToNestedArrayProperty={addToNestedArrayProperty}
            removeFromArrayProperty={removeFromArrayProperty}
            removeFromNestedArrayProperty={removeFromNestedArrayProperty}
        ></EditDatabase>
        <div className="row">
            <div className="col"></div>
        </div>
        <EditDatabase state={state}
            hideDatabaseName={true}
            setStateProperty={setStateProperty}
            setStateArrayProperty={setStateArrayProperty}
            setStateArrayofArrayProperty={setStateArrayofArrayProperty}
            addToArrayProperty={addToArrayProperty}
            addToNestedArrayProperty={addToNestedArrayProperty}
            removeFromArrayProperty={removeFromArrayProperty}
            removeFromNestedArrayProperty={removeFromNestedArrayProperty}
            isOrphaned={true}
        ></EditDatabase>
        <div className="row">
            <div className="col"></div>
        </div>
        <Card headerText="Data from Related Assets" collapsed={user.isAdmin ? false : true} collapsed={true}>
            <div className="row">
                <div className="col"></div>
                <div className="col-8">
                    <div className="input-group">
                        <Typeahead
                            value={selectedApplication}
                            onChange={selected => { selectApp(selected) }}
                            placeholder="Select Related IT Asset"
                            id="ApplicationRelatedItAsset"
                            options={appOptions} />
                        <div className="input-group-append">
                            <button className="btn btn-primary" disabled={!selectedApplication}
                                onClick={addApplication} type="button"><FontAwesomeIcon icon="plus" />&nbsp;&nbsp;IT Asset</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    -- Or --
                </div>
            </div>
            <div className="row">
                <div className="col"></div>
                <div className="col-8">
                    <div className="input-group">
                        <Typeahead options={options} placeholder="Select Table"
                            value={table} onChange={selected => selectTable(selected)}>
                        </Typeahead>
                        <div className="input-group-append">
                            <button className="btn btn-primary" onClick={addTable} disabled={!table}><FontAwesomeIcon icon="plus" />&nbsp;&nbsp;Table</button>
                        </div>
                    </div>
                </div>
            </div>
            <ul className="list-group">
                {state.applicationDependencies.map((appDep, index) => {
                    if (appDep.type !== 'Database Read' && appDep.type !== 'Database Read Write' &&
                        appDep.type !== 'Database') {
                        return <></>
                    }

                    return <div className="list-group-item" key={index}>
                        <div className="row" >
                            <div className="col px-1 mx-1 font-weight-bold">
                                {appDep && applications[appDep.asset] ?
                                    <>{applications[appDep.asset].name}&nbsp;&nbsp;
                                        <FontAwesomeButton icon='share-square' title='Open Asset' small={true} onClick={() => openAsset(appDep.asset)} ></FontAwesomeButton>
                                    </>
                                    : ''}
                            </div>

                            <div className="col-1 px-1 mx-1 text-right">
                                <span role="img" aria-label="Delete"
                                    onClick={() => removeFromArrayProperty('applicationDependencies', index)}
                                    style={{ cursor: 'pointer' }}>
                                    <FontAwesomeIcon icon="trash-alt" className="fa-lg text-danger" />
                                </span>
                            </div>
                        </div>
                        {applications[appDep.asset].tables ?
                            <Card headerText="Tables Used" collapsed={true}>
                                <div className="row">
                                    <div className="col text-right">
                                        <div className="input-group">
                                            <select name="" id="" className="form-control" onChange={e => setSelectedTable(e.target.value)}>
                                                <option>Select Table</option>
                                                {applications[appDep.asset].tables.map((table, i) => {
                                                    return <option key={i} value={table._id}>{table.name}</option>
                                                })}
                                            </select>
                                            <div className="input-group-append">
                                                <button className="btn btn-primary" disabled={!selectedTable}
                                                    onClick={() => {
                                                        addToNestedArrayProperty('applicationDependencies', index, 'tablesUsed',
                                                            { table: selectedTable, fields: [] })
                                                    }}>
                                                    <FontAwesomeIcon icon="plus" />&nbsp;&nbsp;Table
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col"></div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <ul className="list-group">
                                            {appDep.tablesUsed && appDep.tablesUsed.length > 0 ? appDep.tablesUsed.map((table, tableIndex) => {
                                                return <ul className="list-group-item" key={tableIndex}>
                                                    <div className="row">
                                                        <div className="col">Table Name:&nbsp;
                                                            {applications[appDep.asset].tables &&
                                                                applications[appDep.asset].tables.find(t => {
                                                                    return t._id === table.table;
                                                                })?
                                                                applications[appDep.asset].tables.find(t => {
                                                                    return t._id === table.table;
                                                                }).name:''
                                                            }</div>
                                                        <div className="col text-right">
                                                            <button className="btn btn-danger btn-sm" aria-label="Delete Table"
                                                                onClick={() => removeFromNestedArrayProperty('applicationDependencies', index, 'tablesUsed', tableIndex)} >
                                                                <FontAwesomeIcon icon="trash-alt" />&nbsp;&nbsp;Remove Table
                                                         </button>
                                                        </div>
                                                    </div>
                                                    {/*<div className="row">
                                                        <div className="col">Create:</div>
                                                        <div className="col">
                                                            <input type="checkbox" checked={table.isCreate}
                                                                onChange={e => setStateArrayofArrayProperty('applicationDependencies', index, 'tablesUsed', tableIndex, 'isCreate', e)}></input>
                                                        </div>
                                                        <div className="col">Read:</div>
                                                        <div className="col">
                                                            <input type="checkbox" checked={table.isRead}
                                                                onChange={e => setStateArrayofArrayProperty('applicationDependencies', index, 'tablesUsed', tableIndex, 'isRead', e)}></input>
                                                        </div>
                                                        <div className="col">Update:</div>
                                                        <div className="col">
                                                            <input type="checkbox" checked={table.isUpdate}
                                                                onChange={e => setStateArrayofArrayProperty('applicationDependencies', index, 'tablesUsed', tableIndex, 'isUpdate', e)}></input>
                                                        </div>
                                                        <div className="col">Delete:</div>
                                                        <div className="col">
                                                            <input type="checkbox" checked={table.isDelete}
                                                                onChange={e => setStateArrayofArrayProperty('applicationDependencies', index, 'tablesUsed', tableIndex, 'isDelete', e)}></input>
                                                        </div>
                                                        </div>*/}
                                                    {getFieldsUsedCard('applicationDependencies', 'asset', table, index, tableIndex, applications)}
                                                </ul>
                                            }) : ''}
                                        </ul>
                                    </div>
                                </div>
                            </Card> : <></>}
                    </div>
                })}
            </ul>
        </Card>
        <div className="row">
            <div className="col"></div>
        </div>
        <SaveCancel onCancelClick={() => dispatch.Applications.changeApplicationModalIsEditing(false)} onSaveClick={onSave}></SaveCancel>
    </>
}
