import React, { useMemo } from "react";
import { useSelector } from 'react-redux';
import Store from '../../../Store';
import Card from '../../Card';
import ViewRelatedAssets from '../Dependencies/ViewRelatedAssets';

export default function ViewData(props) {
    const currentApplication = useSelector(state => Store.select.Applications.currentApplication(state));

    const ownedTables = useMemo(() => {
        if (!currentApplication.tables) {
            return [];
        }

        return currentApplication.tables.filter(t => {
            return !t.isOrphaned;
        })
    }, [currentApplication.tables]);

    const orphanedTables = useMemo(() => {
        if (!currentApplication.tables) {
            return [];
        }

        return currentApplication.tables.filter(t => {
            return t.isOrphaned;
        })
    }, [currentApplication.tables])

    if (!currentApplication || !currentApplication.tables) {
        return <div></div>
    }

    return <>
        <Card headerText="Owned Tables" badgeCount={ownedTables.length} collapsed={true}>
            <div className="row">
                <div className="col">
                    <ul className="list-group">
                        {ownedTables ? ownedTables.map((table, tableIndex) => {
                            return <li className="list-group-item" key={tableIndex}>
                                <div className="row">
                                    <label className="col-2 font-weight-bold">
                                        Table Name:</label>
                                    <div className="col">
                                        {table.name}
                                    </div>

                                    <label className="col-2 font-weight-bold">
                                        Logical Name:</label>
                                    <div className="col">
                                        {table.logicalName}
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col font-weight-bold">
                                        Description:</label>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        {table.description}
                                    </div>
                                </div>
                                {/*<div className="row">
                                    <div className="col">Create:</div>
                                    <div className="col">
                                        <input type="checkbox" checked={table.isCreate} disabled></input>
                                    </div>
                                    <div className="col">Read:</div>
                                    <div className="col">
                                        <input type="checkbox" checked={table.isRead} disabled></input>
                                    </div>
                                    <div className="col">Update:</div>
                                    <div className="col">
                                        <input type="checkbox" checked={table.isUpdate} disabled></input>
                                    </div>
                                    <div className="col">Delete:</div>
                                    <div className="col">
                                        <input type="checkbox" checked={table.isDelete} disabled></input>
                                    </div>
                        </div>*/}
                                <Card headerText="Fields" collapsed={true}>
                                    <div className="row">
                                        <div className="col">
                                            <table className="table table-striped table-bordered table-sm">
                                                <thead>
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>Logical Name</th>
                                                        <th>Field Type</th>
                                                        <th>Field Length</th>
                                                    </tr>
                                                </thead>
                                                <tbody>{table.fields ? table.fields.map((field, fieldIndex) => {
                                                    return <tr key={fieldIndex}>
                                                        <td>
                                                            {field.name}
                                                        </td>
                                                        <td>
                                                            {field.logicalName}
                                                        </td>
                                                        <td>
                                                            {field.fieldType}
                                                        </td>
                                                        <td>
                                                            {field.length}
                                                        </td>
                                                    </tr>
                                                }) : <></>}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </Card>
                                <br />
                            </li>
                        }) : ''}
                    </ul>
                </div>
            </div>
        </Card>
        <br />
        {orphanedTables.length > 0 ?
            <>
                <Card headerText="Orphaned Tables" collapsed={true} badgeCount={orphanedTables.length} >
                    <div className="row">
                        <div className="col">
                            <ul className="list-group">
                                {orphanedTables ? orphanedTables.map((table, tableIndex) => {
                                    return <li className="list-group-item" key={tableIndex}>
                                        <div className="row">
                                            <label className="col-2 font-weight-bold">
                                                Table Name:</label>
                                            <div className="col">
                                                {table.name}
                                            </div>

                                            <label className="col-2 font-weight-bold">
                                                Logical Name:</label>
                                            <div className="col">
                                                {table.logicalName}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <label className="col font-weight-bold">
                                                Description:</label>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                {table.description}
                                            </div>
                                        </div>
                                        {/*<div className="row">
                                    <div className="col">Create:</div>
                                    <div className="col">
                                        <input type="checkbox" checked={table.isCreate} disabled></input>
                                    </div>
                                    <div className="col">Read:</div>
                                    <div className="col">
                                        <input type="checkbox" checked={table.isRead} disabled></input>
                                    </div>
                                    <div className="col">Update:</div>
                                    <div className="col">
                                        <input type="checkbox" checked={table.isUpdate} disabled></input>
                                    </div>
                                    <div className="col">Delete:</div>
                                    <div className="col">
                                        <input type="checkbox" checked={table.isDelete} disabled></input>
                                    </div>
                        </div>*/}
                                        <Card headerText="Fields" collapsed={true}>
                                            <div className="row">
                                                <div className="col">
                                                    <table className="table table-striped table-bordered table-sm">
                                                        <thead>
                                                            <tr>
                                                                <th>Name</th>
                                                                <th>Logical Name</th>
                                                                <th>Field Type</th>
                                                                <th>Field Length</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>{table.fields ? table.fields.map((field, fieldIndex) => {
                                                            return <tr key={fieldIndex}>
                                                                <td>
                                                                    {field.name}
                                                                </td>
                                                                <td>
                                                                    {field.logicalName}
                                                                </td>
                                                                <td>
                                                                    {field.fieldType}
                                                                </td>
                                                                <td>
                                                                    {field.length}
                                                                </td>
                                                            </tr>
                                                        }) : <></>}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </Card>
                                        <br />
                                    </li>
                                }) : ''}
                            </ul>
                        </div>
                    </div>
                </Card>
                <br></br></>
            : <></>
        }

        <Card headerText="Data from Related Assets" collapsed={true}>
            <ViewRelatedAssets showOnlyData={true}></ViewRelatedAssets>
        </Card>
        <br />
        {/*
        <Card headerText="Mainframe Focus Masters" collapsed={true}>
            <table className="table table-sm table-striped table-bordered">
                <thead>
                    <tr>
                        <th>Name</th>
                    </tr>
                </thead>
                <tbody>
                    {currentApplication.focusMasters ? currentApplication.focusMasters.map((f, i) => {
                        return <tr key={i}>
                            <td>
                                {f.name}
                            </td>
                        </tr>
                    }) : <></>}
                </tbody>
            </table>
                </Card>*/}
        <div className="row">
            <div className="col"></div>
        </div>

    </>
}
