/* eslint-disable no-loop-func */
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import Store from "../../Store";
import ViewApi from "./ViewApi";
import ViewDatabase from "./ViewDatabase";
import Card from "../Card";
import ViewLibrary from "./ViewLibrary";
import { Graph } from "react-d3-graph";
import {
  decorateGraphNodesWithInitialPositioning,
  graphConfig,
} from "../../helpers/graphHelper";

export default function ViewInterface(props) {
  const currentInterface = useSelector((state) =>
    Store.select.AssetInterface.currentAssetInterface(state)
  );
  const applications = useSelector((state) => state.Applications.applications);
  const currentInterfaceId = useSelector(
    (state) => state.AssetInterface.currentInterfaceId
  );
  const teams = useSelector((state) => state.Teams.teams);

  const dependentTree = useMemo(() => {
    let depth = 3;
    let name;

    if (currentInterface) {
      name = currentInterface.name;
    }
    const data = {
      nodes: [],
      links: [],
      children: [],
      name: name,
      key: currentInterfaceId,
    };

    data.nodes.push({
      id: currentInterfaceId,
      name: name,
      symbolType: "star",
    });

    Object.values(applications).forEach((app) => {
      if (!app.interfaceDependencies || app.interfaceDependencies.length === 0)
        return;

      var appDependent = app.interfaceDependencies.find((a) => {
        return a.isNotUsed !== true && a.interface === currentInterfaceId;
      });

      if (!depth) {
        depth = 1;
      }

      if (appDependent && depth < 5) {
        data.nodes.push({
          id: app._id,
          name: app.name,
        });

        data.links.push({
          source: currentInterfaceId,
          target: app._id,
        });
      }
    });

    data.nodes = decorateGraphNodesWithInitialPositioning(data.nodes);

    return data;
  }, []);

  const exportAssets = useMemo(() => {
    const assets = [];

    Object.values(applications).forEach((app) => {
      if (app.wpiiInventoryStatus !== "Active") {
        return;
      }

      if (!app.interfaceDependencies || app.interfaceDependencies.length === 0)
        return;

      var appDependent = app.interfaceDependencies.find((a) => {
        return a.isNotUsed !== true && a.interface === currentInterfaceId;
      });

      if (appDependent) {
        console.log(app);
        assets.push({
          Name: app.name,
          Id:app._id,
          'Is Ring Fenced':app.isRingFenced == true?'Yes':'No',
          'Acronym':app.acronym,
          'Compass Id': app.oitId,
          'Azure DevOps Project': app.azureDevOpsProject,
          'Azure DevOps Repo Name': app.azureDevOpsRepoName,
          Description: app.description,
          'Responsible Group': teams[app.team]?.name,
          'Responsible Sub-Group': app.subTeam && teams[app.team].subTeams.find(s => { return s._id === app.subTeam }) ? teams[app.team].subTeams.find(s => { return s._id === app.subTeam }).name : '',

        });
      }
    });

    return assets;
  }, []);

  const exportApps = async () => {
    const XLSX = await import('xlsx');
    const wb = XLSX.utils.book_new();

    wb.Props = {
        Title: "Assets for " + currentInterface.name,
        Subject: "Assets for " + currentInterface.name,
        Author: "FDOT",
        CreatedDate: new Date()
    };

        const workSheet = XLSX.utils.json_to_sheet(exportAssets);

        const workSheetName = "Assets";
        const fileName= "Assets_" + currentInterface.name;

        XLSX.utils.book_append_sheet(wb, workSheet, workSheetName);
        const bin = XLSX.write(wb, { bookType: 'xlsx', type: "binary" });

        require("downloadjs")(bin, fileName, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
  };

  if (!currentInterface) return "";

  const getYesNo = (value) => {
    return value ? "Yes" : "No";
  };

  return (
    <>
      <div className="row">
        <div className="col-2 font-weight-bold">Name:</div>
        <div className="col">{currentInterface.name}</div>
        <div className="col-2 font-weight-bold">Status:</div>
        <div className="col">{currentInterface.status}</div>
        <div className="col-2 font-weight-bold">Type:</div>
        <div className="col">{currentInterface.type}</div>
      </div>
      {currentInterface.relatedApplication ? (
        <div className="row">
          <div className="col-2 font-weight-bold">Related Asset:</div>
          <div className="col">
            <a
              href={`/Inventory/${currentInterface.relatedApplication}`}
              title="Open Asset in new Tab"
              target="_blank"
              rel="noopener noreferrer"
            >
              Open Asset
            </a>
          </div>
        </div>
      ) : (
        <></>
      )}
      <div className="row">
        {currentInterface.team ? (
          <>
            <div className="col-2 font-weight-bold">Responsible Group:</div>
            <div className="col">{teams[currentInterface.team].name}</div>
          </>
        ) : (
          ""
        )}
        {currentInterface.subTeam ? (
          <>
            <div className="col-2 font-weight-bold">Responsible Sub-Group:</div>
            <div className="col">
              {
                teams[currentInterface.team].subTeams.find((s) => {
                  return s._id === currentInterface.subTeam;
                }).name
              }
            </div>
          </>
        ) : (
          ""
        )}
      </div>
      {!props.isSolution ? (
        <>
          <div className="row">
            <label className="col-2 font-weight-bold">Impacted By DIM:</label>
            <div className="col">
              {getYesNo(currentInterface.isImpactedByDim)}
            </div>
            <label className="col-2 font-weight-bold">Impacted By WPII:</label>
            <div className="col">
              {getYesNo(currentInterface.isImpactedByWpii)}
            </div>
            <label className="col-2 font-weight-bold">Impacted By PALM:</label>
            <div className="col">
              {getYesNo(currentInterface.isImpactedByPalm)}
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
      <div className="row">
        <div className="col-1 font-weight-bold">Description:</div>
        <div className="col">{currentInterface.description}</div>
      </div>
      <div className="row">
        <div className="col-1 font-weight-bold">Package Id:</div>
        <div className="col">{currentInterface.packageId}</div>
      </div>
      {currentInterface.type === "Service Bus" ? (
        <div className="row">
          <label className="col-1 font-weight-bold">Service Bus Type:</label>
          <div className="col">{currentInterface.serviceBusType}</div>
        </div>
      ) : (
        ""
      )}
      {currentInterface.type === "API" ? (
        <ViewApi operations={currentInterface.operations}></ViewApi>
      ) : (
        ""
      )}
      {currentInterface.type === "Database" ? (
        <ViewDatabase
          tables={currentInterface.tables}
          interface={currentInterface}
        ></ViewDatabase>
      ) : (
        ""
      )}
      {currentInterface.type === "Library" ? (
        <ViewLibrary classes={currentInterface.classes}></ViewLibrary>
      ) : (
        ""
      )}
      <br />
      <Card headerText="IT Asset Dependents Graph" collapsed={false}>
      <div className="row">
          <div className="col">
            <button className="btn btn-primary" onClick={exportApps}>
              Export
            </button>
          </div>
        </div>
        {dependentTree ? (
          <div className="dependentChart">
            <Graph data={dependentTree} config={graphConfig} id="Dependents" />
          </div>
        ) : (
          <></>
        )}
      </Card>
      <div className="row">
        <div className="col"></div>
      </div>
    </>
  );
}
