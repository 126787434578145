import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default function PreviousNext(props) {
    const className = props.inverseColors ? "btn btn-outline-light btn-sm" : "btn btn-primary btn-sm";
    return <>
        <button className={className} title="Previous Asset" onClick={props.onPrevious}>
            <FontAwesomeIcon icon="arrow-left" className="inverse"></FontAwesomeIcon>
        </button>
        &nbsp;&nbsp;
    <button className={className} title="Next Asset" onClick={props.onNext}>
            <FontAwesomeIcon icon="arrow-right" className="inverse"></FontAwesomeIcon>
        </button>
    </>
}
