
import React from 'react'
import { useSelector } from 'react-redux';
import localforage from 'localforage';
import routeConfig from '../routeConfig';

export default function NoMatch(props) {
  const user = useSelector(state => state.User.user);

  if (!user || !user.oid) {
    const url = window.location.href;
    localforage.setItem('deep-link-redirect-url', url).then(() => {
      window.location.href = `${routeConfig.loginUrl}`;
    });
  }

  return <div className="container">
    <div className="row">
      <div className="col">
        <h3>No route found for this url!</h3>
      </div>
    </div>
  </div>
}

