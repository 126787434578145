import { ApplicationInsights } from '@microsoft/applicationinsights-web';

let appInsights = null;
const loadAppInsights = (APP_INSIGHTS_KEY) => {
    if (appInsights)
        return;

    if (process.env.NODE_ENV === 'production') {
        appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: APP_INSIGHTS_KEY
            }
        });
        appInsights.loadAppInsights();
    } else {
        appInsights = {
            trackEvent: () => {

            },
            setAuthenticatedUserContext: () => {

            }
        }
    }
}

export {
    loadAppInsights,
    appInsights
};
