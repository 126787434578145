const graphConfig = {
    "automaticRearrangeAfterDropNode": false,
    "collapsible": true,
    "directed": false,
    "focusAnimationDuration": 0.75,
    "focusZoom": 1,
    "height": 500,
    "highlightDegree": 1,
    "highlightOpacity": 0.2,
    "linkHighlightBehavior": true,
    "maxZoom": 8,
    "minZoom": 0.1,
    "nodeHighlightBehavior": true,
    "panAndZoom": true,
    "staticGraph": false,
    "staticGraphWithDragAndDrop": false,
    "width": 1200,
    "d3": {
        "alphaTarget": 0.05,
        "gravity": -100,
        "linkLength": 100,
        "linkStrength": 1
    },
    "node": {
        "color": "#F3F3FF",
        "fontColor": "white",
        "fontSize": 12,
        "fontWeight": "normal",
        "highlightColor": "#FFFFFF",
        "highlightFontSize": 12,
        "highlightFontWeight": "bold",
        "highlightStrokeColor": "#007bff",
        "highlightStrokeWidth": 3,
        "labelProperty": "name",
        "mouseCursor": "pointer",
        "opacity": 1,
        "renderLabel": true,
        "size": 450,
        "strokeColor": "#2593B8",
        "strokeWidth": 1.5,
        "svg": "",
        "symbolType": "circle"
    },
    "link": {
        "color": "#2593B8",
        "fontColor": "red",
        "fontSize": 10,
        "fontWeight": "normal",
        "highlightColor": "#007bff",
        "highlightFontSize": 8,
        "highlightFontWeight": "bold",
        "mouseCursor": "pointer",
        "opacity": 1,
        "renderLabel": false,
        "semanticStrokeWidth": false,
        "strokeWidth": 4,
        "markerHeight": 6,
        "markerWidth": 6
    }
}

const decorateGraphNodesWithInitialPositioning = (nodes) => {
    return nodes.map(n =>
        Object.assign({}, n, {
            x: n.x || Math.floor(Math.random() * 500),
            y: n.y || Math.floor(Math.random() * 500),
        })
    );
};

export {
    decorateGraphNodesWithInitialPositioning,
    graphConfig
}