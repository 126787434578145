import React from 'react';
import { useSelector } from 'react-redux';
import store from '../../../Store';
import ViewAssessmentGroup from './ViewAssessmentGroup';
import QuickAssessmentsQuestions from './QuickAssessmentQuestions';

export default function ViewQuickAssessment(props) {
    const application = useSelector(state => store.select.Applications.currentApplication(state));

    if ((!application || !props.selectedAssessment)) {
        return <></>
    }

    if (props.selectedAssessment.useGrouping) {
        return <>
            {props.selectedAssessment.groups.map((g, i) => {
                return <div className="row" key={i}>
                    <div className="col">
                        <ViewAssessmentGroup group={g} assessmentId={props.selectedAssessment._id}>
                        </ViewAssessmentGroup>
                    </div>
                </div>
            })}
        </>
    }
    return <QuickAssessmentsQuestions questions={props.selectedAssessment.questions} assessmentId={props.selectedAssessment._id}>
    </QuickAssessmentsQuestions>
}
