import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import PreviousNext from '../../PreviousNext';
import Store from '../../../Store';
import { appInsights } from '../../../appInsights';
import ViewPalm from './ViewPalm';
import EditPalm from './EditPalm';
import ViewPalmImpactDetail from './ViewPalmImpactDetail';
import EditPalmImpactDetail from './EditPalmImpactDetail';
import SimplifiedAsset from '../../Application/SimplifiedAsset';
import EditSimplifiedAsset from '../../Application/EditSimplifiedAsset';
import useCanEditAsset from '../../../hooks/useCanEditAsset';
import usePreviousNextModal from '../../../hooks/usePreviousNextModal';
import FontAwesomeButton from '../../FontAwesomeButton';
import axios from 'axios';
import routeConfig from '../../../routeConfig';
import { key } from 'localforage';

export default function PalmAssessmentModal(props) {
    const dispatch = useDispatch();
    const applicationModalIsEditing = useSelector(state => state.Applications.applicationModalIsEditing);
    const showPalmAssessmentModal = useSelector(state => state.Applications.showPalmAssessmentModal);
    const currentApplicationId = useSelector(state => state.Applications.currentApplicationId);
    const currentApplication = useSelector(state => Store.select.Applications.currentApplication(state));
    const assetInfoOpen = useSelector(state => state.Applications.assetInfoOpen);
    const assessmentInfoOpen = useSelector(state => state.Applications.assessmentInfoOpen);
    const impactDetailOpen = useSelector(state => state.Applications.impactDetailOpen);
    const canEdit = useCanEditAsset();
    const {
        nextApp,
        previousApp,
        tabClicked,
        getTabView
    } = usePreviousNextModal();
    const [impactSuggestions, setImpactSuggestions] = useState([]);
    const [impactSuggestionOpen, setImpactSuggestionOpen] = useState(null);

    useEffect(() => {
        if (currentApplicationId && showPalmAssessmentModal) {
            dispatch.Applications.loadApplication(currentApplicationId);
            dispatch.Applications.setTabVisible('assessmentInfoOpen');

            appInsights.trackEvent({
                name: 'Asset Loaded',
                properties: {
                    assetName: currentApplication.name,
                    assetId: currentApplication._id
                }
            });
        }
        // eslint-disable-next-line
    }, [currentApplicationId, showPalmAssessmentModal]);

    useEffect(() => {
        axios.get(`${routeConfig.baseUrl}/ImpactSuggestion?query={"assessmentType":"PALM"}&select=name`).then(resp => {
            setImpactSuggestions(resp.data);
        });
    }, []);

    const toggleModal = () => {
        dispatch.Applications.changeWpiiAssessmentModalVisibility(!showPalmAssessmentModal)
    }

    const getCurrentView = () => {
        if (assessmentInfoOpen) {
            return getTabView(<EditPalm />, <ViewPalm />);
        }
        if (assetInfoOpen) {
            return getTabView(<EditSimplifiedAsset />, <SimplifiedAsset />);
        }
        if (impactDetailOpen) {
            return getTabView(<EditPalmImpactDetail impactType="PALM" arrayProperty="palmImpactedTables" impactSuggestion={impactSuggestionOpen} />,
                <ViewPalmImpactDetail arrayProperty="palmImpactedTables" impactSuggestion={impactSuggestionOpen} />)
        }
        return <></>
    }

    const openAsset = () => {
        window.open(`/Inventory/${currentApplicationId}`);
    }

    const palmTabClicked = (propName, id) => {
        setImpactSuggestionOpen(id);
        tabClicked(propName, id);
    }

    const onEdit = () => {
        dispatch.Applications.changeApplicationModalIsEditing(true);
        appInsights.trackEvent({
            name: 'Assessment Edit Clicked',
            properties: {
                appName: currentApplication.name,
                appId: currentApplication._id
            }
        });
    }

    const getClassName = (propName, id) => {
        if ((propName === 'assessmentInfoOpen' && assessmentInfoOpen) ||
            (propName === 'assetInfoOpen' && assetInfoOpen && id == impactSuggestionOpen)) {
            return "nav-link btn btn-link active "
        }
        return "nav-link btn btn-link"
    }

    const getNavItem = (propName, labelName, id) => {
        return <li className={`nav-item ${labelName === 'IT Asset Info' ? 'ml-auto' : ''}`} key={labelName}>
            <button type="button" className={getClassName(propName, id)}
                onClick={() => palmTabClicked(propName, id)}>
                {labelName}
            </button>
        </li>
    }

    return <Modal isOpen={showPalmAssessmentModal} size="lg">
        <ModalHeader className="py-2 bg-primary text-white" toggle={toggleModal}>
            {!applicationModalIsEditing ? <PreviousNext onPrevious={previousApp} onNext={nextApp} inverseColors={true}></PreviousNext> : ''}
            &nbsp;&nbsp;&nbsp;
            <span>
                {currentApplication ? currentApplication.name : ''}
            </span>
            <FontAwesomeButton icon='share-square' title='Open Asset' onClick={openAsset}></FontAwesomeButton>
        </ModalHeader>
        <ModalBody className="pt-1 bg-light">
            <>
                <nav className="nav nav-tabs d-flex">
                    {getNavItem('assessmentInfoOpen', 'PALM Triage Assessment')}
                    {currentApplication && currentApplication.palmIsImpacted === 'Yes' ?
                        impactSuggestions.map(is => {
                            return getNavItem('impactDetailOpen', is.name, is._id)
                        })
                        : <></>}
                    {getNavItem('assetInfoOpen', 'IT Asset Info')}
                </nav>
                {!applicationModalIsEditing && canEdit === true ?
                    <div className="row my-0">
                        <div className="col text-right">
                            <button className="btn btn-link" onClick={onEdit}>Edit</button>
                        </div>
                    </div> : ''}
                {getCurrentView()}

                {!applicationModalIsEditing ?
                    <div>
                        <PreviousNext onPrevious={previousApp} onNext={nextApp}></PreviousNext>
                    </div> : ''}
            </>
        </ModalBody>
    </Modal>
}
