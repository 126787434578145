import React, { useEffect, useState } from 'react';
import { useSelector, useStore } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from "axios";
import routeConfig from '../../routeConfig';
import { Graph } from "react-d3-graph";
import Card from '../Card';
import {
    decorateGraphNodesWithInitialPositioning,
    graphConfig
} from '../../helpers/graphHelper';
import d from 'final-form-arrays';

export default function AssetDependencyGraph(props) {
    const store = useStore();
    const [data, setData] = useState(null);
    const application = useSelector(state => store.select.Applications.currentApplication(state));

    useEffect(() => {
        axios.get(`${routeConfig.baseUrl}/Application/${application._id}/Dependencies`).then(res => {
            const data = {
                nodes: [],
                links: [],
                children: [],
                name: application.name,
                key: application._id
            };

            data.nodes.push({
                id: application._id,
                name: application.name,
                symbolType: 'star'
            })

            for (let app of res.data) {
                data.nodes.push({
                    id: app._id,
                    name: app.name
                });

                data.links.push({
                    source: application._id,
                    target: app._id
                })

            }
            data.nodes = decorateGraphNodesWithInitialPositioning(data.nodes)
            setData(data);
        })
    }, []);

    if (!data) {
        return <h3>
            <div className="spinner-border text-primary" role="status">
                <span className="sr-only">Loading...</span>
            </div>&nbsp;&nbsp;Application Dependencies are Loading...
                        </h3>
    }

    return <>
        <div className="row">
            <div className="col">
                <ul className="list-group">
                    {data && data.nodes ? data.nodes.map((n, i) => {
                        if (i === 0) {
                            return <React.Fragment key={i}></React.Fragment>
                        }
                        return <li key={i} className="list-group-item">
                            {n.name} &nbsp;&nbsp;
                            <a className="btn-primary btn" target="_blank" rel="noopener noreferrer"
                                href={`./Inventory/${n.id}`}>
                                <FontAwesomeIcon icon="share-square" />
                            </a>
                        </li>
                    }) : <></>}
                </ul>
            </div>
        </div>
        <Card headerText="Related Assets Graph">
            <div className="row">
                <div className="col">
                    <div className="dependentChart" >
                        <Graph data={data}
                            config={graphConfig} id="Dependents" />
                    </div >
                </div>
            </div>
        </Card>
    </>
}