import React from "react";
import { useSelector } from 'react-redux';
import Store from '../../../Store';
import { format as formatPhone } from 'phone-fns'

export default function ViewRoles(props) {
    const currentApplication = useSelector(state => Store.select.Applications.currentApplication(state));

    const getViewPeople = (persons, role) => {
        let people = [];
        if (persons) {
            persons.forEach(p => {
                if (p !== null) {
                    people.push({
                        name: `${p.firstName} ${p.lastName} (${p.racfId})`,
                        phoneNumber: p.phoneNumber,
                        emailAddress: p.emailAddress,
                        role: role
                    });
                }
            });
        }

        return people;
    };

    const getPeople = () => {
        let people = [];
        people = people.concat(getViewPeople(currentApplication.wpiiImpactResponsibleStaff, 'WPII Impact Responsible Staff'));
        people = people.concat(getViewPeople(currentApplication.contactPersons, 'Initial Assessment Contact'));
        people = people.concat(getViewPeople(currentApplication.supporters, 'Supporter'));
        people = people.concat(getViewPeople(currentApplication.wpiiLiasons, 'WPII Liaison'));
        people = people.concat(getViewPeople(currentApplication.updatePersons, 'Asset Updater'));

        return people;
    };


    if (!currentApplication) {
        return <div></div>
    }

    return <table className="table table-striped table-bordered table-sm table-hover my-1" >
        <thead className="thead-light">
            <tr>
                <th>Role</th>
                <th>Name</th>
                <th>Email</th>
                <th>Phone Number</th>
            </tr>
        </thead>
        <tbody>
            {getPeople().sort((a, b) => {
                if (a.role === b.role) {
                    return a.name < b.name ? -1 : 1;
                }
                return a.role < b.role ? -1 : 1;
            }).map((p, i) => {
                return <tr key={i}>
                    <td>{p.role}</td>
                    <td>{p.name}
                        {p.emailAddress ? <a target="_blank" title={`Chat with ${p.name} in Teams`}
                            href={`https://teams.microsoft.com/l/chat/0/0?users=${p.emailAddress}`}>
                            <img src='./Teams-32x32.png'></img></a> : ''}
                    </td>
                    <td>{p.emailAddress}</td>
                    <td>{formatPhone('(NNN) NNN-NNNN', p.phoneNumber)}</td>
                </tr>
            })
            }
        </tbody>
    </table >
}
