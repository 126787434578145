import React from 'react';

export default function NotAuthorized(props) {
    return <div className="row">
        <div className="">
            You do not have access to the call you made. Please Login if you have not already!
        </div>

    </div>
}

