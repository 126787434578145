export const ApplicationModal = {
    state: {
        currentApplicationId: null,
        canEdit: true,
        showApplicationModal: false,
        applicationModalIsEditing: false
    },
    reducers: {
        setCurrentApplicationId(state, id) {
            var s = {
                ...state,
                currentApplicationId: id
            };

            return s;
        },
        changeApplicationModalVisibility(state, show) {
            return {
                ...state,
                showApplicationModal: show
            }
        },
        test(state) {
            return state
        },
        changeApplicationModalIsEditing(state, isEditing) {
            return {
                ...state,
                applicationModalIsEditing: isEditing
            }
        },
    },
    selectors: {
        currentApplication() {
            return (rootState, props) => {
                return rootState.Applications.applications[rootState.ApplicationModal.currentApplicationId];
            }
        }
    },
    effects: {

    }
}
