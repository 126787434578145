import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import { hot, AppContainer } from 'react-hot-loader'


const rootEl = document.getElementById('root');
const Root = () => (
    <AppContainer>
        <App />
    </AppContainer>
);

ReactDOM.render(<Root />, rootEl);

if (module.hot) {
    let render = () => {
        try {
            const NextApp = require('./App').default;
            ReactDOM.render(
                <NextApp loadMetadata={false} />,
                rootEl
            );
        }
        catch (error) {
            console.log(error);
        }
    }

    module.hot.accept('./App', () => {
        render();
    });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();


export default hot(module)(App)
