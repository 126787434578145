import React, { useState } from "react";
import Card from "../Card";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import Store from '../../Store';

function InterfaceDependencyList(props) {
    const [interfaceSelected, setInterfaceSelected] = useState('');

    if (!props.assetInterfaces)
        return '';

    return <Card headerText="Dependencies" collapsed={true}>
        <div className="row">
            <div className="col text-right">
                <div className="input-group">
                    <select className="form-control" value={interfaceSelected} onChange={e => setInterfaceSelected(e.target.value)}>
                        <option value=''>Select</option>
                        {props.databaseInterfaces.map((dI, i) => {
                            return <option value={dI._id} key={i}>
                                {dI.name}
                            </option>
                        })}
                    </select>
                    <div className="input-group-append">
                        <button className="btn btn-primary" title="Add Interface"
                            disabled={interfaceSelected === ''}
                            onClick={() => props.addToNestedArrayProperty(props.parentArrayName, props.parentIndex, 'dependencies', { interface: interfaceSelected })}>
                            <FontAwesomeIcon icon="plus"></FontAwesomeIcon>
                            &nbsp;&nbsp;Interface
                </button>
                    </div>
                </div>

            </div>
        </div>
        <div className="row">
            <div className="col">
                <table className="table table-striped table-bordered table-sm">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Use</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.dependencies ? props.dependencies.map((dependency, i) => {
                            return <tr key={i}>
                                <td>
                                    {props.assetInterfaces[dependency.interface].name}
                                </td>
                                <td>
                                    <textarea name="" id="" className="form-control"
                                        value={dependency.use}
                                        onChange={e => props.setStateArrayofArrayProperty(props.parentArrayName, props.parentIndex, 'dependencies', i, 'use', e)}></textarea>
                                </td>
                                <td></td>
                            </tr>
                        }) : ''}
                    </tbody>
                </table>
            </div>
        </div>
    </Card>
}

const mapState = state => {
    return {
        assetInterfaces: state.AssetInterface.assetInterfaces,
        databaseInterfaces: Store.select.AssetInterface.databaseInterfaces(state),
        currentInterfaceId: state.AssetInterface.currentInterfaceId,
    }
};

export default connect(mapState)(InterfaceDependencyList)
