import React, { useEffect, Suspense, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import SimplifiedAsset from './SimplifiedAsset';
import PreviousNext from '../PreviousNext';
import EditSimplifiedAsset from "./EditSimplifiedAsset";
import Store from '../../Store';
import ViewAssetComponents from './AssetComponents/ViewAssetComponents';
import EditAssetComponents from './AssetComponents/EditAssetComponents';
import ViewInterfaceDependencies from './Dependencies/ViewInterfaceDependencies';
import EditInterfaceDependencies from './Dependencies/EditInterfaceDependencies';
import ViewDocuments from './Documents/ViewDocuments';
import EditDocuments from './Documents/EditDocuments';
import ViewRoles from './Roles/ViewRoles';
import EditRoles from './Roles/EditRoles';
import { appInsights } from '../../appInsights';
import EditComponentsRelationships from '../Assessment/Wpii/EditComponentsRelationships';
import ViewComponentsRelationships from '../Assessment/Wpii/ViewComponentsRelationships';
import ViewData from './Data/ViewData';
import EditData from './Data/EditData';
import Corrections from './Corrections/Corrections';
import useCanEditAsset from '../../hooks/useCanEditAsset';
import usePreviousNextModal from '../../hooks/usePreviousNextModal';
import EditCompletionCertification from './EditCompletionCertification';
import History from './History';
import FontAwesomeButton from '../FontAwesomeButton';
import ViewCmdb from './Cmdb/ViewCmdb';

const ViewTechnicalInformation = React.lazy(() => import('./TechnicalInformation/ViewTechnicalInformation'));
const EditTechnicalInformation = React.lazy(() => import('./TechnicalInformation/EditTechnicalInformation'));

export default function ApplicationModal(props) {
    const dispatch = useDispatch();

    const certify = useSelector(state => state.Applications.certify);
    const applicationModalIsEditing = useSelector(state => state.Applications.applicationModalIsEditing);
    const showApplicationModal = useSelector(state => state.Applications.showApplicationModal);
    const currentApplicationId = useSelector(state => state.Applications.currentApplicationId);
    const isCreatingNewApplication = useSelector(state => state.Applications.isCreatingNewApplication);
    const currentApplication = useSelector(state => Store.select.Applications.currentApplication(state));
    const currentApplicationCompletion = useSelector(state => Store.select.Applications.currentApplicationCompletion(state));
    const componentsOpen = useSelector(state => state.Applications.componentsOpen);
    const assetInfoOpen = useSelector(state => state.Applications.assetInfoOpen);
    const interfaceOpen = useSelector(state => state.Applications.interfaceOpen);
    const relatedAssetsOpen = useSelector(state => state.Applications.relatedAssetsOpen);
    const technicalInfoOpen = useSelector(state => state.Applications.technicalInfoOpen);
    const dataInfoOpen = useSelector(state => state.Applications.dataInfoOpen);
    const documentsOpen = useSelector(state => state.Applications.documentsOpen);
    const correctionsOpen = useSelector(state => state.Applications.correctionsOpen);
    const historyOpen = useSelector(state => state.Applications.historyOpen);
    const cmdbOpen = useSelector(state => state.Applications.cmdbOpen);
    const interfacesToComponentsOpen = useSelector(state => state.Applications.interfacesToComponentsOpen);
    const rolesOpen = useSelector(state => state.Applications.rolesOpen);
    const user = useSelector(state => state.User.user);
    const menuConfiguration = useSelector(state => state.ApplicationLoadState.menuConfiguration);
    const canEdit = useCanEditAsset();
    const quickAssessments = useSelector(state => state.QuickAssessment.quickAssessments);
    const [assessments, setAssessments] = useState([]);
    const [selectedAssessment, setSelectedAssessment] = useState('');
    const {
        nextApp,
        previousApp,
        tabClicked,
        getTabView,
        gridRows
    } = usePreviousNextModal();

    useEffect(() => {
        dispatch.AssetInterface.getInterfaces();
        dispatch.PpmProject.getProjects();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (quickAssessments) {
            const assessments = [{ label: 'Cloud First Assessment', route: 'CloudFirst' },
            { label: 'WPII Assessment', route: 'Wpii' }];

            for (let assessment of Object.values(quickAssessments).filter(q => {
                return !q.isObsolete;
            })) {
                assessments.push({ label: assessment.name, route: `Quick/${assessment._id}` });
            }

            assessments.sort((a, b) => {
                return a.label - b.label;
            });

            setAssessments(assessments);
        }
    }, [quickAssessments]);

    useEffect(() => {
        if (currentApplicationId && showApplicationModal) {
            dispatch.Applications.setTabVisible('assetInfoOpen');
            if (!isCreatingNewApplication) {
                dispatch.Applications.loadApplication(currentApplicationId);
                appInsights.trackEvent({
                    name: 'Asset Loaded',
                    properties: {
                        assetName: currentApplication.name,
                        assetId: currentApplication._id
                    }
                });
            }
        }
        // eslint-disable-next-line
    }, [currentApplicationId, showApplicationModal]);

    const isTabComplete = (tab) => {
        if (currentApplicationCompletion && currentApplicationCompletion.tabCompletion[tab] && currentApplicationCompletion.tabCompletion[tab][new Date().getFullYear()]
            && currentApplicationCompletion.tabCompletion[tab][new Date().getFullYear()].isComplete) {
            return true;
        }

        return false;
    }

    const toggleModal = () => {
        dispatch.Applications.changeApplicationModalIsEditing(false);
        dispatch.Applications.changeApplicationModalVisibility(!showApplicationModal)
    }

    const openAsset = () => {
        window.open(`/Assessment/${selectedAssessment}/${currentApplicationId}`);
    }

    const getCurrentView = () => {
        if (assetInfoOpen) {
            return getTabView(<EditSimplifiedAsset />, <SimplifiedAsset />);
        }
        if (componentsOpen) {
            return getTabView(<EditAssetComponents />, <ViewAssetComponents />);
        }
        if (interfaceOpen) {
            return getTabView(<EditInterfaceDependencies />, <ViewInterfaceDependencies />);
        }
        if (technicalInfoOpen) {
            return getTabView(<EditTechnicalInformation />, <ViewTechnicalInformation />);
        }
        if (documentsOpen) {
            return getTabView(<EditDocuments />, <ViewDocuments />);
        }
        if (rolesOpen) {
            return getTabView(<EditRoles />, <ViewRoles />);
        }
        if (interfacesToComponentsOpen) {
            return getTabView(<EditComponentsRelationships />, <ViewComponentsRelationships />);
        }
        if (dataInfoOpen) {
            return getTabView(<EditData />, <ViewData />);
        }
        if (correctionsOpen) {
            return <Corrections></Corrections>
        }
        if (historyOpen) {
            return <History></History>
        }
        if (cmdbOpen) {
            return <ViewCmdb></ViewCmdb>
        }

        return <></>
    }

    const onEdit = () => {
        dispatch.Applications.changeApplicationModalIsEditing(true);
        appInsights.trackEvent({
            name: 'Asset Edit Clicked',
            properties: {
                appName: currentApplication.name,
                appId: currentApplication._id
            }
        });
    }

    const getClassName = (propName) => {
        if ((propName === 'assetInfoOpen' && assetInfoOpen) ||
            (propName === 'interfaceOpen' && interfaceOpen) ||
            (propName === 'componentsOpen' && componentsOpen) ||
            (propName === 'relatedAssetsOpen' && relatedAssetsOpen) ||
            (propName === 'documentsOpen' && documentsOpen) ||
            (propName === 'rolesOpen' && rolesOpen) ||
            (propName === 'dataInfoOpen' && dataInfoOpen) ||
            (propName === 'interfacesToComponentsOpen' && interfacesToComponentsOpen) ||
            (propName === 'correctionsOpen' && correctionsOpen) ||
            (propName === 'historyOpen' && historyOpen) ||
            (propName === 'technicalInfoOpen' && technicalInfoOpen)) {
            return "nav-link btn btn-link active "
        }
        return "nav-link btn btn-link"
    }

    const getNavItem = (propName, labelName) => {
        if (!currentApplication) {
            return;
        }

        if (isCreatingNewApplication && propName !== 'assetInfoOpen') {
            return;
        }
        if (currentApplication.relatedInterface) {
            if (propName === 'dataInfoOpen' || propName === 'interfaceOpen' ||
                propName === 'componentsOpen' || propName === 'interfacesToComponentsOpen') {
                return;
            }
        }
        if (propName === "componentsOpen" && !(menuConfiguration.isAssetComponentsActive ||
            (user.isOit && menuConfiguration.isAssetComponentsActiveToOit))) {
            return;
        }
        if (propName === "interfaceOpen" && !(menuConfiguration.isAssetInterfaceActive ||
            (user.isOit && menuConfiguration.isAssetInterfaceActiveToOit))) {
            return;
        }
        if (propName === "technicalInfoOpen" && !(menuConfiguration.isAssetSupplementalActive ||
            (user.isOit && menuConfiguration.isAssetSupplementalActiveToOit))) {
            return;
        }
        if (propName === "documentsOpen" && !(menuConfiguration.isDocumentsActive ||
            (user.isOit && menuConfiguration.isDocumentsActiveToOit))) {
            return;
        }
        if (propName === "dataInfoOpen" && !(menuConfiguration.isDataActive ||
            (user.isOit && menuConfiguration.isDataActiveToOit))) {
            return;
        }
        if (propName === "interfacesToComponentsOpen" && !(menuConfiguration.isInterfacesToComponentsActive ||
            (user.isOit && menuConfiguration.isInterfacesToComponentsActiveToOit))) {
            return;
        }

        let light = <></>;

        if (labelName !== "Corrections" && labelName !== 'History' && labelName !== 'Documents' && labelName !== 'Roles' && labelName !== 'CMDB') {
            light = isTabComplete(labelName) ?
                <div className="led-green-mini" title={`${labelName} tab is certified complete.`}>&nbsp;</div> :
                <div className="led-red-mini" title={`${labelName} tab is not certified complete.`}>&nbsp;</div>;
        }

        return <li className="nav-item">
            <button type="button" className={getClassName(propName)}
                onClick={() => tabClicked(propName)}>
                {light}
                {labelName}
            </button>
        </li>
    }

    return <Modal isOpen={showApplicationModal} size="lg">
        <ModalHeader className="py-2 bg-primary text-white w-100" toggle={toggleModal}>
            {!applicationModalIsEditing && gridRows ? <PreviousNext onPrevious={previousApp} onNext={nextApp} inverseColors={true}></PreviousNext> : ''}
            &nbsp;&nbsp;&nbsp;
                     <span>
                {currentApplication ? currentApplication.name : ''}
            </span>
            {!isCreatingNewApplication ?
                <div className="float-right" style={{ marginLeft: 50 }}>
                    <div className="input-group">
                        <select className="form-control" value={selectedAssessment} onChange={e => setSelectedAssessment(e.target.value)}>
                            <option value=''>Link to Assessment</option>
                            {assessments.map((a, i) => {
                                return <option key={i} value={a.route}>{a.label}</option>
                            })}
                        </select>
                        <div className="input-group-append">
                            <FontAwesomeButton icon='share-square' title='Open Asset' onClick={openAsset} disabled={selectedAssessment === ''}></FontAwesomeButton>
                        </div>
                    </div>
                </div> : <></>
            }
        </ModalHeader>
        <ModalBody className="pt-1 bg-light">
            <>
                <nav className="nav nav-tabs">
                    {getNavItem('assetInfoOpen', 'Asset')}
                    {getNavItem('technicalInfoOpen', 'Architecture')}
                    {getNavItem('dataInfoOpen', 'Data')}
                    {getNavItem('componentsOpen', 'Components')}
                    {getNavItem('interfaceOpen', 'Interfaces')}
                    {getNavItem('interfacesToComponentsOpen', 'Component Relationships')}
                    {getNavItem('documentsOpen', 'Documents')}
                    {getNavItem('rolesOpen', 'Roles')}
                    {getNavItem('cmdbOpen', 'CMDB')}
                    {getNavItem('correctionsOpen', 'Corrections')}
                    {getNavItem('historyOpen', 'History')}
                </nav>
                {!applicationModalIsEditing && canEdit === true && correctionsOpen === false && historyOpen === false && cmdbOpen === false ?
                    <div className="row my-0">
                        <div className="col text-right">
                            {certify === false ?
                                <>
                                    <button className="btn btn-link" onClick={() => dispatch.Applications.setCertify(true)}>Certify Tab Complete</button>
                                    <button className="btn btn-link" onClick={onEdit}>Edit</button></>
                                : <>
                                    <button className="btn btn-link" onClick={() => dispatch.Applications.setCertify(false)}>Exit Certify Tab Complete</button>
                                </>}
                        </div>
                    </div> :
                    <div className="row">
                        <div className="col"></div>
                    </div>
                }
                <Suspense fallback={<h3>
                    <div className="spinner-border text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>&nbsp;&nbsp;Architecture Is Loading...
                        </h3>}>

                    {certify === false ? getCurrentView() : <></>}
                </Suspense>
                {certify === true ?
                    <>
                        <br />
                        <EditCompletionCertification></EditCompletionCertification>
                        <br />
                    </>
                    : <></>}
                {!applicationModalIsEditing && gridRows ?
                    <div>
                        <PreviousNext onPrevious={previousApp} onNext={nextApp}></PreviousNext>
                    </div>
                    : ''}
            </>
        </ModalBody>
    </Modal >
}
