import axios from 'axios';
import { toastr } from 'react-redux-toastr'
import routeConfig from '../routeConfig';
export const ApplicationPlatform = {
    state: {
        currentId: null,
        applicationPlatforms: {

        },
    },
    reducers: {
        loadApplicationPlatforms(state, applicationPlatforms) {
            let loadedApplicationPlatforms = {};
            for (let i = 0; i < applicationPlatforms.length; i++) {
                loadedApplicationPlatforms[applicationPlatforms[i]._id] = applicationPlatforms[i];
            }
            return { ...state, applicationPlatforms: loadedApplicationPlatforms };
        },
        addApplicationPlatform(state, applicationPlatform) {
            return {
                ...state, applicationPlatforms: {
                    ...state.applicationPlatforms, [applicationPlatform._id]: applicationPlatform
                }, currentId: applicationPlatform._id
            }
        },
        saveApplicationPlatform(state, applicationPlatform) {
            return {
                ...state, applicationPlatforms: {
                    ...state.applicationPlatforms, [applicationPlatform._id]: {
                        ...state.applicationPlatforms[applicationPlatform._id], ...applicationPlatform
                    }
                }
            }
        },
        setCurrentId(state, id) {
            return { ...state, currentId: id };
        }
    },
    effects: {
        async getApplicationPlatforms() {
            const resp = await axios.get(`${routeConfig.baseUrl}/applicationPlatform`);
            this.loadApplicationPlatforms(resp.data);
        },
        async persistNewApplicationPlatform(applicationPlatform, rootState) {
            const response = await axios.post(`${routeConfig.baseUrl}/applicationPlatform`, applicationPlatform);
            this.addApplicationPlatform(response.data);
            toastr.success('New Application Platform Added')
        },
        async persistApplicationPlatform(payload, rootState) {
            const response = await axios.put(`${routeConfig.baseUrl}/applicationPlatform/${rootState.ApplicationPlatform.currentId}`,
                rootState.ApplicationPlatform.applicationPlatforms[rootState.ApplicationPlatform.currentId]);
            this.addApplicationPlatform(response.data);
            toastr.success(`${response.data.name} Saved`);
        },
        async loadApplicationPlatform(id) {
            const response = await axios.get(`${routeConfig.baseUrl}/applicationPlatform/${id}`);
            this.addApplicationPlatform(response.data);
        }
    }
}
