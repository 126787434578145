import React, { useState } from 'react';
import { useSelector, useStore, useDispatch } from "react-redux";
import SaveCancel from '../SaveCancel';
import ApplicationPlatformOptions from '../ApplicationPlatformOptions';
import AddButton from '../AddButton';
import DeleteButton from '../DeleteButton';
import { useNestedArrayState } from '../../hooks/useNestedArrayState';

export default function EditSolutionApplicationPlatforms() {
    const dispatch = useDispatch();
    const store = useStore();
    const [selectedApplicationPlatform, setSelectedApplicationPlatform] = useState('');
    const applicationPlatforms = useSelector(state => state.ApplicationPlatform.applicationPlatforms);
    const currentInterface = useSelector(state => store.select.AssetInterface.currentAssetInterface(state));

    const {
        state,
        addToArrayProperty,
        removeFromArrayProperty,
    } = useNestedArrayState(currentInterface);

    const save = () => {
        dispatch.AssetInterface.saveInterface(state);
    }

    return <>
        <div className="row">
            <div className="col-6"></div>
            <div className="col">
                <div className="input-group">
                    <select className="form-control" value={selectedApplicationPlatform}
                        onChange={e => setSelectedApplicationPlatform(e.target.value)}>
                        <ApplicationPlatformOptions></ApplicationPlatformOptions>
                    </select>
                    <div className="input-group-append">
                        <AddButton onClick={() => addToArrayProperty('applicationPlatforms', selectedApplicationPlatform)} text="Application Platform"></AddButton>
                    </div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col">
                <table className="table table-striped table-bordered">
                    <thead>
                        <tr>
                            <th>Platform</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {state.applicationPlatforms ? state.applicationPlatforms.map((platform, i) => {
                            return <tr key={i}>
                                <td>{applicationPlatforms[platform].name}</td>
                                <td><DeleteButton onClick={() => removeFromArrayProperty('applicationPlatforms', i)} hideText={true}></DeleteButton></td>
                            </tr>
                        }) : ''}
                    </tbody>
                </table>
            </div>
        </div>
        <div className="row">
            <div className="col">
                <SaveCancel onSaveClick={save} onCancelClick={() => dispatch.AssetInterface.changeApplicationModalIsEditing(false)}></SaveCancel>
            </div>
        </div>
    </>
}
