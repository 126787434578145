import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import TeamOptions from '../TeamOptions';
import routeConfig from '../../routeConfig';
import axios from 'axios';
import Card from '../Card';
import FontAwesomeButton from '../FontAwesomeButton';
import { appInsights } from '../../appInsights';
import { format } from 'date-fns';
import DeepCopy from 'deep-copy';
import { useParams } from "react-router-dom";

export default function QuickAssessmentReport(props) {
    const { id } = useParams();
    const [teamFilter, setTeamFilter] = useState('');
    const [subTeamFilter, setSubTeamFilter] = useState('');
    const teams = useSelector(state => state.Teams.teams);

    const [assessment, setAssessment] = useState(null);
    const [apps, setApps] = useState([])
    const quickAssessments = useSelector(state => state.QuickAssessment.quickAssessments);
    const [additionalFilters, setAdditionalFilters] = useState({});

    useEffect(() => {
        if (id && quickAssessments) {
            setAssessment(quickAssessments[id]);
        }
    }, [id, quickAssessments]);

    const checkAdditionalFilters = (app, filterKeys, arrayFilter) => {
        for (let i = 0; i < filterKeys.length; i++) {
            const key = filterKeys[i];
            if (additionalFilters[key] === undefined || additionalFilters[key] === 'Select') {
                continue;
            }

            if (arrayFilter[key] && app.quickAssessments && app.quickAssessments[assessment._id]) {
                app.quickAssessments[assessment._id][arrayFilter[key]] = app.quickAssessments[assessment._id][arrayFilter[key]]
                    .filter(row => {
                        return row[key] === additionalFilters[key];
                    });

                if (!app.quickAssessments[assessment._id][arrayFilter[key]] ||
                    app.quickAssessments[assessment._id][arrayFilter[key]].length === 0) {
                    return false;
                }
            } else if (app.quickAssessments && app.quickAssessments[assessment._id]) {
                const itemValue = app.quickAssessments[assessment._id][key];

                if (additionalFilters[key] === "" && (!app.quickAssessments || app.quickAssessments[assessment._id] === undefined ||
                    itemValue === undefined)) {
                    continue;
                }

                if (app.quickAssessments && app.quickAssessments[assessment._id] &&
                    itemValue === additionalFilters[key]) {
                    continue;
                }
                return false;
            } else {
                return false;
            }
        }
        return true;
    }

    const filteredApps = useMemo(() => {
        if (!assessment) {
            return [];
        }

        const arrayFilter = {};

        if (assessment.useGrouping) {
            for (let group of assessment.groups.filter(g => {
                return g.isArray === true;
            })) {
                for (let q of group.questions) {
                    if (q.isFilter === true) {
                        arrayFilter[q._id] = group._id;
                    }
                }
            }
        }

        const filteredApps = [];
        for (let app of apps) {
            const a = DeepCopy(app);
            if (teamFilter !== '' && a.team !== teamFilter) {
                continue;
            }

            if (subTeamFilter && a.subTeam !== subTeamFilter) {
                continue;
            }

            var filterKeys = Object.keys(additionalFilters);
            const meetsCriteria = checkAdditionalFilters(a, filterKeys, arrayFilter);

            if (meetsCriteria) {
                filteredApps.push(a);
            }
        };

        return filteredApps;
        // eslint-disable-next-line
    }, [teamFilter, subTeamFilter, additionalFilters, assessment, apps]);

    const getChunkedQuestions = useMemo(() => {
        if (!assessment) {
            return [];
        }
        const chunks = [];
        let arr = [];
        let filteredQuestions = [];
        if (assessment.useGrouping) {
            for (let group of assessment.groups) {
                let groupQuestions = group.questions.filter(q => {
                    return (q.questionType === 'Dropdown' || q.questionType === 'MultiSelect') &&
                        q.hideChart !== true;
                });

                if (groupQuestions.length > 0) {
                    groupQuestions = groupQuestions.map((q) => {
                        return {
                            ...q,
                            isArray: group.isArray,
                            groupId: group._id
                        }
                    });
                    filteredQuestions = [...filteredQuestions, ...groupQuestions];
                }
            }
        } else {
            filteredQuestions = assessment.questions.filter(q => {
                return (q.questionType === 'Dropdown' || q.questionType === 'MultiSelect') &&
                    q.hideChart !== true;
            });
        }

        for (var i = 0; i < filteredQuestions.length; i++) {
            if (arr.length === 3) {
                chunks.push(arr);
                arr = [];
            }
            arr.push(filteredQuestions[i]);
        }
        if (arr.length > 0) {
            chunks.push(arr);
        }
        return chunks;
    }, [assessment])

    const getData = (question) => {
        const results = {}
        for (let app of filteredApps) {
            if (app.quickAssessments && app.quickAssessments[assessment._id]) {
                let values;
                let val = [];
                if (question.isArray === true) {
                    if (app.quickAssessments[assessment._id][question.groupId]) {
                        val = app.quickAssessments[assessment._id][question.groupId].map(g => {
                            return g[question._id];
                        });

                    }
                } else if (app.quickAssessments[assessment._id][question._id] && app.quickAssessments[assessment._id][question._id] !== 'Select') {
                    val = app.quickAssessments[assessment._id][question._id]
                } else {
                    continue;
                }

                if (Array.isArray(val)) {
                    values = val;
                } else {
                    values = [app.quickAssessments[assessment._id][question._id]];
                }

                for (let val of values) {
                    if (!results[val]) {
                        results[val] = 1;
                    } else {
                        results[val] += 1;
                    }
                }
            }
            if (question.showEmpty && (!app.quickAssessments || !app.quickAssessments[assessment._id]
                || !app.quickAssessments[assessment._id][question._id] || !app.quickAssessments[assessment._id][question._id] == '')) {
                if (results[question.emptyText]) {
                    results[question.emptyText] += 1;
                } else {
                    results[question.emptyText] = 1;
                }
            }
        }

        const data = Object.keys(results).map((key, i) => {
            return {
                answer: key,
                count: results[key]
            }
        }).sort((a, b) => {
            if (a.answer.toUpperCase() < b.answer.toUpperCase()) { return -1; }
            if (a.answer.toUpperCase() > b.answer.toUpperCase()) { return 1; }
            return 0;
        });

        return data;
    }

    const updateAdditionalFilters = (question, e) => {
        setAdditionalFilters({ ...additionalFilters, [question._id]: e.target.value });
    }

    const getAdditionalFilters = () => {
        const elements = [];
        if (assessment) {
            let filterQuestions = [];
            if (assessment.useGrouping) {
                for (let group of assessment.groups) {
                    let groupQuestions = group.questions.filter(q => {
                        return q.isFilter === true;
                    });

                    if (groupQuestions.length > 0) {
                        if (group.isArray === true) {
                            groupQuestions = groupQuestions.map(question => {
                                return {
                                    ...question,
                                    isArray: true,
                                    groupId: group._id
                                };
                            })
                        }
                        filterQuestions = [...filterQuestions, ...groupQuestions];
                    }
                }
            }
            else if (assessment.questions) {
                filterQuestions = assessment.questions.filter(q => {
                    return q.isFilter === true;
                });
            }


            if (filterQuestions) {
                let filterCols = [];
                for (let i = 0; i < filterQuestions.length; i++) {
                    filterCols.push(<React.Fragment key={i}>
                        <div className="col-2 font-weight-bold">
                            {filterQuestions[i].text}:
                        </div>
                        <div className="col">
                            <select className="form-control"
                                onChange={e => updateAdditionalFilters(filterQuestions[i], e)}>
                                <option value='Select'>All</option>
                                {filterQuestions[i].options.map((option, i) => {
                                    return <option key={i} value={option}>{option}</option>
                                })}
                            </select>
                        </div></React.Fragment>
                    );

                    if (i === filterQuestions.length - 1 || (i + 1) % 2 === 0) {
                        if (i === filterQuestions.length - 1 && (i + 1) % 2 !== 0) {
                            filterCols.push(<div className="col-6" key={filterQuestions[i]._id}></div>)
                        }
                        elements.push(<div className="row" key={`row${i}`}>
                            {filterCols}
                        </div>)
                        filterCols = [];
                    }
                }
            }
        }
        return elements;
    }

    useEffect(() => {
        axios.get(`${routeConfig.baseUrl}/Report/GetQuickAssessmentReport`).then(response => {
            setApps(response.data);
        });
    }, []);

    const getTotal = (q) => {
        const apps = filteredApps;

        let total = 0;
        for (let i = 0; i < apps.length; i++) {
            if (apps[i].quickAssessments && apps[i].quickAssessments[assessment._id] && apps[i].quickAssessments[assessment._id][q._id]) {
                const number = parseInt(apps[i].quickAssessments[assessment._id][q._id])
                if (!isNaN(number)) {
                    total += number;
                }
            }
        }

        return total;
    }

    const exportFilteredToExcel = async () => {
        const apps = [];

        for (let app of filteredApps) {
            const newApp = {};

            newApp.Name = app.name;
            newApp.Acronym = app.acronym;
            newApp.CompassId = app.oitId;

            newApp['Responsible Group'] = teams[app.team].name;

            if (app.subTeam) {
                const subTeam = teams[app.team].subTeams.find(s => { return s._id === app.subTeam });
                newApp['Responsible Sub-Group'] = subTeam.name;
            }

            for (let question of assessment.questions.sort((a, b) => {
                return a.order - b.order;
            })) {
                if (app.quickAssessments && app.quickAssessments[assessment._id] && app.quickAssessments[assessment._id][question._id]) {
                    if (Array.isArray(app.quickAssessments[assessment._id][question._id])) {
                        newApp[question.text] = app.quickAssessments[assessment._id][question._id].join(', ');
                    } else {
                        newApp[question.text] = app.quickAssessments[assessment._id][question._id];
                    }
                } else {
                    newApp[question.text] = question.emptyText;
                }
            }

            apps.push(newApp);
        }

        const uniqueName = `${assessment.name}_${format(new Date(), 'MM/DD/YYYY_h_mm_A')}`;
        const fileName = `${uniqueName}.xlsx`;

        appInsights.trackEvent({
            name: 'Export Quick Assessment Excel',
            properties: {
                type: assessment.name
            }
        });

        const XLSX = await import('xlsx');
        const wb = XLSX.utils.book_new();

        wb.Props = {
            Title: assessment.name,
            Subject: assessment.name,
            Author: "FDOT",
            CreatedDate: new Date()
        };

        const workSheet = XLSX.utils.json_to_sheet(apps);

        XLSX.utils.book_append_sheet(wb, workSheet, `Assessment_${format(new Date(), 'MM-DD-YYYY_h_mm_A')}`);
        const bin = XLSX.write(wb, { bookType: 'xlsx', type: "binary" });

        require("downloadjs")(bin, fileName, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
    }

    if (!assessment) {
        return <div></div>
    }
    return <div className="container-fluid">
        <div className="row">
            <div className="col text-center">
                <h3>{assessment.name} Report</h3>
            </div>
        </div>
        <Card headerText="Criteria">
            <div className="row">
                <div className="col-2 font-weight-bold">Responsible Group:</div>
                <div className="col">
                    <select value={teamFilter} onChange={e => setTeamFilter(e.target.value)} className="form-control" >
                        <TeamOptions emptyText="All"></TeamOptions>
                    </select>
                </div>
                <div className="col-2 font-weight-bold">Responsible Sub-Group:</div>
                <div className="col">
                    <select value={subTeamFilter} onChange={e => setSubTeamFilter(e.target.value)} className="form-control"
                        disabled={!teamFilter || teamFilter === ''}>
                        <TeamOptions team={teamFilter} emptyText="All"></TeamOptions>
                    </select>
                </div>
            </div>
            {getAdditionalFilters()}
            <div className="row">
                <div className="col text-right">
                    {assessment ?
                        <FontAwesomeButton text="Export Assessments" small={true}
                            icon="file-excel" onClick={exportFilteredToExcel}></FontAwesomeButton>
                        : <></>}
                </div>
            </div>
        </Card>
        <br /><>
            {assessment ?
                getChunkedQuestions.map((q, i) => {
                    return <div className="row" key={i}>
                        {q.map((q, index) => {
                            return <div className="col" key={index}>
                                <Card headerText={q.shortText ? q.shortText : q.text}>
                                    <ResponsiveContainer width="100%" height={225}>
                                        <BarChart data={getData(q)}>
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="answer" />
                                            <YAxis allowDecimals={false} />
                                            <Tooltip />
                                            <Bar dataKey="count" fill="#3E92CC" name="Assets" >

                                            </Bar>
                                        </BarChart>
                                    </ResponsiveContainer>
                                </Card>
                            </div>
                        })}
                    </div>
                })
                : <></>}
            {assessment && assessment.questions.filter(q => {
                return q.questionType === 'Number';
            }).length > 0 ?
                <Card headerText="Numeric Totals">
                    <div className="row">
                        <div className="col">
                            <ul>
                                {assessment ? assessment.questions.filter(q => {
                                    return q.questionType === 'Number';
                                }).map((q, i) => {
                                    return <li><span className="font-weight-bold">Total {q.text}:</span>&nbsp;{getTotal(q)} </li>
                                }) : ''}
                            </ul>
                        </div>
                    </div>
                </Card> : <></>}
        </>
    </div>
}
