import axios from 'axios';
import { toastr } from 'react-redux-toastr'
import routeConfig from '../routeConfig';
axios.defaults.withCredentials = true;

const newTeamId = 'NewTeam';
export const Teams = {
    state: {
        teams: {

        },
        isEditOpen: false,
        districtName: null
    },
    reducers: {
        removeTeam(state, teamId) {
            const { [teamId]: removedTeam, ...newTeams } = state.teams;

            return {
                ...state,
                teams: newTeams
            }
        },
        setCurrentTeam(state, id) {
            return {
                ...state,
                currentTeamId: id
            };
        },
        setDistrictName(state, districtName) {
            return {
                ...state,
                districtName: districtName
            }
        },
        saveTeam(state, team) {
            const id = team._id ? team._id : newTeamId;

            return {
                ...state, teams: {
                    ...state.teams, [id]: {
                        ...state.teams[id], ...team
                    }
                },
                currentTeamId: id
            }
        },
        loadTeams(state, teams) {
            let loadedTeams = {};
            for (let i = 0; i < teams.length; i++) {
                loadedTeams[teams[i]._id] = teams[i];
            }
            return { ...state, teams: loadedTeams };
        },
        addTeam(state) {
            return {
                ...state,
                currentTeamId: newTeamId,
                isEditOpen: true,
                teams: {
                    ...state.teams, NewTeam: {
                    }
                }
            }
        }
    },
    effects: {
        async getTeams(payload, rootstate) {
            if (rootstate.Teams.teams && Object.keys(rootstate.Teams.teams).length > 0) {
                return;
            }

            const resp = await axios.get(`${routeConfig.baseUrl}/Team`);
            this.loadTeams(resp.data);
        },
        async persistNewTeam(payload, rootstate) {
            const response = await axios.post(`${routeConfig.baseUrl}/Team`,
                payload);
            this.saveTeam(response.data);
            toastr.success(`${response.data.name} Added`);
        },
        async persistTeam(payload, rootstate) {
            const response = await axios.patch(`${routeConfig.baseUrl}/Team/${rootstate.Teams.currentTeamId}`,
                payload);
            this.saveTeam(response.data);
            toastr.success(`${response.data.name} Saved`);
        },
        async deleteTeam(team) {
            await axios.delete(`${routeConfig.baseUrl}/Team/${team._id}`);
            this.removeTeam(team._id);
        }
    }
}
