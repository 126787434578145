import React, { useEffect } from 'react';
import { useSelector, useDispatch, useStore } from 'react-redux';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Cell } from 'recharts';
import TeamOptions from '../TeamOptions';
import Card from '../Card';


export default function PalmDashboard(props) {
    const dispatch = useDispatch();
    const store = useStore();
    const teamData = useSelector(state => store.select.Dashboard.teamData(state));
    const impactData = useSelector(state => store.select.Dashboard.impactData(state));
    const impactStatusData = useSelector(state => store.select.Dashboard.palmImpactData(state));
    const teamFilter = useSelector(state => state.Applications.teamFilter);
    const subTeamFilter = useSelector(state => state.Applications.subTeamFilter);
    const typeFilter = useSelector(state => state.Applications.typeFilter);

    const colors = ['#3E92CC', '#2A628F', '#13293D', '#16324F'];
    useEffect(() => {
        dispatch.Applications.filterByWpiiImpactStatus("");
        dispatch.Applications.filterByTeam('');
        dispatch.Applications.filterByInventoryStatus('Active');
        dispatch.Applications.filterByPalmImpact("Yes");
        // eslint-disable-next-line 
    }, []);

    const getColor = (index) => {
        return colors[index % 4];
    }

    const getSubTeamData = () => {
        if (!teamFilter)
            return [];

        const selectedTeamData = teamData.find(t => {
            return t.id === teamFilter;
        });

        if (!selectedTeamData)
            return [];

        return Object.keys(selectedTeamData).filter((key, index) => {
            return (key !== 'id' && key !== 'count' && key !== 'name');
        }).map((key, index) => {
            return {
                name: key,
                count: selectedTeamData[key]
            }
        }).sort((a, b) => {
            if (a.name < b.name) {
                return -1;
            }
            if (a.name > b.name) {
                return 1;
            }
            return 0;
        });
    }
    return <div className="container-fluid">
        <Card headerText="PALM Impacted Asset Statistics" hideCollapse={true}>
            <div className="row">
                <div className="col-2 font-weight-bold">
                    Filter By Responsible Group:
                </div>
                <div className="col">
                    <select className="form-control" value={teamFilter} onChange={e => dispatch.Applications.filterByTeam(e.target.value)} >
                        <option value="">All</option>
                        <TeamOptions hideEmpty={true}></TeamOptions>
                    </select>
                </div>
                <div className="col-2 font-weight-bold">
                    Filter By Responsible Sub-Group:
                </div>
                <div className="col">
                    <select className="form-control" value={subTeamFilter} disabled={!teamFilter || teamFilter === 'Select' || teamFilter === ''} onChange={e => dispatch.Applications.filterBySubTeam(e.target.value)} >
                        <option value="">All</option>
                        <TeamOptions hideEmpty={true} team={teamFilter}></TeamOptions>
                    </select>
                </div>
            </div>
            <div className="row">
                <div className="col-2 font-weight-bold">
                    Filter By Asset Type:
                </div>
                <div className="col-4">
                    <select className="form-control" value={typeFilter} onChange={e => dispatch.Applications.filterByType(e.target.value)} >
                        <option value="">All</option>
                        <option value="Application">Application</option>
                        <option value="Other">Other</option>
                        <option value="Report">Report</option>
                    </select>
                </div>
            </div>
            <>
                <div className="row">
                    <div className="col">
                        <Card headerText="PALM Impact Status">
                            <ResponsiveContainer width="100%" height={225}>
                                <BarChart data={impactStatusData}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" />
                                    <YAxis allowDecimals={false} />
                                    <Tooltip />
                                    <Bar dataKey="apps" fill="#18435A" name="Assets" >
                                        {
                                            impactData.map((entry, index) => {
                                                return <Cell fill={getColor(index)} key={index} />;
                                            })
                                        }
                                    </Bar>
                                </BarChart>
                            </ResponsiveContainer>
                        </Card>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <Card headerText="Impacted Assets By Responsible Group">
                            <ResponsiveContainer width="100%" height={225}>
                                <BarChart data={teamData}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" />
                                    <YAxis allowDecimals={false} />
                                    <Tooltip />

                                    <Bar dataKey="count" fill="#18435A" name="Assets" >
                                        {
                                            teamData.map((entry, index) => {
                                                return <Cell fill={getColor(index)} key={index} />;
                                            })
                                        }
                                    </Bar>
                                </BarChart>
                            </ResponsiveContainer>
                        </Card>
                    </div>
                </div>
                {teamFilter ?
                    <div className="row">
                        <div className="col">
                            <Card headerText="Impacted Assets By Responsible Sub-Group">
                                <ResponsiveContainer width="100%" height={225}>
                                    <BarChart data={getSubTeamData()}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="name" />
                                        <YAxis allowDecimals={false} />
                                        <Tooltip />

                                        <Bar dataKey="count" fill="#18435A" name="Assets" >
                                        </Bar>
                                    </BarChart>
                                </ResponsiveContainer>
                            </Card>
                        </div>
                    </div> : ''}
            </>
        </Card>
    </div >
}
