import { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import Store from '../Store';
export default function useCanEditAsset() {
    const [canEdit, setCanEdit] = useState(false);
    const user = useSelector(state => state.User.user);
    const application = useSelector(state => Store.select.Applications.currentApplication(state));

    useEffect(() => {
        if (!application)
            return;

        if (user.isAdmin) {
            setCanEdit(true);
            return;
        }

        if (application.wpiiInventoryStatus !== 'Active') {
            setCanEdit(false);
            return;
        }

        if (user.teams.indexOf(application.team) > -1 ||
            user.subTeams.indexOf(application.subTeam) > -1) {
            if (application.wpiiInventoryStatus !== 'Obsolete') {
                setCanEdit(true);
                return;
            }
        }

        try {
            if ((application.wpiiImpactResponsibleStaff &&
                application.wpiiImpactResponsibleStaff.findIndex(p => { return user.staffConnectors.some(s => p && s.id === p.id) }) > -1) ||
                (application.wpiiLiasons &&
                    application.wpiiLiasons.findIndex(p => { return user.staffConnectors.some(s => p && s.id === p.id) }) > -1) ||
                (application.updatePersons &&
                    application.updatePersons.findIndex(p => { return user.staffConnectors.some(s => p && s.id === p.id) }) > -1)) {
                if (application.wpiiInventoryStatus !== 'Obsolete') {
                    setCanEdit(true);
                    return;
                } else {
                    setCanEdit(false);
                }
            } else {
                setCanEdit(false);
            }
        }
        catch (ex) {
            setCanEdit(false);
        }
    }, [user, application]);

    return canEdit;
}
