import React from 'react';
import PropTypes from 'prop-types';
import Card from '../../Card';
import QuickAssessmentsQuestions from './QuickAssessmentQuestions';
import { useStore, useSelector } from 'react-redux';

export default function ViewAssessmentGroup(props) {
    const store = useStore();
    const application = useSelector(state => store.select.Applications.currentApplication(state));

    const {
        group,
        assessmentId
    } = props;
    const getTableHeaders = () => {
        return <thead><tr>
            {group.questions.map((q, i) => {
                return <th key={i}>{q.text}</th>
            })}
        </tr>
        </thead>
    }

    const getTableBody = () => {
        if (application.quickAssessments && application.quickAssessments[assessmentId]
            && application.quickAssessments[assessmentId][group._id]) {
            return <tbody>
                {application.quickAssessments[assessmentId][group._id].map((row, i) => {
                    return <tr key={i}>
                        {group.questions.map((q, questionIndex) => {
                            return <td key={questionIndex}>
                                {application.quickAssessments[assessmentId][group._id][i][q._id]}
                            </td>
                        })}
                    </tr>
                })}
            </tbody>
        }
        return <tbody></tbody>
    }

    const getQuestions = () => {
        return <QuickAssessmentsQuestions questions={group.questions} assessmentId={assessmentId}>
        </QuickAssessmentsQuestions>
    }

    const getTable = () => {
        return <table className="table table-sm table-bordered table-striped">
            {getTableHeaders()}
            {getTableBody()}
        </table>
    }

    return <Card headerText={props.group.headerText}>
        <div className="row">
            <div className="col">
                {props.group.isArray ? getTable() : getQuestions()}
            </div>
        </div>
    </Card>
}

ViewAssessmentGroup.propTypes = {
    group: PropTypes.object.isRequired,
    assessmentId: PropTypes.string.isRequired
}
