import axios from 'axios';
import routeConfig from '../routeConfig';
export const GitStatistics = {
    state: {
        gitStatistics: null,
    },
    reducers: {
        addGitStats(state, gitStats) {
            return {
                ...state,
                gitStatistics: gitStats
            };
        }
    },
    effects: {
        async loadGitStats() {
            const response = await axios.get(`${routeConfig.baseUrl}/AuthorCommitStats/`);
            this.addGitStats(response.data);
        }
    },
    selectors: {

    }
}
