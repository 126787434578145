import { init } from '@rematch/core';
import selectPlugin from '@rematch/select'
import * as models from './models';
import { reducer as toastrReducer } from 'react-redux-toastr'

const reducers = {
    // ... other reducers ...
    toastr: toastrReducer // <- Mounted at toastr.
}

const reduxMiddleware = []

if (process.env.NODE_ENV !== 'production') {
    const freeze = require('redux-freeze')
    reduxMiddleware.push(freeze)
}

const store = init({
    models,
    plugins: [selectPlugin()]
    , redux: {
        reducers,
        reduxMiddleware,
        devtoolOptions: {
            stateSanitizer: (state) => {
                return state.Applications.applications ? {
                    ...state, Applications: {
                        ...state.Applications, applications: Object.keys(state.Applications.applications).length,
                        filteredApplications: Object.keys(state.Applications.filteredApplications).length
                    }
                } : state
            }
        }
    }
});

export const { select, dispatch } = store

export default store
