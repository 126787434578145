import React from 'react';
import { useSelector } from "react-redux";
import store from '../../../Store';
import Card from '../../Card';
import AssetDependencyGraph from "../AssetDependencyGraph";
import ViewRelatedAssets from './ViewRelatedAssets';

export default function ViewInterfaceDependencies(props) {
    const application = useSelector(state => store.select.Applications.currentApplication(state));
    const assetInterfaces = useSelector(state => state.AssetInterface.assetInterfaces);

    return <div>
        <Card headerText="Service Interfaces" collapsed={true}>
            <ul className="list-group">
                {application.interfaceDependencies ? application.interfaceDependencies.map((interfaceDep, index) => {
                    if (!interfaceDep) {
                        return <></>
                    }

                    return <div className="list-group-item" key={index}>
                        <div className="row" >
                            <div className="col">
                                <h5>
                                    {assetInterfaces[application.interfaceDependencies[index].interface] ?
                                        assetInterfaces[application.interfaceDependencies[index].interface].name : ''}
                                </h5>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-1 font-weight-bold">
                                Not Used:
                             </div>
                            <div className="col">
                                {interfaceDep.isNotUsed === true ? 'Yes' : 'No'}
                            </div>
                            <div className="col-1 font-weight-bold">
                                Use:
                             </div>
                            <div className="col">
                                {interfaceDep.use}
                            </div>
                            <div className="col-1 font-weight-bold">
                                Type:
                             </div>
                            <div className="col">
                                {interfaceDep ? interfaceDep.type : <></>}
                            </div>
                        </div>

                        {
                            assetInterfaces[application.interfaceDependencies[index].interface].type === "API" ?
                                <Card headerText="Operations Used" collapsed={true}>
                                    <div className="row">
                                        <div className="col-7">
                                            <table className="table table-striped table-bordered table-sm">
                                                <thead>
                                                    <tr>
                                                        <th>Name</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {application.interfaceDependencies[index].operationsUsed ?
                                                        application.interfaceDependencies[index].operationsUsed.map((operation, operationIndex) => {
                                                            return <tr key={operationIndex}>
                                                                <td>
                                                                    {assetInterfaces[application.interfaceDependencies[index].interface].operations.find(t => {
                                                                        return t._id === operation;
                                                                    }).name}
                                                                </td>
                                                            </tr>
                                                        }) : ''}
                                                </tbody>
                                            </table>

                                        </div>
                                    </div>
                                </Card> : ''
                        }
                        {
                            assetInterfaces[application.interfaceDependencies[index].interface].type === "Library" ?
                                <Card headerText="Classes Used" collapsed={true}>
                                    <div className="row">
                                        <div className="col">
                                            <ul className="list-group">
                                                {application.interfaceDependencies[index].classesUsed ?
                                                    application.interfaceDependencies[index].classesUsed.map((classUsed, classUsedIndex) => {
                                                        return <ul className="list-group-item" key={classUsedIndex}>
                                                            <div className="row">
                                                                <div className="col">Class Name:&nbsp;
                                                        {assetInterfaces[application.interfaceDependencies[index].interface].classes.find(t => {
                                                                    return t._id === classUsed.class;
                                                                }).name}
                                                                </div>
                                                            </div>
                                                            <Card headerText="Methods Used" collapsed={true}>
                                                                <div className="row">
                                                                    <div className="col-8">
                                                                        <table className="table table-striped table-bordered table-sm">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>Name</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {classUsed.methods.map((method, methodIndex) => {
                                                                                    return <tr key={methodIndex}>
                                                                                        <td>
                                                                                            {assetInterfaces[application.interfaceDependencies[index].interface].classes.find(t => {
                                                                                                return t._id === classUsed.class;
                                                                                            }).methods.find(f => {
                                                                                                return f._id === method
                                                                                            }).name}
                                                                                        </td>
                                                                                    </tr>
                                                                                })}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </Card>
                                                        </ul>
                                                    }) : ''}
                                            </ul>
                                        </div>
                                    </div>
                                </Card> : ''
                        }
                        {
                            assetInterfaces[application.interfaceDependencies[index].interface].type === "Database" &&
                                application.interfaceDependencies[index].tablesUsed &&
                                application.interfaceDependencies[index].tablesUsed.length > 0 ?
                                <Card headerText="Tables Used" collapsed={true}>
                                    <div className="row">
                                        <div className="col">
                                            <ul className="list-group">
                                                {application.interfaceDependencies[index].tablesUsed.map((table, tableIndex) => {
                                                    return <ul className="list-group-item" key={tableIndex}>
                                                        <div className="row">
                                                            <div className="col">Table Name:&nbsp;
                                                        {
                                                                    assetInterfaces[application.interfaceDependencies[index].interface].tables.find(t => {
                                                                        return t._id === table.table;
                                                                    }).name
                                                                }</div>
                                                        </div>
                                                        {table.fields && table.fields.length > 0 ?
                                                            <Card headerText="Fields Used" collapsed={true}>
                                                                <div className="row">
                                                                    <div className="col">
                                                                        <table className="table table-sm table-bordered table-striped">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>Name</th>
                                                                                    <th>Logical Name</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {table.fields.map((field, fieldIndex) => {
                                                                                    return <tr key={fieldIndex}>
                                                                                        <td>
                                                                                            {assetInterfaces[application.interfaceDependencies[index].interface].tables.find(t => {
                                                                                                return t._id === table.table;
                                                                                            }).fields.find(f => {
                                                                                                return f._id === field
                                                                                            }).name}
                                                                                        </td>
                                                                                        <td>
                                                                                            {assetInterfaces[application.interfaceDependencies[index].interface].tables.find(t => {
                                                                                                return t._id === table.table;
                                                                                            }).fields.find(f => {
                                                                                                return f._id === field
                                                                                            }).logicalName}
                                                                                        </td>
                                                                                    </tr>
                                                                                })}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </Card>
                                                            : ''}
                                                    </ul>
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                </Card>
                                : ''
                        }
                    </div>
                }) : ''}
            </ul>
        </Card>
        <div className="row">
            <div className="col"></div>
        </div>
        <Card headerText="Miscellaneous Interfaces" collapsed={true}>
            <ViewRelatedAssets></ViewRelatedAssets>
        </Card>
        <div className="row">
            <div className="col"></div>
        </div>
        <Card headerText="Related Assets" collapsed={true}>
            <AssetDependencyGraph></AssetDependencyGraph>
        </Card>
        <div className="row">
            <div className="col"></div>
        </div>
    </div >;
}
