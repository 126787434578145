import axios from 'axios';
import { toastr } from 'react-redux-toastr'
import routeConfig from '../routeConfig';
export const Database = {
    state: {
        currentId: null,
        databases: {

        },
    },
    reducers: {
        loadDatabases(state, databases) {
            let loadedDatabases = {};
            for (let i = 0; i < databases.length; i++) {
                loadedDatabases[databases[i]._id] = databases[i];
            }
            return { ...state, databases: loadedDatabases };
        },
        addDatabase(state, database) {
            return {
                ...state, databases: {
                    ...state.databases, [database._id]: database
                }, currentId: database._id
            }
        },
        saveDatabase(state, database) {
            return {
                ...state, databases: {
                    ...state.databases, [database._id]: {
                        ...state.databases[database._id], ...database
                    }
                }
            }
        },
        setCurrentId(state, id) {
            return { ...state, currentId: id };
        }
    },
    selectors: ((slice) => ({
        currentDatabase() {
            return slice(Database => {
                return Database.databases[Database.currentId];
            });
        }
    })),
    effects: {
        async getDatabases() {
            const resp = await axios.get(`${routeConfig.baseUrl}/DatabasePlatform`);
            this.loadDatabases(resp.data);
        },
        async persistNewDatabase(database, rootState) {
            const response = await axios.post(`${routeConfig.baseUrl}/DatabasePlatform`, database);
            this.addDatabase(response.data);
            toastr.success('New Database Platform Added')
        },
        async persistDatabase(payload, rootState) {
            const response = await axios.put(`${routeConfig.baseUrl}/DatabasePlatform/${rootState.Database.currentId}`,
                rootState.Database.databases[rootState.Database.currentId]);
            this.addDatabase(response.data);
            toastr.success('Database Platform Saved');
        },
        async loadDatabase(id) {
            const response = await axios.get(`${routeConfig.baseUrl}/DatabasePlatform/${id}`);
            this.addDatabase(response.data);
        }
    }
}
