import React, { useState } from 'react';
import { useSelector, useStore, useDispatch } from 'react-redux';
import AddButton from '../AddButton';
import DeleteButton from '../DeleteButton';
import SaveCancel from '../SaveCancel';
import { useNestedArrayState } from '../../hooks/useNestedArrayState';
export default function EditRelatedInterfaces(props) {
    const dispatch = useDispatch();
    const store = useStore();
    const solutions = useSelector(state => store.select.AssetInterface.getSolutions(state));
    const currentInterface = useSelector(state => store.select.AssetInterface.currentAssetInterface(state));
    const [selectedSolution, setSelectedSolution] = useState('');

    const {
        state,
        setStateArrayProperty,
        addToArrayProperty,
        removeFromArrayProperty,
    } = useNestedArrayState(currentInterface)

    const save = () => {
        dispatch.AssetInterface.saveInterface(state);
        dispatch.AssetInterface.changeApplicationModalIsEditing(false);
    }

    return <>
        <div className="row">
            <div className="col-6"></div>
            <div className="col text-right">
                <div className="input-group">
                    <select className="form-control" onChange={e => setSelectedSolution(e.target.value)}>
                        <option value=''>Select</option>
                        {solutions ? Object.values(solutions).map((s, i) => {
                            return <option key={s._id} value={s._id}>{s.name}</option>
                        }) : <></>}
                    </select>
                    <div className="input-group-append">
                        <AddButton disabled={!selectedSolution} onClick={() => addToArrayProperty('relatedSolutions', { solution: selectedSolution })} text="Solution"></AddButton>
                    </div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col">
                <table className="table table-striped table-bordered">
                    <thead>
                        <tr>
                            <th>Solution</th>
                            <th>Notes</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {state.relatedSolutions ? state.relatedSolutions.map((rs, i) => {
                            return <tr key={rs._id}>
                                <td>{solutions.find(s => { return s._id === rs.solution }).name}</td>
                                <td>
                                    <textarea className="form-control" value={rs.notes} onChange={e => setStateArrayProperty('relatedSolutions', i, 'notes', e)} />
                                </td>
                                <td>
                                    <DeleteButton hideText={true} onClick={() => removeFromArrayProperty('relatedSolutions', i)}></DeleteButton>
                                </td>
                            </tr>
                        }) : <></>
                        }
                    </tbody>
                </table>
            </div>
        </div>
        <div className="row">
            <div className="col">
                <SaveCancel onSaveClick={save} onCancelClick={() => dispatch.AssetInterface.changeApplicationModalIsEditing(false)}></SaveCancel>
            </div>
        </div>
    </>
}
