import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNestedArrayState } from '../../../hooks/useNestedArrayState';
import useCanEditAsset from '../../../hooks/useCanEditAsset';
import { format } from 'date-fns';

export default function Correction(props) {
    const { state,
        setState,
        setStateProperty } = useNestedArrayState(props.correction);
    const dispatch = useDispatch();
    const canEdit = useCanEditAsset();
    const user = useSelector(state => state.User.user);

    useEffect(() => {
        setState(props.correction);
    }, [props.correction]);

    const save = () => {
        dispatch.Correction.saveCorrection({ ...state, correctionDate: new Date() });
        dispatch.Applications.addCorrection(props.correction.application);
    }

    const acknowledge = () => {
        dispatch.Correction.saveCorrection({ ...state, status: 'Acknowledged', responseUser: user, responseDate: new Date() });
    }

    const close = () => {
        dispatch.Correction.saveCorrection({ ...state, status: 'Closed', closedUser: user, closedDate: new Date() });
        dispatch.Applications.removeCorrection(props.correction.application);
    }

    return <>
        <div className="row">
            <div className="col font-weight-bold">
                {state._id ? `${state.user.firstName} ${state.user.lastName} commented at ${format(new Date(state.correctionDate), 'MM/DD/YY')}:` : `Comment:`}
            </div>
        </div>
        <div className="row">
            <div className="col">
                {state._id ? state.comment :
                    <textarea className="form-control" onChange={e => setStateProperty("comment", e)}></textarea>
                }
            </div>
        </div>
        {state._id ?
            <div className="commentResponse">
                <div className="row">
                    <div className="col font-weight-bold">
                        {state.responseUser ? `${state.responseUser.firstName} ${state.responseUser.lastName} acknowledged at ${format(new Date(state.responseDate), 'MM/DD/YY')}:` : `Acknowledgement Response:`}
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        {canEdit && state.status === 'Open' ?
                            <textarea onChange={e => setStateProperty("response", e)}
                                className="form-control"></textarea>
                            : <>{state.response}</>}
                    </div>
                </div>
                {state._id && (state.status === 'Acknowledged' || state.status === 'Closed') ?
                    <div className="commentResponse2">
                        <div className="row">
                            <div className="col font-weight-bold">
                                {state.closedUser ? `${state.responseUser.firstName} ${state.responseUser.lastName} closed at ${format(new Date(state.closedDate), 'MM/DD/YY')}:` : `Closed Comment:`}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                {canEdit && state.status === 'Acknowledged' ?
                                    <textarea onChange={e => setStateProperty("closedComment", e)}
                                        className="form-control"></textarea>
                                    : <>{state.closedComment}</>}
                            </div>
                        </div>
                    </div> : <></>}
            </div> : <></>}
        {state.status === 'Open' || state.status === 'Acknowledged' ?
            <div className="row">
                <div className="col">
                    {!state._id ?
                        <>
                            <button className="btn btn-primary" disabled={!state.comment || state.comment.trim() === ''} onClick={save}>Save Correction</button> </> :
                        <></>}
                    {state._id && canEdit && state.status === 'Open' ?
                        <button className="btn btn-primary" onClick={acknowledge} disabled={!state.response || state.response.trim() === ''}>Acknowledge Correction</button>
                        : <></>}
                    {state.status === 'Acknowledged' && canEdit ?
                        <button className="btn btn-primary" disabled={!state.closedComment || state.closedComment.trim() === ''} onClick={close}>Close</button>
                        : <></>}
                </div>
            </div> : <></>
        }
    </>
}
