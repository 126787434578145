import axios from 'axios';
import routeConfig from '../routeConfig';
export const Admin = {
    state: {
        admins: {

        },
    },
    reducers: {
        loadAdmins(state, admins) {
            let loadedAdmins = {};
            for (let i = 0; i < admins.length; i++) {
                loadedAdmins[admins[i]._id] = admins[i];
            }
            return { ...state, admins: loadedAdmins };
        },
        addAdmin(state, admins) {
            return {
                ...state, admins: {
                    ...state.admins, [admins._id]: admins
                }
            }
        },
        removeAdmin(state, admin) {
            const { ...newAdmins } = state.admins;

            return {
                ...state,
                admins: newAdmins
            }
        }
    },
    effects: {
        async getAdmins() {
            const resp = await axios.get(`${routeConfig.baseUrl}/Admin`);
            this.loadAdmins(resp.data);
        },
        async persistAdmin(admin) {
            const resp = await axios.post(`${routeConfig.baseUrl}/Admin`, admin);
            this.addAdmin(resp.data);
        },
        async deleteAdmin(admin) {
            await axios.delete(`${routeConfig.baseUrl}/Admin/${admin._id}`);
            this.removeAdmin(admin);
        }
    }
}
