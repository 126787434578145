import React, { useEffect } from 'react';
import { useSelector, useStore, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Card from '../Card';

export default function ViewImpactedTables(props) {
    const store = useStore();
    const dispatch = useDispatch();
    const currentApplication = useSelector(state => store.select.Applications.currentApplication(state));
    const applications = useSelector(state => state.Applications.applications);

    useEffect(() => {
        if (currentApplication[props.appDependencyProperty] && currentApplication[props.appDependencyProperty][props.impactSuggestion]) {
            for (let asset of currentApplication[props.appDependencyProperty][props.impactSuggestion]) {
                dispatch.Applications.loadApplicationWithoutCompletions(asset.asset);
            }
        }
    }, [])

    const getFieldTable = (fields, index) => {
        return <table className="table table-striped table-bordered table-sm">
            <thead>
                <tr>
                    <th>Name</th>
                    {props.impactTypeForField && props.impactTypeForField.length > 0 ?
                        <th>Impact Type</th>
                        : <></>}
                </tr>
            </thead>
            <tbody>
                {currentApplication[props.arrayProperty][props.impactSuggestion][index].fields.map((field, fieldIndex) => {
                    return <tr key={fieldIndex}>
                        <td>
                            {fields.find(f => f._id === field.field).name}
                        </td>
                        {props.impactTypeForField && props.impactTypeForField.length > 0 ?
                            <td>
                                {field.impactType}
                            </td>
                            : <></>}
                    </tr>
                })}
            </tbody>
        </table>
    }

    const getFieldsUsedCard = (table, index) => {
        const fields = currentApplication.tables.find(t => {
            return t._id === table.table;
        }).fields.sort((a, b) => {
            return a.name < b.name ? -1 : 1;
        });

        return <Card headerText="Fields Used" collapsed={true}>
            <div className="row">
                <div className="col-6">
                    {getFieldTable(fields, index)}
                </div>
            </div>
        </Card>
    }

    return <>
        <Card headerText="Owned Tables Impacted" >
            <div className="row">
                <div className="col">
                    <ul className="list-group">
                        {currentApplication[props.arrayProperty] && currentApplication[props.arrayProperty][props.impactSuggestion] ? currentApplication[props.arrayProperty][props.impactSuggestion].map((table, tableIndex) => {
                            return <ul className="list-group-item" key={tableIndex}>
                                <div className="row">
                                    <div className="col">Table Name:&nbsp;
                                                     {
                                            currentApplication.tables.find(t => {
                                                return t._id === table.table;
                                            }).name
                                        }</div>
                                </div>
                                {getFieldsUsedCard(table, tableIndex)}
                            </ul>
                        }) : ''}
                    </ul>
                </div>
            </div>
        </Card >
        <br />
        <Card headerText="Data from Related Assets Impacted">
            <div className="row">
                <div className="col">
                    <ul className="list-group">
                        {currentApplication[props.appDependencyProperty] && currentApplication[props.appDependencyProperty][props.impactSuggestion]
                            ? currentApplication[props.appDependencyProperty][props.impactSuggestion].map((appDependency, appDepIndex) => {
                                return <li key={appDepIndex} className="list-group-item">
                                    <div className="row">
                                        <div className="col font-weight-bold">
                                            {applications[appDependency.asset].name}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <table className="table table-striped table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>Table Name</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {appDependency.tablesUsed.map((t, ti) => {
                                                    return <tr key={ti}>
                                                        <td>{applications[appDependency.asset].tables ?
                                                            applications[appDependency.asset].tables.find(table => {
                                                                return table._id == t.table
                                                            }).name
                                                            : <></>}</td>
                                                        <td>
                                                            <table className="table table-bordered table-striped">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Columns</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {t.fields.map((f, fi) => {
                                                                        return <tr key={fi}>
                                                                            <td>{applications[appDependency.asset].tables ? applications[appDependency.asset].tables.find(table => { return table._id == t.table }).fields.find(field => {
                                                                                return field._id == f;
                                                                            }).name : <></>}
                                                                            </td>
                                                                        </tr>
                                                                    })}
                                                                </tbody>
                                                            </table>

                                                        </td>
                                                    </tr>
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </li>
                            }) : ''}
                    </ul>
                </div>
            </div>
        </Card>
        <br />
    </>
}

ViewImpactedTables.propTypes = {
    arrayProperty: PropTypes.string,
    appDependencyProperty: PropTypes.string,
    impactType: PropTypes.string,
    impactTypeForField: PropTypes.array,
}
