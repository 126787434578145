import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import store from '../../../Store';
import AddButton from '../../AddButton';
import DeleteButton from '../../DeleteButton';
import SaveCancel from '../../SaveCancel';
import FiscalYearOptions from '../../FiscalYearOptions';
import { useNestedArrayState } from '../../../hooks/useNestedArrayState';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'reactstrap';
import getCurrentFiscalYear from '../../../helpers/getCurrentFiscalYear';
import { toastr } from 'react-redux-toastr';
export default function EditCloudFirstReporting(props) {
    const application = useSelector(state => store.select.Applications.currentApplication(state));
    const dispatch = useDispatch();
    const [tooltipOpen, setToolTipOpen] = useState(false);
    const [selectedFiscalYear, setSelectedFiscalYear] = useState(getCurrentFiscalYear())
    const {
        state,
        setStateProperty,
        setStateArrayProperty,
        addToArrayProperty,
        removeFromArrayProperty,
    } = useNestedArrayState(application);

    const onSave = () => {
        dispatch.Applications.persistApplication({ ...state });
        dispatch.Applications.changeApplicationModalIsEditing(false);
    };

    const addYear = () => {
        if (state.cloudFirstReports.findIndex(c => { return c.reportingPeriod === selectedFiscalYear; }) === -1) {
            addToArrayProperty('cloudFirstReports',
                { reportingPeriod: selectedFiscalYear, strategy: '', readiness: '', targetTimeFrame: '' });
        } else {
            toastr.error('This fiscal year has already been added!');
        }
    }

    const setCloudReadiness = (i, e) => {
        setStateArrayProperty('cloudFirstReports', i, 'readiness', e);
        if (e.target.value === 'Already in the Cloud') {
            setStateArrayProperty('cloudFirstReports', i, 'strategy', e);
            setStateArrayProperty('cloudFirstReports', i, 'targetTimeFrame', 'Complete');
        }
    }

    const getCloudStrategyOptions = (readiness) => {
        if (readiness === 'Remediate or Replace') {
            return <>
                <option value="">Select</option>
                <option value="Replace with SaaS">Replace with SaaS</option>
                <option value="Remediate">Remediate</option>
            </>
        } else if (readiness === 'Cloud Ready') {
            return <>
                <option value="">Select</option>
                <option value="IaaS Lift and Shift">IaaS Lift and Shift</option>
                <option value="PaaS Lift and Shift">PaaS Lift and Shift</option>
            </>
        }

        return <></>
    }

    return <>
        <div className="row">
            <div className="col font-weight-bold">
                Cloud Reporting: &nbsp;
                <FontAwesomeIcon icon="question-circle" id="cloudReportingToolTip"></FontAwesomeIcon>
                <Tooltip placement="bottom" isOpen={tooltipOpen} target="cloudReportingToolTip" toggle={() => setToolTipOpen(!tooltipOpen)} >
                    Select Yes if the Application is hosted in the State Data Center or hosted in the cloud
                </Tooltip>
            </div>
            <div className="col">
                <select value={state.cloudReporting} className="form-control" onChange={e => setStateProperty('cloudReporting', e)}>
                    <option value=''>Select</option>
                    <option value='No'>No</option>
                    <option value='Yes'>Yes</option>
                </select>
            </div>
        </div>
        <div className="row">
            <div className="col font-weight-bold">
                Hosting Type:
            </div>
            <div className="col">
                <select value={state.hostingType} className="form-control" onChange={e => setStateProperty('hostingType', e)}>
                    <option value=''>Select</option>
                    <option value='Cloud'>Cloud</option>
                    <option value='District/Locally'>District/Locally</option>
                    <option value='Hybrid'>Hybrid</option>
                    <option value='State Data Center'>State Data Center</option>
                </select>
            </div>
        </div>
        <div className="row">
            <div className="col font-weight-bold">
                Year Implemented to Cloud:
            </div>
            <div className="col">
                <input value={state.cloudYearImplemented} className="form-control"
                    type="Text" disabled={state.hostingType !== 'Cloud'}
                    onChange={e => setStateProperty('cloudYearImplemented', e)}>
                </input>
            </div>
        </div>
        <div className="row">
            <div className="col-8"></div>
            <div className="col text-right">
                <div className="input-group">
                    <select className="form-control" value={selectedFiscalYear}
                        onChange={e => setSelectedFiscalYear(e.target.value)} >
                        <FiscalYearOptions showOnlyCurrentFiscalYear={true}></FiscalYearOptions>
                    </select>
                    <div className="input-group-append">
                        <AddButton text="Reporting Year" disabled={!selectedFiscalYear}
                            onClick={addYear}>
                        </AddButton>
                    </div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col">
                <table className="table table-striped table-bordered">
                    <thead>
                        <tr>
                            <th>Reporting Period</th>
                            <th>Cloud Readiness </th>
                            <th>Cloud Strategy </th>
                            <th>Target Time-frame </th>
                            <th>Annual Cloud Cost</th>
                            <th>Comments</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {state.cloudFirstReports ? state.cloudFirstReports.map((r, i) => {
                            return <tr key={i}>
                                <td>
                                    {r.reportingPeriod}
                                </td>
                                <td>
                                    <select className="form-control" value={r.readiness} disabled={r.reportingPeriod !== getCurrentFiscalYear()}
                                        onChange={e => setCloudReadiness(i, e)} >
                                        <option value="">Select</option>
                                        <option value="Already in the Cloud">Already in the Cloud</option>
                                        <option value="Cloud Ready">Cloud Ready</option>
                                        <option value="Remediate or Replace">Remediate or Replace</option>
                                    </select></td>
                                <td>{r.readiness === 'Already in the Cloud' ? r.strategy :
                                    <select className="form-control" value={r.strategy} disabled={r.readiness === 'Unfit' || r.reportingPeriod !== getCurrentFiscalYear()}
                                        onChange={e => setStateArrayProperty('cloudFirstReports', i, 'strategy', e)} >

                                        {getCloudStrategyOptions(r.readiness)}
                                    </select>}
                                </td>
                                <td>
                                    {r.readiness === 'Already in the Cloud' ? r.targetTimeFrame :
                                        <select className="form-control" value={r.targetTimeFrame} disabled={r.reportingPeriod !== getCurrentFiscalYear()}
                                            onChange={e => setStateArrayProperty('cloudFirstReports', i, 'targetTimeFrame', e)} >
                                            <option value="">Select</option>
                                            <FiscalYearOptions></FiscalYearOptions>
                                            <option value='Unknown'>Unknown</option>
                                        </select>}
                                </td>
                                <td>
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon1">$</span>
                                        </div>
                                        <input className="form-control" type="number" value={r.costForCloud} disabled={r.readiness !== 'Already in the Cloud' || r.reportingPeriod !== getCurrentFiscalYear()}
                                            onChange={e => setStateArrayProperty('cloudFirstReports', i, 'costForCloud', e)}></input>

                                    </div>

                                </td>
                                <td>
                                    <textarea className="form-control" value={r.comments} disabled={r.reportingPeriod !== getCurrentFiscalYear()}
                                        onChange={e => setStateArrayProperty('cloudFirstReports', i, 'comments', e)}></textarea>
                                </td>
                                <td>{getCurrentFiscalYear() === r.reportingPeriod ?
                                    <DeleteButton hideText={true} onClick={() => removeFromArrayProperty('cloudFirstReports', i)}></DeleteButton> :
                                    ''}
                                </td>
                            </tr>
                        }) : <></>}
                    </tbody>

                </table>
            </div>
        </div>
        <div className="row">
            <div className="col">
                <SaveCancel onCancelClick={() => dispatch.Applications.changeApplicationModalIsEditing(false)} onSaveClick={onSave}></SaveCancel>
            </div>
        </div>
    </>
}
