import React, { useEffect, useState } from 'react'
import Card from '../Card';
import { useSelector } from 'react-redux';
import TeamOptions from '../TeamOptions';
import axios from 'axios';
import routeConfig from '../../routeConfig';

export default function IncompleteCloudFirstReport(props) {
    const [teamFilter, setTeamFilter] = useState('');
    const [subTeamFilter, setSubTeamFilter] = useState('');
    const [results, setResults] = useState([]);
    const [rawResults, setRawResults] = useState([]);
    const teams = useSelector(state => state.Teams.teams);

    const changeResponsibleGroup = (e) => {
        setTeamFilter(e.target.value);
        setSubTeamFilter('');
        if (e.target.value === '') {
            setResults(rawResults);
        } else {
            setResults(rawResults.filter(a => {
                return a.team === e.target.value;
            }))
        }
    }

    const changeResponsibleSubGroup = (e) => {
        setSubTeamFilter(e.target.value);
        setResults(rawResults.filter(a => {
            return a.subTeam === e.target.value;
        }))
    }

    useEffect(() => {
        const fetchData = async () => {
            const result = await axios.get(`${routeConfig.baseUrl}/Report/IncompleteCloudFirstReport`);
            const sorted = result.data.sort((a, b) => {
                if (teams[a.team].name === teams[b.team].name) {
                    return teams[a.team].subTeams.find(s => { return s._id === a.subTeam }).name <
                        teams[b.team].subTeams.find(s => { return s._id === b.subTeam }).name ? -1 : 1;
                }
                return teams[a.team].name < teams[b.team].name ? -1 : 1;
            });
            setResults(sorted);
            setRawResults(sorted);
        }

        fetchData();
        // eslint-disable-next-line
    }, []);

    if (!teams) {
        return <></>
    }

    return <div className="container-fluid">
        <Card headerText="Report Filters" className="my-1 mp-1">
            <div className="row">
                <div className="col-2 font-weight-bold">Responsible Group:</div>
                <div className="col">
                    <select value={teamFilter} onChange={e => changeResponsibleGroup(e)} className="form-control" >
                        <TeamOptions emptyText="All"></TeamOptions>
                    </select>
                </div>
                <div className="col-2 font-weight-bold">Responsible Sub-Group:</div>
                <div className="col">
                    <select value={subTeamFilter} onChange={e => changeResponsibleSubGroup(e)} className="form-control"
                        disabled={!teamFilter || teamFilter === ''}>
                        <TeamOptions team={teamFilter} emptyText="All"></TeamOptions>
                    </select>
                </div>
            </div>
        </Card>
        <br />
        <Card headerText="Incomplete Cloud First Report" className="my-1 mp-1">
            <div className="row">
                <div className="col">
                    <table className="table table-bordered table-striped table-sm">
                        <thead>
                            <tr>
                                <th>Responsible Group</th>
                                <th>Responsible Sub-Group</th>
                                <th>Asset Name</th>
                                <th>Hosting Type</th>

                            </tr>
                        </thead>
                        <tbody>
                            {results.map((app, i) => {
                                return <tr key={i}>
                                    <td>{teams[app.team].name}</td>
                                    <td>{app.subTeam ? teams[app.team].subTeams.find(s => { return s._id === app.subTeam }).name : ''}</td>
                                    <td>{app.name}</td>
                                    <td>{app.hostingType}</td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </Card>
    </div>
}
