import axios from 'axios';
import routeConfig from '../routeConfig';
export const QuickAssessment = {
    state: {
        currentId: null,
        quickAssessments: {

        },
    },
    reducers: {
        loadQuickAssessments(state, quickAssessments) {
            let loadedQuickAssessments = {};
            for (let i = 0; i < quickAssessments.length; i++) {
                loadedQuickAssessments[quickAssessments[i]._id] = quickAssessments[i];
            }
            return { ...state, quickAssessments: loadedQuickAssessments };
        },
        addQuickAssessment(state, quickAssessment) {
            return {
                ...state, quickAssessments: {
                    ...state.quickAssessments, [quickAssessment._id]: quickAssessment
                }, currentId: quickAssessment._id
            }
        },
        setCurrentId(state, id) {
            return { ...state, currentId: id };
        }
    },
    selectors: {
        currentQuickAssessment() {
            return (rootState, props) => {
                return rootState.QuickAssessment.quickAssessments[rootState.QuickAssessment.currentId];
            }
        }
    },
    effects: {
        async getQuickAssessments() {
            const resp = await axios.get(`${routeConfig.baseUrl}/QuickAssessment`);
            this.loadQuickAssessments(resp.data);
        },
        async loadQuickAssessment(id) {
            const response = await axios.get(`${routeConfig.baseUrl}/QuickAssessment/${id}`);
            this.addQuickAssessment(response.data);
        }
    }
}
