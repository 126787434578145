import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Card from "../Card";
import InterfaceDependencyList from './InterfaceDependencyList';

export default function EditApi(props) {
    return <Card headerText="Operations" badgeCount={props.operations.length} collapsed={true}>
        <div className="row">
            <div className="col text-right">
                <button className="btn btn-sm btn btn-primary" onClick={() => props.addToArrayProperty('operations', { name: '', description: '', dependencies: [] })}
                    title="Add Operation">
                    <FontAwesomeIcon icon="plus"></FontAwesomeIcon>&nbsp;&nbsp;Operation</button>
            </div>
        </div>
        <div className="row">
            <div className="col">
                <ul className="list-group">
                    {props.operations ? props.operations.map((operation, operationIndex) => {
                        return <li className="list-group-item" key={operationIndex}>
                            <div className="row">
                                <label className="col-2 font-weight-bold">
                                    Name:</label>
                                <input type="text" className="form-control form-control-sm col" value={operation.name}
                                    onChange={e => props.setStateArrayProperty('operations', operationIndex, 'name', e)} />
                                <label className="col-2 font-weight-bold">
                                    Url:</label>
                                <input type="url" className="form-control form-control-sm col" value={operation.logicalName}
                                    onChange={e => props.setStateArrayProperty('operations', operationIndex, 'url', e)} />
                            </div>
                            <div className="row">
                                <label className="col font-weight-bold">
                                    Description:</label>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <textarea onChange={e => props.setStateArrayProperty('operations', operationIndex, 'description', e)}
                                        value={operation.description} className="form-control form-control-sm" />
                                </div>
                            </div>
                            <InterfaceDependencyList dependencies={operation.dependencies}
                                parentArrayName='operations'
                                parentIndex={operationIndex}
                                setStateArrayofArrayProperty={props.setStateArrayofArrayProperty}
                                addToNestedArrayProperty={props.addToNestedArrayProperty}
                                removeFromNestedArrayProperty={props.removeFromNestedArrayProperty}>
                            </InterfaceDependencyList>
                            <br />
                            <div className="row">
                                <div className="col">
                                    <button className="btn btn-sm btn btn-danger" onClick={() => props.removeFromArrayProperty('operations', operationIndex)}>
                                        <FontAwesomeIcon icon="trash-alt"></FontAwesomeIcon>
                                        &nbsp;&nbsp;Remove Operation
                                        </button>
                                </div>
                            </div>
                        </li>
                    }) : ''}
                </ul>
            </div>
        </div>
    </Card>
}
