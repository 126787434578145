import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { FilePond } from "react-filepond";
import "filepond/dist/filepond.min.css";
import axios from "axios";
import Store from '../../../Store'
import routeConfig from '../../../routeConfig';
import DeleteButton from '../../DeleteButton';

export default function EditDocuments(props) {
    const dispatch = useDispatch();
    const application = useSelector(state => Store.select.Applications.currentApplication(state));
    const applicationId = useSelector(state => state.Applications.currentApplicationId);

    const removeDocuments = async (files) => {
        axios.defaults.headers.delete['Content-Type'] = 'application/json; charset=utf-8';
        await axios.delete(`${routeConfig.baseUrl}/Application/${applicationId}/Documents`, {
            data: files
        });

        dispatch.Applications.loadApplication(applicationId);
    }

    const cancel = async () => {
        await dispatch.Applications.loadApplication(applicationId);
        dispatch.Applications.changeApplicationModalIsEditing(false);
    }

    if (!application)
        return '';
    return <div>
        <div className="row">
            <div className="col"></div>
        </div>
        <div className="row">
            <div className="col">
                Documents are immediately uploaded and deleted from the documents view.
            </div>
        </div>
        <ul className="list-group">
            {application.files.map((file) => {
                return <li className="list-group-item" key={file.blobName}>
                    <div className="row">
                        <div className="col-1">
                            <DeleteButton onClick={() => removeDocuments([file])} hideText={true}
                                text="Document"></DeleteButton>
                        </div>
                        <div className="col">
                            <a download={file.originalname} href={file.url}>{file.originalname}</a>
                        </div>
                    </div>

                </li>
            })}
        </ul>
        <FilePond
            allowMultiple={true}
            maxFiles={3}
            server={{
                url: `${routeConfig.baseUrl}/Application/${applicationId}/Documents`,
                revert: (files, load, error) => {
                    removeDocuments(files);
                    load();
                }
            }}
            onerror={() => {
            }}
            onprocessfiles={() => {
                dispatch.Applications.loadApplication(applicationId);
            }}
            onupdatefiles={fileItems => {
                dispatch.Applications.loadApplication(applicationId);
            }}
        />
        <div className="row">
            <div className="col">
                <button className="btn btn-primary" onClick={cancel}>Exit Edit View</button>
            </div>
        </div>
    </div>
}
