import React from 'react';
import { useSelector, useStore } from 'react-redux';
import Card from '../../Card';

export default function ViewPpmProjects() {
    const store = useStore();
    const currentApplication = useSelector(state => store.select.Applications.currentApplication(state));
    const ppmProjects = useSelector(state => state.PpmProject.projects);
    const user = useSelector(state => state.User.user);

    if (!currentApplication || !currentApplication.ppmProjects ||
        currentApplication.ppmProjects.length === 0 || user.isOit !== true) {
        return <></>
    }

    return <Card headerText="PPM Pro Projects">
        <div className="row">
            <div className="col">
                <table className="table table-sm table-bordered table-striped">
                    <thead>
                        <tr>
                            <th>
                                Project Name
                    </th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentApplication.ppmProjects.map((p, i) => {
                            return <tr key={i}>
                                <td>
                                    {ppmProjects[p].name}
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    </Card>
}