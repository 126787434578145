import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useSelector, useDispatch, useStore } from "react-redux";
import ExportToExcel from "./ExportToExcel";
import TeamOptions from "../../TeamOptions";
import WpiiImpactStatusOptions from "../../WpiiImpactStatusOptions";
import Card from "../../Card";
import ApplicationModal from "../ApplicationModal";
import PalmModal from "../../Assessment/Palm/PalmAssessmentModal";
import AssessmentModal from "../../Assessment/Wpii/WpiiAssessmentModal";
import CloudFirstAssessment from "../../Assessment/CloudFirst/CloudFirstAssessment";
import AddAsset from "../AddAsset";
import { useParams, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SearchList from "./SearchList";
import QuickAssessment from "../../Assessment/Quick/QuickAssessment";
import SelectColumnFilter from "../../Tables/SelectColumnFilter";
import {
  useTable,
  useFilters,
  useSortBy,
  usePagination,
  useFlexLayout,
  useGlobalFilter
} from "react-table";
import ReactPaginate from "react-paginate";
import FilterText from "../../Tables/FilterText";



export default React.memo(
  function ReadOnlyList() {
    const store = useStore();
    const statusFilter = useSelector(
      state => state.Applications.inventoryStatusFilter
    );
    const hostingTypeFilter = useSelector(
      state => state.Applications.hostingTypeFilter
    );
    const cloudReportingFilter = useSelector(
      state => state.Applications.cloudReportingFilter
    );
    const wpiiImpactStatusFilter = useSelector(
      state => state.Applications.wpiiImpactStatusFilter
    );
    const searchTerm = useSelector(state => state.Applications.searchTerm);
    const [isWpiiAssessment, setIsWpiiAssessment] = useState(false);
    const [isPalmAssessment, setIsPalmAssessment] = useState(false);
    const [isSearch, setIsSearch] = useState(false);
    const [isCloudFirstAssessment, setIsCloudFirstAssessment] = useState(false);
    const [isInventory, setIsInventory] = useState(false);
    const [isQuick, setIsQuick] = useState(false);
    const filteredApplications = useSelector(
      state => state.Applications.filteredApplications
    );
    const assessment = useSelector(state =>
      store.select.QuickAssessment.currentQuickAssessment(state)
    );
    const teamFilter = useSelector(state => state.Applications.teamFilter);
    const subTeamFilter = useSelector(
      state => state.Applications.subTeamFilter
    );
    const onlyCorrectionsFilter = useSelector(
      state => state.Applications.onlyCorrectionsFilter
    );
    const dispatch = useDispatch();
    const location = useLocation();
    const { id, quickAssessmentId } = useParams();

    const data = useMemo(() => {

      return Object.values(filteredApplications);
    }, [filteredApplications]);

    const openApp = useCallback((id, type) => {
      console.log(id);
      console.log(isPalmAssessment);
      dispatch.Applications.setCurrentApplicationId(id);
      if (type) {
        switch (type) {
          case "wpii":
            dispatch.Applications.changeWpiiAssessmentModalVisibility(true);
            return;
          case "quick":
            dispatch.Applications.changeQuickAssessmentModalVisibility(true);
            return;
          case "cloud":
            dispatch.Applications.changeCloudAssessmentModalVisibility(true);
            return;
          case "palm":
            dispatch.Applications.changePalmAssessmentModalVisibility(true);
            return;
          default:
            dispatch.Applications.changeApplicationModalVisibility(true);
            return;
        }
      }

      if (isWpiiAssessment) {
        dispatch.Applications.changeWpiiAssessmentModalVisibility(true);
      } else if (isCloudFirstAssessment) {
        dispatch.Applications.changeCloudAssessmentModalVisibility(true);
      } else if (isQuick) {
        dispatch.Applications.changeQuickAssessmentModalVisibility(true);
      } else if (isPalmAssessment) {
        dispatch.Applications.changePalmAssessmentModalVisibility(true)
      } else {
        dispatch.Applications.changeApplicationModalVisibility(true);
      }
    }, [dispatch, isCloudFirstAssessment, isQuick, isWpiiAssessment, isPalmAssessment]);

    const contactTemplate = ({ row }) => {
      if (row.original.wpiiImpactResponsibleStaff.length === 0) {
        return <span></span>;
      }

      return (
        <span>
          {row.original.wpiiImpactResponsibleStaff[0].firstName}&nbsp;
          {row.original.wpiiImpactResponsibleStaff[0].lastName}
        </span>
      );
    };

    const columns = useMemo(() => {
      const quickAssessmentTemplate = state => {
        const field = state.cell.column.id;
        try {
          const split = field.split(".");
          if (split.length === 3) {
            if (state.row.values &&
              state.row.values[field]) {
              return state.row.values[field];
            }


            let question;
            if (assessment.useGrouping) {
              for (let group of assessment.groups) {
                question = group.questions.find(q => {
                  return q._id === split[2];
                });
                if (question) {
                  break;
                }
              }
            } else {
              question = assessment.questions.find(q => {
                return q._id === split[2];
              });
            }
            if (question && question.emptyText) {
              return question.emptyText;
            }
          }
          return "";
        } catch (ex) {
          console.log(ex);
        }
        return "";
      };

      const nameTemplate = ({ row }) => {
        return (
          <div
            title={row.values.name}
            style={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden"
            }}
          >
            <button
              className="btn btn-link"
              onClick={() => openApp(row.original._id)}
              type="button"
            >
              {row.values.name}
            </button>
          </div>
        );
      };

      const descriptionTemplate = ({ row }) => {
        return overflowTemplate(row.values.description);
      };

      const overflowTemplate = (value) => {
        if (value) {
          return (
            <div
              style={{
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden"
              }}
              title={value}
            >
              {value}
            </div>
          );
        }
        return <></>;
      };

      const acronymTemplate = ({ row }) => {
        return overflowTemplate(row.values.acronym);
      };

      if (isWpiiAssessment === true) {
        return [
          {
            Header: "Responsible Group",
            accessor: "wpiiImpactResponsibleGroup",
            width: 200,
            Filter: SelectColumnFilter
          },
          {
            Header: "Name",
            accessor: "name",
            Cell: nameTemplate,
            width: 280,
            Filter: FilterText
          },
          { Header: "ID", accessor: "oitId", width: 60, Filter: FilterText },
          {
            Header: "Acronym",
            accessor: "acronym",
            Cell: acronymTemplate,
            width: 85,
            Filter: FilterText
          },
          {
            Header: "WPII Impact Status",
            accessor: "wpiiImpactStatus",
            width: 275,
            Filter: SelectColumnFilter
          },
          {
            Header: "Description",
            accessor: "description",
            Cell: descriptionTemplate,
            width: 250,
            Filter: FilterText
          },
          {
            Header: "Type",
            accessor: "type",
            width: 100,
            Filter: SelectColumnFilter
          },
          {
            Header: "Project Impact",
            accessor: "wpiiHasProjectImpactYesNo",
            width: 100,
            Filter: SelectColumnFilter
          },
          {
            Header: "Contract Impact",
            accessor: "wpiiHasContractImpactYesNo",
            width: 100,
            Filter: SelectColumnFilter
          },
          {
            Header: "Budget Impact",
            accessor: "wpiiHasBudgetImpactYesNo",
            width: 100,
            Filter: SelectColumnFilter
          },
          {
            Header: "WPII Responsible Staff",
            accessor: "wpiiImpactResponsiblePerson",
            width: 210,
            Filter: FilterText,
            Cell: contactTemplate
          }
        ];
      } else if (isPalmAssessment) {
        return [
          {
            Header: "Responsible Group",
            accessor: "wpiiImpactResponsibleGroup",
            Filter: SelectColumnFilter,
            width: 175
          },
          {
            Header: "Name",
            accessor: "name",
            Cell: nameTemplate,
            Filter: FilterText,
            width: 350
          },
          { Header: "ID", accessor: "oitId", Filter: FilterText, width: 60 },
          {
            Header: "Acronym",
            accessor: "acronym",
            Filter: FilterText,
            Cell: acronymTemplate,
            width: 100
          },
          {
            Header: "Description",
            accessor: "description",
            Filter: FilterText,
            Cell: descriptionTemplate,
            width: 300
          },
          {
            Header: "Assesment Status",
            accessor: "palmStatus",
            Filter: SelectColumnFilter,
            width: 100
          },
          {
            Header: "Is Impacted",
            accessor: "palmIsImpacted",
            Filter: SelectColumnFilter,
            width: 100
          }
        ];
      } else if (isCloudFirstAssessment) {
        return [
          {
            Header: "Responsible Group",
            accessor: "wpiiImpactResponsibleGroup",
            width: 275,
            Filter: SelectColumnFilter
          },
          {
            Header: "Name",
            accessor: "name",
            Cell: nameTemplate,
            width: 300,
            Filter: FilterText
          },
          { Header: "ID", accessor: "oitId", width: 60, Filter: FilterText },
          {
            Header: "Acronym",
            accessor: "acronym",
            Cell: acronymTemplate,
            width: 100,
            Filter: FilterText
          },
          {
            Header: "Description",
            accessor: "description",
            Cell: descriptionTemplate,
            width: 425,
            Filter: FilterText
          },
          {
            Header: "Type",
            accessor: "type",
            width: 100,
            Filter: SelectColumnFilter
          },
          {
            Header: "Cloud Reporting",
            accessor: "cloudReporting",
            width: 150,
            Filter: SelectColumnFilter
          },
          {
            Header: "Hosting Type",
            accessor: "hostingType",
            width: 100,
            Filter: SelectColumnFilter
          }
        ];
      } else if (isQuick) {
        const columns = [
          {
            Header: "Name",
            accessor: "name",
            Cell: nameTemplate,
            width: 275,
            Filter: FilterText
          },
          {
            Header: "Responsible Group",
            accessor: "wpiiImpactResponsibleGroup",
            width: 300,
            Filter: SelectColumnFilter
          },
          { Header: "ID", accessor: "oitId", width: 60, Filter: FilterText },
          { Header: "Acronym", accessor: "acronym", Filter: FilterText, Cell: acronymTemplate },
          {
            Header: "Type",
            accessor: "type",
            width: 100,
            Filter: SelectColumnFilter
          },
          {
            Header: "Description",
            accessor: "description",
            Cell: descriptionTemplate,
            Filter: FilterText
          }
        ];

        let questions = assessment.questions
          .filter(q => {
            return q.showInGrid === true;
          })
          .sort((a, b) => {
            return a.order - b.order;
          });

        if (assessment.groups) {
          for (let group of assessment.groups) {
            const groupQuestions = group.questions
              .filter(q => {
                return q.showInGrid === true;
              })
              .sort((a, b) => {
                return a.order - b.order;
              });
            questions = [...questions, ...groupQuestions];
          }
        }
        for (let q of questions) {
          columns.push({
            accessor: `quickAssessments.${assessment._id}.${q._id}`,
            Header: q.shortText ? q.shortText : q.text,
            Cell: quickAssessmentTemplate,
            Filter: FilterText
          });
        }

        return columns;
      }
      return [
        {
          Header: "Responsible Group",
          accessor: "wpiiImpactResponsibleGroup",
          Filter: SelectColumnFilter,
          width: 175
        },
        {
          Header: "Name",
          accessor: "name",
          Cell: nameTemplate,
          Filter: FilterText,
          width: 350
        },
        { Header: "ID", accessor: "oitId", Filter: FilterText, width: 60 },
        {
          Header: "Acronym",
          accessor: "acronym",
          Filter: FilterText,
          Cell: acronymTemplate,
          width: 100
        },
        {
          Header: "Description",
          accessor: "description",
          Filter: FilterText,
          Cell: descriptionTemplate,
          width: 300
        },
        {
          Header: "Type",
          accessor: "type",
          Filter: SelectColumnFilter,
          width: 100
        },
        {
          Header: "Status",
          accessor: "wpiiInventoryStatus",
          Filter: SelectColumnFilter,
          width: 100
        }
      ];
    }, [
      isWpiiAssessment,
      isCloudFirstAssessment,
      isQuick,
      assessment,
      openApp
    ]);

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      page,
      setGlobalFilter,
      pageOptions,
      pageCount,
      gotoPage,
      setPageSize,
      state: { pageIndex, pageSize }
    } = useTable(
      {
        columns,
        data,
        initialState: {
          sortBy: [
            {
              id: "name",
              desc: false
            }
          ],
          pageSize: 50,
          autoResetFilters: false,
          autoResetPage: false
        },
        autoResetFilters: false,
        autoResetPage: false
      },
      useFilters, // useFilters!
      useGlobalFilter,
      useSortBy,
      usePagination,
      useFlexLayout
    );

    useEffect(() => {
      //console.log(rows);
      dispatch.Applications.setGridRows(
        rows.map(r => {
          return r.original;
        })
      );
    }, [rows, dispatch]);

    useEffect(() => {
    }, [isPalmAssessment])

    useEffect(() => {
      dispatch.QuickAssessment.setCurrentId(quickAssessmentId);
    }, [quickAssessmentId, dispatch]);

    useEffect(() => {
      if (
        (isWpiiAssessment === true && location.pathname.indexOf("Wpii") > -1) ||
        (isCloudFirstAssessment === true &&
          location.pathname.indexOf("CloudFirst") > -1) ||
        (isInventory === true &&
          isSearch === false &&
          location.pathname.indexOf("Inventory") > -1) ||
        (isSearch === true && location.pathname.indexOf("Search") > -1)
      ) {
        return;
      }

      dispatch.Applications.filterByInventoryStatus("Active");
      dispatch.Applications.filterByPalmImpact("");
      dispatch.Applications.search("");

      let type = "";

      if (location.pathname.indexOf("Wpii") > -1) {
        dispatch.Applications.filterByWpiiImpactStatus(
          "3. Impacted by WPII but outside of scope"
        );
        dispatch.Applications.filterByHostingType("");

        setIsSearch(false);
        setIsWpiiAssessment(true);
        type = "wpii";
        setIsInventory(false);
        setIsCloudFirstAssessment(false);
        setIsQuick(false);
      }
      else if (location.pathname.indexOf("Palm") > -1) {
        dispatch.Applications.filterByWpiiImpactStatus("");
        dispatch.Applications.filterByHostingType("");

        setIsSearch(false);
        setIsWpiiAssessment(false);
        setIsPalmAssessment(true);
        type = "palm";
        setIsInventory(false);
        setIsCloudFirstAssessment(false);
        setIsQuick(false);
      }
      else if (location.pathname.indexOf("CloudFirst") > -1) {
        dispatch.Applications.filterByWpiiImpactStatus("");
        type = "cloud";
        setIsSearch(false);
        setIsCloudFirstAssessment(true);
        setIsInventory(false);
        setIsWpiiAssessment(false);
        setIsPalmAssessment(false);
        setIsQuick(false);
      }
      else if (location.pathname.indexOf("Quick") > -1) {
        dispatch.Applications.filterByWpiiImpactStatus("");
        dispatch.Applications.filterByInventoryStatus("Active");
        type = "quick";
        setIsCloudFirstAssessment(false);
        setIsPalmAssessment(false);
        setIsInventory(false);
        setIsWpiiAssessment(false);
        setIsQuick(true);
      }
      else {
        dispatch.Applications.filterByWpiiImpactStatus("");
        dispatch.Applications.filterByHostingType("");
        dispatch.Applications.filterByHostingType("");
        setIsWpiiAssessment(false);
        setIsInventory(true);
        setIsCloudFirstAssessment(false);
        setIsPalmAssessment(false);
        setIsQuick(false);

        if (location.pathname.indexOf("Search") > -1) {
          setIsSearch(true);
          dispatch.Applications.filterByInventoryStatus("");
        } else {
          setIsSearch(false);
        }
      }

      if (id) {
        openApp(id, type);
      } else {
        dispatch.Applications.changeApplicationModalVisibility(false);
        dispatch.Applications.changeApplicationModalIsEditing(false);
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    const getHeaderName = () => {
      if (isSearch === true) return "IT Asset Search";
      if (isWpiiAssessment === true) return "WPII Impact Assessment";
      if (isInventory === true) return "IT Asset Inventory";
      if (isCloudFirstAssessment === true) return "Cloud First Assessment";
      if (isPalmAssessment === true) return "PALM Assessment";
      if (isQuick === true) return assessment ? assessment.name : "";
      return;
    };

    const getHeaderClass = () => {
      if (isWpiiAssessment === true || isPalmAssessment === true) return "bg-success text-white";
      if (isInventory === true) return "bg-primary text-white text-white";
      if (isCloudFirstAssessment === true) return "cloudHeader text-white";
      if (isQuick === true) return "bg-secondary text-white";
      return;
    };

    const getIcon = () => {
      if (isSearch === true)
        return <FontAwesomeIcon icon="search"></FontAwesomeIcon>;
      if (isWpiiAssessment === true || isPalmAssessment === true)
        return <FontAwesomeIcon icon="money-bill"></FontAwesomeIcon>;
      if (isInventory === true)
        return <FontAwesomeIcon icon="boxes"></FontAwesomeIcon>;
      if (isCloudFirstAssessment === true)
        return <FontAwesomeIcon icon="cloud"></FontAwesomeIcon>;
      if (isQuick === true)
        return <FontAwesomeIcon icon="rocket"></FontAwesomeIcon>;
      return;
    };

    const filterByTeam = teamId => {
      dispatch.Applications.filterByTeam(teamId);
    };

    const filterBySubTeam = subTeamId => {
      dispatch.Applications.filterBySubTeam(subTeamId);
    };

    const getGrid = () => {
      if (!headerGroups) {
        return <></>;
      }

      return (
        <>
          <div className="row">
            <div className="col"></div>
          </div>
          <div className="row">
            <div className="col-10"></div>
            <div className="col-2">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search"
                  onChange={e => {
                    setGlobalFilter(e.target.value || undefined);
                  }}
                />
                <div className="input-group-append input-sm">
                  <span className="input-group-text">
                    <FontAwesomeIcon icon="search"></FontAwesomeIcon>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <table
                {...getTableProps()}
                className="table table-sm table-bordered table-striped theader"
              >
                <thead>
                  {headerGroups.map((headerGroup, i) => {
                    return (
                      <React.Fragment key={i}>
                        <tr
                          {...headerGroup.getHeaderGroupProps()}
                          className="text-white bg-primary"
                          key={"i" + i}
                        >
                          {headerGroup.headers.map(column => (
                            <th
                              {...column.getHeaderProps(
                                column.getSortByToggleProps()
                              )}
                            >
                              <span className="font-weight-bold" style={{ fontSize: 14 }}>{column.render("Header")}</span>

                              <span className="float-right">
                                {column.isSorted ? (
                                  column.isSortedDesc ? (
                                    <FontAwesomeIcon icon="sort-up"></FontAwesomeIcon>
                                  ) : (
                                      <FontAwesomeIcon icon="sort-down"></FontAwesomeIcon>
                                    )
                                ) : (
                                    <FontAwesomeIcon icon="sort"></FontAwesomeIcon>
                                  )}
                              </span>
                            </th>
                          ))}
                        </tr>
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps()}>
                              {column.canFilter ? column.render("Filter") : null}
                            </th>
                          ))}
                        </tr>
                      </React.Fragment>
                    );
                  })}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {page.map((row, i) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell, i) => {
                          return (
                            <td {...cell.getCellProps()} className="small">
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div className="row">
            <div className="col-2">
              <ReactPaginate
                pageCount={pageCount}
                nextLabel=">"
                previousLabel="<"
                pageClassName="page-item"
                containerClassName="pagination"
                previousLinkClassName="page-link"
                nextLinkClassName="page-link"
                pageLinkClassName="page-link"
                activeClassName="active"
                onPageChange={e => gotoPage(e.selected)}
                marginPagesDisplayed={1}
                pageRangeDisplayed={3}
              ></ReactPaginate>
            </div>
            <div className="col-2">
              <div className="input-group">
                <select
                  className="form-control"
                  value={pageSize}
                  onChange={e => {
                    setPageSize(Number(e.target.value));
                  }}
                >
                  {[10, 20, 30, 40, 50].map(pageSize => (
                    <option key={pageSize} value={pageSize}>
                      {pageSize}
                    </option>
                  ))}
                </select>
                <div className="input-group-append input-sm">
                  <span className="input-group-text">Items per Page</span>
                </div>
              </div>
            </div>
            <div className="col text-right">
              <span>
                <strong>
                  {pageIndex + 1} of {pageOptions.length} pages ({rows.length}{" "}
                  items)
                </strong>{" "}
              </span>
            </div>
          </div>
        </>
      );
    };

    const getFilterCard = () => {
      return (
        <Card headerText={`${getHeaderName()} Criteria`} className="my-1 mp-1">
          <div className="row">
            <div className="col">
              Please select Responsible Group and then the Responsible
              Sub-Group.
            </div>
          </div>
          <div className="row">
            <div className="col-2 font-weight-bold">Responsible Group:</div>
            <div className="col">
              <select
                value={teamFilter}
                onChange={e => filterByTeam(e.target.value)}
                className="form-control"
              >
                <TeamOptions emptyText="Select" showAll={true}></TeamOptions>
              </select>
            </div>
            <div className="col-2 font-weight-bold">Responsible Sub-Group:</div>
            <div className="col">
              <select
                value={subTeamFilter}
                onChange={e => filterBySubTeam(e.target.value)}
                className="form-control"
                disabled={!teamFilter || teamFilter === "" || teamFilter === 'Select'}
              >
                <TeamOptions team={teamFilter} emptyText="Select"></TeamOptions>
              </select>
            </div>
          </div>
          <div className="row my-1">
            {isInventory === true ? (
              <>
                <div className="col-2 font-weight-bold">IT Asset Status:</div>
                <div className="col-4">
                  <select
                    className="form-control"
                    value={statusFilter}
                    onChange={e =>
                      dispatch.Applications.filterByInventoryStatus(
                        e.target.value
                      )
                    }
                  >
                    <option value="Active">Active</option>
                    <option value="Duplicate">Duplicate</option>
                    <option value="Obsolete">Obsolete</option>
                    <option value="Pending">Pending</option>
                    <option value="">All</option>
                  </select>
                </div>
              </>
            ) : (
                <></>
              )}
            {isCloudFirstAssessment === true ? (
              <>
                <div className="col-2 font-weight-bold">Cloud Reporting:</div>
                <div className="col-4">
                  <select
                    className="form-control"
                    value={cloudReportingFilter}
                    onChange={e =>
                      dispatch.Applications.filterByCloudReporting(
                        e.target.value
                      )
                    }
                  >
                    <option value="">All</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                </div>
              </>
            ) : (
                <></>
              )}
            {isCloudFirstAssessment === true ? (
              <>
                <div className="col-2 font-weight-bold">Hosting Type:</div>
                <div className="col-3">
                  <select
                    className="form-control"
                    value={hostingTypeFilter}
                    onChange={e =>
                      dispatch.Applications.filterByHostingType(e.target.value)
                    }
                  >
                    <option value="">All</option>
                    <option value="Cloud">Cloud</option>
                    <option value="District/Locally">District/Locally</option>
                    <option value="Hybrid">Hybrid</option>
                    <option value="State Data Center">State Data Center</option>
                  </select>
                </div>
              </>
            ) : (
                <></>
              )}
            {isWpiiAssessment === true ? (
              <>
                <div className="col-2 font-weight-bold">
                  WPII Impact Status:
                </div>
                <div className="col-4">
                  <select
                    className="form-control"
                    value={wpiiImpactStatusFilter}
                    onChange={e =>
                      dispatch.Applications.filterByWpiiImpactStatus([
                        e.target.value
                      ])
                    }
                  >
                    <WpiiImpactStatusOptions></WpiiImpactStatusOptions>
                  </select>
                </div>
              </>
            ) : (
                <></>
              )}
            {isInventory === true ? (
              <div className="col-2 font-weight-bold">
                Corrections Needed: &nbsp;&nbsp;
                <input
                  type="checkbox"
                  style={{ verticalAlign: "middle" }}
                  checked={onlyCorrectionsFilter}
                  onChange={() =>
                    dispatch.Applications.filterByCorrectionsNeeded(
                      !onlyCorrectionsFilter
                    )
                  }
                />
              </div>
            ) : (
                <></>
              )}
            <div className="col text-right">
              {isCloudFirstAssessment || isQuick ? (
                <></>
              ) : (
                  <ExportToExcel isPalmAssessment={isPalmAssessment}
                    isWpiiAssessment={isWpiiAssessment}
                  ></ExportToExcel>
                )}
            </div>
          </div>
        </Card>
      );
    };

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-1"></div>
          <div className={`col text-center ${getHeaderClass()} HeaderShadow`}>
            <h4>
              {getIcon()}&nbsp;&nbsp;
              {getHeaderName()}&nbsp;&nbsp;
              {getIcon()}
            </h4>
          </div>
          <div className="col-1 text-right">
            {isInventory && !isSearch ? <AddAsset></AddAsset> : ""}
          </div>
        </div>
        {isSearch ? <SearchList></SearchList> : getFilterCard()}
        {(!isSearch && teamFilter !== "Select") || (isSearch && searchTerm) ? (
          <div className="row flex-nowrap">
            <div className="col">{getGrid()}</div>
          </div>
        ) : (
            <></>
          )}
        <ApplicationModal rows={rows}></ApplicationModal>
        <AssessmentModal rows={rows}></AssessmentModal>
        <CloudFirstAssessment rows={rows}></CloudFirstAssessment>
        <QuickAssessment rows={rows}></QuickAssessment>
        <PalmModal rows={rows}></PalmModal>
      </div>
    );
  },
  (prevProps, nextProps) => {
    return true;
  }
);
