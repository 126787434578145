import React, { useState, useEffect } from 'react'
import axios from 'axios';
import routeConfig from '../routeConfig';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function Help(props) {
    const [helpArray, setHelpArray] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const result = await axios.get(`${routeConfig.baseUrl}/Help`);
            if (result.data && result.data.length > 0) {
                setHelpArray(result.data);
            }
        }

        fetchData();
    }, []);

    return <div className="container-fluid">
        <div className="row">
            <div className="col">
                <h3>Help</h3>
            </div>
        </div>
        <ul className="list-group">
            {helpArray ? helpArray.filter(h => {
                return h.isPublished && !h.isCloudFirst;
            }).map((h) => {
                return <li className="list-group-item" key={h._id}>
                    <div className="row">
                        <div className="col">
                            <a className="btn-primary btn" title={`Download ${h.name}`} target="_blank" rel="noopener noreferrer"
                                href={`${routeConfig.baseUrl}/Help/${h._id}/Download`}>
                                <FontAwesomeIcon icon="file-download" />&nbsp;&nbsp;
                            {h.name}
                            </a>
                            &nbsp;&nbsp;

                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            {h.description}
                        </div>
                    </div>
                </li>
            }) : ''}
        </ul>
    </div>
}
