import React from 'react';

export default function WpiiImpactStatusOptions(props) {
    return <>
        <option value="">All</option>
        <option value="1. Replaced by WPII">1. Replaced by WPII</option>
        <option value="2. Impacted by WPII and may fall in WPII scope">2. Impacted by WPII and may fall in WPII scope</option>
        <option value="3. Impacted by WPII but outside of scope">3. Impacted by WPII but outside of scope</option>
        <option value="4. Not Impacted by WPII">4. Not Impacted by WPII</option>
        <option value="8. Unknown">8. Unknown</option>
    </>
}
