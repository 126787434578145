import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector, useStore } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    useTable, useFilters, useSortBy, usePagination,
    useGlobalFilter
} from 'react-table';
import ReactPaginate from 'react-paginate'
import useReactRouter from 'use-react-router';
import InterfaceModal from './InterfaceModal';
import FilterText from '../Tables/FilterText';

export default function InterfaceList() {
    const store = useStore();
    const dispatch = useDispatch();
    const [isSolution, setIsSolution] = useState(false);
    const assetInterfaces = useSelector(state => store.select.AssetInterface.getInterfaces(state));
    const solutions = useSelector(state => store.select.AssetInterface.getSolutions(state));
    const { location } = useReactRouter();

    useEffect(() => {
        dispatch.AssetInterface.getInterfaces();
        if (location.pathname.indexOf('Solutions') > -1) {
            setIsSolution(true);
        } else {
            setIsSolution(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    const addAssetInterface = async () => {
        await dispatch.AssetInterface.addNewInterface(isSolution);
    }

    const getName = () => {
        return isSolution ? 'Solution' : 'IT Asset Interface';
    }

    const openModal = (id) => {
        dispatch.AssetInterface.showInfoTab();
        dispatch.AssetInterface.showAssetInterface();
        dispatch.AssetInterface.setCurrentInterfaceId(id);
    }

    const nameTemplate = ({ row }) => {
        return <div title={row.original.name}>
            <button type="button" className="btn btn-link" onClick={() => openModal(row.original._id)}>{row.original.type === 'Database' && row.original.databaseName ?
                `${row.original.databaseName} (${row.original.name})` : row.original.name}</button>
        </div >;
    };

    const columns = useMemo(() => {
        return [
            { Header: 'Name', accessor: 'name', Filter: FilterText, Cell: nameTemplate },
            { Header: 'Type', accessor: 'type', Filter: FilterText },
            { Header: 'Description', accessor: 'description', Filter: FilterText },
            { Header: 'Status', accessor: 'status', Filter: FilterText }
        ]
    }, [nameTemplate]);

    const data = useMemo(() => {
        return Object.values(isSolution ? solutions : assetInterfaces);
    }, [isSolution, assetInterfaces.length, solutions.length]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        page,
        pageOptions,
        pageCount,
        gotoPage,
        setPageSize,
        state: { pageIndex, pageSize }
    } = useTable(
        {
            columns,
            data: data,
            initialState: {
                sortBy: [
                    {
                        id: 'name',
                        desc: false,
                    }
                ]
            }
        },
        useFilters, // useFilters!
        useGlobalFilter,
        useSortBy,
        usePagination
    )

    return <>
        <div className="container-fluid">
            <div className="row">
                <h3 className="col">
                    {getName()}s
                </h3>
            </div>
            <div className="row">
                <div className="col text-right">
                    <button className="btn btn-primary" title={getName()} onClick={addAssetInterface}>
                        <FontAwesomeIcon icon="plus"></FontAwesomeIcon>
                            &nbsp;&nbsp; {getName()}
                    </button>
                </div>
            </div> : <></>
            <div className="row">
                <div className="col">
                    <table {...getTableProps()} className="table table-sm table-bordered table-striped">
                        <thead>
                            {headerGroups.map((headerGroup, i) => {
                                return <React.Fragment key={i}>
                                    <tr {...headerGroup.getHeaderGroupProps()} className="text-white bg-primary" key={'i' + i}>
                                        {headerGroup.headers.map(column => (
                                            <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                {column.render('Header')}
                                                <span className="float-right">
                                                    {column.isSorted
                                                        ? column.isSortedDesc
                                                            ? <FontAwesomeIcon icon="sort-up"></FontAwesomeIcon>
                                                            : <FontAwesomeIcon icon="sort-down"></FontAwesomeIcon>
                                                        : <FontAwesomeIcon icon="sort"></FontAwesomeIcon>}
                                                </span>
                                            </th>
                                        ))}
                                    </tr>
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map(column => (
                                            <th {...column.getHeaderProps()}>
                                                {column.canFilter ? column.render('Filter') : null}
                                            </th>
                                        ))}
                                    </tr>
                                </React.Fragment>
                            })}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {page.map((row, i) => {
                                prepareRow(row)
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map((cell, i) => {
                                            return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                        })}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    <div className="row">
                        <div className="col-2">
                            <ReactPaginate pageCount={pageCount} nextLabel=">" previousLabel="<" pageClassName="page-item" containerClassName="pagination"
                                previousLinkClassName="page-link" nextLinkClassName="page-link"
                                pageLinkClassName="page-link" activeClassName="active" onPageChange={e => gotoPage(e.selected)} marginPagesDisplayed={1}
                                pageRangeDisplayed={3} ></ReactPaginate>
                        </div>
                        <div className="col-2">
                            <div className="input-group">
                                <select className="form-control"
                                    value={pageSize}
                                    onChange={e => {
                                        setPageSize(Number(e.target.value))
                                    }} >
                                    {[10, 20, 30, 40, 50].map(pageSize => (
                                        <option key={pageSize} value={pageSize}>
                                            {pageSize}
                                        </option>
                                    ))}
                                </select>
                                <div className="input-group-append input-sm">
                                    <span className="input-group-text" >Items per Page</span>
                                </div>
                            </div>
                        </div>
                        <div className="col text-right">
                            <span>
                                <strong>
                                    {pageIndex + 1} of {pageOptions.length} pages ({rows.length} items)
                        </strong>{' '}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <InterfaceModal name={getName()} isSolution={isSolution}></InterfaceModal>
    </>
}
