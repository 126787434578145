import React from "react";
import { useSelector } from "react-redux";
import store from '../../../Store';
import routeConfig from '../../../routeConfig';

export default function ViewDocuments() {
    const application = useSelector(state => store.select.Applications.currentApplication(state));
    if (!application)
        return '';

    return <div>
        <ul className="list-group">
            {application.files.map((file) => {
                return <li className="list-group-item" key={file.blobName}>
                    <a download={file.originalname} href={`${routeConfig.baseUrl}/Application/${application._id}/Documents/${file.blobName}`}>{file.originalname}</a>
                </li>
            })}
        </ul>
        <div className="row">
            <div className="col"></div>
        </div>
    </div>

}
