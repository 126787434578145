import React, { useState } from 'react';
import { useSelector, useStore, useDispatch } from 'react-redux';
import Card from '../Card';
import DeepCopy from 'deep-copy';
import DeleteButton from '../DeleteButton';
import { format } from 'date-fns';

export default function EditCompletionCertification(props) {
    const store = useStore();
    const dispatch = useDispatch();
    const currentApplication = useSelector(state => store.select.Applications.currentApplication(state));
    const currentApplicationCompletion = useSelector(state => store.select.Applications.currentApplicationCompletion(state));
    const [validationWarningMessage, setValidationWarningMessage] = useState('');
    const [showValidation, setShowValidation] = useState(false);
    const assetInfoOpen = useSelector(state => state.Applications.assetInfoOpen);
    const interfaceOpen = useSelector(state => state.Applications.interfaceOpen);
    const rolesOpen = useSelector(state => state.Applications.rolesOpen);
    const technicalInfoOpen = useSelector(state => state.Applications.technicalInfoOpen);
    const dataInfoOpen = useSelector(state => state.Applications.dataInfoOpen);
    const componentsOpen = useSelector(state => state.Applications.componentsOpen);
    const documentsOpen = useSelector(state => state.Applications.documentsOpen);
    const user = useSelector(state => state.User.user);
    const interfacesToComponentsOpen = useSelector(state => state.Applications.interfacesToComponentsOpen);
    const [comments, setComments] = useState("");

    const validateAssetTab = () => {
        return true;
    }

    const validateArchitectureTab = () => {
        if (!currentApplication.applicationPlatforms || currentApplication.applicationPlatforms.length === 0) {
            setShowValidation(true);
            setValidationWarningMessage("There are no Application Platforms for the asset.");
            return false;
        } else if (!currentApplication.databasePlatforms || currentApplication.databasePlatforms.length === 0) {
            setShowValidation(true);
            setValidationWarningMessage("There are no Database Platforms for the asset.");
            return false;
        } else {
            setShowValidation(false);
        }

        return true;
    }

    const validateDataTab = () => {
        if (currentApplication.tables && currentApplication.tables.length > 0) {
            setShowValidation(false);
        } else {
            setShowValidation(true);
            setValidationWarningMessage("There are no tables for the asset.");
            return false;
        }
        return true;
    }

    const validateComponentsTab = () => {
        if (currentApplication.components && currentApplication.components.length > 0) {
            setShowValidation(false);
        } else {
            setShowValidation(true);
            setValidationWarningMessage("There are no components for the asset.");
            return false;
        }
        return true;
    }

    const validateComponentRelationshipsTab = () => {
        if (currentApplication.components) {
            if (currentApplication.components.indexOf(c => {
                if ((c.interfaces && c.interfaces.length > 0) || (c.relatedAssets && c.relatedAssets.length > 0)) {
                    return true;
                }
                return false;
            }) === -1) {

            }
        }
        return true;
    }

    const cancel = () => {
        setShowValidation(false);
        setComments("");
    }

    const validateInterfacesTab = () => {
        if (currentApplication.interfaceDependencies && currentApplication.interfaceDependencies.length > 0) {
            setShowValidation(false);
        } else {
            setShowValidation(true);
            setValidationWarningMessage("There are no Interfaces for the asset.");
            return false;
        }

        if (currentApplication.applicationDependencies && currentApplication.applicationDependencies.length > 0) {
            setShowValidation(false);
        } else {
            setShowValidation(true);
            setValidationWarningMessage("There are no related IT Assets for the asset.");
            return false;
        }
        return true;
    }

    const validate = () => {
        if (assetInfoOpen) {
            return validateAssetTab();
        }
        if (dataInfoOpen) {
            return validateDataTab();
        }
        if (technicalInfoOpen) {
            return validateArchitectureTab();
        }
        if (componentsOpen) {
            return validateComponentsTab();
        }
        if (interfacesToComponentsOpen) {
            return validateComponentRelationshipsTab();
        }
        if (interfaceOpen) {
            return validateInterfacesTab();
        }

        return true;
    }

    const certify = () => {
        if (validate()) {
            finalCertify();
        }
    }

    const setTabComplete = (completion, tab) => {
        if (!completion.tabCompletion[tab]) {
            completion.tabCompletion[tab] = {};
        }

        completion.tabCompletion[tab] =
        {
            ...completion.tabCompletion[tab],
            [new Date().getFullYear()]: {
                isComplete: true,
                user: user,
                update: new Date(),
                comments: comments
            }
        }
    }

    const isTabComplete = () => {
        const tab = getCurrentTab();
        if (currentApplicationCompletion && currentApplicationCompletion.tabCompletion[tab] && currentApplicationCompletion.tabCompletion[tab][new Date().getFullYear()]
            && currentApplicationCompletion.tabCompletion[tab][new Date().getFullYear()].isComplete) {
            return true;
        }

        return false;
    }

    const getCurrentTab = () => {
        if (assetInfoOpen) {
            return 'Asset';
        }
        if (dataInfoOpen) {
            return 'Data';
        }
        if (technicalInfoOpen) {
            return 'Architecture';
        }
        if (componentsOpen) {
            return 'Components';
        }
        if (interfacesToComponentsOpen) {
            return 'Component Relationships';
        }
        if (interfaceOpen) {
            return 'Interfaces';
        }
        if (documentsOpen) {
            return 'Documents';
        }
        if (rolesOpen) {
            return 'Roles';
        }
    }

    const deleteCompletion = (key) => {
        var completionCopy = DeepCopy(currentApplicationCompletion);
        completionCopy.tabCompletion[getCurrentTab()][key].isComplete = false;

        dispatch.Applications.persistApplicationCompletion(completionCopy);
    }

    const getHeaderClass = () => {
        let isComplete = isTabComplete();
        if (isComplete) {
            return "bg-success text-white"
        }

        return "bg-danger text-white"
    }

    const finalCertify = () => {
        let completion = {
            tabCompletion: {

            },
            application: currentApplication._id
        };

        if (currentApplicationCompletion) {
            completion = DeepCopy(currentApplicationCompletion);
        }

        setTabComplete(completion, getCurrentTab());

        setShowValidation(false);
        setComments("");
        dispatch.Applications.persistApplicationCompletion(completion);
    }

    return <Card headerText="Tab Completion Certification" headerClass={getHeaderClass()} >
        {!showValidation && isTabComplete() === false ?
            <div className="row">
                <div className="col">
                    <button type="button" className="btn btn-primary" onClick={certify}>Certify Tab Complete</button>
                </div>
            </div> : <></>}
        {isTabComplete() === true ?
            <>
                <div className="row">
                    <div className="col">
                        <table className="table table-sm table-bordered table-striped">
                            <thead>
                                <tr>
                                    <th>Year</th>
                                    <th>User</th>
                                    <th>Comments</th>
                                    <th>Date</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.keys(currentApplicationCompletion.tabCompletion[getCurrentTab()]).map((key, i) => {
                                    return <tr key={key}>
                                        <td>{key}</td>
                                        <td>{currentApplicationCompletion.tabCompletion[getCurrentTab()][key].user.firstName}&nbsp;
                                        {currentApplicationCompletion.tabCompletion[getCurrentTab()][key].user.lastName}&nbsp;
                                            ({currentApplicationCompletion.tabCompletion[getCurrentTab()][key].user.emailAddress})
                                        </td>
                                        <td>{currentApplicationCompletion.tabCompletion[getCurrentTab()][key].comments}</td>
                                        <td>
                                            {format(currentApplicationCompletion.tabCompletion[getCurrentTab()][key].update, 'MM/DD/YY')}
                                        </td>
                                        {/* eslint-disable-next-line */}
                                        <td>{key == new Date().getFullYear() ?
                                            <DeleteButton hideText={true} onClick={() => deleteCompletion(key)}></DeleteButton>
                                            : <>{key} - {new Date().getFullYear()}</>}
                                        </td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </>
            : <></>
        }
        {showValidation ?
            <>
                <div className="row text-danger">
                    <div className="col">
                        Completion Warnings:
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <ul className="text-danger">
                            <li>{validationWarningMessage}</li>
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col font-weight-bold">Comments:</div>
                </div>
                <div className="row">
                    <div className="col">
                        <textarea className="form-control" value={comments} onChange={e => setComments(e.target.value)}></textarea>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <button className="btn btn-primary" disabled={comments === ''} onClick={() => finalCertify()}>Certify Tab Complete</button>&nbsp;&nbsp;
                        <button className="btn btn-primary" onClick={cancel}>Cancel</button>
                    </div>
                </div>
            </> : <></>
        }
    </Card>
}
