import axios from 'axios';
import { toastr } from 'react-redux-toastr'
import routeConfig from '../routeConfig';
export const QuestionSet = {
    state: {
        currentId: null,
        questionSets: {

        },
    },
    reducers: {
        loadQuestionSets(state, questionSets) {
            let loadedQuestionSets = {};
            for (let i = 0; i < questionSets.length; i++) {
                loadedQuestionSets[questionSets[i]._id] = questionSets[i];
            }
            return { ...state, questionSets: loadedQuestionSets };
        },
        addQuestionSet(state, questionSet) {
            return {
                ...state, questionSets: {
                    ...state.questionSets, [questionSet._id]: questionSet
                }, currentId: questionSet._id
            }
        },
        saveQuestionSet(state, questionSet) {
            return {
                ...state, questionSets: {
                    ...state.questionSets, [questionSet._id]: {
                        ...state.questionSets[questionSet._id], ...questionSet
                    }
                }
            }
        },
        setCurrentId(state, id) {
            return { ...state, currentId: id };
        }
    },
    effects: {
        async getQuestionSets() {
            const resp = await axios.get(`${routeConfig.baseUrl}/QuestionSet`);
            this.loadQuestionSets(resp.data);
        },
        async persistNewQuestionSet(QuestionSet, rootState) {
            const response = await axios.post(`${routeConfig.baseUrl}/QuestionSet`, QuestionSet);
            this.addQuestionSet(response.data);
            toastr.success('New QuestionSet Added')
        },
        async persistQuestionSet(payload, rootState) {
            const response = await axios.put(`${routeConfig.baseUrl}/QuestionSet/${rootState.QuestionSet.currentId}`,
                rootState.QuestionSet.questionSets[rootState.QuestionSet.currentId]);
            this.addQuestionSet(response.data);
            toastr.success('QuestionSet Saved');
        },
        async loadQuestionSet(id) {
            const response = await axios.get(`${routeConfig.baseUrl}/QuestionSet/${id}`);
            this.addQuestionSet(response.data);
        }
    }
}
