import React, { useState } from 'react';
import { Collapse } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default function Card(props) {
    const [collapsed, setCollapsed] = useState(props.collapsed);

    const classNameForHeader = props.headerClass ? "card-header " + props.headerClass : "card-header bg-primary text-white ";
    return <div className="card">
        <div className={classNameForHeader}>
            <h5>
                {props.hideCollapse ? '' :
                    collapsed === true ?
                        <button className="iconBtn text-white" onClick={() => setCollapsed(false)} type="button">
                            <FontAwesomeIcon icon="plus-circle" />
                        </button> :
                        <button className="iconBtn text-white" onClick={() => setCollapsed(true)} type="button">
                            <FontAwesomeIcon icon="minus-circle" />
                        </button>
                }
                <span className="text-center">
                    {props.headerText}&nbsp;&nbsp;
                    {props.badgeCount ?
                        <span className="badge badge-light">{props.badgeCount}</span>
                        : ''}
                </span>

            </h5>
        </div>
        <Collapse isOpen={!collapsed}>
            <div className="card-body py-2">
                {props.children}
            </div>
        </Collapse>
    </div>
}

