import { useSelector, useDispatch } from 'react-redux';
export default function usePreviousNextModal() {
    const dispatch = useDispatch();
    const gridRows = useSelector(state => state.Applications.gridRows);
    const applicationModalIsEditing = useSelector(state => state.Applications.applicationModalIsEditing);
    const currentApplicationId = useSelector(state => state.Applications.currentApplicationId);

    const getCurrentIndex = () => {
        return gridRows.findIndex((item) => {
            return item._id === currentApplicationId;
        });
    }

    const nextApp = () => {
        var sortedData = gridRows;

        const currentIndex = getCurrentIndex();
        if (currentIndex < sortedData.length - 1) {
            dispatch.Applications.setCurrentApplicationId(sortedData[currentIndex + 1]._id)
        }
    }

    const previousApp = () => {
        var sortedData = gridRows;

        const currentIndex = getCurrentIndex();
        if (currentIndex > 0) {
            dispatch.Applications.setCurrentApplicationId(sortedData[currentIndex - 1]._id);
        }
    }

    const tabClicked = (propName) => {
        dispatch.Applications.changeApplicationModalIsEditing(false);
        dispatch.Applications.setTabVisible(propName);
    }

    const getTabView = (editComponent, viewComponent) => {
        if (applicationModalIsEditing) {
            return editComponent;
        }
        return viewComponent;
    }


    return {
        nextApp,
        previousApp,
        tabClicked,
        getTabView,
        gridRows
    };
}
