import React from 'react';
import AddButton from '../AddButton';
import { useSelector, useDispatch } from 'react-redux';

export default function AddAsset(props) {
    const dispatch = useDispatch();
    const user = useSelector(state => state.User.user);
    const addApplication = () => {
        dispatch.Applications.createNewApplication({
            ...user,
            id: user.staffId
        });
    }

    return <AddButton text="IT Asset" onClick={addApplication} isSmall={true}></AddButton>
}
