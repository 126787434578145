import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
    Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavLink,
    NavItem, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap';
import routeConfig from '../routeConfig';
import DeleteButton from './DeleteButton';

export default function Header() {
    const [isOpen, setIsOpen] = useState(false);
    const [isTest, setIsTest] = useState(false);
    const [isBannerShown, setIsBannerShown] = useState(true);
    const user = useSelector(state => state.User.user);
    const isLoaded = useSelector(state => state.ApplicationLoadState.isLoaded);
    const dashboards = useSelector(state => state.Dashboard.dashboards);
    const quickAssessments = useSelector(state => state.QuickAssessment.quickAssessments);

    useEffect(() => {
        if (window.location.hostname.toUpperCase().indexOf('WARPTEST') > -1 ||
            window.location.hostname.toUpperCase().indexOf('COMPASSTEST') > -1) {
            setIsTest(true);
        }
    }, []);

    const toggle = () => {
        setIsOpen(!isOpen);
    }

    const getReports = () => {
        return <>
            <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret className="HeaderNavItem">
                    Reports
                    </DropdownToggle>
                <DropdownMenu right>
                    <DropdownItem>
                        <Link to="/Reports/CloudStrategicPlan">Cloud Strategic Plan Report</Link> <br />
                        <Link to="/Reports/DuplicateNameReport">Duplicate Name Report</Link> <br />
                        <Link to="/Reports/IncompleteCloudFirstReport">Incomplete Cloud First Report</Link> <br />
                        <Link to="/AuthorGitStatistics">GIT Statistics</Link> <br />
                        <Link to="/Reports/ImpactedTableReport">Impacted Table Report</Link> <br />
                        <Link to="/Reports/AssetChangeReport">IT Asset Change Report</Link> <br />
                        <Link to="/Reports/InactiveStaff">Inactive Staff Report</Link><br />
                        <Link to="/Reports/InventoryStatus">Inventory Status Report</Link><br />
                        {user.isOit ? <>
                            <Link to="/Reports/NpmAuditReport">Npm Audit Report</Link><br />
                        </> : <></>}
                        <Link to="/Reports/PackageAnalysisReport">Package Analysis Report</Link><br />
                            <Link to="/Reports/PalmDashboard">Palm Impacted Asset Statistics</Link><br />
                        <Link to="/Reports/Dashboard">WPII Impacted Asset Statistics</Link><br />
                        <hr />
                        {Object.values(quickAssessments).filter(q => {
                            return q.isObsolete !== true;
                        }).sort((a, b) => { return a.name < b.name ? -1 : 1 })
                            .map((q, i) => {
                                return <React.Fragment key={i}>
                                    <Link to={`/Reports/QuickAssessment/${q._id}`} >{q.name} Report</Link>
                                    <br />
                                </React.Fragment>
                            })}
                    </DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>
        </>
    }

    const getDashboards = () => {
        return <>
            <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret className="HeaderNavItem">
                    Dashboards
                    </DropdownToggle>
                <DropdownMenu right>
                    <DropdownItem>
                        <Link to="/Reports/DashboardConfiguration">Dashboard Configuration</Link><br />
                        <Link to="/Reports/QueryBuilder">Query Builder</Link><br />
                        {dashboards && dashboards.length > 0 ?
                            <hr /> : <></>}
                        {dashboards.filter(d => {
                            return d.rows && d.rows.length > 0;
                        }).map((d, i) => {
                            return <React.Fragment key={d._id}><Link to={`/Dashboard/${d._id}`} >{d.name}</Link> <br /></React.Fragment>
                        })}
                    </DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>
        </>
    }

    const getAdminSection = () => {
        if (user.isAdmin) {
            return <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret className="HeaderNavItem">
                    Administration
                </DropdownToggle>
                <DropdownMenu right>
                    <DropdownItem>
                        <Link to="/Admin">Admin Users</Link><br />
                        <Link to="/ApplicationPlatforms">Application Platforms</Link><br />
                        <Link to="/CronJobDashboard">Cron Job Dashboard</Link><br />
                        <Link to="/Databases">Database Platforms</Link><br />
                        {/*<Link to="/DimScoring">Dim Scoring</Link><br />*/}
                        <Link to="/EditHelp">Edit Help</Link><br />
                        <Link to="/ImpactSuggestion">Impact Suggestions</Link><br />
                        <Link to="/ImportList">Import Assets</Link><br />
                        <Link to="/ImportCloudFirstAssessment">Import Cloud First Assessments</Link><br />
                        <Link to="/ImportInterface">Import Interface</Link><br />
                        <Link to="/MenuConfiguration">Menu Configuration</Link><br />
                        <Link to="/QuickAssessment">Quick Assessments</Link><br />
                        <Link to="/Teams">Teams</Link><br />
                        <Link to="/UpdateUsers">Update Users</Link><br />
                    </DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>
        }
        return;
    }

    const getNavItems = () => {
        if (user && user.oid && isLoaded) {
            return <>
                <NavItem>
                    <NavLink tag={Link} to="/Search" className="HeaderNavItem" id="inventoryNavLink">Search</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink tag={Link} to="/Inventory" className="HeaderNavItem" id="inventoryNavLink">Inventory</NavLink>
                </NavItem>
                <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle nav caret className="HeaderNavItem">
                        Assessments
                        </DropdownToggle>
                    <DropdownMenu right>
                        <DropdownItem>
                            <Link to="/Assessment/CloudFirst">Cloud First Assessment</Link> <br />
                                <Link to="/Assessment/Palm">PALM Assessment</Link> <br />
                            <Link to="/Assessment/Wpii">WPII Assessment</Link> <br />
                            <hr />
                            {Object.values(quickAssessments).filter(q => {
                                return q.isObsolete !== true;
                            }).sort((a, b) => { return a.name < b.name ? -1 : 1 })
                                .map((q, i) => {
                                    return <React.Fragment key={i}>
                                        <Link to={`/Assessment/Quick/${q._id}`} >{q.name}</Link>
                                        <br />
                                    </React.Fragment>
                                })}
                        </DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
                <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle nav caret className="HeaderNavItem">
                        Interfaces
                        </DropdownToggle>
                    <DropdownMenu right>
                        <DropdownItem>
                            <Link to="/Interfaces">Interfaces</Link><br />
                            <Link to="/Solutions">Solutions</Link><br />
                        </DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
                {getReports()}
                {getDashboards()}
                <NavItem>
                    <NavLink tag={Link} to="/Help" className="HeaderNavItem">Help</NavLink>
                </NavItem>
                {getAdminSection()}
                <NavItem>
                    <a className="nav-link HeaderNavItem" href={routeConfig.logoutUrl}>Log out</a>
                </NavItem>
            </>
        }
        return <>
            <NavItem>
                <NavLink tag={Link} to="/Help" className="HeaderNavItem">Help</NavLink>
            </NavItem>
            {!user || !user.oid ?
                <NavItem>
                    <a className="nav-link HeaderNavItem" href={routeConfig.loginUrl}>Login</a>
                </NavItem> : <></>}
        </>
    }

    return <>
        <Navbar color="primary" dark expand="md" style={{ margin: 0, padding: 0 }}>
            <NavbarBrand tag={Link} to="/" style={{ margin: 0, padding: 0 }}>
                <img src="/compass.png" style={{ height: 60 }} alt="Application System List Redux"></img>
            </NavbarBrand>
            <NavbarToggler onClick={toggle} />
            <Collapse isOpen={isOpen} navbar>
                <Nav className="ml-auto" navbar>
                    {getNavItems()}
                </Nav>
            </Collapse>
        </Navbar>
        {isTest && isBannerShown ? <div className="container-fluid bg-warning">
            <div className="row text-center font-weight-bold">
                <div className="col"></div>
                <div className="col">Warning: This is a Test Environment!</div>
                <div className="col text-right">
                    <DeleteButton hideText={true} isSmall={true} onClick={() => setIsBannerShown(false)}></DeleteButton>
                </div>
            </div>
        </div> : ''}
    </>
}
