import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function FontAwesomeButton(props) {
    const text = props.text ? <>&nbsp;&nbsp;{props.text}</> : '';
    return <button disabled={props.disabled} className={`btn ${props.danger ? 'btn-danger' : 'btn-primary'} ${props.small ? 'btn-sm' : ''}`}
        onClick={props.onClick} title={props.title}>
        <FontAwesomeIcon icon={props.icon}></FontAwesomeIcon>
        {text}
    </button>
}
