import { useState } from "react";
import deepCopy from 'deep-copy';
export function useNestedArrayState(initialState) {
    const copiedState = initialState ? deepCopy(initialState) : initialState;
    const [state, setState] = useState(copiedState);

    const setInternalState = (state) => {
        setState(state);
    }

    const setStateProperty = (name, e) => {
        let value = e;
        if (e && e.target) {
            if (e.target.type === "checkbox") {
                value = e.target.checked;
            } else {
                value = e.target.value;
            }
        }

        setState({
            ...state,
            [name]: value
        });
    }

    const setStateNestedProperty = (propertyName, nestPropertyName, value) => {
        const copy = deepCopy(state);

        copy[propertyName][nestPropertyName] = value;
        setState(copy);
    }

    const setStateArrayProperty = (arrayName, index, propName, e) => {
        let arrayCopy = [...state[arrayName]];
        let value = e;
        if (e && e.target) {
            if (e.target.type === "checkbox") {
                value = e.target.checked;
            } else {
                value = e.target.value;
            }
        }
        arrayCopy[index][propName] = value;
        setState({
            ...state,
            [arrayName]: arrayCopy
        });
    }

    const setNestedStateArrayProperty = (propertyName, nestedName, index, propName, e) => {
        const copy = deepCopy(state);

        let value = e;
        if (e && e.target) {
            if (e.target.type === "checkbox") {
                value = e.target.checked;
            } else {
                value = e.target.value;
            }
        }

        copy[propertyName][nestedName][index][propName] = value;
        setState(copy);
    }

    const setNestedNestedPropertyState = (property, nestedProperty, nestedNestedProperty, e) => {
        let value = e;
        if (e && e.target) {
            if (e.target.type === "checkbox") {
                value = e.target.checked;
            } else {
                value = e.target.value;
            }
        }

        var newState = {
            ...state,
            [property]: {
                ...state[property],
                [nestedProperty]: {
                    ...state[property][nestedProperty],
                    [nestedNestedProperty]: value
                }
            }
        };

        setState(newState);
    }

    const setStateArrayofArrayProperty = (arrayName, index, propName, innerArrayIndex, innerPropName, e) => {
        let arrayCopy = [...state[arrayName]];
        let value = e;
        if (e && e.target) {
            if (e.target.type === "checkbox") {
                value = e.target.checked;
            } else {
                value = e.target.value;
            }
        }

        arrayCopy[index][propName][innerArrayIndex][innerPropName] = value;
        setState({
            ...state,
            [arrayName]: arrayCopy
        });
    }

    const addToArrayProperty = (arrayName, value) => {
        let newArray = [value];
        if (state[arrayName]) {
            newArray = [...state[arrayName], value]
        }
        setState({
            ...state,
            [arrayName]: newArray
        });
    }

    const addToNestedNestedPropertyArray = (property, nestedProperty, value) => {
        const copy = deepCopy(state);
        if (!copy[property]) {
            copy[property] = {
                [nestedProperty]: []
            }
        }
        else if (!copy[property][nestedProperty]) {
            copy[property][nestedProperty] = [];
        }
        copy[property][nestedProperty].push(value);
        console.log(copy);
        setState(copy);
    }

    const addToNestedArrayProperty = (arrayName, index, propName, value) => {
        let newArray = [...state[arrayName]];
        let innerArray;

        console.log(arrayName);
        console.log(newArray);
        console.log(index);
        console.log(propName);

        if (newArray[index][propName]) {
            innerArray = newArray[index][propName];
        } else {
            innerArray = [];
        }
        newArray[index][propName] = [...innerArray, value];
        setState({
            ...state,
            [arrayName]: newArray
        });
    }

    const addToNestedNestedArrayProperty = (arrayName, index, nestedArrayName, nestedIndex, propName, value) => {
        let newArray = [...state[arrayName]];
        let innerArray;

        if (newArray[index][nestedArrayName][nestedIndex]) {
            innerArray = newArray[index][nestedArrayName][nestedIndex][propName];
        } else {
            innerArray = [];
        }

        newArray[index][nestedArrayName][nestedIndex][propName] = [...innerArray, value];
        setState({
            ...state,
            [arrayName]: newArray
        });
    }

    const setStateArrayofArrayofArrayProperty = (arrayName, index, propName, innerArrayIndex, innerPropName,
        innerInnerArrayIndex, innerInnerPropName, e) => {
        let arrayCopy = [...state[arrayName]];
        arrayCopy[index][propName][innerArrayIndex][innerPropName][innerInnerArrayIndex][innerInnerPropName] = e.target.value;
        setState({
            ...state,
            [arrayName]: arrayCopy
        });
    }

    const addToNestedArray = (property, arrayName, value) => {
        let copy;
        if (!state[property]) {
            copy = { nestedProperty: {} };
        } else {
            copy = { ...state[property] }
        }
        if (state[property][arrayName]) {
            copy[arrayName] = state[property][arrayName];
        }
        else {
            copy[arrayName] = [];
        }
        copy[arrayName].push(value);
        const newState = {
            ...state,
            [property]: copy
        };
        setState(newState);
    }

    const addToNestedArrayNestedArray = (property, nestedProperty, index, nestedArray, value) => {
        const copy = deepCopy(state);
        if (!copy[property][nestedProperty][index][nestedArray]) {
            copy[property][nestedProperty][index][nestedArray] = [];
        }
        copy[property][nestedProperty][index][nestedArray].push(value);
        setState(copy);
    }

    const removeFromNestedArrayNestedArray = (property, nestedProperty, index, nestedArray, nestedIndex) => {
        const copy = deepCopy(state);
        copy[property][nestedProperty][index][nestedArray] = removeFromArray(copy[property][nestedProperty][index][nestedArray], nestedIndex);

        setState(copy);
    }

    const addToNestedNestedArrayNestedArray = (property, nestedProperty, index, nestedArray, value) => {
        const copy = deepCopy(state);
        copy[property][nestedProperty][index][nestedArray].push(value);
        setState(copy);
    }

    const addToNestedNestedArrayNestedArrayNestedArray = (property, nestedProperty, index, nestedArray, nestedIndex, nestedNestedProps, value) => {
        const copy = deepCopy(state);
        if (!copy[property][nestedProperty][index][nestedArray][nestedIndex][nestedNestedProps]) {
            copy[property][nestedProperty][index][nestedArray][nestedIndex][nestedNestedProps] = [];
        }
        copy[property][nestedProperty][index][nestedArray][nestedIndex][nestedNestedProps].push(value);
        setState(copy);
    }

    const removeFromNestedNestedArrayNestedArray = (property, nestedProperty, index, nestedArray, nestedIndex) => {
        const copy = deepCopy(state);
        copy[property][nestedProperty][index][nestedArray] = removeFromArray(copy[property][nestedProperty][index][nestedArray], nestedIndex);

        setState(copy);
    }

    const removeFromNestedNestedArrayNestedNestedArray = (property, nestedProperty, index, nestedArray, nestedIndex, nestedNestedArray, nestedNestedIndex) => {
        const copy = deepCopy(state);
        console.log(copy[property][nestedProperty][index][nestedArray]);
        copy[property][nestedProperty][index][nestedArray][nestedIndex][nestedNestedArray] = removeFromArray(copy[property][nestedProperty][index][nestedArray][nestedIndex][nestedNestedArray], nestedNestedIndex);

        setState(copy);
    }

    const removeFromNestArray = (propertyName, arrayName, index) => {
        if (index === -1)
            return;

        var a = [...state[propertyName][arrayName]];
        const newArray = removeFromArray(a, index);
        setState({
            ...state,
            [propertyName]: {
                ...state[propertyName],
                [arrayName]: newArray
            }
        });
    }

    const removeFromArray = (array, index) => {
        return [...array.slice(0, index), ...array.slice(index + 1, array.length)];
    }

    const removeFromArrayProperty = (arrayName, index) => {
        if (index === -1)
            return;

        var a = [...state[arrayName]];
        const newArray = removeFromArray(a, index);
        setState({
            ...state,
            [arrayName]: newArray
        });
    }

    const removeFromNestedArrayProperty = (arrayName, arrayIndex, propName, indexToRemove) => {
        var newArray = [...state[arrayName]];
        newArray[arrayIndex][propName] = removeFromArray(newArray[arrayIndex][propName], indexToRemove);
        setState({
            ...state,
            [arrayName]: newArray
        });
    }

    const removeFromNestedNestedArrayProperty = (arrayName, arrayIndex, propName, innerArrayIndex, innerPropName, indexToRemove) => {
        var newArray = [...state[arrayName]];
        newArray[arrayIndex][propName][innerArrayIndex][innerPropName] = removeFromArray(newArray[arrayIndex][propName][innerArrayIndex][innerPropName], indexToRemove);
        setState({
            ...state,
            [arrayName]: newArray
        });
    }


    const clearFromNestedNestedArrayProperty = (arrayName, arrayIndex, propName, innerArrayIndex, innerPropName) => {
        var newArray = [...state[arrayName]];
        newArray[arrayIndex][propName][innerArrayIndex][innerPropName] = [];
        setState({
            ...state,
            [arrayName]: newArray
        });
    }

    return {
        state,
        setState: setInternalState,
        setStateProperty,
        setStateArrayProperty,
        setNestedNestedPropertyState,
        clearFromNestedNestedArrayProperty,
        setStateArrayofArrayProperty,
        setStateArrayofArrayofArrayProperty,
        addToArrayProperty,
        addToNestedArrayProperty,
        addToNestedNestedArrayProperty,
        removeFromArrayProperty,
        removeFromNestedArrayProperty,
        removeFromNestedNestedArrayProperty,
        addToNestedArray,
        removeFromNestArray,
        addToNestedArrayNestedArray,
        removeFromNestedArrayNestedArray,
        addToNestedNestedArrayNestedArray,
        removeFromNestedNestedArrayNestedNestedArray,
        removeFromNestedNestedArrayNestedArray,
        setNestedStateArrayProperty,
        addToNestedNestedPropertyArray,
        addToNestedNestedArrayNestedArrayNestedArray,
        setStateNestedProperty
    };
}
