import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { toastr } from 'react-redux-toastr';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import store from '../../../Store';
import Card from '../../Card';
import SaveCancel from '../../SaveCancel';
import { useNestedArrayState } from '../../../hooks/useNestedArrayState';
import { Typeahead } from 'react-bootstrap-typeahead';
import useApplicationTypeaheadOptions from '../../../hooks/useApplicationTypeaheadOptions';
import AddButton from '../../AddButton';
import DeleteButton from '../../DeleteButton';
import { FilePond } from "react-filepond";
import routeConfig from '../../../routeConfig';
import axios from 'axios';

export default function EditInterfaceDependencies(props) {
    const dispatch = useDispatch();
    const application = useSelector(state => store.select.Applications.currentApplication(state));
    const assetInterfaces = useSelector(state => state.AssetInterface.assetInterfaces);
    const applications = useSelector(state => state.Applications.applications);
    const user = useSelector(state => state.User.user);
    const appOptions = useApplicationTypeaheadOptions();

    const [selectedApplication, setSelectedApplication] = useState(null);

    const [selectedInterface, setSelectedInterface] = useState('');
    const [selectedTable, setSelectedTable] = useState('');
    const [selectedClass, setSelectedClass] = useState('');
    const [selectedMethod, setSelectedMethod] = useState('');
    const [selectedOperation, setSelectedOperation] = useState('');
    const [selectedComponent, setSelectedComponent] = useState({});
    const [depedencies, setDepedencies] = useState([]);

    const {
        state,
        setStateArrayProperty,
        addToArrayProperty,
        addToNestedArrayProperty,
        removeFromArrayProperty,
        addToNestedNestedArrayProperty,
        removeFromNestedArrayProperty,
        removeFromNestedNestedArrayProperty,
        clearFromNestedNestedArrayProperty
    } = useNestedArrayState(application);

    const onSubmit = async values => {
        dispatch.Applications.persistApplication(state);
        dispatch.Applications.changeApplicationModalIsEditing(false);
    }

    useEffect(() => {
        if (!application) {
            return
        }
        for (let appDep of application.applicationDependencies) {
            dispatch.Applications.loadApplication(appDep.asset);
        }
        axios.get(`${routeConfig.baseUrl}/Application/${application._id}/Dependencies`).then(res => {
            setDepedencies(res.data);
        });

        // eslint-disable-next-line 
    }, [application]);

    const selectApp = (selected) => {
        let returnVal;
        if (selected && selected.length > 0) {
            returnVal = selected[0].id;
            if (returnVal && depedencies) {
                for (let appDep of depedencies) {

                    if (appDep._id == returnVal) {
                        toastr.warning("Asset selected already relates to the current asset!");
                    }
                }
            }
        }
        setSelectedApplication(returnVal);
    }

    const getOptions = (array) => {
        const options = [<option key="null">Select</option>];

        if (array) {
            array = array.filter(a => {
                if (a.status === 'Pending') {
                    return false;
                }
                if (a.subTeam) {
                    return application.subTeam === a.subTeam;
                }
                if (a.team) {
                    return application.team === a.team;
                }
                return true;
            });
            array.sort((a, b) => {
                if (a.name < b.name) {
                    return -1;
                }
                if (a.name > b.name) {
                    return 1;
                }
                return 0;
            });
            for (let index = 0; index < array.length; index++) {
                const item = array[index];
                options.push(<option key={index} value={item._id}>{item.name}</option>);
            }
        }

        return options;
    }

    const addApplication = () => {
        const applicationExists = state.applicationDependencies.some((d) => {
            return d === selectedApplication;
        });

        if (applicationExists) {
            toastr.error("The IT Asset has already been added to the dependencies.")
        }
        else {
            dispatch.Applications.loadApplication(selectedApplication);
            addToArrayProperty('applicationDependencies', { asset: selectedApplication, use: '', type: 'FOCUS Include' });
        }
    }

    const addInterface = () => {
        const applicationExists = state.interfaceDependencies.some((d) => {
            return d === selectedInterface;
        });

        if (applicationExists) {
            toastr.error("The interface has already been added to the dependencies.")
        }
        else {
            addToArrayProperty('interfaceDependencies', { interface: selectedInterface });
        }
    }

    const getComponentName = (componentId, assetId) => {
        const component = applications[assetId].components.find(c => {
            return c._id === componentId;
        });

        if (component) {
            return component.name;
        }
    }

    const checkFieldChanged = (fieldId, property, index, tableIndex, e) => {
        if (e.target.checked) {
            addToNestedNestedArrayProperty(property, index, 'tablesUsed', tableIndex, 'fields', fieldId);
        } else {
            const fieldIndex = state[property][index].tablesUsed[tableIndex].fields.findIndex(f => {
                return f === fieldId;
            })
            removeFromNestedNestedArrayProperty(property, index, 'tablesUsed', tableIndex, 'fields', fieldIndex);
        }
    }

    const getFieldTable = (propertyName, subPropertyName, sliceIndex1, sliceIndex2, fields, table, index, tableIndex) => {
        return <table className="table table-striped table-bordered table-sm">
            <thead>
                <tr>
                    <th>Used</th>
                    <th>Name</th>
                    <th>Logical Name</th>
                </tr>
            </thead>
            <tbody>
                {fields.slice(sliceIndex1, sliceIndex2).map((field, fieldIndex) => {
                    return <tr key={fieldIndex}>
                        <td>
                            <input type="checkbox" onChange={e => checkFieldChanged(field._id, propertyName, index, tableIndex, e)}
                                checked={table.fields.findIndex(f => {
                                    return f === field._id
                                }) > -1}></input>
                        </td>
                        <td>
                            {field.name}
                        </td>
                        <td>
                            {field.logicalName}
                        </td>
                    </tr>
                })}
            </tbody>
        </table>
    }

    const getFieldsUsedCard = (propertyName, subPropertyName, table, index, tableIndex, lookupProperty) => {
        const foundTable = lookupProperty[state[propertyName][index][subPropertyName]].tables.find(t => {
            return t._id === table.table;
        });

        if (!foundTable) {
            return <></>
        }
        
        const fields = foundTable.fields.sort((a, b) => {
            return a.name < b.name ? -1 : 1;
        });

        const unselect = () => {
            clearFromNestedNestedArrayProperty(propertyName, index, 'tablesUsed', tableIndex, 'fields');
        }

        const select = () => {
            for (let i = 0; i < fields.length; i++) {
                addToNestedNestedArrayProperty(propertyName, index, 'tablesUsed', tableIndex, 'fields', fields[i]._id);
            }
        }

        return <Card headerText="Fields Used" collapsed={true}>
            <div className="row">
                <div className="col">
                    <button className="btn btn-sm btn-primary" type="button" onClick={select}>Select All</button>
                    &nbsp;&nbsp;
                    <button className="btn btn-sm btn-primary" type="button" onClick={unselect}>Unselect All</button>
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    {getFieldTable(propertyName, subPropertyName, 0, Math.floor(fields.length / 2), fields, table, index, tableIndex)}
                </div>
                <div className="col-6">
                    {getFieldTable(propertyName, subPropertyName, Math.floor(fields.length / 2), fields.length, fields, table, index, tableIndex)}
                </div>
            </div>
        </Card>
    }

    return <>
        <Card headerText="Service Interfaces" collapsed={true}>
            <div className="row">
                <div className="col"></div>
                <div className="col text-right">
                    <div className="input-group">
                        <select name="" id="" className="form-control" onChange={e => setSelectedInterface(e.target.value)}>
                            {getOptions(Object.values(assetInterfaces))}
                        </select>
                        <div className="input-group-append">
                            <button className="btn btn-primary" disabled={!selectedInterface}
                                onClick={addInterface} type="button"><FontAwesomeIcon icon="plus" />&nbsp;&nbsp;Interface</button></div>
                    </div>
                </div>
            </div>
            <div>
                {state.interfaceDependencies.map((interfaceDep, index) => {
                    return <Card collapsed={true} key={index} headerText={assetInterfaces[state.interfaceDependencies[index].interface] ?
                        `${assetInterfaces[state.interfaceDependencies[index].interface].name} - (${assetInterfaces[state.interfaceDependencies[index].interface].type})`
                        : ''}>
                        <div className="row">
                            <div className="col-1 font-weight-bold">
                                Not used:
                            </div>
                            <div className="col-5">{interfaceDep.isNotUsed}
                                <input className="form-control" checked={interfaceDep.isNotUsed} type="checkbox"
                                    onChange={e => setStateArrayProperty('interfaceDependencies', index, 'isNotUsed', e.target.checked)}></input>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-1 font-weight-bold">
                                Use:
                            </div>
                            <div className="col-5">
                                <textarea className="form-control" value={interfaceDep.use}
                                    onChange={e => setStateArrayProperty('interfaceDependencies', index, 'use', e)}></textarea>
                            </div>
                            <div className="col  text-right">
                                <button className="btn btn-danger btn-sm"
                                    onClick={() => removeFromArrayProperty('interfaceDependencies', index)}>
                                    <FontAwesomeIcon icon="trash-alt" />&nbsp;&nbsp;Remove Interface
                                </button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-1 font-weight-bold">
                                Type:
                            </div>
                            <div className="col-5">
                                <select className="form-control" value={interfaceDep.type}
                                    onChange={e => setStateArrayProperty('interfaceDependencies', index, 'type', e)}>
                                    <option value="Read">Read</option>
                                    <option value="Update">Update</option>
                                </select>
                            </div>
                        </div>
                        {assetInterfaces[state.interfaceDependencies[index].interface].type === "Library" ?
                            <Card headerText="Classes Used">
                                <div className="row">
                                    <div className="col text-right">
                                        <div className="input-group">
                                            <select name="" id="" className="form-control" onChange={e => setSelectedClass(e.target.value)}>
                                                <option>Select Class</option>
                                                {assetInterfaces[state.interfaceDependencies[index].interface].classes.map((libraryClass, i) => {
                                                    return <option key={i} value={libraryClass._id}>{libraryClass.name}</option>
                                                })}
                                            </select>
                                            <div className="input-group-append">
                                                <button className="btn btn-primary" disabled={!selectedClass}
                                                    onClick={() => {
                                                        addToNestedArrayProperty('interfaceDependencies', index, 'classesUsed',
                                                            { class: selectedClass, methods: [] })
                                                    }}>
                                                    <FontAwesomeIcon icon="plus" />&nbsp;&nbsp;Class
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <ul className="list-group">
                                            {state.interfaceDependencies[index].classesUsed ? state.interfaceDependencies[index].classesUsed.map((classUsed, classUsedIndex) => {
                                                return <ul className="list-group-item" key={classUsedIndex}>
                                                    <div className="row">
                                                        <div className="col">Class Name:&nbsp;
                                                        {assetInterfaces[state.interfaceDependencies[index].interface].classes.find(t => {
                                                            return t._id === classUsed.class;
                                                        }).name
                                                            }</div>
                                                        <div className="col text-right">
                                                            <button className="btn btn-danger btn-sm" aria-label="Delete Class"
                                                                onClick={() => removeFromNestedArrayProperty('interfaceDependencies', index, 'classesUsed', classUsedIndex)} >
                                                                <FontAwesomeIcon icon="trash-alt" />&nbsp;&nbsp;Remove Class
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <Card headerText="Methods Used" collapsed={true}>
                                                        <div className="row">
                                                            <div className="col-6 text-right">
                                                                <div className="input-group">
                                                                    <select value={selectedMethod} onChange={e => setSelectedMethod(e.target.value)} className="form-control">
                                                                        <option>Select Method</option>
                                                                        {assetInterfaces[state.interfaceDependencies[index].interface].classes.find(t => {
                                                                            return t._id === classUsed.class;
                                                                        }).methods.map((f, i) => {
                                                                            return <option key={i} value={f._id}>{f.name}</option>
                                                                        })}
                                                                    </select>
                                                                    <div className="input-group-append">
                                                                        <button
                                                                            disabled={!selectedMethod}
                                                                            className="btn btn-primary" onClick={() => addToNestedNestedArrayProperty('interfaceDependencies',
                                                                                index, 'classesUsed', classUsedIndex, 'methods', selectedMethod)}>
                                                                            <FontAwesomeIcon icon="plus"></FontAwesomeIcon>
                                                                            &nbsp;&nbsp;Method
                                                                    </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col"></div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-8">
                                                                <table className="table table-striped table-bordered table-sm">
                                                                    <thead>
                                                                        <tr>
                                                                            <th></th>
                                                                            <th>Name</th>
                                                                            <th></th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {classUsed.methods.map((method, methodIndex) => {
                                                                            return <tr key={methodIndex}>
                                                                                <td>
                                                                                    {assetInterfaces[state.interfaceDependencies[index].interface].classes.find(t => {
                                                                                        return t._id === classUsed.class;
                                                                                    }).methods.find(f => {
                                                                                        return f._id === method
                                                                                    }).name}
                                                                                </td>
                                                                                <td>
                                                                                    <button className="btn btn-danger btn-sm" aria-label="Delete Method"
                                                                                        onClick={() => removeFromNestedNestedArrayProperty('interfaceDependencies',
                                                                                            index, 'classesUsed', classUsedIndex, 'methods', methodIndex)} >
                                                                                        <FontAwesomeIcon icon="trash-alt" />&nbsp;&nbsp;Remove Method
                                                                                    </button>
                                                                                </td>
                                                                            </tr>
                                                                        })}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </Card>
                                                </ul>
                                            }) : ''}
                                        </ul>
                                    </div>
                                </div>
                            </Card> : ''}
                        {assetInterfaces[state.interfaceDependencies[index].interface].type === "Database" ?
                            <Card headerText="Tables Used">
                                <div className="row">
                                    <div className="col text-right">
                                        <div className="input-group">
                                            <select name="" id="" className="form-control" onChange={e => setSelectedTable(e.target.value)}>
                                                <option>Select Table</option>
                                                {assetInterfaces[state.interfaceDependencies[index].interface].tables.map((table, i) => {
                                                    return <option key={i} value={table._id}>{table.name}</option>
                                                })}
                                            </select>                        <div className="input-group-append">
                                                <button className="btn btn-primary" disabled={!selectedTable}
                                                    onClick={() => {
                                                        addToNestedArrayProperty('interfaceDependencies', index, 'tablesUsed',
                                                            { table: selectedTable, fields: [] })
                                                    }}>
                                                    <FontAwesomeIcon icon="plus" />&nbsp;&nbsp;Table
                                    </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col"></div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <ul className="list-group">
                                            {state.interfaceDependencies[index].tablesUsed ? state.interfaceDependencies[index].tablesUsed.map((table, tableIndex) => {
                                                return <ul className="list-group-item" key={tableIndex}>
                                                    <div className="row">
                                                        <div className="col">Table Name:&nbsp;
                                                            {assetInterfaces[state.interfaceDependencies[index].interface].tables &&
                                                                assetInterfaces[state.interfaceDependencies[index].interface].tables.find(t => {
                                                                    return t._id === table.table;
                                                                }) ?
                                                                assetInterfaces[state.interfaceDependencies[index].interface].tables.find(t => {
                                                                    return t._id === table.table;
                                                                }).name : ''}
                                                            }</div>
                                                        <div className="col text-right">
                                                            <button className="btn btn-danger btn-sm" aria-label="Delete Table"
                                                                onClick={() => removeFromNestedArrayProperty('interfaceDependencies', index, 'tablesUsed', tableIndex)} >
                                                                <FontAwesomeIcon icon="trash-alt" />&nbsp;&nbsp;Remove Table
                                                            </button>
                                                        </div>
                                                    </div>
                                                    {getFieldsUsedCard('interfaceDependencies', 'interface', table, index, tableIndex, assetInterfaces)}
                                                </ul>
                                            }) : ''}
                                        </ul>
                                    </div>
                                </div>
                            </Card> : ''}
                        {assetInterfaces[state.interfaceDependencies[index].interface].type === "API" ?
                            <Card headerText="Operations Used">
                                <div className="row">
                                    <div className="col-6 text-right">
                                        <div className="input-group">
                                            <select name="" id="" className="form-control" value={selectedOperation} onChange={e => setSelectedOperation(e.target.value)}>
                                                <option>Select Operation</option>
                                                {assetInterfaces[state.interfaceDependencies[index].interface].operations.map((operation, i) => {
                                                    return <option key={i} value={operation._id}>{operation.name}</option>
                                                })}
                                            </select>                        <div className="input-group-append">
                                                <button className="btn btn-primary" disabled={!selectedOperation}
                                                    onClick={() => { addToNestedArrayProperty('interfaceDependencies', index, 'operationsUsed', selectedOperation) }}>
                                                    <FontAwesomeIcon icon="plus" />&nbsp;&nbsp;Operation
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-7">
                                        <table className="table table-striped table-bordered table-sm">
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {state.interfaceDependencies[index].operationsUsed ? state.interfaceDependencies[index]
                                                    .operationsUsed.map((operation, operationIndex) => {
                                                        return <tr key={operationIndex}>
                                                            <td>
                                                                {assetInterfaces[state.interfaceDependencies[index].interface].operations.find(t => {
                                                                    return t._id === operation;
                                                                }).name}
                                                            </td>
                                                            <td><button className="btn btn-danger btn-sm" aria-label="Delete Table"
                                                                onClick={() => removeFromNestedArrayProperty('interfaceDependencies',
                                                                    index, 'operationsUsed', operationIndex)} >
                                                                <FontAwesomeIcon icon="trash-alt" />&nbsp;&nbsp;Remove Operation
                                                        </button></td>
                                                        </tr>
                                                    }) : ''}
                                            </tbody>
                                        </table>

                                    </div>
                                </div>
                            </Card> : ''}
                    </Card>
                })}
            </div>
        </Card>
        <div className="row">
            <div className="col"></div>
        </div>

        <Card headerText="Miscellaneous Interfaces" collapsed={user.isAdmin ? false : true}>
            {user.isAdmin ?
                <Card headerText="Import Related Data">
                    <div className="row">
                        <div className="col">
                            <FilePond
                                name="list"
                                allowMultiple={false}
                                maxFiles={1}
                                allowRevert={false}
                                server={{
                                    url: `${routeConfig.baseUrl}/ImportRelatedData/${application._id}`,
                                    revert: (files, load, error) => {
                                        this.removeDocuments(files);
                                        load();
                                    }
                                }}
                                onerror={() => {
                                }}
                                onprocessfiles={() => {
                                    dispatch.Applications.loadApplication(application._id);
                                }}
                            />
                        </div>
                    </div>
                </Card>
                : <></>}
            <div className="row">
                <div className="col"></div>
                <div className="col-8">
                    <div className="input-group">
                        <Typeahead
                            value={selectedApplication}
                            onChange={selected => { selectApp(selected) }}
                            placeholder="Select Related IT Asset"
                            id="ApplicationRelatedItAsset"
                            options={appOptions} />
                        <div className="input-group-append">
                            <button className="btn btn-primary" disabled={!selectedApplication}
                                onClick={addApplication} type="button"><FontAwesomeIcon icon="plus" />&nbsp;&nbsp;IT Asset</button>
                        </div>
                    </div>
                </div>
            </div>
            <ul className="list-group">
                {state.applicationDependencies.map((dep, index) => {
                    if (dep.type === 'Database Read' || dep.type === 'Database Read Write' ||
                        dep.type === 'Database') {
                        return <React.Fragment key={index}></React.Fragment>
                    }

                    return <div className="list-group-item" key={index}>
                        <div className="row" >
                            <div className="col px-1 mx-1 font-weight-bold">
                                {dep && applications[dep.asset] ?
                                    applications[dep.asset].name : ''}
                            </div>

                            <div className="col-1 px-1 mx-1 text-right">
                                <span role="img" aria-label="Delete"
                                    onClick={() => removeFromArrayProperty('applicationDependencies', index)}
                                    style={{ cursor: 'pointer' }}>
                                    <FontAwesomeIcon icon="trash-alt" className="fa-lg text-danger" />
                                </span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-3 px-1 mx-1 align-middle">
                                <select className="form-control" type="text" value={dep.type}
                                    onChange={e => setStateArrayProperty('applicationDependencies', index, 'type', e)}>
                                    <option value=''>Select</option>
                                    <option value='API Read'>API Read</option>
                                    <option value='API Read Write'>API Read Write</option>
                                    <option value='External Action Blocks'>External Action Blocks</option>
                                    <option value='FOCUS Include'>FOCUS Include</option>
                                    <option value='GEN Action Blocks'>GEN Action Blocks</option>
                                    <option value='Program'>Program</option>
                                </select>
                            </div>
                            <div className="col-1 font-weight-bold px-1 mx-1">
                                Use:
                            </div>
                            <div className="col px-1 mx-1 ">
                                <textarea className="form-control" type="text" value={dep.use}
                                    onChange={e => setStateArrayProperty('applicationDependencies', index, 'use', e)}>
                                </textarea>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <Card headerText="Components Used">
                                    <div className="row">
                                        <div className="col"></div>
                                        <div className="col">
                                            <div className="input-group">
                                                <select className="form-control"
                                                    onChange={(e) => { setSelectedComponent({ ...selectedComponent, [index]: e.target.value }) }}>
                                                    <option value=''>Select</option>
                                                    {applications[dep.asset]
                                                        .components.map((c, i) => {
                                                            return <option value={c._id} key={c._id}>{c.name}</option>
                                                        })}
                                                </select>
                                                <div className="input-group-append">
                                                    <AddButton onClick={() => addToNestedArrayProperty('applicationDependencies',
                                                        index, 'components', selectedComponent[index])} text="Component"
                                                        disabled={!selectedComponent[index]} hideText={true}></AddButton>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <table className="table table-striped table-bordered table-sm">
                                                <thead>
                                                    <tr>
                                                        <th>Name</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {dep.components ? dep.components.map((c, ci) => {
                                                        return <tr key={ci}>
                                                            <td>{getComponentName(c, dep.asset)}</td>
                                                            <td><DeleteButton hideText={true} onClick={() => removeFromNestedArrayProperty('applicationDependencies',
                                                                index, 'components', ci)}></DeleteButton>
                                                            </td>
                                                        </tr>
                                                    }) : <></>}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </Card>
                            </div>
                        </div>
                    </div>
                })}
            </ul>
        </Card>
        <div className="row">
            <div className="col"></div>
        </div>
        <SaveCancel onCancelClick={() => dispatch.Applications.changeApplicationModalIsEditing(false)} onSaveClick={onSubmit}></SaveCancel>
    </>
}
