import axios from 'axios';
import routeConfig from '../routeConfig';
export const Correction = {
    state: {
        corrections: {

        },
        applicationIdLoaded: null,
        correctionsLoading: false
    },
    reducers: {
        loadCorrections(state, corrections) {
            let loadedCorrections = {};
            for (let i = 0; i < corrections.length; i++) {
                loadedCorrections[corrections[i]._id] = corrections[i];
            }
            return { ...state, corrections: loadedCorrections };
        },
        loadExistingCorrection(state, correction) {
            return {
                ...state,
                corrections: {
                    ...state.corrections,
                    [correction._id]: correction
                }
            }
        },
        removeNew(state) {
            const corrections = {
                ...state.corrections
            };

            delete corrections['new'];

            return {
                ...state,
                corrections: corrections
            }
        },
        changeCorrectionsLoading(state, isCorrectionsLoading) {
            return {
                ...state,
                correctionsLoading: isCorrectionsLoading
            }
        },
        addCorrection(state, correction) {
            return {
                ...state, corrections: {
                    ...state.corrections,
                    'new': correction
                }
            }
        },
        setApplicationIdLoaded(state, applicationIdLoaded) {
            return {
                ...state,
                applicationIdLoaded: applicationIdLoaded
            }
        }

    },
    effects: {
        async getQuickAssessments() {
            const resp = await axios.get(`${routeConfig.baseUrl}/QuickAssessment`);
            this.loadQuickAssessments(resp.data);
        },
        async loadApplicationsCorrections(applicationId, rootState) {
            if (rootState.Correction.applicationIdLoaded !== applicationId) {
                this.changeCorrectionsLoading(true);
                axios.get(`${routeConfig.baseUrl}/Correction?query={"application":"${applicationId}"}`).then(resp => {
                    this.loadCorrections(resp.data);
                    this.changeCorrectionsLoading(false);
                    this.setApplicationIdLoaded(applicationId);
                });
            }
        },
        async saveCorrection(correction) {
            if (correction._id) {
                axios.patch(`${routeConfig.baseUrl}/Correction/${correction._id}`, correction).then(resp => {
                    this.loadExistingCorrection(resp.data);
                });
            } else {
                axios.post(`${routeConfig.baseUrl}/Correction`, correction).then(resp => {
                    this.loadExistingCorrection(resp.data);
                    this.removeNew();
                });
            }
        }
    }
}
