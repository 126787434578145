import axios from "axios";
import routeConfig from '../routeConfig';
import { toastr } from 'react-redux-toastr'

export const AssetInterface = {
    state: {
        assetInterfaces: {},
        currentInterfaceId: null,
        isNew: false,
        isEditing: false
    },
    reducers: {
        addInterface(state, assetInterface) {
            return {
                ...state,
                assetInterfaces: {
                    ...state.assetInterfaces,
                    [assetInterface._id]: assetInterface
                },
                currentInterfaceId: assetInterface._id
            }
        },
        loadInterfaces(state, interfaces) {
            let loadedInterfaces = {};

            interfaces.forEach(i => {
                loadedInterfaces[i._id] = i;
            });

            return {
                ...state,
                assetInterfaces: loadedInterfaces
            }
        },
        addNewInterface(state, isSolution) {
            return {
                ...state,
                isNew: true,
                isEditing: true,
                currentInterfaceId: 'New',
                showInfoTab: true,
                showInterfaceModal: true,
                assetInterfaces: {
                    ...state.assetInterfaces,
                    'New': {
                        status: 'Pending',
                        name: isSolution ? "New Solution" : "New Interface",
                        isSolution: isSolution,
                        tables: [],
                        classes: [],
                        operations: [],
                        applicationPlatforms: []
                    }
                }
            }
        },
        newInterfaceFinished(state) {
            return {
                ...state,
                isNew: false,
                isEditing: false
            }
        },
        changeApplicationModalIsEditing(state, isEditing) {
            return {
                ...state,
                isEditing: isEditing
            };
        },
        hideAssetInterfaceModal(state) {
            return {
                ...state,
                showInterfaceModal: false,
                isEditing: false
            };
        },
        showAssetInterface(state) {
            return {
                ...state,
                showInterfaceModal: true,
                showInfoTab: true
            };
        },
        showInfoTab(state) {
            return {
                ...state,
                showInfoTab: true,
                showApplicationPlatformsTab: false,
                showRelatedSolutionsTab: false
            };
        },
        showRelatedSolutionsTab(state) {
            return {
                ...state,
                showInfoTab: false,
                showApplicationPlatformsTab: false,
                showRelatedSolutionsTab: true
            };
        },
        showApplicationPlatformsTab(state) {
            return {
                ...state,
                showInfoTab: false,
                showRelatedSolutionsTab: false,
                showApplicationPlatformsTab: true
            };
        },
        setCurrentInterfaceId(state, id) {
            return {
                ...state,
                currentInterfaceId: id
            }
        }
    },
    selectors: ((slice) => ({
        currentAssetInterface() {
            return slice(assetInterface => {
                if (assetInterface.currentInterfaceId) {
                    return assetInterface.assetInterfaces[assetInterface.currentInterfaceId];
                }
                return null;
            });
        },
        getInterfaces() {
            return slice(assetInterface => {
                const interfaces = Object.values(assetInterface.assetInterfaces).filter(i => {
                    if (!i) {
                        return false;
                    }
                    return !i.isSolution;
                });
                return interfaces;
            });
        },
        getSolutions() {
            return slice(assetInterface => {
                return Object.values(assetInterface.assetInterfaces).filter(i => {
                    if (!i) {
                        return false;
                    }
                    return i.isSolution;
                });
            });
        },
        databaseInterfaces() {
            return (rootState) => {
                return Object.values(rootState.AssetInterface.assetInterfaces).filter(i => {
                    return i.type === "Database";
                });
            }
        }
    })),
    effects: {
        async addAssetInterface(interfaceObj) {
            const response = await axios.post(`${routeConfig.baseUrl}/AssetInterface`, interfaceObj);
            this.addInterface(response.data);
            this.newInterfaceFinished();
            toastr.success(`New ${interfaceObj.isSolution ? 'Solution' : 'Interface'} Saved`, '');
        },
        async getInterfaces() {
            const response = await axios.get(`${routeConfig.baseUrl}/AssetInterface`);
            this.loadInterfaces(response.data);
        },
        async loadInterface(id) {
            const response = await axios.get(`${routeConfig.baseUrl}/AssetInterface/${id}`);
            this.addInterface(response.data);
        },
        async saveInterface(assetInterface) {
            const response = await axios.put(`${routeConfig.baseUrl}/AssetInterface/${assetInterface._id}`, assetInterface);
            this.addInterface(response.data);
            toastr.success(`Interface ${assetInterface.name} Saved`, '');
        }

    }
};
