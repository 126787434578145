import axios from 'axios';
import routeConfig from '../routeConfig';
export const Dashboard = {
    state: {
        questionSetFilter: null,
        teamFilter: null,
        subTeamFilter: null,
        dashboards: []
    },
    reducers: {
        filterByTeam(state, teamId) {
            return {
                ...state,
                teamFilter: teamId
            }
        },
        filterBySubTeam(state, teamId) {
            return {
                ...state,
                subTeamFilter: teamId
            }
        },
        filterByQuestionSet(state, questionSetId) {
            return {
                ...state,
                questionSetFilter: questionSetId
            };
        },
        setDashboards(state, dashboards) {
            return {
                ...state,
                dashboards: dashboards
            };
        }
    },
    effects: {
        loadDashboards(user) {
            axios.get(`${routeConfig.baseUrl}/MyDashboards`).then(resp => {
                this.setDashboards(resp.data);
            });
        }
    },
    selectors: {
        teamData() {
            return (rootstate, props) => {
                let teamData = {};
                const apps = Object.values(rootstate.Applications.filteredApplications);
                for (let i = 0; i < apps.length; i++) {
                    if (!apps[i].team) {
                        continue;
                    }

                    const teamName = rootstate.Teams.teams[apps[i].team].name;
                    const subTeam = rootstate.Teams.teams[apps[i].team].subTeams.find(t => {
                        return t._id === apps[i].subTeam;
                    });
                    if (subTeam) {
                        const subTeamName = subTeam.name;
                        if (teamData[teamName]) {
                            if (teamData[teamName][subTeamName]) {
                                teamData[teamName][subTeamName] += 1;
                            }
                            else {
                                teamData[teamName][subTeamName] = 1;
                            }
                            teamData[teamName].count += 1;
                        } else {
                            teamData[teamName] = {
                                [subTeamName]: 1,
                                count: 1,
                                name: teamName,
                                id: apps[i].team
                            }
                        }
                    }
                }

                return Object.values(teamData).sort((a, b) => {
                    if (a.name < b.name) {
                        return -1;
                    }
                    if (a.name > b.name) {
                        return 1;
                    }
                    return 0;
                });
            }
        },
        palmImpactData() {
            return (rootstate, props) => {
                let complete = 0;
                let notComplete = 0;
                let notStarted = 0;

                let apps = Object.values(rootstate.Applications.applications).filter(a => {
                    if (a.wpiiInventoryStatus !== 'Active') {
                        return false;
                    }

                    if (rootstate.Applications.subTeamFilter) {
                        return a.subTeam === rootstate.Applications.subTeamFilter;
                    }

                    if (rootstate.Applications.teamFilter) {
                        return a.team === rootstate.Applications.teamFilter;
                    }
                    return true;
                });

                if (rootstate.Applications.typeFilter) {
                    apps = apps.filter(a => {
                        return a.type === rootstate.Applications.typeFilter;
                    })
                }

                for (var i = 0; i < apps.length; i++) {
                    if (apps[i].palmStatus === 'Complete') {
                        complete += 1;
                    }
                    else if (apps[i].palmStatus === 'In Progress') {
                        notComplete += 1;
                    }
                    else {
                        notStarted += 1;
                    }
                }

                return [
                    { name: 'Complete', apps: complete },
                    { name: 'In Progress', apps: notComplete },
                    { name: 'Not Started', apps: notStarted }
                ]
            }
        },
        impactData() {
            return (rootstate, props) => {
                let projectImpact = 0;
                let contractImpact = 0;
                let budgetImpact = 0;
                const apps = Object.values(rootstate.Applications.filteredApplications);
                for (var i = 0; i < apps.length; i++) {
                    if (apps[i].wpiiHasProjectImpact) {
                        projectImpact += 1;
                    }
                    if (apps[i].wpiiHasContractImpact) {
                        contractImpact += 1;
                    }
                    if (apps[i].wpiiHasBudgetImpact) {
                        budgetImpact += 1;
                    }
                }

                return [
                    { name: 'Project Impact', apps: projectImpact },
                    { name: 'Contract Impact', apps: contractImpact },
                    { name: 'Budget Impact', apps: budgetImpact }
                ]
            }
        },
        impactStatusData() {
            return (rootstate, props) => {
                const apps = Object.values(rootstate.Applications.filteredApplications);
                const groupedApps = apps.reduce((acc, it) => {
                    acc[it.wpiiImpactStatus] = acc[it.wpiiImpactStatus] + 1 || 1;
                    return acc;
                }, {});

                return Object.keys(groupedApps).map((key, i) => {
                    return {
                        name: key,
                        apps: groupedApps[key]
                    }
                }).sort((a, b) => {
                    if (a.name < b.name) {
                        return -1;
                    }
                    if (a.name > b.name) {
                        return 1;
                    }
                    return 0;
                });
            }
        },
        databaseData() {
            return (rootstate, props) => {
                return Object.keys(rootstate.Database.databases).map((key) => {
                    return {
                        name: rootstate.Database.databases[key].name,
                        apps: Object.values(rootstate.Applications.applications).filter(a => {
                            if ((rootstate.Dashboard.teamFilter && rootstate.Dashboard.teamFilter !== a.team) &&
                                (rootstate.Dashboard.subTeamFilter && rootstate.Dashboard.subTeamFilter !== a.subTeam))
                                return false;

                            return a.databasePlatforms.findIndex(db => {
                                return db.platform === key;
                            }) > -1
                        }).length
                    }
                });
            }
        },
        applicationPlatformsData() {
            return (rootstate, props) => {
                return Object.keys(rootstate.ApplicationPlatform.applicationPlatforms).map((key) => {
                    return {
                        name: rootstate.ApplicationPlatform.applicationPlatforms[key].name,
                        apps: Object.values(rootstate.Applications.applications).filter(a => {
                            if ((rootstate.Dashboard.teamFilter && rootstate.Dashboard.teamFilter !== a.team) &&
                                (rootstate.Dashboard.subTeamFilter && rootstate.Dashboard.subTeamFilter !== a.subTeam))
                                return false;

                            return a.applicationPlatforms.findIndex(ap => {
                                return ap.platform === key;
                            }) > -1
                        }).length
                    }
                });
            }
        }
    }
}
