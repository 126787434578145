import React from "react";
import Card from "../Card";

export default function ViewLibrary(props) {
    return <Card headerText="Classes" badgeCount={props.classes.length} collapsed={true}>
        <div className="row">
            <div className="col">
                <ul className="list-group">
                    {props.classes ? props.classes.map((libraryClass, classIndex) => {
                        return <li className="list-group-item" key={classIndex}>
                            <div className="row">
                                <label className="col-1 font-weight-bold">
                                    Class Name:</label>
                                <div className="col">
                                    {libraryClass.name}
                                </div>
                            </div>
                            <div className="row">
                                <label className="col-1 font-weight-bold">
                                    Description:</label>
                                <div className="col">
                                    {libraryClass.description}
                                </div>
                            </div>
                            <Card headerText="Methods" badgeCount={props.classes[classIndex].methods.length} collapsed={true}>
                                <div className="row">
                                    <div className="col">
                                        <ul className="list-group">
                                            {props.classes[classIndex].methods ? props.classes[classIndex].methods.map((method, index) => {
                                                return <li key={index} className="list-group-item">
                                                    <div className="row">
                                                        <div className="col-1 font-weight-bold">Name:</div>
                                                        <div className="col">
                                                            {method.name}
                                                        </div>

                                                    </div>
                                                    <div className="row">
                                                        <div className="col-1 font-weight-bold">
                                                            Description:
                                                        </div>
                                                        <div className="col">
                                                            {method.description}
                                                        </div>
                                                    </div>
                                                </li>
                                            }) : <></>}
                                        </ul>
                                    </div>
                                </div>
                            </Card>
                            <br />
                        </li>
                    }) : ''}
                </ul>
            </div>
        </div>
    </Card>
}
