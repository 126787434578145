import React from "react";

export default function EditServiceBus(props) {
    return <div className="row">
        <label className="col-1 font-weight-bold">Service Bus Type:</label>
        <div className="col">
            <select onChange={e => props.setStateProperty('serviceBusType', e)} className="form-control">
                <option value="Queue">Queue</option>
                <option value="Topic">Topic</option>
            </select>
        </div>
    </div>
}
