import React, { useEffect } from 'react';
import { useSelector, useStore, useDispatch } from 'react-redux';
import AddButton from '../../AddButton';
import Correction from './Correction';
export default function Corrections(props) {
    const store = useStore();
    const dispatch = useDispatch();
    const application = useSelector(state => store.select.Applications.currentApplication(state));
    const corrections = useSelector(state => state.Correction.corrections);
    const correctionsLoading = useSelector(state => state.Correction.correctionsLoading);
    const user = useSelector(state => state.User.user);

    useEffect(() => {
        dispatch.Correction.loadApplicationsCorrections(application._id);
    }, [application, dispatch]);

    const addCorrection = () => {
        dispatch.Correction.addCorrection({
            user: user, status: 'Open',
            application: application._id, comments: {}
        });
    }

    if (correctionsLoading) {
        return <div><h3>Loading Corrections&nbsp;&nbsp;<div className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
        </div></h3>
        </div>
    }

    return <>
        <div className="row">
            <div className="col text-right">
                <AddButton text="Correction" onClick={addCorrection}></AddButton>
            </div>
        </div>
        <div className="row">
            <div className="col">
                <ul className="list-group">
                    {corrections ?
                        Object.values(corrections).map((correction, i) => {
                            return <li className="list-group-item" key={i}>
                                <Correction correction={correction}></Correction>
                            </li>
                        })
                        : <></>}
                </ul>
            </div>
        </div>
    </>
}
