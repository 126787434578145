import React from 'react'
import { useSelector, useStore } from 'react-redux';

export default function ViewRelatedSolutions(props) {
    const store = useStore();
    const currentInterface = useSelector(state => store.select.AssetInterface.currentAssetInterface(state));
    const solutions = useSelector(state => store.select.AssetInterface.getSolutions(state));

    return <>
        <div className="row">
            <div className="col">
                <table className="table table-striped table-bordered">
                    <thead>
                        <tr>
                            <th>Solution</th>
                            <th>Notes</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentInterface.relatedSolutions ? currentInterface.relatedSolutions.map((rs, i) => {
                            return <tr key={rs._id}>
                                <td>{solutions.find(s => { return s._id === rs.solution }).name}</td>
                                <td>{rs.notes}</td>
                            </tr>
                        }) : <></>
                        }
                    </tbody>
                </table>
            </div>
        </div>
    </>
}
