import React from 'react';
import getCurrentFiscalYear from '../helpers/getCurrentFiscalYear';
export default function FiscalYearOptions(props) {
    if (props.showOnlyCurrentFiscalYear) {
        return <option value={getCurrentFiscalYear()}>{getCurrentFiscalYear()}</option>
    }

    return <>
        <option value="FY 2019-2020">FY 2019-2020</option>
        <option value="FY 2020-2021">FY 2020-2021</option>
        <option value="FY 2021-2022">FY 2021-2022</option>
        <option value="FY 2022-2023">FY 2022-2023</option>
        <option value="FY 2023-2024">FY 2023-2024</option>
        <option value="FY 2024-2025">FY 2024-2025</option>
        <option value="FY 2025-2026">FY 2025-2026</option>
        <option value="FY 2026-2027">FY 2026-2027</option>
        <option value="FY 2027-2028">FY 2027-2028</option>
        <option value="FY 2028-2029">FY 2028-2029</option>
    </>
}
