import React, { useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import Store from '../../../Store';
import { format as formatPhone } from 'phone-fns'
import SaveCancel from '../../SaveCancel';
import { useNestedArrayState } from '../../../hooks/useNestedArrayState';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import StaffSelector from '../../StaffSelector';
import { appInsights } from '../../../appInsights';
import { toastr } from 'react-redux-toastr'

export default function EditRoles() {
    const ASSET_UPDATER = 'Asset Updater';
    const UPDATE_PERSON_PROP = 'updatePersons';
    const WPII_LIAISON = 'WPII Liaison';
    const WPII_LIAISON_PROPS = 'wpiiLiasons';
    const WPII_IMPACT_RESPONSIBLE_STAFF_PROP = 'wpiiImpactResponsibleStaff';
    const WPII_IMPACT_RESPONSIBLE_STAFF = 'WPII Impact Responsible Staff';

    const dispatch = useDispatch();
    const currentApplication = useSelector(state => Store.select.Applications.currentApplication(state));
    const isCreatingNewApplication = useSelector(state => state.Applications.isCreatingNewApplication);
    const [staffSelectorState, setStaffSelectorState] = useState(null);
    const [roleSelected, setRoleSelected] = useState(ASSET_UPDATER);
    const {
        state,
        addToArrayProperty,
        removeFromArrayProperty,
    } = useNestedArrayState(currentApplication);

    const doesRoleExist = (arrayName, user) => {
        if (state[arrayName].findIndex(u => { return u.id === user.staffId; }) > -1) {
            toastr.error('Role Already exists for user!');
            return true;
        }
        return false;
    }

    const cancel = () => {
        if (isCreatingNewApplication) {
            dispatch.Applications.changeApplicationModalVisibility(false);
        }
        dispatch.Applications.changeApplicationModalIsEditing(false);
    }

    const addRole = () => {
        var userToAdd = {
            ...staffSelectorState,
            phoneNumber: staffSelectorState.phone,
            id: staffSelectorState.staffId,
        }

        if (roleSelected === ASSET_UPDATER) {
            if (!doesRoleExist(UPDATE_PERSON_PROP, userToAdd)) {
                addToArrayProperty(UPDATE_PERSON_PROP, userToAdd);
            }
        } else if (roleSelected === WPII_IMPACT_RESPONSIBLE_STAFF) {
            if (!doesRoleExist(WPII_IMPACT_RESPONSIBLE_STAFF_PROP, userToAdd)) {
                addToArrayProperty(WPII_IMPACT_RESPONSIBLE_STAFF_PROP, userToAdd);
            }
        } else if (roleSelected === WPII_LIAISON) {
            if (!doesRoleExist(WPII_LIAISON_PROPS, userToAdd)) {
                addToArrayProperty(WPII_LIAISON_PROPS, userToAdd);
            }
        }

        appInsights.trackEvent({
            name: 'Simplified Asset Role Removed',
            properties: {
                role: roleSelected,
                email: userToAdd.emailAddress,
                appName: currentApplication.name,
                appId: currentApplication._id
            }
        });
    }

    const getIndexOfRole = (array, person) => {
        return array.findIndex(p => {
            if (person._id === p._id) {
                return true;
            }
            if ((p.staffId && person.staffId === p.staffId) || (p.id && person.id === p.id)) {
                return true;
            }

            return false;
        });
    }

    const removeRole = (person) => {
        if (person.role === ASSET_UPDATER) {
            removeFromArrayProperty(UPDATE_PERSON_PROP, getIndexOfRole(state.updatePersons, person));
        } else if (person.role === WPII_IMPACT_RESPONSIBLE_STAFF) {
            removeFromArrayProperty(WPII_IMPACT_RESPONSIBLE_STAFF_PROP, getIndexOfRole(state.wpiiImpactResponsibleStaff, person));
        } else if (person.role === WPII_LIAISON) {
            removeFromArrayProperty(WPII_LIAISON_PROPS, getIndexOfRole(state.wpiiLiasons, person));
        }

        appInsights.trackEvent({
            name: 'Simplified Asset Role Removed',
            properties: {
                role: person.role,
                email: person.emailAddress,
                appName: currentApplication.name,
                appId: currentApplication._id
            }
        });
    };

    const getViewPeople = (persons, role) => {
        let people = [];
        persons.forEach(p => {
            people.push({
                name: `${p.firstName} ${p.lastName} (${p.racfId})`,
                phoneNumber: p.phoneNumber,
                emailAddress: p.emailAddress,
                role: role,
                staffId: p.staffId,
                id: p.id
            });
        });

        return people;
    };

    const getPeople = () => {
        let people = [];
        people = people.concat(getViewPeople(state.wpiiImpactResponsibleStaff, WPII_IMPACT_RESPONSIBLE_STAFF));
        people = people.concat(getViewPeople(state.wpiiLiasons, WPII_LIAISON));
        people = people.concat(getViewPeople(state.updatePersons, ASSET_UPDATER));
        return people;
    };

    const save = (e) => {
        dispatch.Applications.persistApplication(state);
        dispatch.Applications.changeApplicationModalIsEditing(false);

        appInsights.trackEvent({
            name: 'Simplified Asset Save',
            properties: {
                appName: currentApplication.name,
                appId: currentApplication._id
            }
        });
    }


    if (!currentApplication) {
        return <div></div>
    }

    return <>
        <div className="row">
            <div className="col">
                <div className="input-group">
                    <div className="input-group-prepend">
                        <div className="input-group-text">Role:</div>
                    </div>
                    <select className="form-control" value={roleSelected} onChange={e => setRoleSelected(e.target.value)}>
                        <option value="Asset Updater">Asset Updater</option>
                        <option value="WPII Impact Responsible Staff">WPII Impact Responsible Staff</option>
                        <option value="WPII Liaison">WPII Liaison</option>
                    </select>
                </div>
            </div>
            <div className="col">
                <div className="input-group">
                    <StaffSelector onChange={(v) => setStaffSelectorState(v)}></StaffSelector>
                    <div className="input-group-append">
                        <button className="btn btn-primary" disabled={!roleSelected || !staffSelectorState}
                            type="button" title="Add Role" onClick={addRole}>
                            <FontAwesomeIcon icon="plus"></FontAwesomeIcon>&nbsp;&nbsp;
                            Role
                                </button>
                    </div>
                </div>
            </div>
        </div>
        <table className="table table-striped table-bordered table-sm table-hover">
            <thead className="thead-light">
                <tr>
                    <th>Role</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone Number</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {getPeople().sort((a, b) => {
                    if (a.role === b.role) {
                        return a.name < b.name ? -1 : 1;
                    }
                    return a.role < b.role ? -1 : 1;
                }).map((p, i) => {
                    return <tr key={i}>
                        <td>{p.role}</td>
                        <td>{p.name}</td>
                        <td>{p.emailAddress}</td>
                        <td>{formatPhone('(NNN) NNN-NNNN', p.phoneNumber)}</td>
                        <td className="text-center">
                            <button className="btn btn-danger" type="button" onClick={() => removeRole(p)}>
                                <FontAwesomeIcon icon="trash-alt"></FontAwesomeIcon>
                            </button></td>
                    </tr>
                })
                }
            </tbody>
        </table>
        <div className="row">
            <div className="col"></div>
        </div>
        <SaveCancel onSaveClick={save} onCancelClick={cancel}></SaveCancel>
    </>
}
