import React from 'react';

export default function Home(props) {
    return <div>
        <div className="container">
            <div className="row">
                <div className="col text-center">
                    <h5>
                        Please click on Login and proceed to select an option from the top menu.
                    </h5>
                </div>
            </div>
        </div>
        <img src="../CompassLogo.png" alt="Logo" className="img-fluid"></img>
        <div className="container">
            <div className="row">
                <div className="col text-center">
                    {}
                    Compass is maintained by OIT - Application Services. For any Technical Assistance needed, please email the&nbsp;
                         <a href="mailto:CO-COMPASS@dot.state.fl.us">CO-COMPASS</a> directly. <br />
                    The Compass User Manual is available by selecting Help from the top menu. <br />
                </div>
            </div>
        </div>
    </div>
}
