import React from 'react';
import { useSelector } from 'react-redux';
import Store from '../../../Store';
import Card from '../../Card';

export default function ViewComponentsRelationships(props) {
    const currentApplication = useSelector(state => Store.select.Applications.currentApplication(state));
    const interfaces = useSelector(state => state.AssetInterface.assetInterfaces);
    const applications = useSelector(state => state.Applications.applications);

    return <>
        <Card headerText="Data Interfaces To Component Relationships">
            <ul className="list-group">
                {currentApplication.components ? currentApplication.components.map((c, i) => {
                    return c.interfaces && c.interfaces.length > 0 ?
                        <li className="list-group-item">
                            <div className="row">
                                <div className="col-4 font-weight-bold">
                                    {c.name}
                                </div>
                            </div>
                            {c.interfaces.map((interfaceItem, ix) => {
                                return <div className="row" key={ix}>
                                    <div className="col">
                                        {interfaces[interfaceItem].name}
                                    </div>
                                </div>
                            })}
                        </li> : <></>
                }) : ''}
            </ul>
        </Card>
        <Card headerText="Related IT Assets To Component Relationships">
            <ul className="list-group">
                {currentApplication.components.map((c, i) => {
                    return c.relatedAssets && c.relatedAssets.length > 0 ?
                        <li className="list-group-item">
                            <div className="row">
                                <div className="col-4 font-weight-bold">
                                    {c.name}
                                </div>
                            </div>
                            {c.relatedAssets.map((relatedAssetItem, ix) => {
                                return <div className="row" key={ix}>
                                    <div className="col">
                                        {applications[relatedAssetItem].name}
                                    </div>
                                </div>
                            })}
                        </li> : <></>
                })}
            </ul>
        </Card>
    </>
}
