import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import store from '../../../Store';
import SaveCancel from '../../SaveCancel';
import { useNestedArrayState } from '../../../hooks/useNestedArrayState';
import deepCopy from 'deep-copy';
import EditQuickAssessmentQuestions from './EditQuickAssessmentQuestions';
import EditAssessmentGroup from './EditAssessmentGroup';

export default function EditQuickAssessment(props) {
    const application = useSelector(state => store.select.Applications.currentApplication(state));
    const dispatch = useDispatch();
    const { selectedAssessment } = props;
    const {
        state,
        setState,
        setNestedNestedPropertyState,
    } = useNestedArrayState(null);

    useEffect(() => {
        if (!application || !selectedAssessment) {
            return;
        }

        const app = deepCopy(application);

        if (!app.quickAssessments) {
            app.quickAssessments = {};
        }

        if (!app.quickAssessments[selectedAssessment._id]) {
            app.quickAssessments[selectedAssessment._id] = {};
            for (let i = 0; i < selectedAssessment.questions.length; i++) {
                app.quickAssessments[selectedAssessment._id][selectedAssessment.questions[i]._id] = '';
            }

            if (selectedAssessment.groups) {
                for (let group of selectedAssessment.groups) {
                    if (group.isArray && !app.quickAssessments[selectedAssessment._id][group._id]) {
                        app.quickAssessments[selectedAssessment._id][group._id] = [];
                    }

                }
            }
        }

        setState(app);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAssessment, application]);

    const onSave = () => {
        dispatch.Applications.persistApplication({ ...state });
        dispatch.Applications.changeApplicationModalIsEditing(false);
    };

    const getGroupedContent = () => {
        return <>
            {selectedAssessment.groups.map((g, i) => {
                return <div className="row" key={i}>
                    <div className="col">
                        <EditAssessmentGroup group={g} state={state}
                            setNestedNestedPropertyState={setNestedNestedPropertyState}
                            selectedAssessment={selectedAssessment}>
                        </EditAssessmentGroup>
                    </div>
                </div>
            })}
        </>
    }

    if ((!state || !state.quickAssessments)) {
        return <></>
    }

    return <>
        {selectedAssessment.useGrouping ? getGroupedContent() :
            <EditQuickAssessmentQuestions state={state}
                setNestedNestedPropertyState={setNestedNestedPropertyState}
                questions={selectedAssessment.questions} setState={setState}
                selectedAssessment={selectedAssessment}></EditQuickAssessmentQuestions>}
        <div className="row">
            <div className="col">
                <SaveCancel onCancelClick={() => dispatch.Applications.changeApplicationModalIsEditing(false)} onSaveClick={onSave}></SaveCancel>
            </div>
        </div>
    </>
}
