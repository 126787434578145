import React from 'react';

export default function ChartTooltip(props) {
    const { active, payload } = props;

    if (active && payload[0] && payload[0].payload && payload[0].payload._id) {
        return <div className="gitToolTip">
            {props.results.groupFields.map((gf, i) => {
                return <div key={i}>
                    <span className="font-weight-bold">
                        {gf.label}
                    </span>:&nbsp;{payload[0].payload._id[gf.field.replace(/\./g, '_')]}
                </div>
            })}
            <div>
                <span className="font-weight-bold">
                    Assets
                </span>:&nbsp;{payload[0].value}
            </div>
        </div>
    }

    return null;
};
