import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

export default function SaveCancel(props) {
    return <div className="row">
        <div className="col">
            <button className="btn btn-primary btn-sm" onClick={props.onSaveClick ? props.onSaveClick : () => { }} type={props.isSubmit ? "submit" : "button"}>
                <FontAwesomeIcon icon="save"></FontAwesomeIcon>&nbsp;&nbsp;
                Save
            </button>&nbsp;&nbsp;
            {!props.hideCancel ?
                <button className="btn btn-primary btn-sm" type="button" onClick={props.onCancelClick}>
                    <FontAwesomeIcon icon="ban"></FontAwesomeIcon>&nbsp;&nbsp;
                    Cancel
            </button> : ''}
            {props.reset ?
                <button className="btn btn-primary btn-sm" type="button" onClick={props.onCancelClick}>
                    <FontAwesomeIcon icon="ban"></FontAwesomeIcon>&nbsp;&nbsp;
                    Reset
            </button> : ''}
            {props.children}
        </div>
    </div>
}

SaveCancel.propTypes = {
    reset: PropTypes.bool,
    children: PropTypes.object,
    hideCancel: PropTypes.bool,
    onSaveClick: PropTypes.func,
    onCancelClick: PropTypes.func,
    isSubmit: PropTypes.bool
}
