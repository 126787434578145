import React from 'react';
import { useSelector } from 'react-redux';
import store from '../../../Store';
import { format } from 'date-fns';
import PropTypes from 'prop-types';

export default function QuickAssessmentQuestions(props) {
    const application = useSelector(state => store.select.Applications.currentApplication(state));

    if (!application || !props.assessmentId) {
        return <></>
    }

    const getValue = (question) => {
        if (application.quickAssessments && application.quickAssessments[props.assessmentId]) {
            const value = application.quickAssessments[props.assessmentId][question._id];
            if (question.questionType === "Checkbox") {
                return value === true ? 'Yes' : 'No';
            }
            else if (question.questionType === "MultiSelect") {
                return value.join(", ");
            }
            else if (question.questionType === "Date") {
                const dateValue = format(new Date(value), "MM/DD/YYYY");

                if (dateValue === 'Invalid Date') {
                    return ""
                }

                return dateValue;
            }
            else {
                return value;
            }
        }

        return '';
    }

    return <div className="row">
        <div className="col">
            {props.questions.sort(function (a, b) { return a.order - b.order }).map((question, i) => {
                return <div className="row" key={question._id}>
                    <div className="col font-weight-bold">
                        {question.text}:
                            </div>
                    <div className="col">
                        {getValue(question)}
                    </div>
                </div>
            })}
        </div>
    </div>
}

QuickAssessmentQuestions.propTypes = {
    questions: PropTypes.array.isRequired,
    assessmentId: PropTypes.string.isRequired
}
