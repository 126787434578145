import axios from 'axios';
import routeConfig from '../routeConfig';

export const DimScoring = {
    state: {

    },
    reducers: {
        loadDimScoring(state, dimScoring) {
            if (!dimScoring) {
                return state;
            }

            return {
                ...state,
                dimScoring: dimScoring
            }
        }
    },
    effects: {
        async getDimScoring(payload, rootstate) {
            if (rootstate.DimScoring.dimScoring) {
                return;
            }

            const resp = await axios.get(`${routeConfig.baseUrl}/DimScoring`, { withCredentials: true });
            this.loadDimScoring(resp.data[0]);
        },
        async save(dimScoring) {
            let resp = null;

            if (dimScoring._id) {
                resp = await axios.put(`${routeConfig.baseUrl}/DimScoring/${dimScoring._id}`, dimScoring);
            } else {
                resp = await axios.post(`${routeConfig.baseUrl}/DimScoring`, dimScoring);
            }

            this.loadDimScoring(resp.data);
        }
    }
}
