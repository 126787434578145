import React from "react";
import Card from "../Card";

export default function ViewApi(props) {
    return <Card headerText="Operations" badgeCount={props.operations.length} collapsed={true}>
        <div className="row">
            <div className="col">
                <ul className="list-group">
                    {props.operations ? props.operations.map((operation, operationIndex) => {
                        return <li className="list-group-item" key={operationIndex}>
                            <div className="row">
                                <label className="col-2 font-weight-bold">
                                    Name:</label>
                                {operation.name}
                                <label className="col-2 font-weight-bold">
                                    Url:</label>
                                {operation.logicalName}
                            </div>
                            <div className="row">
                                <label className="col font-weight-bold">
                                    Description:</label>
                            </div>
                            <div className="row">
                                <div className="col">
                                    {operation.description}
                                </div>
                            </div>
                            <br />
                        </li>
                    }) : ''}
                </ul>
            </div>
        </div>
    </Card>
}
