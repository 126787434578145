import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import PreviousNext from '../../PreviousNext';
import Store from '../../../Store';
import { appInsights } from '../../../appInsights';
import ViewBusinessImpact from '../../Application/BusinessImpact/ViewBusinessImpact';
import EditBusinessImpact from '../../Application/BusinessImpact/EditBusinessImpact';
import SimplifiedAsset from '../../Application/SimplifiedAsset';
import EditSimplifiedAsset from '../../Application/EditSimplifiedAsset';
import useCanEditAsset from '../../../hooks/useCanEditAsset';
import usePreviousNextModal from '../../../hooks/usePreviousNextModal';
import ViewImpactedTables from '../../Assessment/ViewImpactedTables';
import EditImpactedTables from '../../Assessment/EditImpactedTables';
import FontAwesomeButton from '../../FontAwesomeButton';

export default function WpiiAssessmentModal(props) {
    const dispatch = useDispatch();
    const applicationModalIsEditing = useSelector(state => state.Applications.applicationModalIsEditing);
    const showWpiiAssessmentModal = useSelector(state => state.Applications.showWpiiAssessmentModal);
    const currentApplicationId = useSelector(state => state.Applications.currentApplicationId);
    const currentApplication = useSelector(state => Store.select.Applications.currentApplication(state));
    const assetInfoOpen = useSelector(state => state.Applications.assetInfoOpen);
    const impactedDataOpen = useSelector(state => state.Applications.impactedDataOpen);
    const assessmentInfoOpen = useSelector(state => state.Applications.assessmentInfoOpen);
    const canEdit = useCanEditAsset();
    const {
        nextApp,
        previousApp,
        tabClicked,
        getTabView
    } = usePreviousNextModal();

    useEffect(() => {
        if (currentApplicationId && showWpiiAssessmentModal) {
            dispatch.Applications.loadApplication(currentApplicationId);
            dispatch.Applications.setTabVisible('assessmentInfoOpen');

            appInsights.trackEvent({
                name: 'Asset Loaded',
                properties: {
                    assetName: currentApplication.name,
                    assetId: currentApplication._id
                }
            });
        }
        // eslint-disable-next-line
    }, [currentApplicationId, showWpiiAssessmentModal]);

    const toggleModal = () => {
        dispatch.Applications.changeWpiiAssessmentModalVisibility(!showWpiiAssessmentModal)
    }

    const getCurrentView = () => {
        if (assessmentInfoOpen) {
            return getTabView(<EditBusinessImpact />, <ViewBusinessImpact />);
        }
        if (impactedDataOpen) {
            return getTabView(<EditImpactedTables arrayProperty="wpiiImpactedTables" impactType="WPII" impactTypeForField={['Project', 'Contract', 'Budget']} />,
                <ViewImpactedTables arrayProperty="wpiiImpactedTables" impactType="WPII" impactTypeForField={['Project', 'Contract', 'Budget']} />);
        }
        if (assetInfoOpen) {
            return getTabView(<EditSimplifiedAsset />, <SimplifiedAsset />);
        }
        return <></>
    }

    const openAsset = () => {
        window.open(`/Inventory/${currentApplicationId}`);
    }

    const onEdit = () => {
        dispatch.Applications.changeApplicationModalIsEditing(true);
        appInsights.trackEvent({
            name: 'Assessment Edit Clicked',
            properties: {
                appName: currentApplication.name,
                appId: currentApplication._id
            }
        });
    }

    const getClassName = (propName) => {
        if ((propName === 'assessmentInfoOpen' && assessmentInfoOpen) ||
            (propName === 'assetInfoOpen' && assetInfoOpen) ||
            (propName === 'impactedDataOpen' && impactedDataOpen)) {
            return "nav-link btn btn-link active "
        }
        return "nav-link btn btn-link"
    }

    const getNavItem = (propName, labelName) => {
        return <li className={`nav-item ${labelName === 'IT Asset Info' ? 'ml-auto' : ''}`}>
            <button type="button" className={getClassName(propName)}
                onClick={() => tabClicked(propName)}>
                {labelName}
            </button>
        </li>
    }

    return <Modal isOpen={showWpiiAssessmentModal} size="lg">
        <ModalHeader className="py-2 bg-primary text-white" toggle={toggleModal}>
            {!applicationModalIsEditing ? <PreviousNext onPrevious={previousApp} onNext={nextApp} inverseColors={true}></PreviousNext> : ''}
            &nbsp;&nbsp;&nbsp;
            <span>
                {currentApplication ? currentApplication.name : ''}
            </span>
            <FontAwesomeButton icon='share-square' title='Open Asset' onClick={openAsset}></FontAwesomeButton>
        </ModalHeader>
        <ModalBody className="pt-1 bg-light">
            <>
                <nav className="nav nav-tabs d-flex">
                    {getNavItem('assessmentInfoOpen', 'Assessment')}
                    {getNavItem('impactedDataOpen', 'Impacted Data')}
                    {getNavItem('assetInfoOpen', 'IT Asset Info')}
                </nav>
                {!applicationModalIsEditing && canEdit === true ?
                    <div className="row my-0">
                        <div className="col text-right">
                            <button className="btn btn-link" onClick={onEdit}>Edit</button>
                        </div>
                    </div> : ''}
                {getCurrentView()}

                {!applicationModalIsEditing ?
                    <div>
                        <PreviousNext onPrevious={previousApp} onNext={nextApp}></PreviousNext>
                    </div> : ''}
            </>
        </ModalBody>
    </Modal>
}
