/* eslint-disable no-loop-func */
import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import Store from '../../Store';
import { useNestedArrayState } from '../../hooks/useNestedArrayState';
import EditServiceBus from './EditServiceBus';
import EditApi from './EditApi';
import EditDatabase from './EditDatabase';
import EditLibrary from './EditLibrary';
import SaveCancel from '../SaveCancel';
import TeamOptions from '../TeamOptions';

export default function EditInterface(props) {
    const dispatch = useDispatch();
    const currentInterface = useSelector(state => Store.select.AssetInterface.currentAssetInterface(state));
    const isNew = useSelector(state => state.AssetInterface.isNew);
    const user = useSelector(state => state.User.user);

    const {
        state,
        setStateProperty,
        setStateArrayProperty,
        setStateArrayofArrayProperty,
        addToArrayProperty,
        addToNestedArrayProperty,
        removeFromArrayProperty,
        removeFromNestedArrayProperty
    } = useNestedArrayState(currentInterface)

    const save = () => {
        const stateForSave = { ...state };

        if (stateForSave.team === '') {
            stateForSave.team = null;
        }

        if (stateForSave.subTeam === '') {
            stateForSave.subTeam = null;
        }

        if (isNew === true) {
            dispatch.AssetInterface.addAssetInterface(stateForSave);
        } else {
            dispatch.AssetInterface.saveInterface(stateForSave);
        }
    }

    const cancel = () => {
        if (isNew) {
            dispatch.AssetInterface.hideAssetInterfaceModal();
        } else {
            dispatch.AssetInterface.changeApplicationModalIsEditing(false)
        }
    }

    const createLinkedAsset = async () => {
        await dispatch.Applications.linkInterface(currentInterface);
        await dispatch.AssetInterface.loadInterface(currentInterface._id);
    }

    if (!state)
        return '';

    return <>
        <div className="row">
            <label className="col-1 font-weight-bold">Name:</label>
            <div className="col">
                <input type="text" className="form-control" onChange={e => setStateProperty('name', e)} value={state.name} />
            </div>
        </div>
        <div className="row">
            <label className="col-1 font-weight-bold">Status:</label>
            <div className="col">
                <select onChange={e => setStateProperty('status', e)} className="form-control" value={state.status}>
                    <option value="Available">Available</option>
                    <option value="Unavailable">Unavailable</option>
                </select>
            </div>
        </div>
        <div className="row">
            <label className="col-1 font-weight-bold">Type:</label>
            <div className="col">
                <select onChange={e => setStateProperty('type', e)} className="form-control" value={state.type}>
                    <option value="API">API</option>
                    <option value="Database">Database</option>
                    <option value="Library">Library</option>
                    <option value="Service Bus">Service Bus</option>
                </select>
            </div>
        </div>
        {isNew || user.isAdmin ?
            <div className="row">
                <div className="col-2 font-weight-bold">Responsible Group: </div>
                <div className="col">
                    <select className="form-control form-control-sm " value={state.team}
                        onChange={e => setStateProperty('team', e)}>
                        <TeamOptions></TeamOptions>
                    </select>
                </div>
                <div className="col-2 font-weight-bold" style={{ paddingRight: 0 }}>Responsible Sub-Group: </div>
                <div className="col">
                    <select className="form-control form-control-sm" disabled={!state.team}
                        value={state.subTeam} onChange={e => setStateProperty('subTeam', e)}>
                        <TeamOptions team={state.team}></TeamOptions>
                    </select>
                </div>
            </div> : ''
        }
        {!props.isSolution ? <>
            <div className="row">
                <label className="col-2 font-weight-bold">Impacted By DIMM:</label>
                <div className="col">
                    <input type="checkbox" onChange={e => setStateProperty('isImpactedByDim', e)} checked={state.isImpactedByDim}>
                    </input>
                </div>
                <label className="col-2 font-weight-bold">Impacted By WPII:</label>
                <div className="col">
                    <input type="checkbox" onChange={e => setStateProperty('isImpactedByWpii', e)} checked={state.isImpactedByWpii}>
                    </input>
                </div>
                <label className="col-2 font-weight-bold">Impacted By PALM:</label>
                <div className="col">
                    <input type="checkbox" onChange={e => setStateProperty('isImpactedByPalm', e)} checked={state.isImpactedByPalm}>
                    </input>
                </div>
            </div>
        </> : <></>
        }
        <div className="row">
            <label className="col-1 font-weight-bold">Description:</label>
            <div className="col">
                <textarea className="form-control" onChange={e => setStateProperty('description', e)} value={state.description} ></textarea>
            </div>
        </div>
        <div className="row">
            <label className="col-1 font-weight-bold">Package Id:</label>
            <div className="col">
                <input type="text" className="form-control" onChange={e => setStateProperty('packageId', e)} value={state.packageId} ></input>
            </div>
        </div>
        {state.type === 'Service Bus' ?
            <EditServiceBus setStateProperty={setStateProperty}></EditServiceBus>
            : ''}
        {state.type === 'API' ?
            <>
                <EditApi operations={state.operations}
                    setStateArrayProperty={setStateArrayProperty}
                    setStateArrayofArrayProperty={setStateArrayofArrayProperty}
                    addToArrayProperty={addToArrayProperty}
                    addToNestedArrayProperty={addToNestedArrayProperty}
                    removeFromArrayProperty={removeFromArrayProperty}
                    removeFromNestedArrayProperty={removeFromNestedArrayProperty}
                ></EditApi>
            </> : ''}
        {state.type === 'Database' ?
            <EditDatabase state={state}
                setStateProperty={setStateProperty}
                setStateArrayProperty={setStateArrayProperty}
                setStateArrayofArrayProperty={setStateArrayofArrayProperty}
                addToArrayProperty={addToArrayProperty}
                addToNestedArrayProperty={addToNestedArrayProperty}
                removeFromArrayProperty={removeFromArrayProperty}
                removeFromNestedArrayProperty={removeFromNestedArrayProperty}
            ></EditDatabase> : ''}
        {state.type === 'Library' ?
            <EditLibrary classes={state.classes}
                setStateArrayProperty={setStateArrayProperty}
                setStateArrayofArrayProperty={setStateArrayofArrayProperty}
                addToArrayProperty={addToArrayProperty}
                addToNestedArrayProperty={addToNestedArrayProperty}
                removeFromArrayProperty={removeFromArrayProperty}
                removeFromNestedArrayProperty={removeFromNestedArrayProperty}
            ></EditLibrary>
            : ''}
        <br />
        <div className="row">
            <div className="col"></div>
        </div>
        {user.isAdmin && !currentInterface.relatedApplication && !isNew ?
            <div className="row">
                <div className="col">
                    <button className="btn btn-primary" onClick={createLinkedAsset}>
                        Create Linked Asset
                    </button>
                </div>
            </div> : ''}
        <div className="row">
            <div className="col">
                <SaveCancel onSaveClick={save}
                    onCancelClick={cancel}>
                </SaveCancel>
            </div>
        </div>
    </>
}
