import axios from 'axios';
import history from '../history';
import { toastr } from 'react-redux-toastr'

axios.interceptors.response.use(function (response) {
    // Do something with response data
    return response;
}, function (error) {
    // Do something with response error
    if (error.response && (error.response.status === 403 || error.response.status === 401)) {
        console.log(error.response);

        history.push('/NotAuthorized')
    }

    if (error.response && (error.response.status === 500 || error.response.status === 502 || error.response.status === 404)) {
        console.log(error.response);
        toastr.error('An error has occurred!', 'Please refresh your browser', {
            timeOut: 0
        });
        history.push('/Error')
    }

    // Trow errr again (may be need for some other catch)
    return Promise.reject(error);
});
