import axios from 'axios';
import routeConfig from '../routeConfig';

export const PpmProject = {
    state: {
        projects: null
    },
    reducers: {
        loadProjects(state, projects) {
            const projObj = {};

            for (let proj of projects) {
                projObj[proj._id] = proj;
            }
            return {
                ...state,
                projects: projObj
            }
        }
    },
    effects: {
        async getProjects(payload, rootstate) {
            const resp = await axios.get(`${routeConfig.baseUrl}/PpmProjects`, { withCredentials: true });
            this.loadProjects(resp.data);
        }
    }
}
