import React from 'react'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import EditInterface from "./EditInterface";
import ViewInterface from "./ViewInterface";
import ViewRelatedSolutions from './ViewRelatedSolutions';
import EditRelatedSolutions from './EditRelatedSolutions';
import ViewSolutionApplicationPlatforms from './ViewSolutionApplicationPlatforms';
import EditSolutionApplicationPlatforms from './EditSolutionApplicationPlatforms';


export default function InterfaceModal(props) {
    const store = useStore();
    const dispatch = useDispatch();
    const showInterfaceModal = useSelector(state => state.AssetInterface.showInterfaceModal);
    const isEditing = useSelector(state => state.AssetInterface.isEditing);
    const isNew = useSelector(state => state.AssetInterface.isNew);
    const showInfoTab = useSelector(state => state.AssetInterface.showInfoTab);
    const showRelatedSolutionsTab = useSelector(state => state.AssetInterface.showRelatedSolutionsTab);
    const showApplicationPlatformsTab = useSelector(state => state.AssetInterface.showApplicationPlatformsTab);
    const user = useSelector(state => state.User.user);
    const currentInterface = useSelector(state => store.select.AssetInterface.currentAssetInterface(state))

    const toggleModal = () => {
        dispatch.AssetInterface.changeApplicationModalIsEditing(false);
        dispatch.AssetInterface.hideAssetInterfaceModal()
    }

    const getClassName = (propName) => {
        if ((propName === 'showRelatedSolutionsTab' && showRelatedSolutionsTab) ||
            (propName === 'showApplicationPlatformsTab' && showApplicationPlatformsTab) ||
            (propName === 'showInfoTab' && showInfoTab)) {
            return "nav-link btn btn-link active "
        }
        return "nav-link btn btn-link"
    }

    const getCurrentView = () => {
        if (showInfoTab === true) {
            return isEditing === true ? <EditInterface isSolution={props.isSolution}></EditInterface> : <ViewInterface isSolution={props.isSolution}></ViewInterface>;
        }
        if (!isNew && showRelatedSolutionsTab) {
            return isEditing === true ? <EditRelatedSolutions></EditRelatedSolutions> : <ViewRelatedSolutions></ViewRelatedSolutions>;
        }
        if (!isNew && showApplicationPlatformsTab) {
            return isEditing === true ?
                <EditSolutionApplicationPlatforms></EditSolutionApplicationPlatforms> : <ViewSolutionApplicationPlatforms></ViewSolutionApplicationPlatforms>;
        }
        return <></>
    }

    return <Modal isOpen={showInterfaceModal} toggle={toggleModal} size="lg">
        <ModalHeader className="py-2 bg-primary text-white" toggle={toggleModal}>
            {props.name}
        </ModalHeader>
        <ModalBody className="pt-1 bg-light">
            {(user.isAdmin || (currentInterface && currentInterface.creatUser && user.staffId === currentInterface.creatUser.id))
                && isEditing === false ? <div className="row">
                    <div className="col text-right">
                        <button className="btn btn-link"
                            onClick={() => dispatch.AssetInterface.changeApplicationModalIsEditing(true)}>Edit</button>
                    </div>
                </div> : ''}
            <nav className="nav nav-tabs">
                <li className="nav-item">
                    <button type="button" className={getClassName('showInfoTab')}
                        onClick={() => dispatch.AssetInterface.showInfoTab()}>
                        {props.name}&nbsp;Info
                    </button>
                </li>
                {props.isSolution && !isNew ?
                    <li className="nav-item">
                        <button type="button" className={getClassName('showApplicationPlatformsTab')}
                            onClick={() => dispatch.AssetInterface.showApplicationPlatformsTab()}>
                            Available Platforms
                        </button>
                    </li> : <></>}
                {!props.isSolution && !isNew ? <li className="nav-item">
                    <button type="button" className={getClassName('showRelatedSolutionsTab')}
                        onClick={() => dispatch.AssetInterface.showRelatedSolutionsTab()}>
                        Related Solutions
                    </button>
                </li> : <></>
                }
            </nav>
            <div className="row">
                <div className="col"></div>
            </div>
            {getCurrentView()}
        </ModalBody>
    </Modal>
}
