import React from "react";
import { useSelector } from "react-redux";
import store from '../../../Store';
import Card from '../../Card';

export default function ViewBusinessImpact() {
    const currentApplication = useSelector(state => store.select.Applications.currentApplication(state))
    const user = useSelector(state => state.User.user);

    if (!currentApplication) {
        return <div></div>
    }

    return <div>
        <div className="row">
            <div className="col-3 font-weight-bold">WPII Impact Status:</div>
            <div className="col">
                {currentApplication.wpiiImpactStatus}
            </div>
        </div>
        <div className="row">
            <div className="col-3 font-weight-bold">WPII Impact Assessment:</div>
            <div className="col">
                {currentApplication.wpiiImpactAssessment}
            </div>
        </div>
        <div className="row">
            <div className="col-3 font-weight-bold">WPII Notes:</div>
            <div className="col">
                {currentApplication.wpiiNotes}
            </div>
        </div>
        <div className="row my-1">
            <div className="col font-weight-bold">
                Assessment Technical Notes:
            </div>
        </div >
        <div className="row my-1">
            <div className="col">
                {currentApplication.wpiiTechnicalNotes}
            </div>
        </div>
        {user.isAdmin ?
            <div className="row my-1">
                <div className="col-3 font-weight-bold">OIT Staff Hours:</div>
                <div className="col">
                    {currentApplication.oitStaffHours}
                </div>
                <div className="col-3 font-weight-bold">Intake Request Required:</div>
                <div className="col">
                    {currentApplication.intakeRequestRequired === true ? 'Yes' : 'No'}
                </div>
            </div> : ''
        }
        <Card headerText="Project, Contract, and Budget Potential Impacts">
            <div className="row">
                <div className="col-3 font-weight-bold">Project Use:</div>
                <div className="col">
                    {currentApplication.wpiiHasProjectImpact ? 'Yes' : 'No'}
                </div>
            </div>
            <div className="row">
                <div className="col-3 font-weight-bold">
                    Project Q and A Notes:
                    </div>
                <div className="col" dangerouslySetInnerHTML={{ __html: currentApplication.projectImpactInfo }}>
                </div>
            </div>
            <hr />
            <div className="row">
                <div className="col-3 font-weight-bold">Contract Use:</div>
                <div className="col">
                    {currentApplication.wpiiHasContractImpact ? 'Yes' : 'No'}
                </div>
            </div>
            <div className="row">
                <div className="col-3 font-weight-bold">
                    Contract Q and A Notes:
                            </div>
                <div className="col" dangerouslySetInnerHTML={{ __html: currentApplication.contractImpactInfo }}>
                </div>
            </div>
            <hr />
            <div className="row">
                <div className="col-3 font-weight-bold">Budget Use:</div>
                <div className="col">
                    {currentApplication.wpiiHasBudgetImpact ? 'Yes' : 'No'}
                </div>
            </div>
            <div className="row">
                <div className="col-3 font-weight-bold">
                    Budget Q and A Notes:
                 </div>
                <div className="col" dangerouslySetInnerHTML={{ __html: currentApplication.budgetImpactInfo }}>
                </div>
            </div>
        </Card>
        <div className="row">
            <div className="col"></div>
        </div>
    </div >
}
