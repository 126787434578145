import openSocket from 'socket.io-client';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react'

export default function SocketHandler(props) {
  const dispatch = useDispatch();
  const user = useSelector(state => state.User.user);
  const socketId = useSelector(state => state.ApplicationLoadState.socketId)

  useEffect(() => {
    if (!user) {
      return;
    }
    let socket;

    socket = openSocket();

    socket.on('connect', function () {
      dispatch.ApplicationLoadState.setSocketId(socket.id);
    });

    socket.on('AssetChanged', (msg) => {
      if (socketId !== msg.socketId) {
        dispatch.Applications.saveApplication(msg.asset);
        var event = new CustomEvent("AppChanged", {
          detail: {
            'applicationId': msg.asset._id
          }
        });
        window.dispatchEvent(event);
      }
    });
    // eslint-disable-next-line
  }, [user]);
  return <></>;
}
