import React from 'react';
import { useSelector } from 'react-redux';
import store from '../../../Store';
import NumberFormat from 'react-number-format';

export default function ViewCloudFirstReporting(props) {
    const application = useSelector(state => store.select.Applications.currentApplication(state));

    if (!application)
        return <></>

    return <>
        <div className="row">
            <div className="col font-weight-bold">
                Cloud Reporting:
            </div>
            <div className="col">
                {application.cloudReporting}
            </div>
        </div>
        <div className="row">
            <div className="col font-weight-bold">
                Hosting Type:
            </div>
            <div className="col">
                {application.hostingType}
            </div>
        </div>
        <div className="row">
            <div className="col font-weight-bold">
                Year Implemented to Cloud:
            </div>
            <div className="col">
                {application.cloudYearImplemented}
            </div>
        </div>
        <div className="row">
            <div className="col">
                <table className="table table-striped table-bordered">
                    <thead>
                        <tr>
                            <th>Reporting Period</th>
                            <th>Cloud Readiness</th>
                            <th>Cloud Strategy</th>
                            <th>Target Time-frame</th>
                            <th>Annual Cloud Cost</th>
                            <th>Comments</th>
                        </tr>
                    </thead>
                    <tbody>
                        {application.cloudFirstReports ? application.cloudFirstReports.map((r, i) => {
                            return <tr key={i}>
                                <td>
                                    {r.reportingPeriod}
                                </td>
                                <td>
                                    {r.readiness}
                                </td>
                                <td>
                                    {r.strategy}
                                </td>
                                <td>
                                    {r.targetTimeFrame}
                                </td>
                                <td>
                                    <NumberFormat value={r.costForCloud} displayType={'text'} thousandSeparator={true} prefix='$'></NumberFormat>
                                    {/*r.costForCloud ? `$${r.costForCloud}` : ''*/}
                                </td>
                                <td>
                                    {r.comments}
                                </td>
                            </tr>
                        }) : <></>}
                    </tbody>
                </table>
            </div>
        </div>
    </>
}
