import React from "react";
import { useSelector } from 'react-redux';
import { appInsights } from '../../../appInsights';
import FontAwesomeButton from '../../FontAwesomeButton';
import { format } from 'date-fns';
import { toastr } from 'react-redux-toastr'

export default function ExportToExcel(props) {
    const filteredApplications = useSelector(state => state.Applications.filteredApplications);
    const applications = useSelector(state => state.Applications.applications);
    const user = useSelector(state => state.User.user);
    const teams = useSelector(state => state.Teams.teams);
    const teamFilter = useSelector(state => state.Applications.teamFilter);
    const subTeamFilter = useSelector(state => state.Applications.subTeamFilter);

    const contactAccessor = (app, propName) => {
        return app[propName] && app[propName].length > 0 && app[propName][0] ? app[propName][0].firstName + " " + app[propName][0].lastName : '';
    }

    const contactEmail = (app, propName) => {
        return app[propName] && app[propName].length > 0 && app[propName][0] ? app[propName][0].emailAddress : '';
    }

    const getFormattedJson = (apps) => {
        return Object.values(apps).map((app) => {
            let returnObj ={
                'Inventory Status': app.wpiiInventoryStatus,
                'Inventory Type': app.type,
                'Inventory Other Subtype': app.otherSubtype,
                'Impact Sort Group': teams[app?.team]?.name,
                'Impact Responsible Group': app.subTeam && teams[app.team].subTeams.find(s => { return s._id === app.subTeam }) ? teams[app.team].subTeams.find(s => { return s._id === app.subTeam }).name : '',
                'COMPASS ID': app.oitId,
                'ROADS ID': app.roadsId,
                'Acronym': app.acronym,
                'Name': app.name,
                'Description': app.description,
                'Asset Updater': contactAccessor(app, 'updatePersons'),
                'Technical Notes': app.technicalNotes
            };

            const wpiiObj = {
                'WPII Impact Status': app.wpiiImpactStatus,
                'WPII Assessment': app.wpiiImpactAssessment,
                'WPII Notes': app.wpiiNotes,
                'WPII Impact Responsible Staff Email': contactEmail(app, 'wpiiImpactResponsibleStaff'),
                'Project Impact': app.wpiiHasProjectImpact === true ? 'Yes' : 'No',
                'Contract Impact': app.wpiiHasContractImpact === true ? 'Yes' : 'No',
                'Budget Funding Impact': app.wpiiHasBudgetImpact === true ? 'Yes' : 'No',
                'Payroll Impact': app.hasPayrollImpact === true ? 'Yes' : 'No',
                'WPII Liaison': contactAccessor(app, 'wpiiLiasons'),
                "Palm Wave": app.palmImpactWave,
                'palmUseFLAIRDat': app.palmUseFLAIRDat === true ? 'Yes' : 'No',
                'palmUseContractData': app.palmUseContractData === true ? 'Yes' : 'No',
                'palmUseFinproj': app.palmUseFinproj === true ? 'Yes' : 'No',
                'palmUseContractNum': app.palmUseContractNum === true ? 'Yes' : 'No',
                'palmUseOrgLevelCode': app.palmUseOrgLevelCode === true ? 'Yes' : 'No',
                'palmUseOrgCode': app.palmUseOrgCode === true ? 'Yes' : 'No',
                'palmUseGAAFERFundCode': app.palmUseGAAFERFundCode === true ? 'Yes' : 'No',
                'palmUseStateFund': app.palmUseStateFund === true ? 'Yes' : 'No',
                'palmUseFindId': app.palmUseFindId === true ? 'Yes' : 'No',
                'palmUseGeneralLedgeCode': app.palmUseGeneralLedgeCode === true ? 'Yes' : 'No',
                'palmUseStateProgram': app.palmUseStateProgram === true ? 'Yes' : 'No',
                'palmUseObjectCode': app.palmUseObjectCode === true ? 'Yes' : 'No',
                'palmOtherCoseAccumulator': app.palmOtherCoseAccumulator === true ? 'Yes' : 'No',
                'palmUseVendorCode': app.palmUseVendorCode === true ? 'Yes' : 'No',
                'palmUseExpansionOption': app.palmUseExpansionOption === true ? 'Yes' : 'No'
            }

            const palmObj = {
                'WPII Impact Status': app.wpiiImpactStatus,
                'Project Impact': app.wpiiHasProjectImpact === true ? 'Yes' : 'No',
                'Contract Impact': app.wpiiHasContractImpact === true ? 'Yes' : 'No',
                'Budget Funding Impact': app.wpiiHasBudgetImpact === true ? 'Yes' : 'No',
                'Payroll Impact': app.hasPayrollImpact === true ? 'Yes' : 'No',
                '1. Uses Flair Data Or Interface': app.usesFlairDataOrInterface,
                '2. Uses CBFPIRAF Data': app.usesCBFPIRAFData,
                '3. Uses Chart Of Account Data': app.usesChartOfAccountData,
                '4. Uses Key Department Financial Systems': app.usesKeyDepartmentFinancialSystems,
                '5. Uses Impacted Focus Database Files': app.usesImpactedFocusDatabaseFiles,
                'Palm Status': app.palmStatus,
                'Palm Is Impacted': app.palmIsImpacted,
                "Palm Wave":app.palmImpactWave,
                'Palm Project Identifier (Financial Project)': app.palmUseFinproj === true ? 'Yes' : 'No',
                'Palm Contract': app.palmUseContractNum === true ? 'Yes' : 'No',                 
                'Palm Operating Level Organization': app.palmUseOrgLevelCode === true ? 'Yes' : 'No',
                'Palm Organization Code': app.palmUseOrgCode === true ? 'Yes' : 'No',
                'Palm GAAFR Fund': app.palmUseGAAFERFundCode === true ? 'Yes' : 'No',
                'Palm State Fund': app.palmUseStateFund === true ? 'Yes' : 'No',
                'Palm Fund ID': app.palmUseFindId === true ? 'Yes' : 'No',
                'Palm General Ledger Code': app.palmUseGeneralLedgeCode === true ? 'Yes' : 'No',
                'Palm State Program': app.palmUseStateProgram === true ? 'Yes' : 'No',
                'Palm Object Code': app.palmUseObjectCode === true ? 'Yes' : 'No',
                'Palm Other Cost Accumulator': app.palmOtherCoseAccumulator === true ? 'Yes' : 'No',
                'Palm Vendor Number ': app.palmUseVendorCode === true ? 'Yes' : 'No',
                'Palm Expansion Option': app.palmUseExpansionOption === true ? 'Yes' : 'No'
            }

            if (props.isWpiiAssessment) {
                returnObj = {
                    ...returnObj,
                    ...wpiiObj
                };
            }

            if (props.isPalmAssessment) {
                returnObj = {
                    ...returnObj,
                    ...palmObj
                };
            }

            if (props.isPalmAssessment || props.isWpiiAssessment) {
                delete returnObj.Acronym;
                delete returnObj['Asset Updater'];
                delete returnObj['Technical Notes'];
            }

            if (user.isAdmin && props.isWpiiAssessment) {
                returnObj['Intake Request Required'] = app.intakeRequestRequired === true ? 'Yes' : 'No';
                returnObj['OIT Staff ONLY Hours NO_data_or_interfaces'] = app.oitStaffHours;
            }

            return returnObj;
        });
    }

    const getName = (selectedName) => {
        let name = selectedName;
        if (!selectedName) {
            if (subTeamFilter) {
                name = teams[teamFilter]?.name + "_" + teams[teamFilter].subTeams.find(s => { return s._id === subTeamFilter }).name
            } else if (teamFilter) {
                name = teams[teamFilter]?.name;
            } else {
                name = 'ALL';
            }
        }

        return name;
    }

    const exportToExcel = async (apps, selectedName) => {
        let name = getName(selectedName);

        let uniqueName;
        let title;

        if (props.isWpiiAssessment) {
            uniqueName = `Compass_WPII_Inventory_${name ? name : props.name} _${format(new Date(), 'MM/DD/YYYY_h_mm_A')}`;
            title = "COMPASS WPII Impacted Inventory";
        } else if (props.isPalmAssessment) {
            uniqueName = `Compass_PALM_Inventory_${name ? name : props.name} _${format(new Date(), 'MM/DD/YYYY_h_mm_A')}`;
            title = "COMPASS WPII Impacted Inventory";
        } else {
            uniqueName = `Compass_Inventory_${name ? name : props.name} _${format(new Date(), 'MM/DD/YYYY_h_mm_A')}`;
            title = "COMPASS Inventory";
        }

        const fileName = `${uniqueName}.xlsx`;

        appInsights.trackEvent({
            name: 'Export To Excel',
            properties: {
                type: name ? name : props.name
            }
        });

        const XLSX = await import('xlsx');
        const wb = XLSX.utils.book_new();

        wb.Props = {
            Title: title,
            Subject: title,
            Author: "FDOT",
            CreatedDate: new Date()
        };

        if (apps.length === 0) {
            toastr.error('Only active assets can be exported!');
        } else {
            const formattedJson = getFormattedJson(apps);

            const workSheet = XLSX.utils.json_to_sheet(formattedJson);

            const workSheetName = `${format(new Date(), 'MM-DD-YYYY_h_mm_A')}`;

            XLSX.utils.book_append_sheet(wb, workSheet, workSheetName);
            const bin = XLSX.write(wb, { bookType: 'xlsx', type: "binary" });

            require("downloadjs")(bin, fileName, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
        }
    }

    const exportFilteredToExcel = async () => {
        exportToExcel(Object.values(filteredApplications).filter(app => {
            if (user.isAdmin) {
                return true;
            }
            return app.wpiiInventoryStatus === 'Active';
        }));
    };

    if (!filteredApplications && Object.keys(filteredApplications).length > 0) {
        return '';
    }

    return <div className="row my-1 py-1">
        <div className="col">
            <FontAwesomeButton text="Export Active IT Assets" small={true}
                icon="file-excel" onClick={exportFilteredToExcel}></FontAwesomeButton>
        </div>
    </div>
}
