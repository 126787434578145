import axios from 'axios';
import routeConfig from '../routeConfig';
import localforge from 'localforage';
import { loadAppInsights } from '../appInsights';
import { toastr } from 'react-redux-toastr';

export const ApplicationLoadState = {
    state: {
        isLoaded: false,
        localCache: {},
        serverCache: {},
        environmentVariables: null,
        insightsLoaded: false,
        socketId: null,
        menuConfiguration: {
            isDashboardActive: false,
            isDashboardActiveToOit: false,
            isAssetChangeReportActive: false,
            isAssetChangeReportActiveToOit: false,
            isInactiveStaffReportActive: false,
            isInactiveStaffReportActiveToOit: false
        }
    },
    reducers: {
        setIsLoaded(state) {
            return {
                ...state,
                isLoaded: true
            };
        },
        setSocketId(state, socketId) {
            return {
                ...state,
                socketId: socketId
            };
        },
        setLocalCache(state, cache) {
            return {
                ...state,
                localCache: cache
            };
        },
        setEnvironmentVariables(state, environmentVariables) {
            return {
                ...state,
                environmentVariables: environmentVariables
            }
        },
        setServerCache(state, cache) {
            return {
                ...state,
                serverCache: cache
            };
        },
        setMenuConfiguration(state, menuConfiguration) {
            return {
                ...state,
                menuConfiguration: menuConfiguration
            };
        }
    },
    effects: {
        async loadEnvironmentVariables(payload, rootstate) {
            let serverResponse = await axios.get(`${routeConfig.baseUrl}/Variables`);

            this.setEnvironmentVariables(serverResponse.data);
            loadAppInsights(serverResponse.data.APP_INSIGHTS_KEY)
        },
        async loadMenuConfiguration(payload, rootstate) {
            const result = await axios.get(`${routeConfig.baseUrl}/MenuConfiguration`);
            if (result.data && result.data.length > 0) {
                this.setMenuConfiguration(result.data[0]);
            }
        },
        async saveMenuConfiguration(menuState, rootstate) {
            let menuConfiguration = null;
            if (menuState._id) {
                const resp = await axios.patch(`${routeConfig.baseUrl}/MenuConfiguration/${menuState._id}`, menuState);
                menuConfiguration = resp.data;
            } else {
                const resp = await axios.post(`${routeConfig.baseUrl}/MenuConfiguration`, menuState);
                menuConfiguration = resp.data;
            }

            this.setMenuConfiguration(menuConfiguration);

            toastr.success('Menu Configuration has been updated!')

        },
        async syncApplicationCache(cacheChange, rootstate) {
            const localCache = await localforge.getItem('cache');

            if (localCache.Applications === cacheChange.oldCache) {
                localCache.syncApplicationCache = cacheChange.newCache;
                localforge.setItem('cache', localCache);
                this.setLocalCache(localCache);
            }
        },
        async loadCache(payload, rootstate) {
            let serverResponse = await axios.get(`${routeConfig.baseUrl}/Cache`);
            let serverCache = {};
            serverResponse.data.forEach(cache => {
                serverCache[cache.name] = cache.version;
            });

            const localCache = await localforge.getItem('cache');

            let currentCache = {};

            if (localCache) {
                Object.keys(localCache).forEach(key => {
                    currentCache[key] = localCache[key] === serverCache[key];
                })
            }

            this.setServerCache(serverCache);
            this.setLocalCache(currentCache);
        }
    }
}
