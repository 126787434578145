import React from 'react'
import { useSelector, useStore } from "react-redux";
import Card from '../../Card';

export default function ViewPalm() {
    const store = useStore();
    const currentApplication = useSelector(state => store.select.Applications.currentApplication(state));

    return <div>
        <div className="row my-1">
            <div className="col-2 font-weight-bold">Assessement Status:</div>
            <div className="col">
                {currentApplication.palmStatus}
            </div>
            <div className="col-2 font-weight-bold">Impacted By PALM:</div>
            <div className="col">
                {currentApplication.palmIsImpacted}
            </div>
        </div>
        <br />
        <Card headerText="PALM Assessment Questions">
            <div className="row my-1">
                <div className="col-10 font-weight-bold">1. Does this IT Asset use FLAIR Data or Interface with FLAIR:</div>
                <div className="col">
                    {currentApplication.usesFlairDataOrInterface}
                </div>
            </div>
            <div className="row my-1">
                <div className="col-10 font-weight-bold">2. Does this IT Asset use Contract, Budget, Funding, Payroll, Property, Invoicing, Receipts, Accounting, and/or Financial data:</div>
                <div className="col">
                    {currentApplication.usesCBFPIRAFData}
                </div>
            </div>
            {currentApplication.usesCBFPIRAFData === 'Yes' ?
                <>
                    <div className="row my-1">
                        <div className="col-1"></div>
                        <div className="col-2 font-weight-bold">Project Use:</div>
                        <div className="col">
                            {currentApplication.wpiiHasProjectImpact ? 'Yes' : 'No'}
                        </div>
                    </div>
                    <div className="row my-1">
                        <div className="col-1"></div>
                        <div className="col-2 font-weight-bold">Contract Use:</div>
                        <div className="col">
                            {currentApplication.wpiiHasContractImpact ? 'Yes' : 'No'}
                        </div>
                    </div>
                    <div className="row my-1">
                        <div className="col-1"></div>
                        <div className="col-2 font-weight-bold">Budget Use:</div>
                        <div className="col">
                            {currentApplication.wpiiHasBudgetImpact ? 'Yes' : 'No'}
                        </div>
                    </div>
                    <div className="row my-1">
                        <div className="col-1"></div>
                        <div className="col-2 font-weight-bold">Payroll Use:</div>
                        <div className="col">
                            {currentApplication.hasPayrollImpact ? 'Yes' : 'No'}
                        </div>
                    </div>
                </> : <></>}
            <div className="row my-1">
                <div className="col-10 font-weight-bold">3. Does this IT Asset use Chart of Account (COA) data:</div>
                <div className="col">
                    {currentApplication.usesChartOfAccountData}
                </div>
            </div>
            <div className="row my-1">
                <div className="col-10 font-weight-bold">4. Does this IT Asset use or integrate data from the following key Department Financial Systems:</div>
                <div className="col">
                    {currentApplication.usesKeyDepartmentFinancialSystems}
                </div>
            </div>
            <div className="row my-1">
                <div className="col-10 font-weight-bold">5. Does this IT Asset use or integrate with the following databases, tables, flat files, or FOCUS Masters:</div>
                <div className="col">
                    {currentApplication.usesImpactedFocusDatabaseFiles}
                </div>
            </div>
            <div className="row my-1">
                <div className="col-10 font-weight-bold">6. First PALM Impact Wave:</div>
                <div className="col">
                    {currentApplication.palmImpactWave}
                </div>
            </div>


     
                <div className="row my-1">
                <div className="col-8 font-weight-bold">7. Does this IT asset use any of the following PALM impacted fields?</div> <div class="col- 2 font-weight-bold alert-danger"> Check all that Apply</div>
                    {/*<div className="col">*/}
                    {/*{currentApplication.palmUseFLAIRData}*/}
                    {/*</div>*/}
            </div>
          
            {/*{currentApplication.palmIsImpacted === 'Yes' ?*/}
            {/*        <> */}
       
                <div className="row my-1">
                    <div className="col-1"></div>
                    <div className="col-8 font-weight-bold">Project Identifier (Financial Project) [e.g. FINPROJ 431684-1-52-01, 43168415201]:</div>
                    <div className="col">
                        {currentApplication.palmUseFinproj ? 'Yes' : 'No'}
                    </div>
                </div>
                    <div className="row my-1">
                        <div className="col-1"></div>
                        <div className="col-8 font-weight-bold">Contract [e.g. E4Q15, T9401]:</div>
                        <div className="col">
                            {currentApplication.palmUseContractNum ? 'Yes' : 'No'}
                        </div>
                    </div>
                    <div className="row my-1">
                        <div className="col-1"></div>
                        <div className="col-8 font-weight-bold">Operating Level Organization [e.g. 550000]:</div>
                        <div className="col">
                            {currentApplication.palmUseOrgLevelCode ? 'Yes' : 'No'}
                        </div>
                    </div>
                    <div className="row my-1">
                        <div className="col-1"></div>
                        <div className="col-8 font-weight-bold">Organization Code [e.g. 551600973]:</div>
                        <div className="col">
                            {currentApplication.palmUseOrgCode ? 'Yes' : 'No'}
                        </div>
                    </div>
                    <div className="row my-1">
                        <div className="col-1"></div>
                        <div className="col-8 font-weight-bold">GAAFR Fund [e.g. 10, 20, 30]:</div>
                        <div className="col">
                            {currentApplication.palmUseGAAFERFundCode ? 'Yes' : 'No'}
                        </div>
                    </div>
                    <div className="row my-1">
                        <div className="col-1"></div>
                        <div className="col-8 font-weight-bold">State Fund [e.g. 1, 2, 3, 4, 5, 6, 7, 8, 9]:</div>
                        <div className="col">
                            {currentApplication.palmUseStateFund ? 'Yes' : 'No'}
                        </div>
                    </div>
                    <div className="row my-1">
                        <div className="col-1"></div>
                        <div className="col-8 font-weight-bold">Fund ID [e.g. 540001]:</div>
                        <div className="col">
                            {currentApplication.palmUseFindId ? 'Yes' : 'No'}
                        </div>
                    </div>
                    <div className="row my-1">
                        <div className="col-1"></div>
                        <div className="col-8 font-weight-bold">General Ledger Code[e.g. 94100]:</div>
                        <div className="col">
                            {currentApplication.palmUseGeneralLedgeCode ? 'Yes' : 'No'}
                        </div>
                    </div>
                    <div className="row my-1">
                        <div className="col-1"></div>
                        <div className="col-8 font-weight-bold">State Program[e.g. 1603000000000000]:</div>
                        <div className="col">
                            {currentApplication.palmUseStateProgram ? 'Yes' : 'No'}
                        </div>
                    </div>
                    <div className="row my-1">
                        <div className="col-1"></div>
                        <div className="col-8 font-weight-bold">Object Code [e.g.132700, 891108]:</div>
                        <div className="col">
                            {currentApplication.palmUseObjectCode ? 'Yes' : 'No'}
                        </div>
                    </div>
                    <div className="row my-1">
                        <div className="col-1"></div>
                        <div className="col-8 font-weight-bold">Other Cost Accumulator [e.g. ID059, ID048]:</div>
                        <div className="col">
                            {currentApplication.palmOtherCoseAccumulator ? 'Yes' : 'No'}
                        </div>
                    </div>
                    <div className="row my-1">
                        <div className="col-1"></div>
                        <div className="col-8 font-weight-bold">Vendor Number [e.g. F123456789001 C123456789001 N123456789001]:</div>
                        <div className="col">
                            {currentApplication.palmUseVendorCode ? 'Yes' : 'No'}
                        </div>
                    </div>
                    <div className="row my-1">
                        <div className="col-1"></div>
                        <div className="col-8 font-weight-bold">Expansion Option [A1, AA, ZA]:</div>
                        <div className="col">
                            {currentApplication.palmUseExpansionOption ? 'Yes' : 'No'}
                        </div>
                    </div>
    
        <>
            </>
   
               
        </Card>
    </div>
}