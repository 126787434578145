import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types'
export default function AddButton(props) {
    let className = "btn btn-primary"
    if (props.isSmall) {
        className = "btn btn-primary btn-sm"
    }
    return <button type="button" aria-label={`Add ${props.text}`} title={`Add ${props.text}`} className={className}
        onClick={props.onClick} disabled={props.disabled}>
        <FontAwesomeIcon icon="plus"></FontAwesomeIcon>&nbsp;&nbsp;{props.text}</button>
}

AddButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    text: PropTypes.string,
    disabled: PropTypes.bool,
    isSmall: PropTypes.bool,
}
