import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
export default function DeleteButton(props) {
    const className = () => {
        if (props.isSmall) {
            return "btn btn-danger btn-sm"
        }
        return "btn btn-danger";
    }
    return <button type="button" aria-label={props.text} title={props.text ? props.text : 'Delete'} className={className()}
        onClick={props.onClick}>
        <FontAwesomeIcon icon="trash-alt" ></FontAwesomeIcon>
        {props.hideText ? '' : <span>&nbsp;&nbsp;{props.text}</span>}
    </button>
}

DeleteButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    text: PropTypes.string,
    hideText: PropTypes.bool,
    isSmall: PropTypes.bool
}
