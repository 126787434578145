import React, { useEffect, useState } from 'react';
import axios from 'axios';
import routeConfig from '../../../routeConfig';

export default function CountWidget(props) {
    const [count, setCount] = useState(null);
    const [queryFailed, setQueryFailed] = useState(false);

    useEffect(() => {
        console.log(props.config && props.config.query);
        if (props.config && props.config.query) {
            axios.get(`${routeConfig.baseUrl}/Query/${props.config.query}`).then(resp => {
                setCount(resp.data.count);
            }).catch(err => {
                setQueryFailed(true);
            });
        }
    }, [props.config]);

    if (queryFailed) {
        return <div>Query Failed</div>
    }

    if (!count && count !== 0) {
        return <div className="row">
            <div className="col">
                <h3 className="text-center">
                    <div className="spinner-border text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>&nbsp;&nbsp;Count is Loading...
            </h3>
            </div>
        </div>
    }
    return <div className="row">
        <div className="col text-center">
            <h1>{count}</h1>
        </div>
    </div>
}
