import React from 'react'

export default function DimComingSoon(props) {
    return <div className="container">
        <div className="row">
            <div className="col text-center">
                <h2>
                    DIM Assessment Information Coming Soon!
        </h2>
            </div>
        </div>
        <div className="row">
            <div className="col">
                <p>The Data Infrastructure Modernization (DIM) Initiative is an effort underway in the Department to
                    modernize technology and the various ways in which data is accessed to support the
                    Cloud First Initiative. To be cloud ready, the Department must move off Computer Assets
                    that are not Cloud ready such as those in the State Data Center like the mainframe and
                    the Oracle server infrastructures. It should be noted that the Department’s Work Program
                    and Financial Management processes are dependent on these older technologies that are not
                    Cloud compatible. The DIM Assessment in COMPASS will support modernization efforts by
                    documenting the scope of remediation needed for Cloud readiness for the Department’s Computer Assets.</p>
            </div>
        </div>
    </div>
}
