import axios from 'axios';
import routeConfig from '../routeConfig';
export const ApplicationGitStat = {
    state: {
        applicationGitStat: null,
    },
    reducers: {
        addApplicationGitStat(state, applicationGitStat) {
            return {
                ...state,
                applicationGitStat: applicationGitStat
            };
        }
    },
    effects: {
        async loadApplicationGitStats(id) {
            const response = await axios.get(`${routeConfig.baseUrl}/ApplicationGitStats/${id}`);
            this.addApplicationGitStat(response.data);
        }
    },
    selectors: {

    }
}
