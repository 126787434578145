import React, { useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import WpiiImpactStatusOptions from "../../WpiiImpactStatusOptions";
import store from '../../../Store';
import SaveCancel from '../../SaveCancel';
import Card from '../../Card';
import { useNestedArrayState } from '../../../hooks/useNestedArrayState';
import { RichTextEditorComponent, HtmlEditor, Inject, Toolbar, Image, Link, QuickToolbar } from '@syncfusion/ej2-react-richtexteditor';
import { toastr } from "react-redux-toastr";
export default function EditBusinessImpact(props) {
    const dispatch = useDispatch();
    const projectEditor = useRef(null);
    const contractEditor = useRef(null);
    const budgetEditor = useRef(null);
    const user = useSelector(state => state.User.user);
    const application = useSelector(state => store.select.Applications.currentApplication(state));
    const {
        state,
        setStateProperty,
        setState
    } = useNestedArrayState(application);

    const toolbarSettings = {
        items: ['Bold', 'Italic', 'Underline', '|', 'Formats', 'Alignments', 'OrderedList',
            'UnorderedList', '|', 'CreateLink', '|', 'SourceCode', 'Undo', 'Redo']
    };
    const onSubmit = () => {
        if (state.wpiiImpactStatus === '1. Replaced by WPII' ||
            state.wpiiImpactStatus === '2. Impacted by WPII and may fall in WPII scope' ||
            state.wpiiImpactStatus === '3. Impacted by WPII but outside of scope') {
            if (!state.wpiiHasContractImpact && !state.wpiiHasProjectImpact && !state.wpiiHasBudgetImpact) {
                toastr.error('Project, Contract, or Budget Impact must be selected for the selected WPII Impact Status');
                return;
            }
        }
        const appToBeSaved = {
            ...state,
            projectImpactInfo: projectEditor.current.value,
            contractImpactInfo: contractEditor.current.value,
            budgetImpactInfo: budgetEditor.current.value
        }

        dispatch.Applications.persistApplication(appToBeSaved);
        dispatch.Applications.changeApplicationModalIsEditing(false);
    }

    useEffect(() => {
        console.log(state);
    }, [state]);

    const changeImpactStatus = (e) => {
        if (e.target.value !== '1. Replaced by WPII' &&
            e.target.value !== '2. Impacted by WPII and may fall in WPII scope' &&
            e.target.value !== '3. Impacted by WPII but outside of scope') {
            setState({
                ...state,
                wpiiHasContractImpact: false,
                wpiiHasProjectImpact: false,
                wpiiHasBudgetImpact: false,
                wpiiImpactStatus: e.target.value
            })
        } else {
            setStateProperty('wpiiImpactStatus', e);
        }
    }

    const isDisabled = () => {
        return state.wpiiImpactStatus !== '1. Replaced by WPII' &&
            state.wpiiImpactStatus !== '2. Impacted by WPII and may fall in WPII scope' &&
            state.wpiiImpactStatus !== '3. Impacted by WPII but outside of scope' ? true : false;
    }

    if (!application)
        return '';

    return <div>
        <div className="row">
            <div className="col"></div>
        </div>
        <div className="row">
            <div className="col">
                <div className="row">
                    <div className="col-3 font-weight-bold">WPII Impact Status:</div>
                    <div className="col">
                        <select className="form-control" value={state.wpiiImpactStatus} onChange={e => changeImpactStatus(e)}>
                            <WpiiImpactStatusOptions></WpiiImpactStatusOptions>
                        </select>
                    </div>
                </div>
                <div className="row">
                    <div className="col-3 font-weight-bold">WPII Impact Assessment:</div>
                    <div className="col">
                        <textarea className="form-control" value={state.wpiiImpactAssessment}
                            onChange={e => setStateProperty('wpiiImpactAssessment', e)} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-3 font-weight-bold">WPII Notes:</div>
                    <div className="col">
                        <textarea className="form-control" value={state.wpiiNotes} onChange={e => setStateProperty('wpiiNotes', e)} />
                    </div>
                </div>
                <div className="row my-1">
                    <div className="col-2 font-weight-bold">
                        Assessment Technical Notes:
                    </div>
                    <div className="col">
                        <textarea className="form-control-sm form-control" type="text" value={state.technicalNotes}
                            onChange={e => setStateProperty('technicalNotes', e)}></textarea>
                    </div>
                </div >
                {user.isAdmin ?
                    <div className="row my-1">
                        <div className="col-2 font-weight-bold">OIT Staff Hours:</div>
                        <div className="col">
                            <input className="form-control-sm form-control" type="number" value={state.oitStaffHours}
                                onChange={e => setStateProperty('oitStaffHours', e)}></input>
                        </div>
                        <div className="col font-weight-bold">
                            <div className="form-check">
                                <input className="form-check-input" id="wpiiHasBudgetImpact" type="checkbox"
                                    checked={state.intakeRequestRequired} onChange={e => setStateProperty('intakeRequestRequired', e)} />
                                <label className="form-check-label" htmlFor="wpiiHasBudgetImpact">Intake Request Required</label>
                            </div>
                        </div>
                    </div> : ''}
                <Card headerText="Project, Contract, and Budget Potential Impacts">
                    <div className="row">
                        <div className="col font-weight-bold">Project Use:</div>
                        <div className="col">
                            <input className="form-check-input" id="projectImpact" type="checkbox" checked={state.wpiiHasProjectImpact}
                                onChange={e => setStateProperty('wpiiHasProjectImpact', e)} disabled={isDisabled()} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col font-weight-bold">
                            Project Q and A Notes:
                                    </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <RichTextEditorComponent id="defaultRTE" toolbarSettings={toolbarSettings} ref={projectEditor}
                                value={state.projectImpactInfo ? state.projectImpactInfo : ''}>
                                <Inject services={[HtmlEditor, Toolbar, Image, Link, QuickToolbar]} />
                            </RichTextEditorComponent>
                        </div>
                    </div>
                    <hr />
                    <div className="row">
                        <div className="col font-weight-bold">Contract Use:</div>
                        <div className="col">
                            <input className="form-check-input" id="wpiiHasContractImpact" type="checkbox" disabled={isDisabled()}
                                checked={state.wpiiHasContractImpact} onChange={e => setStateProperty('wpiiHasContractImpact', e)} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col font-weight-bold">
                            Contract Q and A Notes:
                                    </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <RichTextEditorComponent id="defaultRTE" ref={contractEditor} toolbarSettings={toolbarSettings}
                                value={state.contractImpactInfo ? state.contractImpactInfo : ''}>
                                <Inject services={[HtmlEditor, Toolbar, Image, Link, QuickToolbar]} />
                            </RichTextEditorComponent>
                        </div>
                    </div>
                    <hr />
                    <div className="row">
                        <div className="col font-weight-bold">Budget Use:</div>
                        <div className="col">
                            <input className="form-check-input" id="wpiiHasBudgetImpact" type="checkbox" disabled={isDisabled()}
                                checked={state.wpiiHasBudgetImpact} onChange={e => setStateProperty('wpiiHasBudgetImpact', e)} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col font-weight-bold">
                            Budget Q and A Notes:
                                    </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <RichTextEditorComponent id="defaultRTE" ref={budgetEditor} toolbarSettings={toolbarSettings}
                                value={state.budgetImpactInfo ? state.budgetImpactInfo : ''}>
                                <Inject services={[HtmlEditor, Toolbar, Image, Link, QuickToolbar]} />
                            </RichTextEditorComponent>
                        </div>
                    </div>
                </Card>
                <br />
                <SaveCancel onSaveClick={onSubmit} onCancelClick={() => dispatch.Applications.changeApplicationModalIsEditing(false)}></SaveCancel>
                <div className="row">
                    <div className="col"></div>
                </div>
            </div>
        </div>
    </div>
}
