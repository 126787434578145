import React, { useMemo } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Card from "../Card";

export default function EditDatabase(props) {
    const tables = useMemo(() => {
        if (props.isOrphaned) {
            return props.state.tables.filter(t => {
                return t.isOrphaned;
            })
        }
        return props.state.tables.filter(t => {
            return !t.isOrphaned;
        })
    }, [props.state.tables, props.isOrphaned])

    return <>
        {props.hideDatabaseName === true ? <></> :
            <div className="row" >
                <div className="col font-weight-bold">
                    Database Name:
            </div>
                <div className="col">
                    <input type="text" className="form-control col" value={props.state.databaseName}
                        onChange={e => props.setStateProperty('databaseName', e)} />
                </div>
            </div>
        }
        <Card headerText={props.isOrphaned ? 'Orphaned Tables' : "Owned Tables"} badgeCount={tables.length} collapsed={true}>
            <div className="row">
                <div className="col text-right">
                    <button className="btn btn-primary" onClick={() => props.addToArrayProperty('tables', { name: '', logicalName: '', isOrphaned: props.isOrphaned })} title="Add Table">
                        <FontAwesomeIcon icon="plus"></FontAwesomeIcon>&nbsp;&nbsp;Table</button>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <ul className="list-group">
                        {props.state.tables ? props.state.tables.map((table, tableIndex) => {
                            if (props.isOrphaned && !table.isOrphaned) {
                                return <></>
                            }
                            if (!props.isOrphaned && table.isOrphaned) {
                                return <></>
                            }

                            return <li className="list-group-item" key={tableIndex}>
                                <div className="row">
                                    <label className="col-2 font-weight-bold">
                                        Table Name:</label>
                                    <input type="text" className="form-control col" value={table.name}
                                        onChange={e => props.setStateArrayProperty('tables', tableIndex, 'name', e)} />
                                    <label className="col-2 font-weight-bold">
                                        Logical Name:</label>
                                    <input type="text" className="form-control col" value={table.logicalName}
                                        onChange={e => props.setStateArrayProperty('tables', tableIndex, 'logicalName', e)} />
                                </div>
                                <div className="row">
                                    <label className="col font-weight-bold">
                                        Description:</label>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <textarea onChange={e => props.setStateArrayProperty('tables', tableIndex, 'description', e)}
                                            value={table.description} className="form-control" />
                                    </div>
                                </div>
                                {/*<div className="row">
                                    <div className="col">Create:</div>
                                    <div className="col">
                                        <input type="checkbox" checked={table.isCreate}
                                            onChange={e => props.setStateArrayProperty('tables', tableIndex, 'isCreate', e)}></input>
                                    </div>
                                    <div className="col">Read:</div>
                                    <div className="col">
                                        <input type="checkbox" checked={table.isRead}
                                            onChange={e => props.setStateArrayProperty('tables', tableIndex, 'isRead', e)}></input>
                                    </div>
                                    <div className="col">Update:</div>
                                    <div className="col">
                                        <input type="checkbox" checked={table.isUpdate}
                                            onChange={e => props.setStateArrayProperty('tables', tableIndex, 'isUpdate', e)}></input>
                                    </div>
                                    <div className="col">Delete:</div>
                                    <div className="col">
                                        <input type="checkbox" checked={table.isDelete}
                                            onChange={e => props.setStateArrayProperty('tables', tableIndex, 'isDelete', e)}></input>
                                    </div>
                        </div>*/}
                                <Card headerText="Fields" collapsed={true}>
                                    <div className="row">
                                        <div className="col text-right">
                                            <button className="btn btn-primary" title="Add Field"
                                                onClick={() => { props.addToNestedArrayProperty('tables', tableIndex, 'fields', { name: '', logicalName: '' }) }}>
                                                <FontAwesomeIcon icon="plus"></FontAwesomeIcon>&nbsp;&nbsp;Field
                                    </button>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <table className="table table-striped table-bordered table-sm">
                                                <thead>
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>Logical Name</th>
                                                        <th>Description</th>
                                                        <th>Field Type</th>
                                                        <th>Field Length</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>{props.state.tables[tableIndex].fields ? props.state.tables[tableIndex].fields.map((field, fieldIndex) => {
                                                    return <tr key={fieldIndex}>
                                                        <td>
                                                            <input className="form-control" value={field.name}
                                                                onChange={e => props.setStateArrayofArrayProperty('tables', tableIndex, 'fields', fieldIndex, 'name', e)} />
                                                        </td>
                                                        <td>
                                                            <input className="form-control" value={field.logicalName}
                                                                onChange={e => props.setStateArrayofArrayProperty('tables', tableIndex, 'fields', fieldIndex, 'logicalName', e)} />
                                                        </td>
                                                        <td>
                                                            <input className="form-control" value={field.description}
                                                                onChange={e => props.setStateArrayofArrayProperty('tables', tableIndex, 'fields', fieldIndex, 'description', e)} />
                                                        </td>
                                                        <td>
                                                            <input type="text" className="form-control" value={field.fieldType}
                                                                onChange={e => props.setStateArrayofArrayProperty('tables', tableIndex, 'fields', fieldIndex, 'fieldType', e)} />
                                                        </td>
                                                        <td>
                                                            <input type="text" className="form-control" value={field.length}
                                                                onChange={e => props.setStateArrayofArrayProperty('tables', tableIndex, 'fields', fieldIndex, 'length', e)} />
                                                        </td>
                                                        <td>
                                                            <button className="btn btn-danger" title="Delete Field"
                                                                onClick={e => props.removeFromNestedArrayProperty('tables', tableIndex, 'fields', fieldIndex)}>
                                                                <FontAwesomeIcon icon="trash-alt"></FontAwesomeIcon>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                }) : <></>}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col text-right">
                                            <button className="btn btn-primary" title="Add Field"
                                                onClick={() => { props.addToNestedArrayProperty('tables', tableIndex, 'fields', { name: '', logicalName: '' }) }}>
                                                <FontAwesomeIcon icon="plus"></FontAwesomeIcon>&nbsp;&nbsp;Field
                                    </button>
                                        </div>
                                    </div>
                                </Card>
                                <br />
                                <div className="row">
                                    <div className="col">
                                        <button className="btn btn-danger" onClick={() => props.removeFromArrayProperty('tables', tableIndex)}>
                                            <FontAwesomeIcon icon="trash-alt"></FontAwesomeIcon>
                                            &nbsp;&nbsp;Remove Table
                        </button>
                                    </div>
                                </div>
                            </li>
                        }) : ''}
                    </ul>
                </div>
            </div>
        </Card>
    </>
}
