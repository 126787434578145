import React, { useMemo } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function SelectColumnFilter(props) {
    const {
        column: { filterValue, setFilter, preFilteredRows, id },
    } = props;
    // Calculate the options for filtering
    const filter = filterValue ? filterValue : '';
    // using the preFilteredRows
    const options = useMemo(() => {
        const options = new Set()
        preFilteredRows.forEach(row => {
            options.add(row.values[id])
        })
        return [...options.values()]
    }, [id, preFilteredRows])
    return <div className="input-group">
        <div className="input-group-prepend input-sm">
            <span className="input-group-text sm p-1" >
                <FontAwesomeIcon icon="filter" size="sm" ></FontAwesomeIcon></span>
        </div>
        <select className="form-control"
            value={filter}
            onChange={e => { setFilter(e.target.value || '') }} >
            <option value="">All</option>
            {options.map((option, i) => (
                <option key={i} value={option}>
                    {option}
                </option>
            ))}
        </select>
    </div>
}
