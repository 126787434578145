import React from "react";
import { useSelector } from "react-redux";

export default function TeamOptions(props) {
    const {
        team,
        hideEmpty,
        emptyText,
        allSubTeams,
        showAll
    } = props;

    const teams = useSelector(state => state.Teams.teams);
    const options = [];
    if (!hideEmpty === true) {
        const val = showAll ? 'Select' : '';
        const text = showAll ? 'Select' : emptyText === '' ? 'Select' : emptyText;
        options.push(<option key="Select" value={val}>{text}</option>);
    }


    if (team && team !== 'Select') {
        const teamObj = teams[team];
        if (teamObj) {
            teams[team].subTeams.forEach((key, i) => {
                options.push(<option key={key._id} value={key._id}>{key.name}</option>);
            });
        }
    } else if (allSubTeams) {
        const subTeams = [];
        for (let team of Object.values(teams)) {
            for (let subTeam of team.subTeams) {
                subTeams.push(subTeam);
            }
        }
        subTeams.sort((a, b) => {
            var nameA = a.name.toUpperCase(); // ignore upper and lowercase
            var nameB = b.name.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }

            // names must be equal
            return 0;
        });

        for (let i = 0; i < subTeams.length; i++) {
            options.push(<option key={i} value={subTeams[i]._id}>{subTeams[i].name}</option>);
        }
        return options;
    }
    else {
        Object.keys(teams).sort((a, b) => {
            var nameA = teams[a].name.toUpperCase(); // ignore upper and lowercase
            var nameB = teams[b].name.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }

            // names must be equal
            return 0;
        }).forEach((key, i) => {
            const team = teams[key]
            options.push(<option key={key} value={key}>{team.name}</option>);
        });

        if (showAll === true) {
            options.push(<option key="All" value=''>All</option>);
        }
    }

    return options;
}
