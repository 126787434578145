export default function getCurrentFiscalYear() {
    var today = new Date();
    var curMonth = today.getMonth();

    var fiscalYr = "FY ";
    if (curMonth > 6) { //
        var nextYr1 = (today.getFullYear() + 1).toString();
        fiscalYr += today.getFullYear().toString() + "-" + nextYr1;
    } else {
        var nextYr2 = today.getFullYear().toString();
        fiscalYr += (today.getFullYear() - 1).toString() + "-" + nextYr2;
    }

    return fiscalYr;
}
