import React from "react";
import { connect } from 'react-redux';
import store from '../../../Store';

function ViewAssetComponents(props) {
    if (!props.application)
        return <div></div>

    return <>
        <div className="row">
            <div className="col">
                <table className="table table-striped table-bordered">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Type</th>
                            <th>Description</th>
                            <th>Business Use</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.application.components ? props.application.components.map((component, i) => {
                            return <tr key={i}>
                                <td>{component.name}</td>
                                <td>{component.type}</td>
                                <td style={{ whiteSpace: "pre-line" }}>{component.description}</td>
                                <td style={{ whiteSpace: "pre-line" }}>{component.businessUse}</td>
                            </tr>
                        }) : <></>}
                    </tbody>
                </table>
            </div>
        </div>
    </>
}

const mapState = state => {
    return {
        application: store.select.Applications.currentApplication(state)
    }
}

export default connect(mapState)(ViewAssetComponents);
