import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import Store from '../../Store';
import { format } from 'date-fns';
import Clipboard from 'react-clipboard.js';
import FontAwesomeButton from '../FontAwesomeButton';
import ViewPpmProjects from './PpmProProjects/ViewPpmProjects';

export default function SimplifiedAsset(props) {
    const currentApplication = useSelector(state => Store.select.Applications.currentApplication(state));
    const applications = useSelector(state => state.Applications.applications);
    const [duplicateIds, setDuplicateIds] = useState([]);

    const openAsset = () => {
        window.open(`/Inventory/${currentApplication.duplicateId}`);
    }

    const openAssetId = (id) => {
        window.open(`/Inventory/${id}`);
    }

    useEffect(() => {
        if (currentApplication && currentApplication._id) {
            const dups = [];
            for (let app of Object.values(applications)) {
                if (app.duplicateId === currentApplication._id) {
                    console.log(`${app.name} -${app.oitId}`);
                    dups.push(app._id);
                }
            }
            setDuplicateIds(dups);
        }
        // eslint-disable-next-line
    }, [currentApplication._id]);

    if (!currentApplication) {
        return <div></div>
    }

    return <div>
        <div className="row my-1">
            <div className="col-2 font-weight-bold">Name:</div>
            <div className="col">
                {currentApplication.name}
            </div>
            <div className="col-2 font-weight-bold">Acronym:</div>
            <div className="col">
                {currentApplication.acronym}
            </div>
        </div>

        <div className="row my-1">
            <div className="col-2 font-weight-bold">COMPASS ID:</div>
            <div className="col">
                {currentApplication.oitId}
            </div>
            <div className="col-2 font-weight-bold">ROADS ID:</div>
            <div className="col">
                {currentApplication.roadsId}
            </div>
        </div>
        <div className="row my-1">
            <div className="col-2 font-weight-bold">IT Asset Status:</div>
            <div className="col">
                {currentApplication.wpiiInventoryStatus}
            </div>
            <div className="col-2 font-weight-bold">Responsible Group:</div>
            <div className="col">
                {currentApplication.wpiiImpactResponsibleGroup}
            </div>

        </div>
        {currentApplication.wpiiInventoryStatus === 'Duplicate'
            && currentApplication.duplicateId ?
            <div className="row my-1">
                <div className="col-2 font-weight-bold">Active Asset:</div>
                <div className="col">
                    {applications[currentApplication.duplicateId].name} - {applications[currentApplication.duplicateId].oitId}&nbsp;&nbsp;
                    <FontAwesomeButton icon='share-square' title={`Open ${applications[currentApplication.duplicateId].name} in a new window`} onClick={openAsset} small={true}></FontAwesomeButton>
                </div>
            </div> : <></>
        }
        {duplicateIds.length > 0 ?
            <div className="row my-1">
                <div className="col-2 font-weight-bold">Duplicates:</div>
                <div className="col">
                    {duplicateIds.map((d, i) => {
                        return <React.Fragment key={i}>
                            {applications[d].name} - {applications[d].oitId}&nbsp;&nbsp;
                        <FontAwesomeButton icon='share-square' title={`Open ${applications[d].name} in a new window`} onClick={() => openAssetId(d)} small={true}></FontAwesomeButton>
                                                &nbsp;&nbsp;
                        </React.Fragment>
                    })}

                </div>
            </div>
            : <></>}
        <div className="row">
            <div className="col-2 font-weight-bold">IT Asset Type:</div>
            <div className="col">
                {currentApplication.type}
            </div>
            {currentApplication.type === 'Other' ? <>
                <div className="col-2 font-weight-bold">IT Asset Other Subtype:</div>
                <div className="col">
                    {currentApplication.otherSubtype}
                </div>
            </> : <></>}
        </div>
        <div className="row my-1">
            <div className="col font-weight-bold">
                Description:
            </div>
        </div >
        <div className="row my-1">
            <div className="col" style={{ whiteSpace: "pre-line" }}>
                {currentApplication.description}
            </div>
        </div>
        <div className="row my-1">
            <div className="col font-weight-bold">
                Business Metadata:
            </div>
        </div >
        <div className="row my-1">
            <div className="col" style={{ whiteSpace: "pre-line" }}>
                {currentApplication.businessMetadata}
            </div>
        </div>
        <div className="row my-1">
            <div className="col-2 font-weight-bold">
                Ring Fenced:
            </div>
            <div className="col">
                {currentApplication.isRingFenced === true ? 'Yes' : 'No'}
            </div>
        </div>
        {currentApplication.relatedAssets ?
            <>
                <div className="row my-1">
                    <div className="col font-weight-bold">
                        Related Assets:
                </div>
                </div>
                <div className="row my-1">
                    <div className="col">
                        {currentApplication.relatedAssets.map((d, i) => {
                            return <React.Fragment key={i}>
                                {applications[d].name} - {applications[d].oitId}&nbsp;&nbsp;
                        <FontAwesomeButton icon='share-square' title={`Open ${applications[d].name} in a new window`} onClick={() => openAssetId(d)} small={true}></FontAwesomeButton>
                        &nbsp;&nbsp;
                    </React.Fragment>
                        })}
                    </div>
                </div>
            </>
            : <></>
        }
        <ViewPpmProjects></ViewPpmProjects>
        <div className="row my-1">
            <div className="col">
                <Clipboard data-clipboard-text={`${window.location.origin}/Inventory/${currentApplication._id}`}
                    button-className="btn btn-primary">
                    Copy Link to Asset
    </Clipboard>
            </div>
        </div>
        <br />
        {currentApplication.__user ?
            <div className="row my-1">
                <div className="col">
                    {`Last Updated by ${currentApplication.__user.firstName} ${currentApplication.__user.lastName}
                    (${currentApplication.__user.racfId}) on ${format(new Date(currentApplication.updatedAt), 'MM/DD/YYYY h:mm:ss A')}`}
                </div>
            </div> : ''}
    </div >
}
