import React from 'react';
import PropTypes from 'prop-types';
const expr = require('expression-eval');
export default function EditQuickAssessmentQuestions(props) {
    const {
        state,
        setNestedNestedPropertyState,
        selectedAssessment,
        questions
    } = props;


    const getOptions = (question) => {
        const options = [<option key="null" value=''>Select</option>];
        question.options.forEach((option, i) => {
            options.push(<option key={i} value={option}>{option}</option>);
        });

        return options;
    }

    const isQuestionDisabled = (question) => {
        if (!question.disabledExpression || question.disabledExpression === '') {
            return false;
        }

        let ast = null;

        try {
            ast = expr.parse(question.disabledExpression);
        } catch (ex) {

        }

        if (!ast) {
            return false;
        }

        const objParams = {};
        let evaluationQuestions = [];
        if (selectedAssessment.useGrouping) {
            for (let group of selectedAssessment.groups) {
                evaluationQuestions = [...group.questions, ...evaluationQuestions];
            }
        } else {
            evaluationQuestions = questions;
        }

        for (var q of evaluationQuestions) {
            if (q.expressionVariable && q.expressionVariable !== '') {
                objParams[q.expressionVariable] = state.quickAssessments[selectedAssessment._id][q._id];
            }
        }

        const evaluationResult = expr.eval(ast, objParams);

        if (evaluationResult && state.quickAssessments[selectedAssessment._id][question._id]) {
            setNestedNestedPropertyState('quickAssessments', selectedAssessment._id, question._id, '');
        }

        return evaluationResult;
    }

    const updateMultiSelectValue = (e, question) => {
        const arrayValue = Array.from(e.target.options).filter(opt => {
            return opt.selected;
        }).map((opt, i) => {
            return opt.value;
        });

        setNestedNestedPropertyState('quickAssessments', selectedAssessment._id, question._id, arrayValue);
    }

    const getInput = (question) => {
        const isDisabled = isQuestionDisabled(question);
        switch (question.questionType) {
            case "Dropdown":
                return <select className="form-control" disabled={isDisabled}
                    value={state.quickAssessments[selectedAssessment._id][question._id]}
                    onChange={e => setNestedNestedPropertyState('quickAssessments', selectedAssessment._id, question._id, e)}>
                    {getOptions(question)}
                </select>
            case "MultiSelect":
                return <select disabled={isDisabled} multiple className="form-control"
                    onChange={e => updateMultiSelectValue(e, question)} value={state.quickAssessments[selectedAssessment._id][question._id] ?
                        state.quickAssessments[selectedAssessment._id][question._id] : []}>
                    {getOptions(question)}
                </select>
            case "Input":
                return <input className="form-control" type="text" disabled={isDisabled}
                    value={state.quickAssessments[selectedAssessment._id][question._id]}
                    onChange={e => setNestedNestedPropertyState('quickAssessments', selectedAssessment._id, question._id, e)}></input>
            case "Number":
                return <input className="form-control" type="number" disabled={isDisabled}
                    value={state.quickAssessments[selectedAssessment._id][question._id]}
                    onChange={e => setNestedNestedPropertyState('quickAssessments', selectedAssessment._id, question._id, e)}></input>
            case "Date":
                return <input className="form-control" type="date" disabled={isDisabled}
                    value={state.quickAssessments[selectedAssessment._id][question._id]}
                    onChange={e => setNestedNestedPropertyState('quickAssessments', selectedAssessment._id, question._id, e)}></input>

            case "Text Area":
                return <textarea className="form-control" type="text" disabled={isDisabled}
                    value={state.quickAssessments[selectedAssessment._id][question._id]}
                    onChange={e => setNestedNestedPropertyState('quickAssessments', selectedAssessment._id, question._id, e)}></textarea>
            default:
                return <input className="form-control" type="checkbox" disabled={isDisabled}
                    checked={state.quickAssessments[selectedAssessment._id][question._id]}
                    onChange={e => setNestedNestedPropertyState('quickAssessments', selectedAssessment._id, question._id, e)}></input>
        }
    }

    if ((!state || !state.quickAssessments)) {
        return <></>
    }

    return <>
        <div className="row">
            <div className="col">
                {questions.map((question, i) => {
                    return <div className="row" key={question._id}>
                        <div className="col">
                            {question.text}:
                            </div>
                        <div className="col">
                            {getInput(question)}
                        </div>
                    </div>
                })}
            </div>
        </div>
    </>
}

EditQuickAssessmentQuestions.propTypes = {
    state: PropTypes.object.isRequired,
    selectedAssessment: PropTypes.object.isRequired,
    setNestedNestedPropertyState: PropTypes.func.isRequired,
    questions: PropTypes.array.isRequired
}
