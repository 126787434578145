import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function filterText({
    column: { filterValue, setFilter, preFilteredRows, id },
}) {
    const filter = filterValue ? filterValue : '';
    return <div className="input-group">
        <div className="input-group-prepend input-sm">
            <span className="input-group-text sm p-1" >
                <FontAwesomeIcon icon="filter" size="sm" ></FontAwesomeIcon></span>
        </div>
        <input type="text" className="form-control" value={filter}
            onChange={e => setFilter(e.target.value || undefined)}></input>
    </div>

}
