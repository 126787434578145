import React from "react";
import Card from "../Card";

export default function ViewDatabase(props) {
    return <>
        <div className="row">
            <div className="col font-weight-bold">
                Database Name:
    </div>
            <div className="col">
                {props.interface.databaseName}
            </div>
        </div>
        <Card headerText="Tables" badgeCount={props.tables.length} collapsed={true}>
            <div className="row">
                <div className="col">
                    <ul className="list-group">
                        {props.tables ? props.tables.map((table, tableIndex) => {
                            return <li className="list-group-item" key={tableIndex}>
                                <div className="row">
                                    <label className="col-2 font-weight-bold">
                                        Table Name:</label>
                                    {table.name}
                                    <label className="col-2 font-weight-bold">
                                        Logical Name:</label>
                                    {table.logicalName}
                                </div>
                                <div className="row">
                                    <label className="col font-weight-bold">
                                        Description:</label>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        {table.description}
                                    </div>
                                </div>
                                <Card headerText="Fields" collapsed={true}>
                                    <div className="row">
                                        <div className="col">
                                            <table className="table table-striped table-bordered table-sm">
                                                <thead>
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>Logical Name</th>
                                                        <th>Description</th>
                                                        <th>Field Type</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>{props.tables[tableIndex].fields ? props.tables[tableIndex].fields.map((field, fieldIndex) => {
                                                    return <tr key={fieldIndex}>
                                                        <td>
                                                            {field.name}
                                                        </td>
                                                        <td>
                                                            {field.logicalName}
                                                        </td>
                                                        <td>
                                                            {field.description}
                                                        </td>
                                                        <td>
                                                            {field.fieldType}
                                                        </td>
                                                    </tr>
                                                }) : <></>}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </Card>
                                <br />
                            </li>
                        }) : ''}
                    </ul>
                </div>
            </div>
        </Card>
    </>
}
