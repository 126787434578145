import React, { useState, useEffect } from 'react'
import axios from 'axios';
import routeConfig from '../../../routeConfig';
import ChartTooltip from './ChartTooltip';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';

export default function ChartWidget(props) {
    const [results, setResults] = useState(null);
    const COLORS = ['#3E92CC', '#2A628F', '#13293D', '#16324F'];
    const [queryFailed, setQueryFailed] = useState(false);

    useEffect(() => {
        console.log(props.config && props.config.query);
        if (props.config) {
            axios.get(`${routeConfig.baseUrl}/Query/${props.config.query}`).then(resp => {
                setResults(resp.data);
            }).catch(err => {
                setQueryFailed(true);
            });;
        }
    }, [props.config])

    if (queryFailed) {
        return <div>Query Failed</div>
    }

    if (!results) {
        return <div className="row">
            <div className="col">
                <h3 className="text-center">
                    <div className="spinner-border text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>&nbsp;&nbsp;Chart is Loading...
            </h3>
            </div>
        </div>
    }

    return <div className="row">
        <div className="col">
            <ResponsiveContainer width="95%" height={225}>
                {props.config.chartType === 'Bar' ?
                    <BarChart data={results.aggregationResults}>
                        <CartesianGrid strokeDasharray="3 3" />
                        {results.groupFields && results.groupFields.length > 1 ?
                            <></> :
                            <XAxis dataKey="name" />}
                        <YAxis allowDecimals={false} />
                        <Tooltip content={<ChartTooltip results={results}></ChartTooltip>} />
                        <Bar dataKey="count" fill="#0062cc" name="Assets" >
                        </Bar>
                    </BarChart>
                    : <PieChart >
                        <CartesianGrid strokeDasharray="3 3" />
                        <Tooltip content={<ChartTooltip results={results}></ChartTooltip>} />
                        <Pie dataKey="count" fill="#0062cc" name="Assets" data={results.aggregationResults}>
                            {
                                results.aggregationResults.map((entry, index) => <Cell key={index} fill={COLORS[index % COLORS.length]} />)
                            }
                        </Pie>
                    </PieChart>
                }
            </ResponsiveContainer>
        </div>
    </div>
}
