import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import Card from '../../Card';
import DeleteButton from '../../DeleteButton';
import AddButton from '../../AddButton';
import { Typeahead } from 'react-bootstrap-typeahead';

export default function EditPpmProjects({ addToArrayProperty, state, removeFromArrayProperty }) {
    const projects = useSelector(state => state.PpmProject.projects);
    const user = useSelector(state => state.User.user);
    const [selectedProject, setSelectedProject] = useState(null);

    const options = useMemo(() => {
        if (!projects) {
            return [];
        }


        return Object.values(projects).sort((a, b) => {
            if (a.name < b.name) {
                return -1;
            }
            if (a.name > b.name) {
                return 1;
            }
            return 0;
        }).map(p => {
            return {
                id: p._id,
                label: p.name
            }
        });
    }, [projects]);

    const selectProject = (selected) => {
        let returnVal;
        if (selected && selected.length > 0) {
            returnVal = selected[0].id;
        }
        console.log(selected);
        console.log(returnVal);
        setSelectedProject(returnVal);
    }

    if (!user || user.isOit !== true) {
        return <></>
    }

    return <Card headerText="PPM Pro Projects">
        <div className="row">
            <div className="col text-right">
                <div className="input-group">
                    <Typeahead
                        value={selectedProject}
                        onChange={selected => { selectProject(selected) }}
                        placeholder="Select PPM Pro Project"
                        id="ApplicationRelatedItAsset"
                        options={options} />
                    <div className="input-group-append">
                        <AddButton text="PPM Project" onClick={() => addToArrayProperty('ppmProjects', selectedProject)}></AddButton>
                    </div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col">
                <table className="table table-sm table-striped table-bordered">
                    <thead>
                        <tr>
                            <th>Project</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {state.ppmProjects ? state.ppmProjects.map((proj, i) => {
                            return <tr key={proj}>
                                <td>
                                    {projects[proj].name}
                                </td>
                                <td><DeleteButton onClick={() => removeFromArrayProperty('ppmProjects', i)} hideText={true}></DeleteButton></td>
                            </tr>
                        }) : <></>}
                    </tbody>
                </table>
            </div>
        </div>
    </Card>

}