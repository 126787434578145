import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch, useStore } from 'react-redux';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import PreviousNext from '../../PreviousNext';
import { appInsights } from '../../../appInsights';
import ViewQuickAssessment from './ViewQuickAssessment';
import EditQuickAssessment from './EditQuickAssessment';
import useCanEditAsset from '../../../hooks/useCanEditAsset';
import usePreviousNextModal from '../../../hooks/usePreviousNextModal';
import routeConfig from '../../../routeConfig';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SimplifiedAsset from '../../Application/SimplifiedAsset';
import EditSimplifiedAsset from '../../Application/EditSimplifiedAsset';
import FontAwesomeButton from '../../FontAwesomeButton';

export default function QuickAssessment(props) {
    const store = useStore();
    const dispatch = useDispatch();
    const applicationModalIsEditing = useSelector(state => state.Applications.applicationModalIsEditing);
    const showQuickAssessmentModal = useSelector(state => state.Applications.showQuickAssessmentModal);
    const currentApplicationId = useSelector(state => state.Applications.currentApplicationId);
    const currentApplication = useSelector(state => store.select.Applications.currentApplication(state));
    const currentQuickAssessment = useSelector(state => store.select.QuickAssessment.currentQuickAssessment(state));
    const canEdit = useCanEditAsset();
    const [assetInfoOpen, setAssetInfoOpen] = useState(false);
    const [assessmentOpen, setAssessmentOpen] = useState(true);
    const {
        nextApp,
        previousApp,
        getTabView
    } = usePreviousNextModal();

    useEffect(() => {
        if (currentApplicationId && showQuickAssessmentModal === true) {
            dispatch.Applications.loadApplication(currentApplicationId);

            appInsights.trackEvent({
                name: 'Quick Assessment Loaded',
                properties: {
                    assetName: currentApplication.name,
                    assetId: currentApplication._id
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentApplicationId, showQuickAssessmentModal]);

    const openAsset = () => {
        window.open(`/Inventory/${currentApplicationId}`);
    }

    const toggleModal = () => {
        dispatch.Applications.changeQuickAssessmentModalVisibility(!showQuickAssessmentModal)
    }

    const getCurrentView = () => {
        if (assessmentOpen) {
            return getTabView(<EditQuickAssessment selectedAssessment={currentQuickAssessment} />, <ViewQuickAssessment selectedAssessment={currentQuickAssessment} />);
        } else if (assetInfoOpen) {
            return getTabView(<EditSimplifiedAsset />, <SimplifiedAsset />);
        }
    }

    const getClassName = (propName) => {
        if ((propName === 'assessmentOpen' && assessmentOpen) ||
            (propName === 'assetInfoOpen' && assetInfoOpen)) {
            return "nav-link btn btn-link active "
        }
        return "nav-link btn btn-link"
    }

    const tabClicked = (propName) => {
        if (propName === "assessmentOpen") {
            setAssessmentOpen(true);
            setAssetInfoOpen(false);
        }
        if (propName === "assetInfoOpen") {
            setAssessmentOpen(false);
            setAssetInfoOpen(true);
        }
    }

    const getNavItem = (propName, labelName) => {
        return <li className={`nav-item ${labelName === 'IT Asset Info' ? 'ml-auto' : ''}`}>
            <button type="button" className={getClassName(propName)}
                onClick={() => tabClicked(propName)}>
                {labelName}
            </button>
        </li>
    }

    const onEdit = () => {
        dispatch.Applications.changeApplicationModalIsEditing(true);
        appInsights.trackEvent({
            name: 'Quick Assessment Edit Clicked',
            properties: {
                appName: currentApplication.name,
                appId: currentApplication._id
            }
        });
    }

    if (!currentQuickAssessment) {
        return <></>
    }

    return <Modal isOpen={showQuickAssessmentModal} size="lg">
        <ModalHeader className="py-2 bg-primary text-white" toggle={toggleModal}>
            {!applicationModalIsEditing ? <PreviousNext onPrevious={previousApp} onNext={nextApp} inverseColors={true}></PreviousNext> : ''}
            &nbsp;&nbsp;&nbsp;
            <span>
                {currentApplication ? currentApplication.name : ''}
            </span>
            <FontAwesomeButton icon='share-square' title='Open Asset' onClick={openAsset}></FontAwesomeButton>
        </ModalHeader>
        <ModalBody className="pt-1 bg-light">
            <>
                <nav className="nav nav-tabs">
                    {getNavItem('assessmentOpen', 'Assessment')}
                    {getNavItem('assetInfoOpen', 'IT Asset Info')}
                </nav>
                <div className="row my-1">
                    {!applicationModalIsEditing && canEdit === true ?
                        <div className="col text-right">
                            <button className="btn btn-link" onClick={onEdit}>Edit</button>
                        </div>
                        : ''}
                </div>
                <div className="row">
                    {currentQuickAssessment.description && currentQuickAssessment.description !== '' ?
                        <div className="col">{currentQuickAssessment.description}</div>
                        : <></>}
                    {currentQuickAssessment.file && currentQuickAssessment.file !== '' ?
                        <div className="col">
                            <a className="btn-primary btn" title={`Download Help`} target="_blank" rel="noopener noreferrer"
                                href={`${routeConfig.baseUrl}/QuickAssessment/${currentQuickAssessment._id}/Download`}>
                                <FontAwesomeIcon icon="file-download" />&nbsp;&nbsp;
                            Help
                            </a>
                        </div>
                        : <></>}
                </div>
                {getCurrentView()}
                {!applicationModalIsEditing ?
                    <div>
                        <PreviousNext onPrevious={previousApp} onNext={nextApp}></PreviousNext>
                    </div> : ''}
            </>
        </ModalBody>
    </Modal>
}
