import 'react-bootstrap-typeahead/css/Typeahead.css';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import axios from 'axios';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';

export default function StaffSelector(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState([]);
    const environmentVariables = useSelector(state => state.ApplicationLoadState.environmentVariables);

    const onChange = (selected) => {
        let returnVal;
        if (selected && selected.length > 0) {
            returnVal = selected[0];
            if (selected[0]) {
                returnVal.staffId = selected[0].id
            }
        }
        if (props.onChange) {
            props.onChange(returnVal);
        }
    }

    const handleSearch = async (query) => {
        setIsLoading(true);

        var baseUrl = environmentVariables.STAFF_SEARCH_STAFF_URL;
        var url = '/SearchStaffBySearchCriteria?status=active&partialName=';
        if (props.includeInactive) {
            url = '/SearchStaffBySearchCriteria?partialName=';
        }
        const resp = await axios.get(baseUrl + url + query, {
            headers: {
                'Ocp-Apim-Subscription-Key': environmentVariables.ARCULUS_API_KEY
            },
            withCredentials: false
        });

        setOptions(resp.data);
        setIsLoading(false);
    }

    const getLabel = (option) => {
        if (option.active === false) {
            return `${option.firstName} ${option.lastName} (${option.racfId}) Inactive Date: ` + format(new Date(option.endDate), 'MM-DD-YYYY')
        }
        return `${option.firstName} ${option.lastName} (${option.racfId})`;
    }

    return <AsyncTypeahead
        isLoading={isLoading}
        options={options}
        minLength={3}
        onSearch={handleSearch}
        onChange={onChange}
        placeholder="Search for a FDOT User"
        labelKey={(option) => getLabel(option)}
    />
}

const StaffSelectorAdapter = ({ input, meta, ...rest }) => (
    <StaffSelector
        {...input}
        {...rest}
        onChange={(value) => input.onChange(value)}
    />
)

export {
    StaffSelectorAdapter,
    StaffSelector
};
