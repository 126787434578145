import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import routeConfig from '../../routeConfig';
import GridWidget from './Widgets/GridWidget';
import CountWidget from './Widgets/CountWidget';
import GaugeWidget from './Widgets/GaugeWidget';
import ChartWidget from './Widgets/ChartWidget';
import Card from '../Card';

export default function Dashboard() {
    const { id } = useParams();
    const [dashboardConfiguration, setDashboardConfiguration] = useState(null);

    useEffect(() => {
        if (id) {
            axios.get(`${routeConfig.baseUrl}/Dashboard/${id}`).then(resp => {
                setDashboardConfiguration(resp.data);
            });
        }
    }, [id]);

    const getWidget = (widgetConfig) => {
        if (!widgetConfig.query) {
            return <div>No Query Selected For Widget</div>
        }
        switch (widgetConfig.widgetType) {
            case "Grid":
                return <GridWidget config={widgetConfig}></GridWidget>
            case "Chart":
                return <ChartWidget config={widgetConfig}></ChartWidget>
            case "Gauge":
                return <GaugeWidget config={widgetConfig}></GaugeWidget>
            case "Count":
                return <CountWidget config={widgetConfig}></CountWidget>
            default:
                return <div></div>
        }
    }

    if (!dashboardConfiguration) {
        return <div className="container-fluid">
            <div className="row">
                <div className="col">
                    <h3 className="text-center">
                        <div className="spinner-border text-primary" role="status">
                            <span className="sr-only">Dashboard Loading...</span>
                        </div>&nbsp;&nbsp;Dashboard is Loading...
                    </h3>
                </div>
            </div>
        </div>
    }

    return <div className="container-fluid">
        <div className="row">
            <div className="col">
                <h3 className="text-center">
                    {dashboardConfiguration.name}
                </h3>
            </div>
        </div>
        {dashboardConfiguration.rows ? dashboardConfiguration.rows.map((r, i) => {
            return <div className="row" key={i}>
                <div className="col">
                    <div className="row">
                        {r.cols ? r.cols.map((col, ci) => {
                            return <div className="col" key={ci}>
                                <Card headerText={col.title}>
                                    {getWidget(col)}
                                </Card>
                            </div>
                        }) : <></>}
                    </div>
                </div>
            </div>
        }) : <></>}
    </div>
}
