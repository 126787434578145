import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useStore, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Card from '../Card';
import AddButton from '../AddButton';
import SaveCancel from '../SaveCancel';
import { useNestedArrayState } from '../../hooks/useNestedArrayState';
import routeConfig from '../../routeConfig';
import axios from 'axios';
import natural from 'natural';
import FontAwesomeButton from '../FontAwesomeButton';
import DeleteButton from '../DeleteButton';
import { Typeahead } from 'react-bootstrap-typeahead';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toastr } from 'react-redux-toastr';
//import { delete } from 'request';

export default function EditImpactedTables(props) {
    const store = useStore();
    const dispatch = useDispatch();
    const currentApplication = useSelector(state => store.select.Applications.currentApplication(state));
    const applications = useSelector(state => state.Applications.applications);
    const [selectedTable, setSelectedTable] = useState('');
    const [impactSuggestion, setImpactSuggestion] = useState([]);
    const [selectedItems, setSelectedItems] = useState({});
    const user = useSelector(state => state.User.user);
    const [selectedApplication, setSelectedApplication] = useState(null);
    const [table, setTable] = useState('');
    const [assets, setAssets] = useState([]);
    const [showOwnedRejected, setShowOwnedRejected] = useState(false);
    const [showRelatedRejected, setShowRelatedRejected] = useState(false);

    const [selectedAppDependencies, setSelectedAppDependencies] = useState({});

    const {
        state,
        setStateArrayProperty,
        setStateArrayofArrayProperty,
        clearFromNestedNestedArrayProperty,
        addToNestedNestedArrayProperty,
        setStateProperty,
        addToNestedArrayProperty,
        addToNestedArray,
        removeFromNestArray,
        addToNestedArrayNestedArray,
        removeFromNestedArrayNestedArray,
        addToNestedNestedArrayNestedArrayNestedArray,
        removeFromNestedNestedArrayNestedArray,
        removeFromNestedNestedArrayNestedNestedArray,
        setNestedStateArrayProperty,
        setStateNestedProperty,
        setState,
        addToNestedNestedPropertyArray
    } = useNestedArrayState(currentApplication);

    useEffect(() => {
        if (props.impactSuggestion) {
            axios.get(`${routeConfig.baseUrl}/ImpactSuggestion/${props.impactSuggestion}`).then(resp => {
                setImpactSuggestion(resp.data);
                for (let asset of resp.data.assets) {
                    dispatch.Applications.loadApplicationWithoutCompletions(asset.asset);
                }
                unselectAll()
            });
        }
    }, [props.impactSuggestion]);

    useEffect(() => {
        if (props.dependencyProperty && !state[props.dependencyProperty] && props.arrayProperty && !state[props.arrayProperty]) {
            setState({
                ...state,
                [props.dependencyProperty]:
                    { [props.impactSuggestion]: [] },
                [props.arrayProperty]:
                    { [props.impactSuggestion]: [] }
            }
            );
        }
        else if (props.dependencyProperty && !state[props.dependencyProperty]) {
            setStateProperty(props.dependencyProperty, { [props.impactSuggestion]: [] });
        }
        else if (props.arrayProperty && !state[props.arrayProperty]) {
            setStateProperty(props.arrayProperty, { [props.impactSuggestion]: [] })
        }
        else if (props.impactSuggestion && !state[props.dependencyProperty][props.impactSuggestion] && props.arrayProperty && !state[props.arrayProperty][props.impactSuggestion]) {
            setState({
                ...state,
                [props.dependencyProperty]:
                {
                    ...state[props.dependencyProperty],
                    [props.impactSuggestion]: []
                },
                [props.arrayProperty]:
                {
                    ...state[props.arrayProperty],
                    [props.impactSuggestion]: []
                }
            }
            );
        }
    }, [props.dependencyProperty, props.impactSuggestion, props.arrayProperty]);

    useEffect(() => {
        axios.get(`${routeConfig.baseUrl}/AssetTables`).then(resp => {
            const assets = [];
            for (let asset of resp.data) {
                if (currentApplication.applicationDependencies.findIndex(appDep => {
                    return appDep.asset === asset._id;
                }) > -1) {
                    assets.push(asset);
                }
            }
            setAssets(assets);
        });
    }, []);

    const checkFieldChanged = (fieldId, index, e) => {
        if (e.target.checked) {
            addToNestedArrayNestedArray(props.arrayProperty, props.impactSuggestion, index, 'fields', { field: fieldId });
        } else {
            const fieldIndex = state[props.arrayProperty][props.impactSuggestion][index].fields.findIndex(f => {
                return f.field === fieldId;
            })
            removeFromNestedArrayNestedArray(props.arrayProperty, props.impactSuggestion, index, 'fields', fieldIndex);
        }
    }

    const checkRelatedFieldChanged = (fieldId, appDepIndex, tableIndex, e) => {
        const assetIndex = state[props.dependencyProperty][props.impactSuggestion].findIndex(f => currentApplication.applicationDependencies[appDepIndex].asset === f.asset);

        if (e.target.checked) {
            const newFields = [...state[props.dependencyProperty][props.impactSuggestion][assetIndex].tablesUsed[tableIndex].fields];
            newFields.push(fieldId);
            addToNestedNestedArrayNestedArrayNestedArray(props.dependencyProperty, props.impactSuggestion, assetIndex, 'tablesUsed', tableIndex, 'fields', fieldId);
        } else {
            const fieldIndex = state[props.dependencyProperty][props.impactSuggestion][assetIndex].tablesUsed[tableIndex].fields.findIndex(f => {
                return f == fieldId;
            });

            removeFromNestedNestedArrayNestedNestedArray(props.dependencyProperty, props.impactSuggestion, assetIndex, 'tablesUsed', tableIndex, 'fields', fieldIndex);
        }
    }

    const openAsset = (appId) => {
        window.open(`/Inventory/${appId}`);
    }

    const selectTable = (selected) => {
        let returnVal;

        if (selected && selected.length > 0) {
            returnVal = selected[0];
        }

        setTable(returnVal);
    }

    const addTable = () => {
        let assetIndex = -1;
        if (state[props.dependencyProperty] && state[props.dependencyProperty][props.impactSuggestion]) {
            assetIndex = state[props.dependencyProperty][props.impactSuggestion].findIndex((d) => {
                return d === table.asset;
            });
        }

        if (assetIndex > -1) {
            setNestedStateArrayProperty(props.dependencyProperty, props.impactSuggestion, assetIndex, 'tablesUsed', [...state[props.dependencyProperty][props.impactSuggestion][assetIndex].tablesUsed, { table: table.id, fields: [] }]);
        }
        else {
            dispatch.Applications.loadApplication(table.asset);
            addToNestedNestedPropertyArray(props.dependencyProperty, props.impactSuggestion, { asset: table.asset, tablesUsed: [{ table: table.id, fields: [] }] });
        }
    }

    const selectApp = (selected) => {
        let returnVal;
        if (selected && selected.length > 0) {
            returnVal = selected[0].id;
        }
        setSelectedApplication(returnVal);
    }

    const addApplication = () => {
        const applicationExists = state[props.dependencyProperty] && state[props.dependencyProperty][props.impactSuggestion] &&
            state[props.dependencyProperty][props.impactSuggestion].some((d) => {
                return d === selectedApplication;
            });

        if (applicationExists) {
            toastr.error("The IT Asset has already been added")
        }
        else {
            dispatch.Applications.loadApplication(selectedApplication);
            addToNestedNestedPropertyArray(props.dependencyProperty, props.impactSuggestion, { asset: selectedApplication, use: '', type: 'Database' });
        }
    }

    const assetOptions = useMemo(() => {
        if (!applications) {
            return [];
        }
        return Object.values(applications).filter(a => {
            return a.wpiiInventoryStatus === 'Active' &&
                currentApplication.applicationDependencies.findIndex(appDep => {
                    return appDep.asset === a._id;
                }) > -1
        }).sort((a, b) => {
            if (a.name < b.name) {
                return -1;
            }
            if (a.name > b.name) {
                return 1;
            }
            return 0;
        }).map((value, i) => {
            return {
                id: value._id,
                label: `${value.name} (${value.wpiiImpactResponsibleGroup}) - ${value.oitId}`
            }
        });
    }, [applications, currentApplication]);

    const options = useMemo(() => {
        if (!assets) {
            return [];
        }

        let tables = [];

        for (let asset of assets) {
            for (let table of asset.tables) {
                const appDependency = currentApplication.applicationDependencies.find(appDep => {
                    return appDep.asset === asset._id;
                });

                if (appDependency.tablesUsed.findIndex(t => {
                    return table._id === t.table;
                }) > -1) {
                    tables.push({
                        name: `${table.name.trim()} (${asset.name})`,
                        _id: table._id,
                        asset: asset._id
                    })
                }
            }
        }

        tables = tables.sort((a, b) => {
            if (a.name < b.name) {
                return -1;
            }
            if (a.name > b.name) {
                return 1;
            }
            return 0;
        }).map(p => {
            return {
                id: p._id,
                label: p.name,
                asset: p.asset
            }
        });

        return tables;
    }, [assets]);

    const addSelected = () => {
        const arrayCopy = [...state[props.arrayProperty][props.impactSuggestion]];

        for (let key of Object.keys(selectedItems)) {
            if (selectedItems[key] === true) {
                const keySplit = key.split('||');

                const table = keySplit[0];
                const field = keySplit[1];
                const impactType = keySplit[2];
                const distance = keySplit[3];

                let tableIndex = arrayCopy.findIndex(item => {
                    return item.table === table;
                });

                if (tableIndex === -1) {
                    arrayCopy.push({
                        table: table,
                        fields: []
                    });
                    tableIndex = arrayCopy.length - 1;
                }

                let fieldIndex = arrayCopy[tableIndex].fields.findIndex(f => {
                    return f.field === field;
                });

                if (fieldIndex === -1) {
                    const newField = {
                        field: field,
                        impactType: impactType,
                        distance: distance,
                        wasSuggestion: true
                    };

                    arrayCopy[tableIndex].fields.push(newField);
                }
            }
        }

        console.log(arrayCopy);
        setStateNestedProperty(props.arrayProperty, props.impactSuggestion, arrayCopy);
    }

    const onSave = () => {
        dispatch.Applications.persistApplication(state);
        dispatch.Applications.changeApplicationModalIsEditing(false);
    }

    const getFieldTable = (sliceIndex1, sliceIndex2, fields, table, index, isAppDependency, appDependencyIndex) => {
        const checkboxChange = (e, field) => {
            if (isAppDependency) {
                checkRelatedFieldChanged(field._id, appDependencyIndex, index, e)
            } else {
                checkFieldChanged(field._id, index, e)
            }
        }

        return <table className="table table-striped table-bordered table-sm">
            <thead>
                <tr>
                    <th>Impacted</th>
                    <th>Name</th>
                    {props.impactTypeForField && props.impactTypeForField.length > 0 ?
                        <th>Impact Type</th>
                        : <></>}
                    <th>Logical Name</th>
                </tr>
            </thead>
            <tbody>
                {fields.slice(sliceIndex1, sliceIndex2).map((field, fieldIndex) => {
                    return <tr key={fieldIndex}>
                        <td>
                            <input type="checkbox" onChange={e => checkboxChange(e, field)}
                                checked={table.fields.findIndex(f => {
                                    return f.field == field._id || f === field._id;
                                }) > -1}></input>
                        </td>
                        <td>
                            {field.name}
                        </td>
                        {props.impactTypeForField && props.impactTypeForField.length > 0 ?
                            <td>
                                <select disabled={table.fields.findIndex(f => {
                                    return f.field === field._id
                                }) === -1} className="form-control" value={table.fields.findIndex(f => {
                                    return f.field === field._id
                                }) > -1 && table.fields.find(f => {
                                    return f.field === field._id
                                }).impactType}
                                    onChange={e => setStateArrayofArrayProperty(props.arrayProperty, index, 'fields', table.fields.findIndex(f => {
                                        return f.field === field._id
                                    }), 'impactType', e)}>
                                    <option value=''>Select</option>
                                    {props.impactTypeForField.map((f) => {
                                        return <option key={f} value={f}>{f}</option>
                                    })}
                                </select>
                            </td>
                            : <></>}
                        <td>
                            {field.logicalName}
                        </td>
                    </tr>
                })}
            </tbody>
        </table>
    }

    const appDependencySuggestionsResults = useMemo(() => {
        const suggestions = [];
        if (!impactSuggestion || !impactSuggestion.assets) {
            return suggestions;
        }
        for (let asset of impactSuggestion.assets) {
            for (let appDep of currentApplication.applicationDependencies) {
                if (appDep.asset === asset.asset) {
                    if (!showRelatedRejected && currentApplication.rejectedAssets[props.impactSuggestion]
                        && currentApplication.rejectedAssets[props.impactSuggestion][asset.asset]) {
                        continue;
                    }
                    const assetToAdd = { asset: asset.asset };
                    const tables = [];
                    for (let table of asset.tablesUsed) {
                        for (let tableUsed of appDep.tablesUsed) {
                            if (tableUsed.table === table.table) {
                                if (!showRelatedRejected && currentApplication.rejectedTables[props.impactSuggestion] && currentApplication.rejectedTables[props.impactSuggestion][tableUsed.table]) {
                                    continue;
                                }

                                tables.push({ table: table.table, fields: [] });

                                for (let field of table.fields) {
                                    for (let fieldUsed of tableUsed.fields) {
                                        if (field === fieldUsed) {
                                            if (!showRelatedRejected && currentApplication.rejectedColumns[props.impactSuggestion] && currentApplication.rejectedColumns[props.impactSuggestion][field]) {
                                                continue;
                                            }
                                            tables[tables.length - 1].fields.push(field);
                                        }
                                    }
                                }

                            }
                        }
                    }

                    assetToAdd.tablesUsed = tables;
                    suggestions.push(assetToAdd);
                }
            }
        }

        return suggestions;
    }, [impactSuggestion, currentApplication.rejectedAssets, currentApplication.rejectedTables, currentApplication.rejectedColumns, showRelatedRejected]);

    const suggestionsResults = useMemo(() => {
        const suggestions = [];
        if (impactSuggestion && impactSuggestion.columns) {
            for (let impactedColumn of impactSuggestion.columns) {
                for (let table of currentApplication.tables) {
                    for (let field of table.fields) {
                        const distance = natural.DamerauLevenshteinDistance(field.name.toUpperCase().trim(), impactedColumn.name.toUpperCase().trim());
                        if (distance <= 2) {
                            if (!showOwnedRejected && currentApplication.rejectedSuggestions[props.impactSuggestion] && currentApplication.rejectedSuggestions[props.impactSuggestion].findIndex(r => {
                                return r.table == table._id && r.field == field._id;
                            }) > -1) {
                                continue;
                            }

                            if (state[props.arrayProperty][props.impactSuggestion]) {
                                const tableIndex = state[props.arrayProperty][props.impactSuggestion].findIndex(t => {
                                    return t.table == table._id;
                                })

                                if (tableIndex > -1) {
                                    const fieldIndex = state[props.arrayProperty][props.impactSuggestion][tableIndex].fields.findIndex(f => {
                                        return f.field == field._id;
                                    });

                                    if (fieldIndex > -1) {
                                        continue;
                                    }
                                }
                            }
                            const index = suggestions.findIndex(f => {
                                return field._id === f.field;
                            });

                            if (index === -1) {
                                suggestions.push({
                                    table: table._id,
                                    tableName: table.name,
                                    field: field._id,
                                    fieldName: field.name,
                                    impactType: impactedColumn.impactType,
                                    notExact: distance > 0 ? true : false,
                                    distance: distance,
                                    column: impactedColumn.name.toUpperCase().trim()
                                });
                            } else if (distance === 0) {
                                suggestions[index].notExact = false;
                            }
                        }
                    }
                }
            }
        }

        return suggestions.sort((a, b) => {
            if (a.tableName === b.tableName) {
                return a.fieldName < b.fieldName ? -1 : 1;
            }
            return a.tableName < b.tableName ? -1 : 1;
        });
    }, [impactSuggestion, showOwnedRejected, currentApplication.rejectedSuggestions]);

    const selectAll = () => {
        var selectionObj = {};

        for (let suggestion of suggestionsResults) {
            selectionObj[`${suggestion.table}||${suggestion.field}||${suggestion.impactType}||${suggestion.distance}`] = true;
        }

        setSelectedItems(selectionObj);
    }

    const unselectAll = () => {
        const suggestions = suggestionsResults;
        var selectionObj = {};

        for (let s of suggestions) {
            selectionObj[`${s.table}||${s.field}||${s.impactType}||${s.distance}`] = false;
        }

        setSelectedItems(selectionObj);
    }

    const changeSuggestion = (suggestion, e) => {
        setSelectedItems({
            ...selectedItems,
            [`${suggestion.table}||${suggestion.field}||${suggestion.impactType}||${suggestion.distance}`]: e.target.checked
        });
    }

    const getOwnedSuggestionCard = () => {
        const suggestions = suggestionsResults;

        if (suggestions.length === 0 && (!currentApplication.rejectedSuggestions[props.impactSuggestion] ||
            currentApplication.rejectedSuggestions[props.impactSuggestion].length === 0)) {
            return <></>
        }

        return <><Card headerText="Owned Column Name Impact Suggestions">
            <div className="row">
                <div className="col">
                    <button className="btn btn-primary" onClick={selectAll}>Select All</button>
                    &nbsp;&nbsp;
                    <button className="btn btn-primary" onClick={unselectAll}>Unselect All</button>
                </div>
            </div>
            <div className="row">
                <div className="col text-right">* Denotes Suggestion is close to Column Name (Suggestion Column Name)</div>
            </div>
            <table className="table table-sm table-bordered table-striped">
                <thead>
                    <tr>
                        <th>Select</th>
                        <th>Table Name</th>
                        <th>Column Name</th>
                        {props.arrayProperty !== 'palmImpactedTables' ? <th>Impact Type</th> : <></>}
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {suggestions.map((s, i) => {
                        return <tr key={i}>
                            <td>
                                <input type="checkbox"
                                    checked={selectedItems[`${s.table}||${s.field}||${s.impactType}||${s.distance}`] || selectedItems[`${s.table}||${s.field}||${s.impactType}||${s.distance}`] === true}
                                    onChange={e => changeSuggestion(s, e)} />
                            </td>
                            <td>{s.tableName}</td>
                            <td>{s.fieldName} {s.notExact ? `* (${s.column})` : <></>}</td>
                            {props.arrayProperty !== 'palmImpactedTables' ? <td>
                                {s.impactType}
                            </td> : <></>}
                            <td className="text-center">
                                <FontAwesomeButton onClick={() => dispatch.Applications.rejectSuggestion({ impactSuggestion: props.impactSuggestion, suggestion: s })} small={true} icon="ban" title="Reject"></FontAwesomeButton>
                            </td>
                        </tr>
                    })}
                </tbody>
            </table>
            <div className="row">
                <div className="col">
                    <AddButton text="Selected" onClick={addSelected}></AddButton>
                </div>
                <div className="col text-right">
                    <input type="checkbox" checked={showOwnedRejected} onChange={e => setShowOwnedRejected(e.target.checked)}></input> Show Rejected
                </div>
            </div>
        </Card>
            <br />
        </>
    }

    const getRelatedSuggestionCard = () => {
        const select = () => {
            const selections = {};
            for (let appDep of appDependencySuggestionsResults) {
                selections[appDep.asset] = {};
                for (let table of appDep.tablesUsed) {
                    selections[appDep.asset][table.table] = {};
                    for (let field of table.fields) {
                        selections[appDep.asset][table.table][field] = true;
                    }
                }
            }
            console.log(selections);
            setSelectedAppDependencies(selections);
        }

        const unselect = () => {
            setSelectedAppDependencies({});
        }

        const addSelectedRelated = () => {
            const dependencies = [...state[props.dependencyProperty][props.impactSuggestion]]
            console.log(selectedAppDependencies);
            for (let appDep of Object.keys(selectedAppDependencies)) {
                let foundDepIndex = dependencies.findIndex(d => {
                    return d.asset == appDep;
                })

                if (foundDepIndex === -1) {
                    dependencies.push({ asset: appDep, tablesUsed: [] });
                    foundDepIndex = dependencies.length - 1;
                }

                for (let table of Object.keys(selectedAppDependencies[appDep])) {
                    let tableIndex = dependencies[foundDepIndex].tablesUsed.findIndex(t => {
                        return table == t.table;
                    });

                    if (tableIndex === -1) {
                        dependencies[foundDepIndex].tablesUsed.push({
                            table: table,
                            fields: []
                        });
                        tableIndex = dependencies[foundDepIndex].tablesUsed.length - 1;
                    }

                    for (let field of Object.keys(selectedAppDependencies[appDep][table])) {
                        let fieldIndex = dependencies[foundDepIndex].tablesUsed[tableIndex].fields.findIndex(f => {
                            return f == field;
                        })

                        if (fieldIndex === -1) {
                            dependencies[foundDepIndex].tablesUsed[tableIndex].fields.push(field);
                        }
                    }
                }
            }

            setStateNestedProperty(props.dependencyProperty, props.impactSuggestion, dependencies);
        }

        const checkboxAssetChange = (e, asset) => {
            if (e.target.checked) {
                setSelectedAppDependencies({
                    ...selectedAppDependencies,
                    [asset]: {}
                });
            } else {
                const tempTables = {
                    ...selectedAppDependencies,
                };
                delete tempTables[asset];

                setSelectedAppDependencies(tempTables);
            }
        }

        const checkboxColumnChange = (e, asset, table, field) => {
            if (e.target.checked) {
                const tempAssets = {
                    ...selectedAppDependencies,
                };
                if (!tempAssets[asset]) {
                    tempAssets[asset] = {};
                    tempAssets[asset][table] = {};
                }
                tempAssets[asset][table][field] = true;
                setSelectedAppDependencies(tempAssets);
            } else {
                const tempAssets = {
                    ...selectedAppDependencies,
                };

                delete tempAssets[asset][table][field];

                setSelectedAppDependencies(tempAssets);
            }
        }

        const checkboxTableChange = (e, asset, table) => {
            if (e.target.checked) {
                const tempAssets = {
                    ...selectedAppDependencies,
                };
                if (!tempAssets[asset]) {
                    tempAssets[asset] = {};
                }
                tempAssets[asset][table] = {};
                setSelectedAppDependencies(tempAssets);
            } else {
                const tempAssets = {
                    ...selectedAppDependencies,
                };

                delete tempAssets[asset][table];

                setSelectedAppDependencies(tempAssets);
            }
        }

        if (appDependencySuggestionsResults.length === 0) {
            return <></>
        }

        return <>
            <Card headerText="Data from Related Assets Suggestions">
                <div className="row">
                    <div className="col">
                        <button className="btn btn-sm btn-primary" type="button" onClick={select}>Select All</button>
                    &nbsp;&nbsp;
                    <button className="btn btn-sm btn-primary" type="button" onClick={unselect}>Unselect All</button>
                    </div>
                </div>
                <table className="table table-bordered table-striped">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Asset</th>
                            <th></th>
                            <th>Tables and Columns</th>
                        </tr>
                    </thead>
                    <tbody>
                        {appDependencySuggestionsResults.map((appDep, i) => {
                            if (!applications[appDep.asset] || !applications[appDep.asset].tables) {
                                return <React.Fragment key={i}></React.Fragment>
                            }

                            return <tr key={i}>
                                <td>
                                    <input type="checkbox" onChange={e => checkboxAssetChange(e, appDep.asset)} checked={selectedAppDependencies[appDep.asset]}></input>
                                </td>
                                <td>
                                    {applications[appDep.asset].name}
                                </td>
                                <td>
                                    <FontAwesomeButton onClick={() => dispatch.Applications.rejectAssetSuggestion({ impactSuggestion: props.impactSuggestion, suggestion: appDep.asset })} small={true} icon="ban" title="Reject"></FontAwesomeButton>
                                </td>
                                <td>
                                    <table className="table table-striped table-bordered table-sm">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>Table</th>
                                                <th></th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {appDep.tablesUsed.map((table, ti) => {
                                                const tableFound = applications[appDep.asset].tables.find(f => { return f._id === table.table });
                                                if (!tableFound) {
                                                    return <></>
                                                }
                                                return <tr key={ti}>
                                                    <td>
                                                        <input type="checkbox" onChange={e => checkboxTableChange(e, appDep.asset, tableFound._id)} checked={selectedAppDependencies[appDep.asset] && selectedAppDependencies[appDep.asset][tableFound._id]}></input>
                                                    </td>
                                                    <td>
                                                        {tableFound.name}
                                                    </td>
                                                    <td>
                                                        <FontAwesomeButton onClick={() => dispatch.Applications.rejectTableSuggestion({ impactSuggestion: props.impactSuggestion, suggestion: tableFound._id })} small={true} icon="ban" title="Reject"></FontAwesomeButton>
                                                    </td>
                                                    <td>
                                                        <table className="table table-striped table-bordered table-sm">
                                                            <thead>
                                                                <tr>
                                                                    <th></th>
                                                                    <th>Column</th>
                                                                    <th></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {table.fields.map((field, fi) => {
                                                                    return <tr key={fi}>
                                                                        <td>
                                                                            <input type="checkbox" onChange={e => checkboxColumnChange(e, appDep.asset, table.table, field)} checked={selectedAppDependencies[appDep.asset] &&
                                                                                selectedAppDependencies[appDep.asset][tableFound._id]
                                                                                && selectedAppDependencies[appDep.asset][tableFound._id][field]}></input>
                                                                        </td>
                                                                        <td>
                                                                            {tableFound.fields.find(f => { return f._id === field }).name}
                                                                        </td>
                                                                        <td>
                                                                            <FontAwesomeButton onClick={() => dispatch.Applications.rejectColumnSuggestion({ impactSuggestion: props.impactSuggestion, suggestion: field })} small={true} icon="ban" title="Reject"></FontAwesomeButton>
                                                                        </td>
                                                                    </tr>
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>
                <div className="row">
                    <div className="col">
                        <AddButton text="Selected" onClick={addSelectedRelated}></AddButton>
                    </div>
                    <div className="col text-right">
                        <input type="checkbox" checked={showRelatedRejected} onChange={e => setShowRelatedRejected(e.target.checked)}></input> Show Rejected
                </div>
                </div>
            </Card>
            <br />
        </>
    }

    const getRelatedFieldsUsedCard = (propertyName, subPropertyName, table, assetIndex, tableIndex, lookupProperty, asset) => {
        const foundTable = lookupProperty[state[propertyName][props.impactSuggestion][assetIndex][subPropertyName]].tables.find(t => {
            return t._id === table.table;
        });

        if (!foundTable) {
            return <></>
        }

        const appDepIndex = currentApplication.applicationDependencies.findIndex(a => {
            return a.asset === asset;
        });

        const appDep = currentApplication.applicationDependencies[appDepIndex];
        const t = appDep.tablesUsed.find(t => {
            return t.table === table.table;
        });

        const fields = foundTable.fields.filter(f => {
            return t.fields.findIndex(ft => { return ft === f._id }) > -1;
        }).sort((a, b) => {
            return a.name < b.name ? -1 : 1;
        });


        const unselect = () => {
            clearFromNestedNestedArrayProperty(propertyName, assetIndex, 'tablesUsed', tableIndex, 'fields');
        }

        const select = () => {
            for (let i = 0; i < fields.length; i++) {
                addToNestedNestedArrayProperty(propertyName, assetIndex, 'tablesUsed', tableIndex, 'fields', fields[i]._id);
            }
        }

        return <Card headerText="Fields Used" collapsed={true}>
            <div className="row">
                <div className="col">
                    <button className="btn btn-sm btn-primary" type="button" onClick={select}>Select All</button>
                    &nbsp;&nbsp;
                    <button className="btn btn-sm btn-primary" type="button" onClick={unselect}>Unselect All</button>
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    {getFieldTable(0, fields.length === 1 ? 1 : Math.floor(fields.length / 2), fields, table, tableIndex, true, appDepIndex)}
                </div>
                <div className="col-6">
                    {getFieldTable(Math.floor(fields.length / 2), fields.length - 1, fields, table, tableIndex, true, appDepIndex)}
                </div>
            </div>
        </Card>
    }

    const getFieldsUsedCard = (table, index) => {
        const fields = currentApplication.tables.find(t => {
            return t._id === table.table;
        }).fields.sort((a, b) => {
            return a.name < b.name ? -1 : 1;
        });

        const unselect = () => {
            setStateArrayProperty(props.arrayProperty, index, 'fields', []);
        }

        const select = () => {
            for (let i = 0; i < fields.length; i++) {
                addToNestedArrayProperty(props.arrayProperty, index, 'fields', { field: fields[i]._id });
            }
        }

        return <Card headerText="Fields Used" collapsed={true}>
            <div className="row">
                <div className="col">
                    <button className="btn btn-sm btn-primary" type="button" onClick={select}>Select All</button>
                    &nbsp;&nbsp;
                    <button className="btn btn-sm btn-primary" type="button" onClick={unselect}>Unselect All</button>
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    {getFieldTable(0, Math.floor(fields.length / 2), fields, table, index)}
                </div>
                <div className="col-6">
                    {getFieldTable(Math.floor(fields.length / 2), fields.length, fields, table, index)}
                </div>
            </div>
        </Card>
    }

    const getTableName = (table, asset) => {
        const tableFound = applications[asset.asset].tables.find(tz => {
            return tz._id === table.table;
        });

        if (tableFound) {
            return tableFound.name;
        }
        return '';
    }

    const getDataFromRelatedAssetsCard = () => {
        return <Card headerText="Data from Related Assets" collapsed={user.isAdmin ? false : true} collapsed={true}>
            <div className="row">
                <div className="col"></div>
                <div className="col-8">
                    <div className="input-group">
                        <Typeahead
                            value={selectedApplication}
                            onChange={selected => { selectApp(selected) }}
                            placeholder="Select Related IT Asset"
                            id="ApplicationRelatedItAsset"
                            options={assetOptions} />
                        <div className="input-group-append">
                            <button className="btn btn-primary" disabled={!selectedApplication}
                                onClick={addApplication} type="button"><FontAwesomeIcon icon="plus" />&nbsp;&nbsp;IT Asset</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    -- Or --
                </div>
            </div>
            <div className="row">
                <div className="col"></div>
                <div className="col-8">
                    <div className="input-group">
                        <Typeahead options={options} placeholder="Select Table" id="TableTypeAhead"
                            value={table} onChange={selected => selectTable(selected)}>
                        </Typeahead>
                        <div className="input-group-append">
                            <button className="btn btn-primary" onClick={addTable} disabled={!table}><FontAwesomeIcon icon="plus" />&nbsp;&nbsp;Table</button>
                        </div>
                    </div>
                </div>
            </div>
            <ul className="list-group">
                {state[props.dependencyProperty] && state[props.dependencyProperty][props.impactSuggestion] ? state[props.dependencyProperty][props.impactSuggestion].map((asset, index) => {
                    return <div className="list-group-item" key={index}>
                        <div className="row" >
                            <div className="col px-1 mx-1 font-weight-bold">
                                {asset && applications[asset.asset] ?
                                    <>{applications[asset.asset].name}&nbsp;&nbsp;
                                        <FontAwesomeButton icon='share-square' title='Open Asset' small={true} onClick={() => openAsset(asset.asset)} ></FontAwesomeButton>
                                    </>
                                    : ''}
                            </div>

                            <div className="col-1 px-1 mx-1 text-right">
                                <span role="img" aria-label="Delete"
                                    onClick={() => removeFromNestArray(props.dependencyProperty, props.impactSuggestion, index)}
                                    style={{ cursor: 'pointer' }}>
                                    <FontAwesomeIcon icon="trash-alt" className="fa-lg text-danger" />
                                </span>
                            </div>
                        </div>
                        <Card headerText="Tables Used" collapsed={true}>
                            <div className="row">
                                <div className="col text-right">
                                    <div className="input-group">
                                        <select name="" id="" className="form-control" onChange={e => setSelectedTable(e.target.value)}>
                                            <option>Select Table</option>
                                            {applications[asset.asset].tables ? applications[asset.asset].tables.map((table, i) => {
                                                const appDep = currentApplication.applicationDependencies.find(a => {
                                                    return a.asset === asset.asset;
                                                });

                                                if (appDep.tablesUsed.findIndex(t => {
                                                    return t.table === table._id;
                                                }) === -1) {
                                                    return <></>
                                                }

                                                return <option key={i} value={table._id}>{table.name}</option>
                                            }) : <></>}
                                        </select>
                                        <div className="input-group-append">
                                            <button className="btn btn-primary" disabled={!selectedTable}
                                                onClick={() => {
                                                    addToNestedArrayNestedArray(props.dependencyProperty, props.impactSuggestion, index, 'tablesUsed',
                                                        { table: selectedTable, fields: [] })
                                                }}>
                                                <FontAwesomeIcon icon="plus" />&nbsp;&nbsp;Table
                                                </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col"></div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <ul className="list-group">
                                        {applications[asset.asset].tables && asset.tablesUsed && asset.tablesUsed.length > 0 ? asset.tablesUsed.map((table, tableIndex) => {
                                            return <ul className="list-group-item" key={tableIndex}>
                                                <div className="row">
                                                    <div className="col font-weight-bold">Table Name:&nbsp;
                                                     {getTableName(table, asset)}
                                                    </div>
                                                    <div className="col text-right">
                                                        <button className="btn btn-danger btn-sm" aria-label="Delete Table"
                                                            onClick={() => removeFromNestedNestedArrayNestedArray(props.dependencyProperty, props.impactSuggestion, index, 'tablesUsed', tableIndex)} >
                                                            <FontAwesomeIcon icon="trash-alt" />&nbsp;&nbsp;Remove Table
                                                         </button>
                                                    </div>
                                                </div>
                                                {getRelatedFieldsUsedCard(props.dependencyProperty, 'asset', table, index, tableIndex, applications, asset.asset)}
                                            </ul>
                                        }) : ''}
                                    </ul>
                                </div>
                            </div>
                        </Card>
                    </div>
                }) : <></>}
            </ul>
        </Card>
    }

    return <>
        <Card headerText="Owned Tables Impacted">
            {getOwnedSuggestionCard()}
            <Card headerText="Owned Tables Used" >
                <div className="row">
                    <div className="col text-right">
                        <div className="input-group">
                            <select name="" id="" className="form-control" onChange={e => setSelectedTable(e.target.value)}>
                                <option>Select Table</option>
                                {currentApplication.tables ? currentApplication.tables.map((table, i) => {
                                    return <option key={i} value={table._id}>{table.name}</option>
                                }) : <></>}
                            </select>
                            <div className="input-group-append">
                                <AddButton className="btn btn-primary" disabled={!selectedTable} text="Table"
                                    onClick={() => {
                                        addToNestedArray(props.arrayProperty, props.impactSuggestion, { table: selectedTable, fields: [] });
                                    }} />
                            </div>
                        </div>
                    </div>
                    <div className="col"></div>
                </div>
                <div className="row">
                    <div className="col">
                        <ul className="list-group">
                            {state[props.arrayProperty] && state[props.arrayProperty][props.impactSuggestion] ? state[props.arrayProperty][props.impactSuggestion].map((table, tableIndex) => {
                                return <ul className="list-group-item" key={tableIndex}>
                                    <div className="row">
                                        <div className="col">Table Name:&nbsp;
                                                     {currentApplication.tables ?
                                                currentApplication.tables.find(t => {
                                                    return t._id === table.table;
                                                }).name
                                                : <></>}</div>
                                        <div className="col text-right">
                                            <DeleteButton text="Table"
                                                onClick={() => removeFromNestArray(props.arrayProperty, props.impactSuggestion, tableIndex)} >
                                            </DeleteButton>
                                        </div>
                                    </div>
                                    {getFieldsUsedCard(table, tableIndex)}
                                </ul>
                            }) : ''}
                        </ul>
                    </div>
                </div>
            </Card>
        </Card>
        <br />
        <Card headerText="Related Data Impacted">
            {getRelatedSuggestionCard()}
            <br />
            {getDataFromRelatedAssetsCard()}
        </Card>
        <div className="row">
            <div className="col">
                <SaveCancel onCancelClick={() => dispatch.Applications.changeApplicationModalIsEditing(false)}
                    onSaveClick={onSave}></SaveCancel>
            </div>
        </div>
    </>
}

EditImpactedTables.propTypes = {
    arrayProperty: PropTypes.string,
    dependencyProperty: PropTypes.string,
    impactType: PropTypes.string,
    impactTypeForField: PropTypes.array,
    showAllImpacts: PropTypes.bool
}
