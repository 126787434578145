import React from "react";
import { useDispatch, useSelector, useStore } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SaveCancel from '../../SaveCancel';
import { useNestedArrayState } from '../../../hooks/useNestedArrayState';

export default function EditAssetComponents(props) {
    const store = useStore();
    const dispatch = useDispatch();
    const currentApplication = useSelector(state => store.select.Applications.currentApplication(state));

    const {
        state,
        setStateArrayProperty,
        addToArrayProperty,
        removeFromArrayProperty,
    } = useNestedArrayState(currentApplication);

    const onSave = () => {
        dispatch.Applications.persistApplication({ ...state });
        dispatch.Applications.changeApplicationModalIsEditing(false);
    };

    if (!state || !state.components) {
        return <div></div>
    }

    return <>
        <div className="row">
            <div className="col text-right">
                <button className="btn btn-primary"
                    onClick={() => addToArrayProperty('components', { name: '', type: 'Application Section', description: '', businessUse: '' })}>
                    <FontAwesomeIcon icon="plus" />
                    &nbsp;&nbsp; Component
                </button>
            </div>
        </div>
        <form>
            <div className="row">
                <div className="col">
                    <table className="table table-striped table-bordered">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Type</th>
                                <th>Description</th>
                                <th>Business Use</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {state.components.map((component, i) => {
                                return <tr key={i}>
                                    <td>
                                        <input className="form-control" value={component.name}
                                            onChange={e => setStateArrayProperty('components', i, 'name', e)} />
                                    </td>
                                    <td>
                                        <select className="form-control" value={component.type}
                                            onChange={e => setStateArrayProperty('components', i, 'type', e)}>
                                            <option value="Application Section">Application Section</option>
                                            <option value="Batch Job">Batch Job</option>
                                            <option value="External Action Block">External Action Block</option>
                                            <option value="GEN Action Block">GEN Action Block</option>
                                            <option value="GraphQL Api">GraphQL Api</option>
                                            <option value="JCL">JCL</option>
                                            <option value="Program">Program</option>
                                            <option value="Report">Report</option>
                                            <option value="Rest Api">Rest Api</option>
                                            <option value="Soap Api">Soap Api</option>
                                            <option value="CICS Transaction">CICS Transaction</option>
                                            <option value="Wcf Api">Wcf Api</option>
                                        </select>
                                    </td>
                                    <td>
                                        <textarea className="form-control" value={component.description}
                                            onChange={e => setStateArrayProperty('components', i, 'description', e)} />
                                    </td>
                                    <td>
                                        <textarea className="form-control" value={component.businessUse}
                                            onChange={e => setStateArrayProperty('components', i, 'businessUse', e)} />
                                    </td>
                                    <td>
                                        <button className="btn btn-danger" type="button" onClick={() => removeFromArrayProperty('components', i)}>
                                            <FontAwesomeIcon icon="trash-alt" />
                                        </button>
                                    </td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="row">
                <div className="col text-right">
                    <button type="button" className="btn btn-primary"
                        onClick={() => addToArrayProperty('components', { name: '', type: 'Application Section', description: '', businessUse: '' })}>
                        <FontAwesomeIcon icon="plus" />
                        &nbsp;&nbsp; Component
                </button>
                </div>
            </div>
            <SaveCancel onCancelClick={() => dispatch.Applications.changeApplicationModalIsEditing(false)} onSaveClick={onSave}></SaveCancel>
        </form>
    </>
}
