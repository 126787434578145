import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import PreviousNext from '../../PreviousNext';
import Store from '../../../Store';
import { appInsights } from '../../../appInsights';
import ViewCloudFirstReporting from './ViewCloudFirstReporting';
import EditCloudFirstReporting from './EditCloudFirstReporting';
import SimplifiedAsset from '../../Application/SimplifiedAsset';
import EditSimplifiedAsset from '../../Application/EditSimplifiedAsset';
import useCanEditAsset from '../../../hooks/useCanEditAsset';
import usePreviousNextModal from '../../../hooks/usePreviousNextModal';
import routeConfig from '../../../routeConfig';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import FontAwesomeButton from '../../FontAwesomeButton';
import axios from 'axios';

export default function CloudFirstAssessment(props) {
    const [helpArray, setHelpArray] = useState(null);
    const dispatch = useDispatch();
    const applicationModalIsEditing = useSelector(state => state.Applications.applicationModalIsEditing);
    const showCloudAssessmentModal = useSelector(state => state.Applications.showCloudAssessmentModal);
    const currentApplicationId = useSelector(state => state.Applications.currentApplicationId);
    const currentApplication = useSelector(state => Store.select.Applications.currentApplication(state));
    const cloudFirstReportingOpen = useSelector(state => state.Applications.cloudFirstReportingOpen);
    const assetInfoOpen = useSelector(state => state.Applications.assetInfoOpen);
    const canEdit = useCanEditAsset();
    const {
        nextApp,
        previousApp,
        tabClicked,
        getTabView
    } = usePreviousNextModal();

    useEffect(() => {
        const fetchData = async () => {
            const result = await axios.get(`${routeConfig.baseUrl}/Help`);
            if (result.data && result.data.length > 0) {
                setHelpArray(result.data);
            }
        }

        fetchData();
    }, []);

    const openAsset = () => {
        window.open(`/Inventory/${currentApplicationId}`);
    }

    useEffect(() => {
        if (currentApplicationId && showCloudAssessmentModal === true) {
            dispatch.Applications.loadApplication(currentApplicationId);
            dispatch.Applications.setTabVisible('cloudFirstReportingOpen');

            appInsights.trackEvent({
                name: 'Asset Cloud First Loaded',
                properties: {
                    assetName: currentApplication.name,
                    assetId: currentApplication._id
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentApplicationId, showCloudAssessmentModal]);

    const toggleModal = () => {
        dispatch.Applications.changeCloudAssessmentModalVisibility(!showCloudAssessmentModal)
    }

    const getCurrentView = () => {
        if (cloudFirstReportingOpen) {
            return getTabView(<EditCloudFirstReporting />, <ViewCloudFirstReporting />);
        }
        if (assetInfoOpen) {
            return getTabView(<EditSimplifiedAsset />, <SimplifiedAsset />);
        }
        return <></>
    }

    const onEdit = () => {
        dispatch.Applications.changeApplicationModalIsEditing(true);
        appInsights.trackEvent({
            name: 'Assessment Edit Clicked',
            properties: {
                appName: currentApplication.name,
                appId: currentApplication._id
            }
        });
    }

    const getClassName = (propName) => {
        if ((propName === 'cloudFirstReportingOpen' && cloudFirstReportingOpen) ||
            (propName === 'assetInfoOpen' && assetInfoOpen)) {
            return "nav-link btn btn-link active "
        }
        return "nav-link btn btn-link"
    }

    const getNavItem = (propName, labelName) => {
        return <li className={`nav-item ${labelName === 'IT Asset Info' ? 'ml-auto' : ''}`}>
            <button type="button" className={getClassName(propName)}
                onClick={() => tabClicked(propName)}>
                {labelName}
            </button>
        </li>
    }

    return <Modal isOpen={showCloudAssessmentModal} size="lg">
        <ModalHeader className="py-2 bg-primary text-white" toggle={toggleModal}>
            {!applicationModalIsEditing ? <PreviousNext onPrevious={previousApp} onNext={nextApp} inverseColors={true}></PreviousNext> : ''}
            &nbsp;&nbsp;&nbsp;
            <span>
                {currentApplication ? currentApplication.name : ''}
            </span>
            <FontAwesomeButton icon='share-square' title='Open Asset' onClick={openAsset}></FontAwesomeButton>
        </ModalHeader>
        <ModalBody className="pt-1 bg-light">
            <>
                <nav className="nav nav-tabs">
                    {getNavItem('cloudFirstReportingOpen', 'Cloud First Reporting')}
                    {getNavItem('assetInfoOpen', 'IT Asset Info')}
                </nav>

                <div className="row my-1">
                    {helpArray ? helpArray.filter(h => {
                        return h.isPublished && h.isCloudFirst === true;
                    }).map((h) => {
                        return <div className="col" key={h._id}>
                            <a className="btn-primary btn btn-sm" title={`Download ${h.name}`} target="_blank" rel="noopener noreferrer"
                                href={`${routeConfig.baseUrl}/Help/${h._id}/Download`} >
                                <FontAwesomeIcon icon="file-download" />&nbsp;&nbsp;
                            {h.name}
                            </a>
                        </div>
                    }) : ''}
                    {!applicationModalIsEditing && canEdit === true ?
                        <div className="col text-right">
                            <button className="btn btn-link" onClick={onEdit}>Edit</button>
                        </div>
                        : ''}
                </div>
                <div className="row">
                    <div className="col"></div>
                </div>
                {getCurrentView()}
                {!applicationModalIsEditing ?
                    <div>
                        <PreviousNext onPrevious={previousApp} onNext={nextApp}></PreviousNext>
                    </div> : ''}
            </>
        </ModalBody>
    </Modal>
}
