import React, { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import store from '../../../Store';
import Card from '../../Card';
import FontAwesomeButton from '../../FontAwesomeButton';

export default function ViewRelatedAssets(props) {
    const dispatch = useDispatch();
    const application = useSelector(state => store.select.Applications.currentApplication(state));
    const applications = useSelector(state => state.Applications.applications);
    const { showOnlyData } = props;

    useEffect(() => {
        if (!application || !application.applicationDependencies || application.applicationDependencies.length === 0) {
            return
        }
        for (let appDep of application.applicationDependencies) {
            dispatch.Applications.loadApplicationWithoutCompletions(appDep.asset);
        }
        // eslint-disable-next-line 
    }, [application._id])

    const getComponentName = (componentId, assetId) => {
        if (applications[assetId] && applications[assetId].components) {
            const component = applications[assetId].components.find(c => {
                return c._id === componentId;
            });

            if (component) {
                return component.name;
            }
        }
    }

    const getTableName = (table, assetId) => {
        if (applications[assetId] &&
            applications[assetId].tables) {
            const tableFound = applications[assetId].tables.find(t => {
                return t._id === table.table;
            });

            if (tableFound) {
                return tableFound.name;
            }
        }

        return '';
    }

    const getTableRow = (table, assetId) => {
        const rows = [];

        if (!applications[assetId] || !applications[assetId].tables) {
            return <></>
        }

        const tableFound = applications[assetId].tables.find(t => {
            return t._id === table.table;
        });

        if (tableFound) {
            for (let field of table.fields) {
                const foundField = tableFound.fields.find(f => {
                    return f._id === field
                });

                if (!foundField) {
                    continue;
                }

                rows.push(<tr key={field}>
                    <td>
                        {foundField.name}
                    </td>
                    <td>
                        {foundField.logicalName}
                    </td>
                </tr>);
            }
        }

        return rows;
    }

    const openAsset = (appId) => {
        window.open(`/Inventory/${appId}`);
    }

    return <>
        <ul className="list-group">
            {application.applicationDependencies ? application.applicationDependencies.filter(dep => {
                if (showOnlyData) {
                    return dep.type === 'Database Read' || dep.type === 'Database Read Write' ||
                        dep.type === 'Database';
                } else {
                    return dep.type !== 'Database Read' && dep.type !== 'Database Read Write' &&
                        dep.type !== 'Database';
                }
            }).map((dep, i) => {
                return <li className="list-group-item" key={i}>
                    <h5>{applications[dep.asset].name}&nbsp;&nbsp;
                        <FontAwesomeButton icon='share-square' title='Open Asset' small={true}
                            onClick={() => openAsset(dep.asset)} ></FontAwesomeButton>
                    </h5>
                    <div className="row">
                        <div className="col font-weight-bold">Type:</div>
                        <div className="col">{dep.type}</div>
                        <div className="col font-weight-bold">Use:</div>
                        <div className="col">{dep.use}</div>
                    </div>

                    {!showOnlyData && (dep.components && dep.components.length > 0) ?
                        <div className="row">
                            <div className="col">
                                <Card headerText="Components Used">
                                    <div className="row">
                                        <div className="col">
                                            {dep.components.map((c, ci) => {
                                                return <div key={ci}>{getComponentName(c, dep.asset)}</div>
                                            })}
                                        </div>
                                    </div>
                                </Card>
                            </div>
                        </div> : <></>}
                    {showOnlyData ?
                        <Card headerText="Tables Used" collapsed={true}>
                            <div className="row">
                                <div className="col">
                                    <ul className="list-group">
                                        {dep.tablesUsed.map((table, tableIndex) => {
                                            return <ul className="list-group-item" key={tableIndex}>
                                                <div className="row">
                                                    <div className="col">Table Name:&nbsp;
                                                        {getTableName(table, dep.asset)}
                                                    </div>
                                                </div>
                                                {/*<div className="row">
                                                    <div className="col">Create:</div>
                                                    <div className="col">
                                                        <input type="checkbox" checked={table.isCreate} disabled></input>
                                                    </div>
                                                    <div className="col">Read:</div>
                                                    <div className="col">
                                                        <input type="checkbox" checked={table.isRead} disabled></input>
                                                    </div>
                                                    <div className="col">Update:</div>
                                                    <div className="col">
                                                        <input type="checkbox" checked={table.isUpdate} disabled></input>
                                                    </div>
                                                    <div className="col">Delete:</div>
                                                    <div className="col">
                                                        <input type="checkbox" checked={table.isDelete} disabled></input>
                                                    </div>
                                        </div>*/}
                                                {table.fields && table.fields.length > 0 ?
                                                    <Card headerText="Fields Used" collapsed={true}>
                                                        <div className="row">
                                                            <div className="col">
                                                                <table className="table table-sm table-bordered table-striped">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Name</th>
                                                                            <th>Logical Name</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {getTableRow(table, dep.asset)}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </Card>
                                                    : ''}
                                            </ul>
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </Card> : <></>
                    }
                </li>
            }) : ''}
        </ul>
        <br />
    </>;
}
