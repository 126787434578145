import { useSelector } from "react-redux";
import React from 'react';

export default function ApplicationPlatformOptions() {
    const applicationPlatforms = useSelector(state => state.ApplicationPlatform.applicationPlatforms);
    const options = [<option key="null">Select</option>];

    if (applicationPlatforms) {
        Object.keys(applicationPlatforms).forEach((key, i) => {
            options.push(<option key={key} value={key}>{applicationPlatforms[key].name}</option>);
        });
    }

    return options;
}
