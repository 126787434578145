import React from 'react';
import './App.css';
import { Provider } from "react-redux";
import store from './Store';
import { Router } from "react-router-dom";
import Routes from './components/Routes'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faPlus, faTrashAlt, faMinusCircle, faPlusCircle, faFile, faArrowRight, faFileExcel,
  faArrowUp, faArrowDown, faSort, faSortDown, faSortUp, faFilter,
  faFileDownload, faArrowLeft, faSave, faBan, faFileExport, faQuestionCircle, faShareSquare,
  faMoneyBill, faListAlt, faCloud, faEdit, faBoxes, faScroll, faSearch, faTrash, faRocket
} from '@fortawesome/free-solid-svg-icons'
import './helpers/axiosIntercepters';
import history from './history';
import { appInsights } from './appInsights';

library.add([faPlus, faTrashAlt, faMinusCircle, faPlusCircle, faFile, faQuestionCircle,
  faArrowRight, faArrowLeft, faSave, faFileDownload, faBan, faFileExcel, faFileExport,
  faArrowDown, faArrowUp, faSort, faSortDown, faSortUp, faFilter,
  faMoneyBill, faListAlt, faCloud, faBoxes, faScroll, faEdit, faSearch, faTrash, faRocket,
  faShareSquare]);
export default function App() {
  history.listen((location, action) => {
    if (appInsights) {
      console.log('Route change : ' + location.pathname);
      appInsights.trackEvent({
        name: 'Route Change',
        properties: {
          route: location.pathname
        }
      });
    }
  });

  return <Provider store={store}>
    <Router history={history}>
      <Routes></Routes>
    </Router>
  </Provider>
}
