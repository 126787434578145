import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Store from '../../../Store';
import SaveCancel from '../../SaveCancel';
import { useNestedArrayState } from '../../../hooks/useNestedArrayState';
import DeleteButton from '../../DeleteButton';
import Card from '../../Card';

export default function EditComponentsRelationships(props) {
    const currentApplication = useSelector(state => Store.select.Applications.currentApplication(state));
    const interfaces = useSelector(state => state.AssetInterface.assetInterfaces);
    const applications = useSelector(state => state.Applications.applications);

    const dispatch = useDispatch();
    const [selectedInterface, setSelectedInterface] = useState({});
    const [selectedItAsset, setSelectedItAsset] = useState({});
    const {
        state,
        setStateArrayProperty,
        removeFromNestedArrayProperty,
        addToNestedArrayProperty,
    } = useNestedArrayState(currentApplication);

    const save = () => {
        dispatch.Applications.persistApplication(state);
        dispatch.Applications.changeApplicationModalIsEditing(false)
    }

    const cancel = () => {
        dispatch.Applications.changeApplicationModalIsEditing(false);
    }

    const addSelectedInterface = (index) => {
        if (!state.components[index].interfaces) {
            setStateArrayProperty('components', index, 'interfaces', []);
        }

        addToNestedArrayProperty('components', index, 'interfaces', selectedInterface[index]);
    }

    const addSelectedItAsset = (index) => {
        if (!state.components[index].relatedAssets) {
            setStateArrayProperty('components', index, 'relatedAssets', []);
        }

        addToNestedArrayProperty('components', index, 'relatedAssets', selectedItAsset[index]);
    }

    return <>
        <Card headerText="Data Interfaces To Component Relationships">
            <ul className="list-group">
                {state.components.map((c, i) => {
                    return <li className="list-group-item">
                        <div className="row">
                            <div className="col-4 font-weight-bold">
                                {c.name}
                            </div>
                            <div className="col">
                                <div class="input-group">
                                    <select className="form-control" onChange={e => setSelectedInterface({ ...selectedInterface, [i]: e.target.value })}>
                                        <option value=''>Select</option>
                                        {currentApplication.interfaceDependencies.map((id, i) => {
                                            return <option value={id.interface}>
                                                {interfaces[id.interface].name}
                                            </option>
                                        })}
                                    </select>
                                    <div class="input-group-append">
                                        <button className="btn btn-primary" type="button" disabled={!selectedInterface[i]}
                                            onClick={() => { addSelectedInterface(i) }}>
                                            Add Interface
                                    </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {c.interfaces.map((interfaceItem, ix) => {
                            return <div className="row" key={ix}>
                                <div className="col-1">
                                    <DeleteButton onClick={() => removeFromNestedArrayProperty('components', i, 'interfaces', ix)} hideText={true}></DeleteButton>
                                </div>
                                <div className="col">
                                    {interfaces[interfaceItem].name}
                                </div>
                            </div>
                        })}
                    </li>
                })}
            </ul>
        </Card>
        <div className="row"> <div className="col"> </div> </div>
        <Card headerText="Related IT Assets To Component Relationships">
            <ul className="list-group">
                {state.components.map((c, i) => {
                    return <li className="list-group-item">
                        <div className="row">
                            <div className="col-4 font-weight-bold">
                                {c.name}
                            </div>
                            <div className="col">
                                <div class="input-group">
                                    <select className="form-control" onChange={e => setSelectedItAsset({ ...selectedItAsset, [i]: e.target.value })}>
                                        <option value=''>Select</option>
                                        {currentApplication.applicationDependencies.map((id, i) => {
                                            return <option value={id.asset}>
                                                {applications[id.asset].name}
                                            </option>
                                        })}
                                    </select>
                                    <div class="input-group-append">
                                        <button className="btn btn-primary" type="button" disabled={!selectedItAsset[i]}
                                            onClick={() => { addSelectedItAsset(i) }}>
                                            Add IT Asset
                                    </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {c.relatedAssets.map((relatedAsset, ix) => {
                            return <div className="row" key={ix}>
                                <div className="col-1">
                                    <DeleteButton onClick={() => removeFromNestedArrayProperty('components', i, 'relatedAssets', ix)} hideText={true}></DeleteButton>
                                </div>
                                <div className="col">
                                    {applications[relatedAsset].name}
                                </div>
                            </div>
                        })}
                    </li>
                })}
            </ul>
        </Card>
        <div className="row">
            <div className="col"></div>
        </div>
        <div className="row">
            <div className="col">
                <SaveCancel onSaveClick={save} onCancelClick={cancel}></SaveCancel>
            </div>
        </div>
    </>
}
