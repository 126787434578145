import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import routeConfig from '../../routeConfig';
import { format } from 'date-fns';
import { capitalCase } from 'change-case';
export default function History(props) {
    const currentApplicationId = useSelector(state => state.Applications.currentApplicationId);
    const [history, setHistory] = useState(null);
    const [queryBuilderSchema, setQueryBuilderSchema] = useState(null);

    useEffect(() => {
        if (currentApplicationId) {
            axios.get(`${routeConfig.baseUrl}/Application/${currentApplicationId}/History`).then(resp => {
                setHistory(resp.data);
            });
        }
    }, [currentApplicationId]);

    useEffect(() => {
        axios.get(`${routeConfig.baseUrl}/Report/QueryBuilderSchema`).then(resp => {
            setQueryBuilderSchema(resp.data);
        });
    }, [])

    const getStringDifference = (value) => {
        if(typeof value !== "string"){
            return null;
        }
        const output = [];
        const lines = value.split('\n@@ ');
        for (let i = 0, l = lines.length; i < l; i++) {
            const line = lines[i];
            const lineOutput = {
                pieces: [],
            };
            const location = /^(?:@@ )?[-+]?(\d+),(\d+)/?.exec(line)?.slice(1);
            if(location == null || location.length < 2){ continue; }
            lineOutput.location = {
                line: location[0] || 0,
                chr: location[1] || 0,
            };
            const pieces = line.split('\n').slice(1);
            for (
                let pieceIndex = 0, piecesLength = pieces.length;
                pieceIndex < piecesLength;
                pieceIndex++
            ) {
                const piece = pieces[pieceIndex];
                if (!piece.length) {
                    continue;
                }
                const pieceOutput = {
                    type: 'context',
                };
                if (piece.substr(0, 1) === '+') {
                    pieceOutput.type = 'success';
                } else if (piece.substr(0, 1) === '-') {
                    pieceOutput.type = 'danger';
                }
                pieceOutput.text = piece.slice(1);
                lineOutput.pieces.push(pieceOutput);
            }
            output.push(lineOutput);
        }

        console.log(output);
        const context = [];

        context.push('<ul>');
        for (let i = 0, l = output.length; i < l; i++) {
            let line = output[i];
            context.push(
                `<li>Characters: ${line.location.line} - ${parseInt(line.location.line) + parseInt(line.location.chr)}<div>`
            );
            let pieces = line.pieces;
            for (
                let pieceIndex = 0, piecesLength = pieces.length;
                pieceIndex < piecesLength;
                pieceIndex++
            ) {
                let piece = pieces[pieceIndex];
                if (piece.type === 'danger') {
                    context.push(`<span class="bg-${piece.type}"><del>${piece.text}</del></span>`);
                } else {
                    context.push(`<span class="bg-${piece.type}">${piece.text}</span>`);
                }
            }
            context.push('</div></li>');
        }
        context.push('</ul>');

        console.log(context);

        const innerHtml = {
            __html: context.join('').replace(/%0A/g, '<br/>')
        };

        console.log(innerHtml.__html);

        return <div dangerouslySetInnerHTML={innerHtml}></div>
    }

    const getValue = (diffArray, diffKey) => {
        if (!diffArray[0] || typeof diffArray[0] === 'object') {
            return '';
        }

        if (diffArray.length === 1) {
            return `to '${diffArray[0]}'`;
        } else {
            return `from '${diffArray[0]}' to '${diffArray[1]}`;
        }
    }

    const getHistoryInfo = (differences) => {
        const historyRows = [];
        for (let diffKey of Object.keys(differences).filter(d => {
            return d !== "__user" && d !== "updatedAt";
        })) {
            let foundField = queryBuilderSchema.fields.find(f => {
                return f.field === diffKey;
            });

            if (!foundField) {
                foundField = queryBuilderSchema.lookups[diffKey];
            } else {
                foundField = foundField.label;
            }

            if (!foundField) {
                foundField = capitalCase(diffKey);
            }

            if (differences[diffKey].length === 3) {
                historyRows.push(<li key={diffKey}>Changed <span className="font-weight-bold">
                    {foundField}
                </span>
                    <br></br>
                    {getStringDifference(differences[diffKey][0])}
                </li>);
            }
            else if (diffKey === "files") {
                if (differences[diffKey])
                    historyRows.push(<li key={diffKey}>Changed <span className="font-weight-bold">
                        {foundField}
                    </span>
                    </li>);
            }
            else {
                const row = <li key={diffKey}>Changed <span className="font-weight-bold">
                    {foundField}
                </span> {getValue(differences[diffKey])}</li>
                historyRows.push(row);
            }
        }

        return <ul>{historyRows.map(r => {
            return r;
        })}</ul>
    }

    const getHistoryRowUserInfo = (h) => {
        let userJsx;
        if (h.user) {
            return <>{h.user.firstName} {h.user.lastName} ({h.user.emailAddress}) </>
        }

        return <></>
    }

    if (!history || !queryBuilderSchema) {
        return <h3>
            <div className="spinner-border text-primary" role="status">
                <span className="sr-only">Loading History...</span>
            </div>&nbsp;&nbsp;
            Loading History

        </h3>
    }

    return <div className="row">
        <div className="col">
            <ul className="list-group">
                {history.map((h, i) => {
                    return <li className="list-group-item" key={i}>
                        <div className="row">
                            <div className="col">
                                {getHistoryRowUserInfo(h)} - {format(new Date(h.updatedAt), 'MM/DD/YYYY h:mm aa')}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                {getHistoryInfo(h.diff)}
                            </div>
                        </div>
                    </li>
                })}
            </ul>
        </div>
    </div>
}
