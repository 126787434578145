import React, { useEffect, useState, useMemo } from 'react';
import axios from 'axios';
import routeConfig from '../../../routeConfig';
import FontAwesomeButton from '../../FontAwesomeButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format } from 'date-fns';
import {
    useTable, useFilters, useSortBy, usePagination,
    useGlobalFilter
} from 'react-table';
import ReactPaginate from 'react-paginate';
import FilterText from '../../Tables/FilterText';

export default function GridWidget(props) {
    const [gridData, setGridData] = useState({ selectFields: [], assets: [] });
    const [usesGrouping, setUsesGrouping] = useState(false);
    const [queryFailed, setQueryFailed] = useState(false);

    const columns = useMemo(() => {
        const template = ({ row, cell }) => {
            return <div title={props.name} style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
                <a href={`/Inventory/${row.original._id}`} target="_blank" rel="noopener noreferrer">{row.values[cell.column.id]}</a>
            </div>;
        };

        if (!gridData) {
            return [];
        }
        return gridData.selectFields.sort((a, b) => {
            return a.order - b.order;
        }).map((s, i) => {
            if (i === 0 && usesGrouping === false) {
                return { Header: s.label, accessor: s.field, Filter: FilterText, Cell: template }
            }
            return { Header: s.label, accessor: s.field.replace(/\./g, '_'), Filter: FilterText }
        });
    }, [gridData, usesGrouping]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        page,
        pageOptions,
        pageCount,
        gotoPage,
        setPageSize,
        state: { pageIndex, pageSize }
    } = useTable(
        {
            columns,
            data: gridData ? gridData.assets : [],
            initialState: {
                sortBy: [
                    {
                        id: 'name',
                        desc: false,
                    }
                ]
            }
        },
        useFilters, // useFilters!
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    useEffect(() => {
        if (props.config && props.config.query) {
            axios.get(`${routeConfig.baseUrl}/Query/${props.config.query}`).then(resp => {
                console.log('Success');
                if (resp.data.aggregationResults) {
                    const groupedResults = resp.data.aggregationResults.reduce((acc, obj) => {
                        let key = '';
                        for (let i = 0; i <= resp.data.groupFields.length - 2; i++) {
                            key += obj._id[resp.data.groupFields[i].field] + '-';
                        }

                        if (!acc[key]) {
                            acc[key] = []
                        }
                        acc[key].push(obj)
                        return acc
                    }, {});

                    const additionalFields = {};
                    const returnObjects = [];
                    for (let arrayObj of Object.values(groupedResults)) {
                        let returnObj = {};
                        for (let i = 0; i <= resp.data.groupFields.length - 2; i++) {
                            returnObj[resp.data.groupFields[i].label] = arrayObj[0]._id[resp.data.groupFields[i].field];
                        }

                        let total = 0;
                        for (let i = 0; i < arrayObj.length - 1; i++) {
                            returnObj[arrayObj[i]._id[resp.data.groupFields[resp.data.groupFields.length - 1].field]] = arrayObj[i].count;
                            total += arrayObj[i].count;
                            additionalFields[arrayObj[i]._id[resp.data.groupFields[resp.data.groupFields.length - 1].field]] = 1;
                        }

                        returnObj.total = total;

                        returnObjects.push(returnObj);
                    }

                    for (let obj of returnObjects) {
                        for (let field of Object.keys(additionalFields)) {
                            if (!obj[field]) {
                                obj[field] = 0;
                            }
                        }
                    }

                    const selectFields = [];

                    for (let i = 0; i <= resp.data.groupFields.length - 2; i++) {
                        selectFields.push({
                            label: resp.data.groupFields[i].label,
                            field: resp.data.groupFields[i].label
                        });
                    }

                    for (let field of Object.keys(additionalFields)) {
                        selectFields.push({ label: field, field: field });
                    }

                    selectFields.push({ label: 'Total', field: 'total' });
                    setUsesGrouping(true);
                    setGridData({ assets: returnObjects, selectFields: selectFields })
                } else {
                    setGridData(resp.data);
                }
            }).catch(err => {
                setQueryFailed(true);
            });
        }
    }, [props.config]);


    const getFormattedJson = (apps) => {
        return Object.values(apps).map((app) => {
            var returnObj = {};

            gridData.selectFields.map((s, i) => {
                returnObj[s.label] = app[s.field.replace(/\./g, '_')];
            });

            return returnObj;
        });
    }

    const exportToExcel = async () => {
        const name = props.config.title;

        const uniqueName =
            `${name ? name : props.name}_${format(new Date(), 'MM/DD/YYYY_h_mm_A')}`;
        const fileName = `${uniqueName}.xlsx`;

        const XLSX = await import('xlsx');
        const wb = XLSX.utils.book_new();

        wb.Props = {
            Title: name,
            Subject: name,
            Author: "FDOT",
            CreatedDate: new Date()
        };

        const formattedJson = getFormattedJson(gridData.assets);
        const workSheet = XLSX.utils.json_to_sheet(formattedJson);
        const workSheetName = `export_${format(new Date(), 'MM-DD-YYYY_h_mm_A')}`;

        XLSX.utils.book_append_sheet(wb, workSheet, workSheetName);
        const bin = XLSX.write(wb, { bookType: 'xlsx', type: "binary" });

        require("downloadjs")(bin, fileName, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
    }

    if (queryFailed) {
        return <div>Query Failed</div>
    }

    if (!gridData) {
        return <div className="row">
            <div className="col">
                <h3 className="text-center">
                    <div className="spinner-border text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>&nbsp;&nbsp;Grid is Loading...
            </h3>
            </div>
        </div>
    }
    return <>
        <div className="row">
            <div className="col text-right">
                <FontAwesomeButton text="Export" small={true}
                    icon="file-excel" onClick={exportToExcel}></FontAwesomeButton>
            </div>
        </div>
        <div className="row">
            <div className="col">
                <table {...getTableProps()} className="table table-sm table-bordered table-striped">
                    <thead >
                        {headerGroups.map((headerGroup, i) => {
                            return <React.Fragment key={i}>
                                <tr {...headerGroup.getHeaderGroupProps()} className="text-white bg-primary" key={'i' + i}>
                                    {headerGroup.headers.map(column => (
                                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                            {column.render('Header')}
                                            <span className="float-right">
                                                {column.isSorted
                                                    ? column.isSortedDesc
                                                        ? <FontAwesomeIcon icon="sort-up"></FontAwesomeIcon>
                                                        : <FontAwesomeIcon icon="sort-down"></FontAwesomeIcon>
                                                    : <FontAwesomeIcon icon="sort"></FontAwesomeIcon>}
                                            </span>
                                        </th>
                                    ))}
                                </tr>
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (
                                        <th {...column.getHeaderProps()}>
                                            {column.canFilter ? column.render('Filter') : null}
                                        </th>
                                    ))}
                                </tr>
                            </React.Fragment>
                        })}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map((row, i) => {
                            prepareRow(row)
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell, i) => {
                                        return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                <div className="row">
                    <div className="col-2">
                        <ReactPaginate pageCount={pageCount} nextLabel=">" previousLabel="<" pageClassName="page-item" containerClassName="pagination"
                            previousLinkClassName="page-link" nextLinkClassName="page-link"
                            pageLinkClassName="page-link" activeClassName="active" onPageChange={e => gotoPage(e.selected)} marginPagesDisplayed={1}
                            pageRangeDisplayed={3} ></ReactPaginate>
                    </div>
                    <div className="col-2">
                        <div className="input-group">
                            <select className="form-control"
                                value={pageSize}
                                onChange={e => {
                                    setPageSize(Number(e.target.value))
                                }} >
                                {[10, 20, 30, 40, 50].map(pageSize => (
                                    <option key={pageSize} value={pageSize}>
                                        {pageSize}
                                    </option>
                                ))}
                            </select>
                            <div className="input-group-append input-sm">
                                <span className="input-group-text" >Items per Page</span>
                            </div>
                        </div>
                    </div>
                    <div className="col text-right">
                        <span>
                            <strong>
                                {pageIndex + 1} of {pageOptions.length} pages ({rows.length} items)
                        </strong>{' '}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </>
}
