import axios from 'axios';
import { toastr } from 'react-redux-toastr'
import routeConfig from '../routeConfig';

export const Server = {
    state: {
        currentId: null,
        servers: {

        },
    },
    reducers: {
        loadServers(state, servers) {
            let loadedServers = {};
            for (let i = 0; i < servers.length; i++) {
                loadedServers[servers[i]._id] = servers[i];
            }
            return { ...state, servers: loadedServers };
        },
        addServer(state, server) {
            return {
                ...state, servers: {
                    ...state.servers, [server._id]: server
                }, currentId: server._id
            }
        },
        saveServer(state, server) {
            return {
                ...state, servers: {
                    ...state.servers, [server._id]: {
                        ...state.servers[server._id], ...server
                    }
                }
            }
        },
        setCurrentId(state, id) {
            return { ...state, currentId: id };
        }
    },
    effects: {
        async getServers() {
            const resp = await axios.get(`${routeConfig.baseUrl}/Server`);
            this.loadServers(resp.data);
        },
        async persistNewServer(server, rootState) {
            const response = await axios.post(`${routeConfig.baseUrl}/Server`, server);
            this.addServer(response.data);
            toastr.success('New Server Platform Added')
        },
        async persistServer(payload, rootState) {
            const response = await axios.put(`${routeConfig.baseUrl}/Server/${rootState.Server.currentId}`,
                rootState.Server.servers[rootState.Server.currentId]);
            this.addServer(response.data);
            toastr.success('Server Platform Saved');
        },
        async loadServer(id) {
            const response = await axios.get(`${routeConfig.baseUrl}/Server/${id}`);
            this.addServer(response.data);
        }
    }
}
